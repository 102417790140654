import {
  ApiNotification,
  ApiGetRequest,
  ApiPostRequest,
  ApiGetRequestPartisipasi,
  ApiPostRequestPartisipasi,
} from '../../../utils/Api';
import { Message } from 'element-ui';

import moment from 'moment';

const KasKoperasi = {
  namespaced: true,
  state: {
    data: {
      loading: false,
      wallet: {},
      products: [],
      limit: 10,
      page: 1,
      total: 0,
      searchPembukuan: '',
      cashwallet_id: '',
      transactions: [],
      transaction_detail: [],
      snapShootKas: [],
      snapShootKasToday: [],
      mutasiKas: [],
      filterProduk: '',
      dateStart: '',
      dateEnd: '',
      filterStatus: '',
      filtlerTipe: '',
      columns: [
        {
          prop: 'expand',
          label: '',
          type: 'expand',
        },
        {
          prop: 'cashtransaction_id',
          label: 'ID Trx',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: '130',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_type',
          label: 'Tipe',
          minWidth: '120',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'description',
          label: 'Deskripsi',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'nominal',
          label: 'Nominal',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_from',
          label: 'Dari',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_to',
          label: 'Ke',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'cashwallet_id',
          label: 'ID Wallet',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'created_date',
          label: 'Waktu',
          minWidth: '140',
          'class-name': 'body-14 col-black-2',
        },
      ],
      columns_saldo: [
        {
          prop: 'expand',
          label: '',
          type: 'expand',
        },
        {
          prop: 'cashtransaction_id',
          label: 'ID Trx',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_category',
          label: 'Status',
          minWidth: '130',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_type',
          label: 'Tipe',
          minWidth: '120',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'description',
          label: 'Deskripsi',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'nominal',
          label: 'Nominal',
          minWidth: '100',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_from',
          label: 'Dari',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'transaction_to',
          label: 'Ke',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'cashwallet_id',
          label: 'ID Wallet',
          minWidth: '90',
          'class-name': 'body-14 col-black-2',
        },
        {
          prop: 'created_date',
          label: 'Waktu',
          minWidth: '140',
          'class-name': 'body-14 col-black-2',
        },
      ],
      product_klaspay: {},
    },
  },
  mutations: {
    changeKas(state, payload) {
      state.data = Object.assign({}, state.data, payload);
    },
  },
  actions: {
    async checkProductIdKoperasi({ commit }) {
      const result = await ApiGetRequestPartisipasi(
        `klaspay/external/onklasproduct/check`
      );

      if (result.error) {
        Message({
          type: 'error',
          message: result.error ? result.error : 'Gagal mengambil data pelajar',
        });

        commit('changeKas', {
          product_klaspay: {},
        });

        return false;
      } else {
        let data_product = result.data.data || [];
        let product_spp = data_product.filter((item) => {
          if (item.product_id.toLowerCase().indexOf('koperasi') !== -1) {
            return item;
          }
        });

        if (product_spp && product_spp.length > 0) {
          if (parseFloat(product_spp[0].merchant_admin_charge_fix) > 0) {
            product_spp[0].merchant_admin_charge_fix =
              parseFloat(product_spp[0].merchant_admin_charge_fix) -
              parseFloat(product_spp[0].admin_charge_min);
          }
        }
        commit('changeKas', {
          product_klaspay:
            product_spp && product_spp.length > 0 ? product_spp[0] : {},
        });

        return result.data.data || {};
      }
    },

    async setAdminKoperasi(context, payload = null) {
      const result = await ApiPostRequestPartisipasi(
        `klaspay/external/onklasproduct/set/id/${payload.id}`,
        payload.data
      );

      if (result.error) {
        Message({
          type: 'error',
          message: result.error ? result.error : 'Gagal setting biaya admin',
        });

        return false;
      } else {
        Message({
          type: 'success',
          message: 'Setting Biaya Admin berhasil',
        });

        return true;
      }
    },

    async getDetailWallet({ commit }) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/koperasi/cash-wallet`);
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        commit('changeKas', {
          loading: false,
          wallet: getResult.data.data || [],
        });
      }
    },
    async getProduct({ commit }) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/cash-product`);
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        commit('changeKas', {
          loading: false,
          products: (getResult.data.data && getResult.data.data.products) || [],
          total: (getResult.data.data && getResult.data.data.total) || 0,
        });
      }
    },
    async getListMutasi({ commit, state }, page = null) {
      if (page) {
        commit('changeKas', {
          page: page,
        });
      }

      commit('changeKas', {
        loading: true,
      });

      const { data } = state;
      let params = {
        limit: data.limit,
        page: data.page,
        search: data.search,
        status: parseInt(data.filterStatus) || 0,
        tipe: data.filterTipe || '',
      };

      if (
        data.dateStart &&
        data.dateStart !== '' &&
        data.dateEnd &&
        data.dateEnd !== ''
      ) {
        params.date_from = moment(data.dateStart).format('YYYY-MM-DD');
        params.date_to = moment(data.dateEnd).format('YYYY-MM-DD');
      }

      const getResult = await ApiPostRequest(
        `/klaspay/koperasi/cash-transaction/history`,
        params
      );
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        const dataPembukuan = getResult.data.data;
        commit('changeKas', {
          loading: false,
          cashwallet_id: dataPembukuan.cashwallet_id || '',
          transactions: dataPembukuan.transactions || [],
          transaction_detail: dataPembukuan.transaction_detail || [],
          total: dataPembukuan.total || 0,
        });
      }
    },
    async getListPembukuan({ commit, state }, page = null) {
      if (page) {
        commit('changeKas', {
          page: page,
        });
      }

      commit('changeKas', {
        loading: true,
      });

      const { data } = state;

      const getResult = await ApiPostRequest(
        `klaspay/koperasi/cash-transaction/history`,
        {
          limit: data.limit,
          offset: data.page,
          search: data.searchPembukuan,
          produk: data.filterProduk,
          dateStart:
            data.dateStart &&
            data.dateStart !== '' &&
            moment(data.dateStart).format('YYYY-MM-DD'),
          dateEnd:
            data.dateEnd &&
            data.dateEnd !== '' &&
            moment(data.dateEnd).format('YYYY-MM-DD'),
        }
      );
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        const dataPembukuan = getResult.data.data;
        commit('changeKas', {
          loading: false,
          cashwallet_id: dataPembukuan.cashwallet_id || '',
          transactions: dataPembukuan.transactions || [],
          transaction_detail: dataPembukuan.transaction_detail || [],
          total: dataPembukuan.total || 0,
        });
      }
    },
    async createPembukuan({ commit }, dataPost = {}) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiPostRequest(
        `klaspay/koperasi/cash-transaction`,
        dataPost
      );

      if (getResult.error) {
        commit('changeKas', {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification(
          'error',
          'Gagal menambahkan data pembukuan, mohon coba kembali'
        );
      } else {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('success', 'Data pembukuan berhasil ditambahkan');
      }
    },
    async getMutasiKas({ commit }) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/koperasi/saldo/mutasi`);
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        commit('changeKas', {
          loading: false,
          mutasiKas: (getResult.data.data && getResult.data.data.mutasi) || [],
        });
      }
    },
    async getSnapShootToday({ commit }) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiGetRequest(
        `klaspay/koperasi/cashsnapshot/today`
      );
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        commit('changeKas', {
          loading: false,
          snapShootKasToday:
            (getResult.data.data && getResult.data.data.snapshot) || {},
        });
      }
    },
    async getSnapShoot({ commit }) {
      commit('changeKas', {
        loading: true,
      });

      const getResult = await ApiGetRequest(
        `klaspay/koperasi/cashsnapshot/all`
      );
      if (getResult.error) {
        commit('changeKas', {
          loading: false,
        });

        ApiNotification('error', 'Gagal mengambil data, mohon coba kembali');
      } else {
        commit('changeKas', {
          loading: false,
          snapShootKas:
            (getResult.data.data && getResult.data.data.snapshots) || [],
        });
      }
    },
  },
};

export default KasKoperasi;
