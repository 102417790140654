import {
  ApiGetRequestCabdin,
  ApiPostRequestCabdin,
  ApiPutRequestCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "date",
        label: "Tanggal",
        minWidth: 50,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "groupSiswa",
        label: "Siswa",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "groupOrangTua",
        label: "Wali",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "note",
        label: "Kronologi Kasus",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "counseling_teacher",
        label: "Guru BK",
        minWidth: 70,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jenisPembinaan",
        label: "Jenis Pembinaan",
        minWidth: 80,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "description",
        label: "Keterangan",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      }
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    loading: false,
    isUpdate: false,
    resProses: false,
    listExport: [],
    jsonData: [],
    listBimbinganKonseling: [],
    detailBimbinganKonseling: {},
    detailImport: {},
    formData: {
      uuid: "",
      name: "",
      nisn: "",
      address: "",
      father_name: "",
      mother_name: "",
      teacher: "",
      counseling_teacher: "",
      description: "",
      note: "",
      counseling_type: "",
      date: ""
    },
  },
};

const mutations = {
  changeIntegrasiBimbinganKonseling(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async clearForm({commit}) {
    commit("changeIntegrasiBimbinganKonseling", {
      formData: {
        uuid: "",
        name: "",
        nisn: "",
        address: "",
        father_name: "",
        mother_name: "",
        teacher: "",
        counseling_teacher: "",
        description: "",
        note: "",
        counseling_type: "",
        date: ""
      }
    });
  },
  detailItemBk({commit},payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      isUpdate: true,
      formData: {
        uuid: payload.uuid,
        name: payload.name,
        nisn: payload.nisn,
        address: payload.address,
        father_name: payload.father_name,
        mother_name: payload.mother_name,
        teacher: payload.teacher,
        counseling_teacher: payload.counseling_teacher,
        description: payload.description,
        note: payload.note,
        counseling_type: payload.counseling_type,
        date: payload.date,
      }
    });
  },
  async getExportBk({ commit, state }, payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiBimbinganKonseling", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      page: data.page,
      key: data.key,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/counseling/export`, params);

    commit("changeIntegrasiBimbinganKonseling", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

    } else {
      if (!result.data) {
        Message({
          type: "error", message: "Cors Error, tidak terhubung dengan server",
        });
        return;
      }
      commit("changeIntegrasiBimbinganKonseling", {
        listExport: result.data.data,
        resProses: true,
      });
    }
  },
  async getData({ commit, state }, payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      loading: true,
    });

    const { data } = state;
    let limit = null
    if (payload) {
      if(typeof payload == "object" && typeof payload.limit !== 'undefined') {
        await commit("changeIntegrasiBimbinganKonseling", {
          limit: payload.limit ?? data.limit,
        });
        limit = payload.limit
      }else{
        await commit("changeIntegrasiBimbinganKonseling", {
          page: payload || data.page,
        });
      }
    }

    let params = {
      limit: ((limit !=null) ? limit : data.limit),
      // sort: `-created_at`,
      key: data.key,
      page: state.data.page
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/counseling`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiBimbinganKonseling", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiBimbinganKonseling", {
        listBimbinganKonseling: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async submitDataBimbinganKonseling({ commit, state, dispatch }, payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      loading: true,
      resProses: false,
    });
    const { data } = state;
    const formData = new FormData();
    let isUpdate = true
    if(payload){
      if(typeof payload.proses !== "undefined"){
        if(payload.proses=="post"){
          formData.append("_method", "POST");
          isUpdate = false
        }else{
          formData.append("_method", "PUT");
        }
      }
    }
    formData.append("name", data.formData.name);
    formData.append("nisn", data.formData.nisn);
    formData.append("address", data.formData.address);
    formData.append("father_name", data.formData.father_name);
    formData.append("mother_name", data.formData.mother_name);
    formData.append("teacher", data.formData.teacher);
    formData.append("counseling_teacher", data.formData.counseling_teacher);
    formData.append("description", data.formData.description);
    formData.append("note", data.formData.note);
    formData.append("counseling_type", data.formData.counseling_type);
    if(!data.isUpdate){
      formData.append("date", data.formData.date);
    }
    let result = "";
    if (isUpdate) {
      result = await ApiPutRequestCabdin(
        `/web/counseling/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostRequestCabdin(`/web/counseling`, formData);
    }

    commit("changeIntegrasiBimbinganKonseling", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiBimbinganKonseling", {
          errors: errors,
        });
      }
    } else {
      if (!result.data) {
        Message({
          type: "error", message: "Cors Error, tidak terhubung dengan server",
        });
        return;
      }
      Message({
        type: "success",
        message: `Data telah tersimpan.`,
      });
      dispatch("getData");
      commit("changeIntegrasiBimbinganKonseling", {
        resProses: true,
        isUpdate: false,
      });
      dispatch("clearForm");
    }
  },
  async deleteData({ commit, dispatch }, payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      loading: true,
      resProses: false,
    });
    if(!payload.uuid){
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeIntegrasiBimbinganKonseling", {
        loading: false,
      });
      return false;
    }
    const result = await ApiDeleteRequestCabdin(`/web/counseling/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeIntegrasiBimbinganKonseling", {
        loading: false,
      });
      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah dihapus",
      });
      commit("changeIntegrasiBimbinganKonseling", {
        loading: false,
        resProses: true,
      });
      dispatch("getData");
      return true;
    }
  },
  async importExcelBimbinganKonseling({ commit }, payload) {
    commit("changeIntegrasiBimbinganKonseling", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiBimbinganKonseling", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "counselings": payload.formData
    }
    type = "counseling"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiBimbinganKonseling", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
