import repository from "@/repositories/DanaPartisipasiRepository"
import StrukturErrorService from "@/services/StrukturErrorService"
//import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers"

export default class DanaPartisipasiService {
	constructor() {
		this.dataRepository = repository
	}

	async detailDanaPartisipasiStudent(id) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		try {
			return await this.dataRepository.getData222(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listBillGet(payload) {
		const {filterQ} = payload

		try {
			const params = {
				q: filterQ || undefined,
			}

			return await this.dataRepository.getListBill(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async detailBillGet(id) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		try {
			return await this.dataRepository.getDetailBill(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async billByUserUuidGet(payload) {
		const {userUuids} = payload
		if (typeof userUuids !== 'object') {
			throw new Error("userUuids must array")
		}

		const params = {
			student_ids: userUuids
		}

		try {
			return await this.dataRepository.getBillByUserUuid(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async detailBillHistoryGet(id) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		try {
			return await this.dataRepository.getDetailBillHistory(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async billActivationPost(id, payload) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		const {isActive} = payload

		try {
			const formData = {
				is_active: isActive
			}

			return await this.dataRepository.postBillActivation(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async createBillPost(payload) {

		const {
			bill_name,
			is_active,
			expired_date,
			list,
		} = payload

		if (typeof bill_name !== 'object') {
			throw new Error("ID required")
		}

		try {
			const formData = {
				bill_name: bill_name,
				is_active: is_active,
				expired_date: expired_date,
				list: list,
			}

			return await this.dataRepository.postCreateBill(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async updateBillPost(id, payload) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		const {
			bill_name,
			is_active,
			expired_date,
			list,
			bill_id,
		} = payload

		try {
			const formData = {
				bill_id: bill_id,
				bill_name: bill_name,
				is_active: is_active,
				expired_date: expired_date,
				list: list,
			}

			return await this.dataRepository.postUpdateBill(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async appInProductCheckGet() {
		try {
			return await this.dataRepository.getAppInProductCheck()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async appInProductPost(id, payload) {
		const {formData} = payload

		try {
			return await this.dataRepository.postAppInProduct(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

}
