import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    items: [],
    page: 1,
    total: 0,
    limit: 10,
    filter: "",
    sortBy: "",
    sortType: "",
    jenjang: "",
    status: "",
    formAturJadwal: {
      date: "",
      start: "",
      end: "",
    },
    rulesAturJadwal: {
      date: [{ required: true, message: "Anda harus melengkapi data ini" }],
      start: [{ required: true, message: "Anda harus melengkapi data ini" }],
      end: [{ required: true, message: "Anda harus melengkapi data ini" }],
    },
    columns: [
      {
        prop: "schedule",
        label: "Jadwal",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "grade",
        label: "Jenjang",
        width: 120,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "student",
        label: "Peserta",
        width: 120,
        sortable: true,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "title",
        label: "Judul Quiz",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "140",
        "class-name": "body-14 col-black-2",
      },
    ],
    page_statistik: 1,
    total_statistik: 0,
    limit_statistik: 10,
    filter_statistik: "",
    columns_statistik: [
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        width: 200,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "kelas",
        label: "Kelas",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "nilai_bakat",
        label: "Nilai Bakat",
        width: 250,
        "class-name": "body-14 col-black-2 text-center p-0 column-nilai-bakat",
      },
      // {
      //   prop: 'score',
      //   label: 'Skor',
      //   width: 100,
      //   'class-name': 'body-14 col-black-2 text-center',
      // },
      // {
      //   prop: 'huruf',
      //   label: 'Huruf',
      //   width: 100,
      //   'class-name': 'body-14 col-black-2 text-center',
      // },
    ],
    items_statistik: [
      // {
      //   nis_nik: '15410100096/0594287207',
      //   name: 'Surya Rakhmat Fadhillah',
      //   nilai_bakat: 'NUMERASI',
      //   total_nilai: 81,
      //   score: 71,
      //   huruf: 'A',
      // },
      // {
      //   nis_nik: '15410100096/0594287207',
      //   name: 'Surya Rakhmat Fadhillah',
      //   nilai_bakat: 'LITERASI TES FIKSI',
      //   total_nilai: 72,
      //   score: 71,
      //   huruf: 'A',
      // },
      // {
      //   nis_nik: '15410100096/0594287207',
      //   name: 'Surya Rakhmat Fadhillah',
      //   nilai_bakat: 'LITERASI TES INFORMASI',
      //   total_nilai: 60,
      //   score: 71,
      //   huruf: 'A',
      // },
      // {
      //   nis_nik: '15410100113/42905792911',
      //   name: 'Vania Sisca Wulandari',
      //   nilai_bakat: 'NUMERASI',
      //   total_nilai: 20,
      //   score: 40,
      //   huruf: 'D',
      // },
      // {
      //   nis_nik: '15410100113/42905792911',
      //   name: 'Vania Sisca Wulandari',
      //   nilai_bakat: 'LITERASI TES FIKSI',
      //   total_nilai: 25,
      //   score: 40,
      //   huruf: 'D',
      // },
      // {
      //   nis_nik: '15410100113/42905792911',
      //   name: 'Vania Sisca Wulandari',
      //   nilai_bakat: 'LITERASI TES INFORMASI',
      //   total_nilai: 10,
      //   score: 40,
      //   huruf: 'D',
      // },
    ],
    summary_peserta: {},
    summary_statistik: false,
    page_peserta: 1,
    total_peserta: 0,
    limit_peserta: 10,
    filter_peserta: "",
    columns_peserta: [
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "selesai",
        label: "Selesai",
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "nilai_bakat",
        label: "Nilai Bakat",
        minWidth: 100,
        "class-name": "body-14 col-black-2 text-center p-0 column-nilai-bakat",
      },
      // {
      //   prop: 'score',
      //   label: 'Skor',
      //   width: 100,
      //   'class-name': 'body-14 col-black-2 text-center',
      // },
      // {
      //   prop: 'huruf',
      //   label: 'Huruf',
      //   width: 100,
      //   'class-name': 'body-14 col-black-2 text-center',
      // },
      {
        label: "Aksi",
        width: 100,
        "class-name": "body-14 col-black-2 text-center column-nilai-bakat",
      },
    ],
    items_peserta: [],
    indexRowSpan: [],
    loading: false,
    error: false,
  },
};

const mutations = {
  changeTryOutAkm(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async setJadwalAkm({ commit }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const result = await ApiPostRequest(
      `school/training/request/tryout/${payload.tryout_request}`,
      payload.form
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Set Jadwal AKM Berhasil",
      });

      return true;
    }
  },

  async getListAkm({ commit, state }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    if (payload) {
      commit("changeTryOutAkm", {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      type: "akm",
      grade: data.jenjang,
      status: data.status,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    const result = await ApiGetRequest(
      `school/training/request/tryout`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      commit("changeTryOutAkm", {
        loading: false,
        items: result.data.data || [],
        total: result.data && result.data.meta ? result.data.meta.total : 0,
      });

      return true;
    }
  },

  async summaryStatistik({ commit }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/tryout/review-akm/${payload.schoolExamSchedule}/header`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      commit("changeTryOutAkm", {
        loading: false,
        summary_statistik: result.data.data || false,
      });

      return true;
    }
  },

  async getListStatistik({ commit, state }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const { data } = state;
    let params = {
      page: data.page_statistik,
      limit: data.limit_statistik,
      q: data.filter_statistik,
    };

    const result = await ApiGetRequest(
      `school/tryout/review-akm/${payload.schoolExamSchedule}/student`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      let data_result = result.data.data || [];
      let update_result = [];
      let indexRowSpan = [];
      let rowItem = 0;

      data_result.map((item) => {
        if (item.template) {
          item.template.map((itemTemplate, indexTemplate) => {
            update_result.push({
              student: item.student,
              template: item.template,
              ...itemTemplate,
            });

            if (indexTemplate === 0) {
              indexRowSpan.push(rowItem);
            }

            rowItem += 1;
          });
        } else {
          update_result.push(item);
          rowItem += 1;
        }
      });

      commit("changeTryOutAkm", {
        loading: false,
        items_statistik: update_result,
        indexRowSpan,
        total_statistik:
          result.data && result.data.meta ? result.data.meta.total : 0,
      });

      return true;
    }
  },

  async getDetailAkm({ commit }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/training/request/${payload.request_id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      commit("changeTryOutAkm", {
        loading: false,
        detail: result.data.data || false,
      });

      return true;
    }
  },

  async getPesertaTryOut({ commit, state }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const { data } = state;
    let params = {
      page: data.page_peserta,
      limit: data.limit_peserta,
      q: data.filter_peserta,
    };

    const result = await ApiGetRequest(
      `school/tryout/review-akm/${payload.schoolExamSchedule}/student`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      let data_result = result.data.data || [];
      let update_result = [];
      let indexRowSpan = [];
      let rowItem = 0;

      data_result.map((item) => {
        if (item.template) {
          item.template.map((itemTemplate, indexTemplate) => {
            update_result.push({
              student: item.student,
              template: item.template,
              ...itemTemplate,
            });

            if (indexTemplate === 0) {
              indexRowSpan.push(rowItem);
            }

            rowItem += 1;
          });
        } else {
          update_result.push(item);
          rowItem += 1;
        }
      });

      commit("changeTryOutAkm", {
        loading: false,
        items_peserta: update_result,
        indexRowSpan,
        total_peserta:
          result.data && result.data.meta ? result.data.meta.total : 0,
      });

      return true;
    }
  },

  async summaryPeserta({ commit }, payload = null) {
    commit("changeTryOutAkm", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/tryout/review-akm/${payload.schoolExamSchedule}/header`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeTryOutAkm", {
        loading: false,
      });

      return false;
    } else {
      commit("changeTryOutAkm", {
        loading: false,
        summary_peserta: result.data.data || {},
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
