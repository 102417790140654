import {
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jurusan",
        label: "Jurusan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tempat_bekerja",
        label: "Tempat Bekerja",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "mulai",
        label: "Tahun Mulai",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jenis_pekerjaan",
        label: "Jenis Pekerjaan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "keterangan",
        label: "Keterangan",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    loading: false,
    listAlumniWorking: [],
    jsonData: [],
    listExportAlumniWorking: [],
    detailAlumniWorking: {},
    detailImport: {},
    formData: {
      uuid: "",
      name: "",
      highschool_major: "",
      workplace: "",
      job: "",
      start_at: "",
      description: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changeIntegrasiAlumniBekerja(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getAlumnusWorking({ commit, state }, payload) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });
    const { data } = state;

    if(payload){
      data.page = payload
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/alumnus-worker`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiAlumniBekerja", {
        listAlumniWorking: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async submitAlumnus({ commit, state }) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formData.uuid !== ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }

    formData.append("name", data.formData.name);
    formData.append("highschool_major", data.formData.highschool_major);
    formData.append("workplace", data.formData.workplace);
    formData.append("job", data.formData.job);
    formData.append("start_at", data.formData.start_at);
    formData.append("description", data.formData.description);

    if (data.formData.file != "string" && data.formData.file != null) {
      formData.append("mediafile", data.formData.file);
    }
    let result = "";

    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/alumnus-worker/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/alumnus-worker`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiAlumniBekerja", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },

  async deleteAlumnusBekerja({ commit }, payload) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(
      `/web/alumnus-worker/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Alumnus Bekerja telah dihapus",
      });
      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
      });

      return true;
    }
  },
  async getAlumnusPTNById({ commit }, payload) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(`/alumnus/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
        detailAlumniWorking: result.data.data,
      });
    }
  },
  async getExportAlumnusPTN({ commit, state }, payload) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiAlumniBekerja", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/alumnus-worker/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiAlumniBekerja", {
        listExportAlumniWorking: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelAlumniBekerja({ commit }, payload) {
    commit("changeIntegrasiAlumniBekerja", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiAlumniBekerja", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "alumnus_workers": payload.formData
    }
    type = "alumnus-worker"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiAlumniBekerja", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
