import axios from 'axios'
import {HandlerAlertUnauthorized} from "@/utils/Helpers";

const ApiService = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

// Simplify the retrieval of token and adding it to the request headers
ApiService.interceptors.request.use(
  (config) => {
    const localData = JSON.parse(
      localStorage.getItem('storeonklas') || 'null'
    )
    const token = localData?.userToken ?? ''
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

function handleError(error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
				HandlerAlertUnauthorized()
        break
    }

    const responseError = {
      status: error.response.status,
      code: error.response.status,
      error: error.response.data.error || error.response.data.message,
      errorList: error.response.data.errors
    }
    if (error.response.status === 307) {
      responseError.data = error.response.data
    }
    return Promise.reject(responseError)
  } else if (error.request) {
    console.log('Error with request:', error.request)
  }
  return Promise.reject(error)
}

ApiService.interceptors.response.use(
  async (response) => {
    // Anda bisa melakukan pengecekan atau transformasi pada response di sini
    return response
  },
  async (error) => {
    // Anda bisa menggunakan async/await di sini jika perlu
    return handleError(error)
  }
)

export default ApiService
