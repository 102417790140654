import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    showmodal: false,
    showmodaluser: false,
    error: false,
    loading: false,
    items: [],
    month: "",
    roles: [],
    classrooms: [],
    users: [],
    searchUser: "",
    currentPage: 1,
    limit: 10,
    page: 1,
    total: 0,
    typeCalender: "grid",
    editCalender: false,
    captionForm: "Tambah Kalender Sekolah",
    name: "",
    hari_khusus: false,
    jam_khusus: false,
    pihak_khusus: false,
    days: [],
    day_off: false,
    color: "#000000",
    showtglakhir: true,
    start_date: new Date(),
    end_date: null,
    start_time: null,
    end_time: null,
    rolesSelected: [],
    classes: [],
    customUsers: [],
    grouproles: [],
    groupclasses: [],
    userSelected: [],
    selectedAll: [],
    bulan: [
      {
        id: 1,
        label: "Januari",
      },
      {
        id: 2,
        label: "Februari",
      },
      {
        id: 3,
        label: "Maret",
      },
      {
        id: 4,
        label: "April",
      },
      {
        id: 5,
        label: "Mei",
      },
      {
        id: 6,
        label: "Juni",
      },
      {
        id: 7,
        label: "Juli",
      },
      {
        id: 8,
        label: "Agustus",
      },
      {
        id: 9,
        label: "September",
      },
      {
        id: 10,
        label: "Oktober",
      },
      {
        id: 11,
        label: "November",
      },
      {
        id: 12,
        label: "Desember",
      },
    ],
    day_options: [
      {
        id: 1,
        label: "Senin",
      },
      {
        id: 2,
        label: "Selasa",
      },
      {
        id: 3,
        label: "Rabu",
      },
      {
        id: 4,
        label: "Kamis",
      },
      {
        id: 5,
        label: "Jumat",
      },
      {
        id: 6,
        label: "Sabtu",
      },
      {
        id: 7,
        label: "Minggu",
      },
    ],
    columns_event: [
      {
        prop: "dates",
        label: "Hari & Tanggal",
        minWidth: "130",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama_acara",
        label: "Nama Acara",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jam_kerja",
        label: "Jam Kerja / Sekolah",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "berlaku_untuk",
        label: "Berlaku Untuk",
        minWidth: "250",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    column_choose_user: [
      {
        prop: "select",
        label: "Check",
        type: "selection",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nik",
        label: "NIK / NISN",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    filterBulan: "",
    dataDateCalendar: new Date(),
    currentDate: {},
    calendar: {},
    filterClass: "",
  },
};

const mutations = {
  changeKalender(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListCalendar({ commit, state }, payload = null) {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const filter = `${year}-${month + 1 < 10 ? "0" + (month + 1) : month}`;
    if (!payload) {
      payload = filter;
    }

    commit("changeKalender", {
      loading: true,
      month: payload,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/calendar?month=${payload || ""}&style=${
        data.typeCalender
      }&class=${data.filterClass || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali",
      });

      commit("changeKalender", {
        loading: false,
      });
    } else {
      const dataTgl = getResult.data.data;
      const attributes = [];

      if (dataTgl && Object.keys(dataTgl).length > 0) {
        await Object.keys(dataTgl).map((tgl) => {
          if (dataTgl[tgl].length > 0) {
            dataTgl[tgl].map((subItem) => {
              let hexcolor = subItem.color ? subItem.color : "#000000";
              hexcolor = hexcolor.replace("#", "");
              var r = parseInt(hexcolor.substr(0, 2), 16);
              var g = parseInt(hexcolor.substr(2, 2), 16);
              var b = parseInt(hexcolor.substr(4, 2), 16);
              var yiq = (r * 299 + g * 587 + b * 114) / 1000;
              let textColor = yiq >= 128 ? "black" : "white";

              attributes.push({
                key: subItem.id,
                customData: {
                  title: subItem.name,
                  color: subItem.color ? subItem.color : "#000000",
                  textColor: textColor,
                  start_time: subItem.start_time,
                  end_time: subItem.end_time,
                  id: subItem.id,
                  tgl: tgl,
                  classes: subItem.classes,
                  custom_users: subItem.custom_users,
                  roles: subItem.roles,
                  day_off: subItem.day_off,
                },
                dates: tgl,
              });
            });
          }
        });
      }

      commit("changeKalender", {
        loading: false,
        items: getResult.data.data,
        attributes: attributes,
      });
    }
  },
  async showEventById({ commit, dispatch, state }, payload = {}) {
    const { date, id } = payload;
    commit("changeKalender", { loading: true, error: false });
    const getResult = await ApiGetRequest(
      `school/klastime/calendar/${date || ""}/${id || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali",
      });

      commit("changeKalender", {
        loading: false,
      });
    } else {
      const { event, date } = getResult.data.data;

      let getRolesId = [];
      if (event.roles && event.roles.length > 0) {
        getRolesId = event.roles.map((item) => {
          return item.id;
        });

        await dispatch("getTotalRoles", {
          roles: getRolesId || [],
        });
      }

      let getClassId = [];
      if (event.classes && event.classes.length > 0) {
        getClassId = event.classes.map((item) => {
          return item.id;
        });
        await dispatch("getTotalClasses", {
          classes: getClassId || [],
        });
      }

      const { data } = state;

      let selectAllData = Object.assign(
        [],
        getClassId + getRolesId + event.custom_users
      );
      data.selectedAll = selectAllData;

      if (event.roles && event.roles.length > 0) {
        let allGroupsRoles = data.grouproles;
        await allGroupsRoles.map((item) => {
          const check = event.roles.filter(
            (itemE) => itemE.name === item.label
          );
          if (check.length > 0) {
            item.id = check[0].id;
          }
        });
      }

      if (event.classes && event.classes.length > 0) {
        let allGroupClasses = data.groupclasses;
        await allGroupClasses.map((item) => {
          const check = event.classes.filter(
            (itemE) => itemE.name === item.label
          );
          if (check.length > 0) {
            item.id = check[0].id;
          }
        });
      }
      
      if (event.start_time && event.end_time) {
        data.jam_khusus = true;
      } else {
        data.jam_khusus = false;
      }

      commit("changeKalender", {
        loading: false,
        editCalender: getResult.data.data,
        classes: getClassId || [],
        groupclasses: data.groupclasses || [],
        days: event.days || [],
        hari_khusus: event.days ? true : false,
        day_off: event.day_off || false,
        start_time: event.start_time || null,
        end_time: event.end_time || null,
        name: event.name || "",
        color: event.color || "#000000",
        start_date: date || "",
        end_date: date || "",
        rolesSelected: getRolesId || [],
        grouproles: data.grouproles || [],
        customUsers: event.custom_users || [],
      });
    }
  },
  async createEvent({ commit }, payload = null) {
    commit("changeKalender", {
      loading: true,
    });

    const getResult = await ApiPostRequest(`school/klastime/calendar`, payload);

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan agenda, mohon coba kembali",
      });

      commit("changeKalender", {
        error: getResult.errorList,
        loading: false,
      });

      return false;
    } else {
      commit("changeKalender", {
        loading: false,
        error: false,
      });

      Message({
        type: "success",
        message: "Data agenda kalender berhasil disimpan",
      });

      return true;
    }
  },
  async updateEvent({ commit, state }, payload = null) {
    commit("changeKalender", {
      loading: true,
    });

    const { data } = state;
    const getResult = await ApiPutRequest(
      `school/klastime/calendar/${(data.editCalender &&
        data.editCalender.date) ||
        ""}`,
      payload
    );
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mengubah agenda, mohon coba kembali",
      });

      commit("changeKalender", {
        error: getResult.errorList,
        loading: false,
      });

      return false;
    } else {
      commit("changeKalender", {
        loading: false,
        error: false,
      });

      Message({
        type: "success",
        message: "Data agenda kalender berhasil di ubah",
      });
    }
  },
  async deleteEvent({ commit }, payload = null) {
    commit("changeKalender", {
      loading: true,
    });

    const getResult = await ApiDeleteRequest(
      `school/klastime/calendar/${payload.date || ""}/${payload.id || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menghapus data agenda, mohon coba kembali",
      });

      commit("changeKalender", {
        loading: false,
      });

      return false;
    } else {
      commit("changeKalender", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Event calender berhasil dihapus",
      });

      return true;
    }
  },
  async getTotalRoles({ commit }, payload) {
    let url = `school/klastime/user/grouped?type=staff`;
    if (payload.roles) {
      url += `&roles[]=${payload.roles.join("&roles[]=")}`;
    }

    const getResult = await ApiGetRequest(url);
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data, coba kembali",
      });

      return false;
    } else {
      const dataRoles = getResult.data.data;
      dataRoles.map((item, index) => {
        dataRoles[index].name = item.label;
      });

      commit("changeKalender", {
        grouproles: dataRoles,
      });
    }
  },
  async getTotalClasses({ commit }, payload) {
    let url = `school/klastime/user/grouped?type=student`;
    if (payload.classes) {
      url += `&classes[]=${payload.classes.join("&classes[]=")}`;
    }

    const getResult = await ApiGetRequest(url);
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data user, coba kembali",
      });

      return false;
    } else {
      const dataClasses = getResult.data.data;
      dataClasses.map((item, index) => {
        dataClasses[index].name = item.label;
      });

      commit("changeKalender", {
        groupclasses: dataClasses,
      });

      return true;
    }
  },
  async getListUsers({ commit, state }, pagedata = null) {
    commit("changeKalender", {
      loading: true,
    });

    if (pagedata) {
      commit("changeKalender", {
        currentPage: pagedata,
      });
    }

    const { data } = state;
    const dataRoles = [];
    if (data.rolesSelected) {
      data.rolesSelected.map((item) => dataRoles.push(`notroles[]=${item}`));
    }

    const dataClasses = [];
    if (data.classes) {
      data.classes.map((item) => dataClasses.push(`notclasses[]=${item}`));
    }

    let url = `school/klastime/user?limit=${data.limit}&page=${data.currentPage}&q=${data.searchUser}`;
    if (dataRoles.length > 0) {
      url += `&${dataRoles.join("&")}`;
    }

    if (dataClasses.length > 0) {
      url += `&${dataClasses.join("&")}`;
    }

    const getResult = await ApiGetRequest(url);
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mengambil data user, coba kembali",
      });

      commit("changeKalender", {
        error: getResult.errorList || false,
        loading: false,
      });

      return false;
    } else {
      commit("changeKalender", {
        loading: false,
        users: getResult.data.data,
        currentPage: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
