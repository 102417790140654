const state = {
  data: {
    tabMenu: [
      {
        name: "Perpustakaan",
        label: "Perpustakaan",
        lock: false,
        url: "/klaspustaka/perpustakaan"
      },
      {
        name: "WebKatalog",
        label: "Web Katalog",
        lock: false,
        url: "/klaspustaka/web-katalog"
      }
    ],
    perpusMenu: [
      {
        name: "List Buku",
        active: true
      },
      {
        name: "Stok & Arsip",
        active: false
      },
      {
        name: "Pinjaman",
        active: false
      }
    ],
    activePerpusMenu: "List Buku",
    bukuInputMenu: [
      {
        name: "Daftar Buku",
        active: true
      },
      {
        name: "Kategori",
        active: false
      }
    ],
    activeBukuInputMenu: "Daftar Buku",
    stokArsipMenu: [
      {
        name: "Stok & QR",
        active: true
      },
      {
        name: "Arsip",
        active: false
      }
    ],
    activeStokArsipMenu: "Stok & QR"
  }
};

const mutations = {
  changeKlaspustaka(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
