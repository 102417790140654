import {
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "nama",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "document",
        label: "Dokumen",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],

    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    loading: false,
    listSuratTugas: [],
    detailSuratTugas: {},
    formData: {
      uuid: "",
      name: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changeIntegrasiSuratTugas(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getSuratTugas({ commit, state }, payload) {
    commit("changeIntegrasiSuratTugas", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiSuratTugas", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/letter`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiSuratTugas", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiSuratTugas", {
        listSuratTugas: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async submitSuratTugas({ commit, state }) {
    commit("changeIntegrasiSuratTugas", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();
    if (data.formData.uuid !== ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }


    formData.append("name", data.formData.name);

    if (
      data.formData.file != "string" &&
      data.formData.file != null
    ) {
      formData.append("mediafile", data.formData.file);
    }

    let result = "";
    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/letter/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/letter`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiSuratTugas", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },
  async deleteSuratTugas({ commit }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/letter/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Surat Tugas telah dihapus",
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
