import { ApiGetRequest } from '@/utils/Api';
import moment from 'moment';
import { Message } from 'element-ui';

const state = {
  data: {
    error: false,
    loading: false,
    showmodalDetail: false,
    nameExcelOutput: "",
    search: '',
    items: [
      {
        id: 1,
        nik_nuptk: 112233,
        nama_lengkap: 'Miqbal Aulia Rafi',
        profesi: 'Software Engineer',
      },
    ],
    exportExcelJsonFieldStart: {
      'No': {
        field: "no",
        callback: (value, index) => {
          return ++index;
        },
      },
      'Nama': 'name',
      'Profesi': {
        field: "profesi",
        callback: (value) => {
          return value.join(', ');
        },
      },
    },
    exportExcelJsonFieldEnd: {
      'Hadir': "h",
      'Izin': "i",
      'Alpha': "a",
    },
    exportExcelJsonFieldContent: {},
    exportExcelJsonFieldData: {},
    nameTextProfesi: null,
    export_fields: {},
    itemsExample: [],
    limit: 10,
    page: 1,
    total: 0,
    limitProfesi: 10,
    pageProfesi: 1,
    totalProfesi: 0,
    typefilter: 'bulan',
    filterCetak: 'bulan',
    filter_date: moment().format('YYYY-MM-DD'),
    current_year: moment().format('YYYY'),
    current_month: moment().format('MM'),
    current_date: moment().format('DD'),
    currentMonth: [],
    rolesSelectedMultiple: [],
    rolesSelected: '',
    tglActive: '',
    month: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember',],
    columns_rekap_staff: [
      {
        prop: 'nik_nuptk',
        label: 'NIK / NUPTK',
        sortable: true,
        minWidth: '100',
      },
      {
        prop: 'nama_lengkap',
        label: 'Nama Lengkap',
        sortable: true,
        minWidth: '100',
      },
      {
        prop: 'profesi',
        label: 'Profesi',
        sortable: false,
        minWidth: '100',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '100',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_rekap_guru_profesi: [],
    dum: [],
    dataPresensiGuru: {
      tglAwal: "", tglAkhir: "",
    },
  },
};

const mutations = {
  changeRekapPresensiGuru(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getRecapListStaff({ commit, state }, payload) {
    commit('changeRekapPresensiGuru', {
      loading: true,
    });

    if (payload) {
      await commit('changeRekapPresensiGuru', {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      q: data.search,
      role: data.rolesSelected,
      page: data.page,
    };

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-staff`,
      params
    );

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
      commit('changeRekapPresensiGuru', {
        loading: false,
      });

      return [];
    } else {
      if(!result.data){
        Message({
          type: 'error',
          message: "CORS Error, Ulangi lagi",
        });
        commit('changeRekapPresensiGuru', {
          loading: false,
        });
        return [];
      }
      await commit('changeRekapPresensiGuru', {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },
  async handlerListExportExcel({commit,state},payload) {
    const {data} = state;
    data.exportExcelJsonFieldData = {};
    await commit("changeRekapPresensiGuru", {
      exportExcelJsonFieldData: {},
    });
    const dataExpo = payload.dt;
    const objectFieldContent = {};
    dataExpo.map((item)=>{
      let dtLop = item.child;
      for (const childKey in item.child) {
        let fieldCustom = {
          field: dtLop[childKey].prop,
          callback: (value) => {
            return value ? ((typeof value=='object') ? value.time ? value.time : value.label  : value ) : value;
          }
        }
        objectFieldContent[dtLop[childKey].prop] = fieldCustom;
      }
    })
    await commit("changeRekapPresensiGuru", {
      exportExcelJsonFieldData: {...data.exportExcelJsonFieldStart,...objectFieldContent},
    });
  },
  async getPresensiProfesiRangeApi({commit, state,dispatch}) {
    const {data} = state;
    let aw = new Date(data.dataPresensiGuru.tglAwal);
    let ak = new Date(data.dataPresensiGuru.tglAkhir);
    let resDiff = moment(ak).diff(aw, 'days');
    if (resDiff < 0) {
      Message({
        type: 'info', message: 'Tanggal Akhir Harus Lebih Dari Tanggal Awal'
      });
      return false;
    }
    if (resDiff > 60) {
      Message({
        type: 'info', message: 'Batas Rekap 60 Hari'
      });
      return false;
    }
    let tglAwalFormat = moment(aw).format("DD-MM-YYYY");
    let tglAkhirFormat = moment(ak).format("DD-MM-YYYY");
    let nameProfesi = (data.rolesSelectedMultiple ? (data.rolesSelectedMultiple.join('_')) : ``);
    await commit("changeRekapPresensiGuru", {
      nameExcelOutput: `export-presensi-(${nameProfesi})-${tglAwalFormat}-${tglAkhirFormat}`,
    });
    const s = moment.isMoment(data.dataPresensiGuru.tglAwal) ? data.dataPresensiGuru.tglAwal : moment(data.dataPresensiGuru.tglAwal);
    const e = moment.isMoment(data.dataPresensiGuru.tglAkhir) ? data.dataPresensiGuru.tglAkhir : moment(data.dataPresensiGuru.tglAkhir);
    const params = {
      limit: 0,
      startDate: moment(data.dataPresensiGuru.tglAwal).format('YYYY-MM-DD'),
      endDate: moment(data.dataPresensiGuru.tglAkhir).format('YYYY-MM-DD'),
    };
    if(data.search){
      params.q = data.search;
    }
    let url = `school/klastime/attendance-report-staff?`;
    if(data.rolesSelectedMultiple.length){
      url += `role[]=${data.rolesSelectedMultiple.join("&role[]=")}`;
    }
    const result = await ApiGetRequest(url, params);

    if (result.error) {
      Message({
        type: "error", message: result.error,
      });
      commit("changeRekapPresensiGuru", {
        loading: false,
      });
      return [];
    }
    if (!result.data) {
      Message({
        type: "error", message: "Cors Error, tidak terhubung dengan server. Ulangi lagi",
      });
      commit("changeRekapPresensiGuru", {
        loading: false,
      });
      return;
    }
    await commit("changeRekapPresensiGuru", {
      loading: false,
      itemsExample: result.data.data,
      pageProfesi: 1,
      limitProfesi: result.data ? result.data.data.length : 0,
      totalProfesi: result.data ? result.data.data.length : 0,
    });
    const columns_sem = [];
    const nameMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    const saveMonth = [];
    let indexMonth = 0
    let resDiffRange = [...Array(1 + e.diff(s, 'days')).keys()].map(n => {
      let awa = moment(s).add(n, 'days')
      let dateFull = awa.format("YYYY-MM-DD");
      let dtTanggal = {
        prop: dateFull,
        label: `${awa.get('date')}`,
        width: 50,
      }
      if (saveMonth.includes(awa.get('month'))) {
        columns_sem[(indexMonth - 1)].child.push(dtTanggal)
      } else {
        columns_sem.push({
          label: `${nameMonth[awa.get('month')]}`, child: [dtTanggal]
        })
        saveMonth.push(awa.get('month'))
        indexMonth++
      }
    });
    let dtRekap = [
      {
        prop: `h`, label: `H`, width: 50,
      },
      {
        prop: `i`, label: `I`, width: 50,
      },
      {
        prop: `a`, label: `A`, width: 50,
      },
    ]
    columns_sem.push({
      label: `Rekap`, child: dtRekap
    })
    dispatch("handlerListExportExcel",{dt:columns_sem});
    commit("changeRekapPresensiGuru", {
      loading: false,
      typefilter: "profesi",
      columns_rekap_guru_profesi: columns_sem,
      dum: resDiffRange,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
