import { ApiGetRequest } from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    majors: [],
  },
};

const mutations = {
  changeMajor(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getMajor({ commit }) {
    const result = await ApiGetRequest(`school/major`);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return [];
    } else {
      commit('changeMajor', {
        majors: result.data.data,
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
