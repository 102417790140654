import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
  ApiPostMultipart,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_kartu_pelajar: [
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis_nik",
        label: "NISN / NIS",
        minWidth: "180",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "classes",
        label: "Kelas",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "update_at",
        label: "Update data",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "90",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns: [
      {
        prop: "nis_nik",
        label: "NISN/NIS",
        minWidth: "180",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "150",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
			{
				prop: "grade",
				label: "",
				width: 50,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "classes",
				label: "Kelas",
				minWidth: "150",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "major",
				label: "Jurusan",
				minWidth: "150",
				"class-name": "body-14 col-black-2",
			},
      {
        prop: "setting_spp",
        label: "Setting SPP",
        width: "200",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "soft_deleted",
        label: "Status",
        width: 90,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" },
    ],
    currentStatus: "active",
    currentKelas: "",
    class: [],
    majorSelected: "",
    currentHideAlumni: true,
    major: [],
    loading: false,
    jsonData: [],
    provinces: [],
    cities: [],
    districts: [],
    goldarah: ["A", "AB", "B", "O"],
    ukuranbaju: ["S", "M", "L", "XL", "XXL", "XXXL"],
    agama: ["Islam", "Protestan", "Katolik", "Hindu", "Budha", "Konghucu"],
    dataForm: {
      nisn: "",
      nis: "",
      name: "",
      email: "",
      phone: "",
      gender: "",
      place_of_birth: "",
      date_of_birth: "",
      weight: "",
      height: "",
      blood_type: "",
      class: "",
      grade: "",
      major_name: "",
      major_id: "",
      province_id: "",
      city_id: "",
      sub_district_id: "",
      address: "",
      religion: "",
      uniform_size: "",
      sport_shirt_size: "",
    },
    rules: {
      nisn: [
        {
          required: true,
          message: "NISN wajib diisi",
          trigger: "blur",
        },
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("NISN harus angka"));
            }
          },
          trigger: "blur",
        },
      ],
      nis: [
        {
          required: true,
          message: "NIS wajib diisi",
          trigger: "blur",
        },
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("NISN harus angka"));
            }
          },
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "Nama wajib diisi",
          trigger: "blur",
        },
      ],
      grade: [
        {
          required: true,
          message: "Jenjang wajib diisi",
          trigger: "blur",
        },
      ],
      class: [
        {
          required: true,
          message: "Angkatan wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          type: "email",
          message: "Alamat email tidak sesuai",
          trigger: "change",
        },
      ],
      phone: [
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("Telp tidak sesuai"));
            }
          },
          trigger: "blur",
        },
      ],
      weight: [
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("Berat tidak sesuai"));
            }
          },
          trigger: "blur",
        },
      ],
      height: [
        {
          validator(rule, value, callback) {
            if (Number.isInteger(Number(value))) {
              callback();
            } else {
              callback(new Error("Tinggi tidak sesuai"));
            }
          },
          trigger: "blur",
        },
      ],
    },
    error: [],
    errors: false,
  },
};

const mutations = {
  changePelajar(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getPelajar({ commit, state }, payload) {
    commit("changePelajar", {
      loading: true,
    });
    const { data } = state;

    let limit = null
    if (payload) {
      if(typeof payload == "object" && typeof payload.limit !== 'undefined'){
        await commit("changePelajar", {
          limit: (typeof payload.limit !== 'undefined') ? payload.limit : data.limit
        });
        limit = (typeof payload.limit !== 'undefined') ? payload.limit : data.limit
      }else{
        await commit("changePelajar", {
          page: payload || data.page,
        });
      }
    }

    const params = {
      limit: (limit!=null?limit:state.data.limit),
      page: state.data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };
    if(data.currentHideAlumni && !data.majorSelected && !data.currentKelas) {
      params.hide_alumnus = 1
    }

    if (
      data.majorSelected &&
      data.majorSelected !== "" &&
      data.majorSelected > 0
    ) {
      params.school_major_id = data.majorSelected;
    }

    if (
      data.currentKelas &&
      data.currentKelas !== "" &&
      data.currentKelas > 0
    ) {
      params.school_class_id = data.currentKelas;
    }

    const result = await ApiGetRequest(`school/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });
    } else {
      const send = {
        loading: false,
      }
      send.items = result.data.data
      if(typeof payload == "object") {
        commit("changePelajar", send);
        return {
          data: result.data.data
        };
      }else{
        send.total = result.data.meta.total
        commit("changePelajar", send);
        return {
          data: result.data.data,
          total: result.data.meta.total,
        };
      }
    }
  },
  async getPelajarById({ commit, state, dispatch }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const { data } = state;
    const result = await ApiGetRequest(`school/student/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });
    } else {
      let newDataForm = data.dataForm;
      newDataForm = Object.assign({}, newDataForm, result.data.data.student);
      newDataForm = Object.assign({}, newDataForm, result.data.data);
      newDataForm["major_id"] =
        newDataForm &&
        newDataForm.student &&
        newDataForm.student.major &&
        newDataForm.student.major.id;

      dispatch(
        "fileStorage/getDataFileStorage",
        { uuid: payload.id },
        { root: true }
      );

      let dataCities = await dispatch(
        "cities/getCities",
        newDataForm.province_id,
        { root: true }
      );

      let dataDistricts = await dispatch(
        "districts/getDistricts",
        newDataForm.city_id,
        { root: true }
      );

      commit("changePelajar", {
        dataForm: newDataForm,
        cities: dataCities,
        districts: dataDistricts,
        id: payload.id,
        loading: false,
      });
    }
  },
  async resetAkunPelajar(context, payload) {
    const { formData, id } = payload;
    const result = await ApiPutRequest(`school/user/${id}/password`, formData);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data password berhasil di reset",
      });

      return true;
    }
  },
  async deleteAkunPelajar(context, payload) {
    const { id } = payload;
    const result = await ApiDeleteRequest(`school/student/${id}`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah dinonaktifkan",
      });

      return true;
    }
  },
  async restoreAkunPelajar(context, payload) {
    const { id } = payload;
    const result = await ApiPostRequest(`school/student/${id}/restore`, {});
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
    } else {
      Message({
        type: "success",
        message: "Akun telah diaktifkan kembali",
      });
      return true;
    }
  },
  async importExcelPelajar({ commit }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const result = await ApiPostRequest(`school/student`, payload.formData);
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePelajar", {
        loading: false,
      });

      return false;
    } else {
      commit("changePelajar", {
        loading: false,
      });

      const items = result.data.data;

      // check kalo return respone ada error
      const errorItems = await items.filter((item) => {
        if (item.error) {
          return item;
        }
      });

      if (errorItems.length > 0) {
        commit("changePelajar", {
          jsonData: errorItems,
        });

        let message = "";
        if (errorItems.length != payload.formData.length) {
          message = "Beberapa data gagal ditambahkan ke server";
        } else {
          message = "Data gagal ditambahkan ke server";
        }

        Message({
          type: "error",
          message: message,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data Pelajar berhasil ditambahkan",
        });

        return true;
      }
    }
  },
  async createPelajar({ commit, state }) {
    commit("changePelajar", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPostRequest(
      `school/student/store-manual`,
      data.dataForm
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      commit("changePelajar", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changePelajar", {
        loading: false,
        error: false,
      });

      Message({
        type: "success",
        message: "Data Pelajar berhasil ditambahkan",
      });

      return result.data.data;
    }
  },
  async updatePelajar({ commit, state }) {
    commit("changePelajar", {
      error: false,
    });

    const { data } = state;
    if (data.dataForm && data.dataForm.nis_nik) {
      data.dataForm.nis_nik = data.dataForm.nis_nik.replace("onklas-", "");
    }

    if (data.dataForm && data.dataForm.nisn) {
      data.dataForm.nisn = data.dataForm.nisn.replace("onklas-", "");
    }

    if (data.dataForm && data.dataForm.nis) {
      data.dataForm.nis = data.dataForm.nis.replace("onklas-", "");
    }

    if (data.dataForm && data.dataForm.student && data.dataForm.student.nis) {
      data.dataForm.student.nis = data.dataForm.student.nis.replace(
        "onklas-",
        ""
      );
    }

    if (data.dataForm && data.dataForm.student && data.dataForm.student.nisn) {
      data.dataForm.student.nisn = data.dataForm.student.nisn.replace(
        "onklas-",
        ""
      );
    }

    if (
      data.dataForm &&
      data.dataForm.student &&
      data.dataForm.student.user &&
      data.dataForm.student.user.nis_nik
    ) {
      data.dataForm.student.user.nis_nik = data.dataForm.student.user.nis_nik.replace(
        "onklas-",
        ""
      );
    }

    commit("changePelajar", {
      loading: true,
    });
    const result = await ApiPutRequest(
      `school/student/${data.id}`,
      data.dataForm
    );
    commit("changePelajar", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changePelajar", {
        error: result.errorList ? result.errorList : false,
      });

      return false;
    }

    Message({
      type: "success",
      message: "Data Pelajar berhasil diubah",
    });

    return true;
  },

  async updateImageUser({ commit }, payload) {
    commit("changePelajar", {
      loading: true,
    });

    const result = await ApiPostMultipart(
      `school/user/${payload.id}/profile-image`,
      payload.dataForm
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah gambar profil",
      });

      commit("changePelajar", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    } else {
      commit("changePelajar", {
        loading: false,
        error: false,
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
