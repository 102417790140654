import repository from "@/repositories/PembayaranSppRepository"
import StrukturErrorService from "@/services/StrukturErrorService"
//import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers"

export default class PembayaranSppService {
	constructor() {
		this.dataRepository = repository
	}

	async listStudentFeeInvoiceGet(payload) {

		const {months, user_id, class_id} = payload
		if (typeof months !== "object") {
			throw new Error("months must type object")
		}
		if (!user_id && !class_id) {
			throw new Error("!user_id and !class_id, one of them must be filled in")
		}

		try {
			const params = {
				months,
				user_id: user_id || undefined,
				class_id: class_id || undefined,
			}
			return await this.dataRepository.getStudentInvoice(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listDiscountGet(id, payload) {

		const {
			months, user_id, class_id,
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		if (typeof months !== "object") {
			throw new Error("months must type object")
		}

		try {
			const params = {
				months,
				user_id: user_id || undefined,
				class_id: class_id || undefined,

				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}
			return await this.dataRepository.getDiscount(id, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listInvoiceGet(id, payload) {

		if (!id) {
			throw new Error("ID required")
		}

		const {
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}
			return await this.dataRepository.getInvoice(id, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async studentDebtGet(payload) {

		const {
			page, limit, filterQ,
			sort_field, sort_dir,
			school_class_id,
			school_major_id,
			show
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
				school_class_id: school_class_id || undefined,
				school_major_id: school_major_id || undefined,
				show: show || undefined,
			}
			return await this.dataRepository.getStudentDebt(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async sppStudentFeeInvoiceGet(payload) {

		const {
			page, limit, filterQ,
			sort_field, sort_dir,
			paid_start, paid_end,
			issued_end, issued_start,
			filterClasses, filterStatus,
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,

				classes: filterClasses || undefined,
				filter: filterStatus || undefined,

				issued_start: issued_start || undefined,
				issued_end: issued_end || undefined,

				paid_start: paid_start || undefined,
				paid_end: paid_end || undefined,
			}

			return await this.dataRepository.getSppStudentFeeInvoice(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async tagihanSppPengaturanGet(payload) {

		const {
			page, limit, filterQ,
			sort_field, sort_dir,
			classes, filterMajor,
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,

				classes: classes || undefined,
				major: filterMajor || undefined,
			}

			return await this.dataRepository.getTagihanSppPengaturan(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async tagihanSppPengaturanByIdPost(id, dataSubmit) {
		if (!id) {
			throw new Error("ID required")
		}

		/*const dataSubmit = [
			{
				student_id: student_id,
				discount: discount,
				remark: remark,
			}
		]*/
		try {
			const formData = {
				data: dataSubmit
			}

			return await this.dataRepository.postTagihanSppPengaturanById(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async createTagihanSppPengaturanPost(formBiayaSpp) {

		try {
			const formData = {
				data: formBiayaSpp
			}

			return await this.dataRepository.postTagihanSppPengaturan(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async updateTagihanSppPengaturanPut(id, idProductSpp, formBiayaSpp) {
		if (!id || !idProductSpp) {
			throw new Error("ID required")
		}

		try {
			const formData = {
				data: formBiayaSpp
			}

			return await this.dataRepository.postTagihanSppPengaturan(id, idProductSpp, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async tagihanSppPengaturanByIdAndProductIdDelete(id, idProductSpp) {

		try {
			return await this.dataRepository.postTagihanSppPengaturan(id, idProductSpp)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async createTagihanSppPost(payload) {

		const {month, student_id, school_class_id} = payload

		try {
			const formData = {
				month: month,
				student_id: student_id,
				school_class_id: school_class_id,
			}

			return await this.dataRepository.postCreateTagihanSpp(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async onklasProductCheckGet() {

		try {

			return await this.dataRepository.getOnklasProductCheck()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async setAdminSppPost(id, payload) {
		if (!id) {
			throw new Error("ID required")
		}

		const {product_klaspay} = payload

		try {

			const formData = {
				data: product_klaspay
			}

			return await this.dataRepository.postSetAdminSpp(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

}
