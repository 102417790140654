import { ApiGetRequest, ApiPostRequest, ApiPutRequest, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        category: {
            columns: [{
                    prop: 'name',
                    label: 'Kategori',
                    'class-name': 'body-14 col-black-2',
                    sortable: true,
                },
                {
                    label: 'Aksi',
                    minWidth: '50',
                    'class-name': 'body-14 col-black-2',
                },
            ],
            items: [],
            sortBy: '',
            sortType: '',
            filter: '',
            errors: [],
            loading: false,
        },
        subcategory: {
            columns: [{
                    prop: 'name',
                    label: 'Sub Kategori',
                    'class-name': 'body-14 col-black-2',
                    sortable: true,
                },
                {
                    label: 'Aksi',
                    minWidth: '50',
                    'class-name': 'body-14 col-black-2',
                },
            ],
            items: [],
            sortBy: '',
            sortType: '',
            filter: '',
            errors: [],
            loading: false,
        },
        formType: '',
        selectedCategory: null,
        form: {
            id: '',
            name: '',
        },
        formRules: {
            name: [
                { required: true, message: 'Nama tidak boleh kosong', trigger: 'blur' },
            ],
        },
        errors: [],
    },
};

const mutations = {
    changePerpustakaanKategori(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getKategori({ commit, state }, payload) {
        const { data } = state;
        data.category.loading = true

        commit('changePerpustakaanKategori', {
            category: data.category,
        });

        if (payload) {
            await commit('changePerpustakaanKategori', {
                page: payload || data.page,
            });
        }

        const params = {};

        if (data.category.sortBy) {
            params.sort_field = data.category.sortBy
        }

        if (data.category.sortType) {
            params.sort_dir = data.category.sortType
        }

        if (data.category.filter) {
            params.f = data.category.filter
        }

        const result = await ApiGetRequest(`school/library/subject`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            data.category.loading = false
            commit('changePerpustakaanKategori', {
                category: data.category,
            });

            return [];
        } else {
            data.category.items = result.data.data
            data.category.loading = false
            await commit('changePerpustakaanKategori', {
                category: data.category,
            });
        }
    },

    async getSubKategori({ commit, state }, payload) {
        const { data } = state;
        data.subcategory.loading = true

        commit('changePerpustakaanKategori', {
            subcategory: data.subcategory,
        });

        if (payload) {
            await commit('changePerpustakaanKategori', {
                page: payload || data.page,
            });
        }

        const params = {
            book_subject_id: data.selectedCategory,
        };

        if (data.subcategory.sortBy) {
            params.sort_field = data.subcategory.sortBy
        }

        if (data.subcategory.sortType) {
            params.sort_dir = data.subcategory.sortType
        }

        if (data.subcategory.filter) {
            params.f = data.subcategory.filter
        }

        const result = await ApiGetRequest(`school/library/category`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            data.subcategory.loading = false
            commit('changePerpustakaanKategori', {
                subcategory: data.subcategory,
            });

            return [];
        } else {
            data.subcategory.items = result.data.data
            data.subcategory.loading = false
            await commit('changePerpustakaanKategori', {
                subcategory: data.subcategory,
            });
        }
    },

    async submitKategori({ commit, state }) {
        const { data } = state;

        let endPoint = 'school/library/subject'
        let body = {
            name: data.form.name,
        }

        if (data.formType == 'category') {
            data.category.loading = true
            commit('changePerpustakaanKategori', {
                category: data.category,
            });
        } else {
            data.subcategory.loading = true
            commit('changePerpustakaanKategori', {
                subcategory: data.subcategory,
            });
            endPoint = 'school/library/category'
            body.book_subject_id = data.selectedCategory
        }

        let result = ""

        if (data.form.id != '') {
            result = await ApiPutRequest(`${endPoint}/${data.form.id}`, body);
        } else {
            result = await ApiPostRequest(`${endPoint}`, body);
        }

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                data.category.loading = false
                data.subcategory.loading = false
                commit('changePerpustakaanKategori', {
                    errors: errors,
                    category: data.category,
                    subcategory: data.subcategory,
                });
            }
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            data.category.loading = false
            data.subcategory.loading = false
            commit('changePerpustakaanKategori', {
                errors: [],
                form: {
                    id: '',
                    name: '',
                },
                category: data.category,
                subcategory: data.subcategory,
            });

            return true;
        }
    },

    async deleteKategori({ commit, state }, payload) {
        const { data } = state;

        let endPoint = 'school/library/subject'

        if (data.formType == 'category') {
            data.category.loading = true
            commit('changePerpustakaanKategori', {
                category: data.category,
            });
        } else {
            data.subcategory.loading = true
            commit('changePerpustakaanKategori', {
                subcategory: data.subcategory,
            });
            endPoint = 'school/library/category'
        }

        if (payload && payload.id) {
            const result = await ApiDeleteRequest(`${endPoint}/${payload.id}`);

            if (result.error) {
                Message({
                    type: 'error',
                    message: result.error,
                });
                data.category.loading = true
                data.subcategory.loading = true
                commit('changePerpustakaanKategori', {
                    errors: result.errorList,
                    category: data.category,
                    subcategory: data.subcategory,
                });
                return false;
            } else {
                Message({
                    type: 'success',
                    message: 'Data telah dihapus.',
                });
                data.category.loading = false
                data.subcategory.loading = false
                commit('changePerpustakaanKategori', {
                    errors: [],
                    category: data.category,
                    subcategory: data.subcategory,
                });
                return true;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};