import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import PembayaranSppService from "@/services/PembayaranSppService";
import moment from "moment";

const state = {
	data: {
		showModalTunggakan: false,
		showModalSelectMonth: false,
		loading: false,
		filterStatus: "active",
		filterMajor: null,
		filterClasses: null,
		filtertype: "1",
		filterQ: null,
		filterYearMonth: moment().format("YYYY-MM"),
		dataPrint: false,
		itemsMessageTable: null,
		errorDataItems: null,
		limit: 10,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		riwayat_bayar: [],
		columns: [
			{
				prop: "expand",
				label: "",
				type: "expand"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "130",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nisn",
				label: "NISN / NIS",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "classes",
				label: "Kelas",
				minWidth: "90",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "major",
				label: "Jurusan",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "school_fee",
				label: "Biaya SPP",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "total_debt",
				label: "Tunggakan",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "90",
				"class-name": "body-14 col-black-2"
			}
		],
		rekapTunggakan: {
			serverError: false,
			messageError: null
		}
	}
};

const mutations = {
	changeBayarSppTunggakan(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeBayarSppTunggakanLoading(state, payload) {
		state.data.loading = payload
	},
	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {

	onShowModal({commit}, isOpen) {
		commit("changeBayarSppTunggakan", {showModalTunggakan: isOpen})
	},

	onShowModalSelectMonth({commit}, isOpen) {
		commit("changeBayarSppTunggakan", {showModalSelectMonth: isOpen})
	},

	async fetchTunggakanStudentDebt({commit, state}, payload = null) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppTunggakanLoading", true)

		if (payload) {
			await commit("changeBayarSpp", {
				page: payload || data.page
			});
		}

		const params = {
			page: data.page,
			limit: data.limit,
			filterQ: data.filterQ,
			sort_field: data.sortField,
			sort_dir: data.sortDir,
			show: data.filterStatus
		};

		if (data.filterClasses) {
			params.school_class_id = data.filterClasses;
		}

		if (data.filterMajor) {
			params.school_major_id = data.filterMajor;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.studentDebtGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Tunggakan"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeBayarSppTunggakan", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppTunggakan", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeBayarSppTunggakanLoading", false)
		}
	},

	async fetchStudentFeeInvoice({commit, state}, payload) {

		const {data} = state
		const {status} = payload

		const params = {
			sort_field: 'name',
			sort_dir: 'asc',
			filterStatus: status,
			filtertype: 1,
			issued_start: data.filterYearMonth,
			issued_end: data.filterYearMonth
		};

		if (data.filterClasses) {
			params.filterClasses = data.filterClasses;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.sppStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				commit("changeBayarSppTunggakan", {
					riwayat_bayar: result.data.data
				})
			}
			commit("changeBayarSppTunggakan", {
				rekapTunggakan: {
					serverError: false,
					messageError: null
				}
			})

			return true
		} catch (e) {

			commit("changeBayarSppTunggakan", {
				riwayat_bayar: [],
			})

			if (e.status >= 500) {
				Message({
					type: "error",
					message: SERVER_ERROR_MESSAGE
				});
				commit("changeBayarSppTunggakan", {
					rekapTunggakan: {
						serverError: true,
						messageError: SERVER_ERROR_MESSAGE
					}
				})
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data REKAP"
			Message({
				type: "error",
				message: m
			});
			commit("changeBayarSppTunggakan", {
				rekapTunggakan: {
					serverError: true,
					messageError: m
				}
			})

			return false;
		}
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
