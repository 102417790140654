import {
  ApiPostRequestCabdin,
  ApiPostRequest,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    loading: false,
    formData: {
      npsn: "",
      password: "",
    },

    // ========= Galeri ==========
    field_galeri: [
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "70",
        "class-name": "text-center body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "theme",
        label: "Tema",
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "description",
        label: "Keterangan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],

    dataUUID:'',
    limitGaleri: 10,
    pageGaleri: 1,
    totalGaleri: 0,
    sortGaleri: "",
    keyGaleri: "",
    filterGaleri: "",
    uuidLogin: "",
    loadingGaleri: false,
    listGaleriPhoto: [],
    listGaleriVideo: [],
    linkImage: [],
    formDataGaleri: {
      uuid: "",
      name: "",
      class: "",
      theme: "",
      type: "",
      link: "",
      images: "",
      description: "",
    },
    // ========= Galeri ==========

    cabdinlogin: {},
  },
};

const mutations = {
  changeIntegrasi(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async submitLoginData({ commit, state }) {
    commit("changeIntegrasi", {
      loading: true,
    });

    const { data } = state;

    let formData = {
      npsn: data.formData.npsn,
      password: data.formData.password,
    };

    let result = "";

    result = await ApiPostRequestCabdin(`/web/login`, formData);
    if (result.data.message) {
      Message({
        type: "error",
        message: result.data.message,
      });

      commit("changeIntegrasi", {
        loading: false,
      });

      return false;
    } else {
      commit("changeIntegrasi", {
        loading: false,
        cabdinlogin: result,
        
      });

      return result;
    }
  },
  async updateDataOnklas({ commit, state }) {
    commit("changeIntegrasi", {
      loading: true,
    });

    const { data } = state;

    let formData = {
      cabdin_token: data.cabdinlogin.data.meta.token,
    };

    let result = "";

    result = await ApiPostRequest(`/school/cabdin/token`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal update token",
      });

      commit("changeIntegrasi", {
        loading: false,
      });

      return false;
    } else {
      commit("changeIntegrasi", {
        loading: false,
        cabdinlogin: result,
      });

      return result;
    }
  },

  // ===============Galeri====================
  async getGaleriPhoto({ commit, state }, payload) {
    commit("changeIntegrasi", {
      loadingGaleri: true,
    });

    if (payload) {
      await commit("changeIntegrasi", {
        page: payload || data.pageGaleri,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limitGaleri,
      sort: `-created_at`,
      key: data.keyGaleri,
    };

    if (data.filterGaleri && data.filterGaleri.length > 0) {
      params["q"] = data.filterGaleri;
    }

    const result = await ApiGetRequestCabdin(
      `/web/gallery?filter[type]=photo`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasi", {
        loadingGaleri: false,
      });
    } else {
      commit("changeIntegrasi", {
        listGaleriPhoto: result.data.data,
        totalGaleriPhoto: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loadingGaleri: false,
      });
    }
  },

  async getGaleriVideo({ commit, state }, payload) {
    commit("changeIntegrasi", {
      loadingGaleri: true,
    });

    if (payload) {
      await commit("changeIntegrasi", {
        page: payload || data.pageGaleri,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limitGaleri,
      sort: `-created_at`,
      key: data.keyGaleri,
    };

    if (data.filterGaleri && data.filterGaleri.length > 0) {
      params["q"] = data.filterGaleri;
    }

    const result = await ApiGetRequestCabdin(
      `/web/gallery?filter[type]=video`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasi", {
        loading: false,
      });
    } else {
      commit("changeIntegrasi", {
        listGaleriVideo: result.data.data,
        totalGaleri: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loadingGaleri: false,
      });
    }
  },

  async submitGaleri({ commit, state }) {
    commit("changeIntegrasi", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formDataGaleri.uuid !== ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }

    formData.append("name", data.formDataGaleri.name);
    formData.append("class", data.formDataGaleri.class);
    formData.append("theme", data.formDataGaleri.theme);
    formData.append("type", data.formDataGaleri.type);
    formData.append("description", data.formDataGaleri.description);
    if (data.formDataGaleri.type === `photo`) {
      console.log(`data.linkImage.length`, data.linkImage);
      if (data.linkImage.length > 0) {
        data.linkImage.forEach((item, index) => {
          console.log(`----item`, item);
          formData.append(`mediafiles[${index}]`, item);
        });
      }
    } else {
      // console.log(`data.formData.videos.length > 0`,data.formData.videosx);
      if (data.formDataGaleri.link.length > 0) {
        data.formDataGaleri.link.forEach((itemVideo, indexVideo) => {
          formData.append(`videos[${indexVideo}]`, itemVideo.value);
        });
      }
    }

    let result = "";
    if (data.formDataGaleri.uuid !== ``) {
      result = await ApiPostMultipartCabdin(
        `/web/gallery/${data.formDataGaleri.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/gallery`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeIntegrasi", {
        loading: false,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasi", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },

  async deleteGaleri({ commit }, payload) {
    commit("changeIntegrasi", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/gallery/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Galeri telah dihapus",
      });
      commit("changeIntegrasi", {
        loading: false,
      });

      return true;
    }
  },

  // async getDetailGaleri({ commit, state }, payload) {
  //   commit("changeIntegrasi", {
  //     loading: true,
  //   });

  //   if (payload) {
  //     await commit("changeIntegrasi", {
  //       page: payload || data.page,
  //     });
  //   }

  //   const { data } = state;

  //   let params = {
  //     limit: data.limit,
  //     sort: `-created_at`,
  //     key: data.key,
  //   };

  //   if (data.filter && data.filter.length > 0) {
  //     params["q"] = data.filter;
  //   }

  //   const result = await ApiGetRequest(
  //     `/api/web/school/${payload.uuid}/gallery?filter[type]=${payload.type}`,
  //     params
  //   );

  //   if (result.error) {
  //     Message({
  //       type: "error",
  //       message: result.error,
  //     });

  //     commit("changeIntegrasi", {
  //       loading: false,
  //     });
  //   } else {
  //     commit("changeIntegrasi", {
  //       detailGaleri: result.data.data,
  //       total: result.data.meta
  //         ? result.data.meta.total
  //         : result.data.data.length,
  //       loading: false,
  //     });
  //   }
  // },
  // ===============Galeri====================
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
