import {ApiGetRequest} from "@/utils/Api";
import moment from "moment";
import {Message} from "element-ui";

const state = {
	data: {
		error: false,
		loading: false,
		nameExcelOutput: "export-presensi-siswa",
		search: "",
		items: [{
			id: 1, nik_nuptk: 112233, nama_lengkap: "Miqbal Aulia Rafi", profesi: "Software Engineer",
		},],
		exportExcelJsonFieldStart: {
			'No': 'no',
			'Nama Siswa': 'name',
			'Kelas': 'class_name',
			'Jurusan': "major_name",
		},
		exportExcelJsonFieldEnd: {
			'Hadir': "h",
			'Izin': "i",
			'Alpha': "a",
		},
		exportExcelJsonFieldContent: {},
		exportExcelJsonFieldData: {},
		nameTextClass: null,
		nameTextMajor: null,
		export_fields: {},
		itemsExample: [],
		limit: 10,
		page: 1,
		total: 0,
		limitKelas: 10,
		pageKelas: 1,
		totalKelas: 0,
		majors: [],
		typefilter: "bulan",
		filter_class: "",
		filter_major: "",
		showmodalDetail: false,
		filterCetak: "bulan",
		filter_date: moment().format("YYYY-MM-DD"),
		current_year: moment().format("YYYY"),
		current_month: moment().format("MM"),
		current_date: moment().format("DD"),
		currentMonth: [],
		rolesSelected: "",
		tglActive: "",
		month: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember",],
		dataPresensiKelas: {
			tglAwal: "", tglAkhir: "",
		},
		columns_rekap_pelajar: [{
			prop: "nis_nisn",
			label: "NIS / NISN",
			sortable: true,
			minWidth: "100",
		}, {
			prop: "nama_lengkap",
			label: "Nama Lengkap",
			sortable: true,
			minWidth: "100",
		}, {
			prop: "kelas",
			label: "Kelas",
			sortable: false,
			minWidth: "100",
		}, {
			prop: "jurusan",
			label: "Jurusan",
			sortable: false,
			minWidth: "100",
		}, {
			fixed: "right",
			label: "Aksi",
			width: "100",
			"class-name": "body-14 col-black-2",
		},],
		columns_rekap_pelajar_kelas: [],
		dum: []
	},
};

const mutations = {
	changeRekapPresensiPelajar(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {
	async getRecapListStudent({commit, state}, payload) {
		commit("changeRekapPresensiPelajar", {
			loading: true,
		});

		if (payload) {
			await commit("changeRekapPresensiPelajar", {
				page: payload || data.page,
			});
		}

		const {data} = state;
		const params = {
			limit: data.limit,
			q: data.search,
			filter_class: data.filter_class,
			filter_major: data.filter_major,
			page: data.page,
		};

		const result = await ApiGetRequest(`school/klastime/attendance-recap-student`, params);

		if (result.error) {
			Message({
				type: "error", message: result.error,
			});
			commit("changeRekapPresensiPelajar", {
				loading: false,
			});

			return [];
		} else {
			await commit("changeRekapPresensiPelajar", {
				items: result.data.data, total: result.data.meta.total, loading: false,
			});
		}
	},
	async getJurusan({commit, state}, payload) {
		commit("changeRekapPresensiPelajar", {
			loading: true,
		});

		if (payload) {
			await commit("changeRekapPresensiPelajar", {
				page: payload || data.page,
			});
		}

		const {data} = state;

		const result = await ApiGetRequest(`school/major`);

		if (result.error) {
			Message({
				type: "error", message: result.error,
			});

			commit("changeRekapPresensiPelajar", {
				loading: false,
			});
		} else {
			commit("changeRekapPresensiPelajar", {
				majors: result.data.data, loading: false,
			});

			return {
				data: result.data.data,
			};
		}
	},
	async changeFormatDateStartEnd({commit, state}) {
		let { data } = state;
		await commit("changeRekapPresensiPelajar", {
			dataPresensiKelas: {
				tglAwal: moment(data.dataPresensiKelas.tglAwal).format("YYYY-MM-DD"),
				tglAkhir: moment(data.dataPresensiKelas.tglAkhir).format("YYYY-MM-DD"),
			}
		});
	},
	async handlerListExportExcel({commit,state},payload) {
		const {data} = state;
		const dataExpo = payload.dt;
		const objectFieldContent = {};
		dataExpo.map((item)=>{
			let dtLop = item.child;
			for (const childKey in item.child) {
				objectFieldContent[dtLop[childKey].prop] = dtLop[childKey].prop;
			}
		})
		await commit("changeRekapPresensiPelajar", {
			exportExcelJsonFieldData: {...data.exportExcelJsonFieldStart,...objectFieldContent},
		});
		console.log(data.exportExcelJsonFieldStart)
	},
	async getPresensiKelasRangeApi({commit, state, dispatch}) {
		await dispatch("changeFormatDateStartEnd");
		const {data} = state;
		await commit("changeRekapPresensiPelajar", {
			loading: true,
		});
		let aw = new Date(data.dataPresensiKelas.tglAwal);
		let ak = new Date(data.dataPresensiKelas.tglAkhir);
		let resDiff = moment(ak).diff(aw, 'days');
		if (resDiff < 0) {
			Message({
				type: 'info', message: 'Tanggal Akhir Harus Lebih Dari Tanggal Awal'
			});
			return false;
		}
		if (resDiff > 60) {
			Message({
				type: 'info', message: 'Batas Rekap 60 Hari'
			});
			return false;
		}
		let tglAwalFormat = moment(aw).format("DD-MM-YYYY");
		let tglAkhirFormat = moment(ak).format("DD-MM-YYYY");
		let nameClassMajor = data.nameTextClass+(data.nameTextMajor ? ` ${data.nameTextMajor}` : ``);
		await commit("changeRekapPresensiPelajar", {
			nameExcelOutput: `${data.nameExcelOutput}-(${nameClassMajor})-${tglAwalFormat}-${tglAkhirFormat}`,
		});
		const s = moment.isMoment(data.dataPresensiKelas.tglAwal) ? data.dataPresensiKelas.tglAwal : moment(data.dataPresensiKelas.tglAwal);
		const e = moment.isMoment(data.dataPresensiKelas.tglAkhir) ? data.dataPresensiKelas.tglAkhir : moment(data.dataPresensiKelas.tglAkhir);
		const params = {
			limit: data.limit,
			startDate: data.dataPresensiKelas.tglAwal,
			endDate: data.dataPresensiKelas.tglAkhir,
			class: data.filter_class,
		};
		if(data.filter_major){
			params.major = data.filter_major
		}
		if(data.search){
			params.name = data.search
		}

		const result = await ApiGetRequest(`school/klastime/attendance-report-student`, params);

		if (result.error) {
			Message({
				type: "error", message: result.error,
			});
			commit("changeRekapPresensiPelajar", {
				loading: false,
			});
			return [];
		}
		if (!result.data) {
			Message({
				type: "error", message: "Cors Error, tidak terhubung dengan server. Ulangi lagi",
			});
			commit("changeRekapPresensiPelajar", {
				loading: false,
			});
			return;
		}
		await commit("changeRekapPresensiPelajar", {
			loading: false,
			itemsExample: result.data.data,
			pageKelas: 1,
			limitKelas: result.data ? result.data.data.length : 0,
			totalKelas: result.data ? result.data.data.length : 0,
		});
		const columns_sem = [];
		const nameMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
		const saveMonth = [];
		let indexMonth = 0
		let resDiffRange = [...Array(1 + e.diff(s, 'days')).keys()].map(n => {
			let awa = moment(s).add(n, 'days');
			let dateFull = awa.format("YYYY-MM-DD");
			let dtTanggal = {
				prop: dateFull,
				label: `${awa.get('date')}`,
				width: 50,
			}
			if (saveMonth.includes(awa.get('month'))) {
				columns_sem[(indexMonth - 1)].child.push(dtTanggal)
			} else {
				columns_sem.push({
					label: `${nameMonth[awa.get('month')]}`, child: [dtTanggal]
				})
				saveMonth.push(awa.get('month'))
				indexMonth++
			}
		});
		let dtRekap = [{
			prop: `h`, label: `H`, width: 50,
		}, {
			prop: `i`, label: `I`, width: 50,
		}, {
			prop: `a`, label: `A`, width: 50,
		},];
		columns_sem.push({
			label: `Rekap`,
			child: dtRekap
		});
		dispatch("handlerListExportExcel",{dt:columns_sem});
		commit("changeRekapPresensiPelajar", {
			loading: false,
			typefilter: "kelas",
			columns_rekap_pelajar_kelas: columns_sem,
			dum: resDiffRange,
		});
	},
};

export default {
	namespaced: true, state, mutations, actions,
};
