import {
  ApiNotification,
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "../../../utils/Api";
import moment from "moment";
import { Message } from "element-ui";
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";

const Klaspay = {
  namespaced: true,
  state: {
    data: {
			loadingWallet: true,
			reqWalletError: false,
      loading: true,
      wallet: {},
      channel: {},
      idTransaksi: "",
      chooseChannel: {},
      nominalValue: 0,
      showFormTopup: false,
      nominal: [
        {
          label: "Rp. 25.000,-",
          value: 25000,
        },
        {
          label: "Rp. 50.000,-",
          value: 50000,
        },
        {
          label: "Rp. 100.000,-",
          value: 100000,
        },
        {
          label: "Rp. 500.000,-",
          value: 500000,
        },
      ],
      trxCheckout: {},
      mutasiKlaspay: [],
      historyMutasi: [],
      snapShootKlaspay: [],
      snapShootKlaspayToday: {},
      bank: [],
      detail_bank: false,
      saldoBalance: 0,
      tarik_saldo: [],
      filter_tarik_saldo: "",
      filter_bank_tarik_saldo: "",
      limit_tarik_saldo: 10,
      page_tarik_saldo: 1,
      total_tarik_saldo: 0,
      columns_topup: [],
      columns_topup_pelajar: [
        {
          prop: "select",
          label: "Check",
          type: "selection",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nisn_nik",
          label: "NIS / NISN",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "class_room",
          label: "Kelas",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "major",
          label: "Jurusan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },

        {
          prop: "wallet_id",
          label: "ID Wallet",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
      ],
      columns_rekening: [
        {
          prop: "name",
          label: "Bank",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "bank_account_number",
          label: "No Rekening",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "bank_account_holder",
          label: "Nama Pemegang Rekening",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "aksi",
          label: "Aksi",
          minWidth: "50",
          "class-name": "body-14 col-black-2",
        },
      ],
      limit_rekening: 10,
      page_rekening: 1,
      total_rekening: 0,
      filter_rekening: "",
      columns_tarik_saldo: [
        {
          prop: "updated_at",
          label: "Tanggal",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "cooperative.name",
          label: "Nama",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "bank_account_number",
          label: "Keterangan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "bank_name",
          label: "Keterangan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "amount",
          label: "Nominal",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
      ],
      columns_guru: [
        {
          prop: "select",
          label: "Check",
          type: "selection",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
          sortable: true,
        },
        {
          prop: "nisn_nik",
          label: "NIK",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
          sortable: true,
        },
        {
          prop: "role",
          label: "Profesi",
          minWidth: "150",
          "class-name": "body-14 col-black-2",
          sortable: true,
        },
        {
          prop: "wallet_id",
          label: "ID Wallet",
          width: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "aksi",
          label: "Aksi",
          minWidth: "50",
          "class-name": "body-14 col-black-2",
        },
      ],
      columns_pelajar: [
        {
          prop: "select",
          label: "Check",
          type: "selection",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nisn_nik",
          label: "NIS / NISN",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "class_room",
          label: "Kelas",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "major",
          label: "Jurusan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "generation",
          label: "Angkatan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "wallet_id",
          label: "ID Wallet",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "aksi",
          label: "Aksi",
          minWidth: "50",
          "class-name": "body-14 col-black-2",
        },
      ],
      column_preview_pelajar: [
        {
          prop: "name",
          label: "Nama",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nisn_nik",
          label: "NIS / NISN",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "class_room",
          label: "Kelas",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "major",
          label: "Jurusan",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "wallet_id",
          label: "ID Wallet",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "aksi",
          label: "Aksi",
          minWidth: "50",
          "class-name": "body-14 col-black-2",
        },
      ],
      columns_saldo: [
        {
          prop: "expand",
          label: "",
          type: "expand",
        },
        {
          prop: "transaction_id",
          label: "ID Trx",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "status",
          label: "Status",
          minWidth: "130",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "transaction_type",
          label: "Tipe",
          minWidth: "120",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "transaction_to_desc",
          label: "Deskripsi",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nominal",
          label: "Nominal",
          minWidth: "100",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "transaction_from",
          label: "Dari",
          minWidth: "90",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "transaction_to",
          label: "Ke",
          minWidth: "90",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "cashwallet_id",
          label: "ID Wallet",
          minWidth: "90",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "created_date",
          label: "Waktu",
          minWidth: "140",
          "class-name": "body-14 col-black-2",
        },
      ],
      limit_students: 10,
      page_students: 1,
      total_students: 0,
      total_students_topup: 0,
      search_students: "",
      students: [],
      limit_users: 10,
      page_users: 1,
      search_users: "",
      total_users: 0,
      users: [],
      userSelect: [],
      amountIsiSaldo: 0,
      dateStart: "",
      dateEnd: "",
      filterStatus: "",
      filterTipe: "",
      roles: [],
      filterroles: "Teacher",
      classrooms: [],
      filterclasses: 0,
      page_history: 1,
      limit_history: 10,
      search_history: "",
      total_history: 0,
      cashwallet_id: "",
      transactions: [],
      transaction_detail: [],
    },
  },
  mutations: {
    changeKlaspay(state, payload) {
      state.data = Object.assign({}, state.data, payload);
    },
		changeKlaspayTarikSaldoLoading(state, payload) {
			state.data.tarik_saldo_loading = payload;
		},
		changeKlaspayUserSelectReset(state) {
			state.data.userSelect = [];
		},
		changeKlaspayLoading(state, payload) {
			state.data.loading = payload;
		},
  },
  actions: {
    async getDataRfid({ commit }, payload) {
      commit("changeKlaspay", { loading: true, error: false });

      let formData = {
        rfid: payload.rfid,
      };

      const getResult = await ApiPostRequest(
        `school/cooperative/user/scan-rfid`,
        formData
      );
      if (getResult.error) {
        Message({
          type: "error",
          message: "Gagal menampilkan data.",
        });

        commit("changeKlaspay", {
          error: getResult.errorList || false,
          loading: false,
        });

        return false;
      } else {
        commit("changeKlaspay", {
          loading: false,
          columns_topup: getResult.data.data,
          total_students_topup: getResult.data.data.length,
          error: false,
        });

        Message({
          type: "success",
          message: "Data berhasil ditampilkan",
        });

        return true;
      }
    },
    async getRoles({ commit }) {
      const getResult = await ApiGetRequest(`school/user/roles`);

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification(
          "error",
          "Gagal menampilkan data, mohon mencoba kembali"
        );
      } else {
        commit("changeKlaspay", {
          loading: false,
          roles: getResult.data.data,
        });
      }
    },
    async getClassRoom({ commit }) {
      const getResult = await ApiGetRequest(`school/class`);
      if (getResult.error) {
        ApiNotification(
          "error",
          "Gagal menampilkan data, mohon mencoba kembali"
        );

        commit("changeKlaspay", {
          loading: false,
        });
      } else {
        commit("changeKlaspay", {
          loading: false,
          classrooms: getResult.data.data,
        });
      }
    },
    async getDetailWallet({ commit }) {
      commit("changeKlaspay", {
        loadingWallet: true,
				reqWalletError: false,
      });

      const getResult = await ApiGetRequest(`klaspay/wallet`);
      if (getResult.error) {
        commit("changeKlaspay", {
					reqWalletError: true,
					loadingWallet: false,
        });

				let message = "Gagal mengambil data, mohon coba kembali"
				if (getResult.code >= 500) {
					message = SERVER_ERROR_MESSAGE
				}

        ApiNotification("error", message);
      } else {
        commit("changeKlaspay", {
					loadingWallet: false,
          wallet: getResult.data.data || [],
        });
      }
    },
    async getListBank({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`school/bank`);
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          bank: getResult.data.data || [],
        });
      }
    },
    async getAccountBank({ commit, state }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      let params = {
        limit: data.limit_rekening,
        page: data.page_rekening,
        q: data.filter_rekening,
      };

      const getResult = await ApiGetRequest(
        `school/cooperative/bank-account`,
        params
      );
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          account_bank: getResult.data.data || [],
          total_rekening:
            (getResult.data.meta && getResult.data.meta.total) || 0,
        });
      }
    },
    async getDetailAccountBank({ commit }, bank_id = null) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(
        `school/cooperative/bank-account/${bank_id}`
      );
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          detail_bank: getResult.data.data || false,
        });
      }
    },
    async hapusAccountBank({ commit }, bank_id = "") {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiDeleteRequest(
        `school/cooperative/bank-account/${bank_id}`
      );

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal menghapus data rekening");
      } else {
        commit("changeKlaspay", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Berhasil menghapus data rekening");
      }
    },
    async updateAccountBank({ commit, state }, dataPost = {}) {
      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      const getResult = await ApiPutRequest(
        `school/cooperative/bank-account/${
          data.detail_bank && data.detail_bank.id
        }`,
        dataPost
      );

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal mengubah no rekening baru");
      } else {
        commit("changeKlaspay", {
          loading: false,
          detail_bank: false,
          error: false,
        });

        ApiNotification("success", "No rekening berhasil diubah");
      }
    },
    async createAccountBank({ commit }, dataPost = {}) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiPostRequest(
        `school/cooperative/bank-account`,
        dataPost
      );

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal menambahkan no rekening baru");
      } else {
        commit("changeKlaspay", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "No rekening baru telah ditambahkan");
      }
    },
    async getBalance({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`school/cooperative/balance`);

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal menambahkan no rekening baru");
      } else {
        commit("changeKlaspay", {
          loading: false,
          saldoBalance: getResult.data.data,
        });
      }
    },
    async getListTarikSaldo({ commit, state }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      let params = {
        q: data.filter_tarik_saldo || "",
        limit: data.limit_tarik_saldo || 10,
        page: data.page_tarik_saldo || "",
        bank: data.filter_bank_tarik_saldo || "",
        tglawal:
          data.dateStart &&
          data.dateStart !== "" &&
          moment(data.dateStart).format("YYYY-MM-DD"),
        tglakhir:
          data.dateEnd &&
          data.dateEnd !== "" &&
          moment(data.dateEnd).format("YYYY-MM-DD"),
      };

      const getResult = await ApiGetRequest(
        `school/cooperative/withdraw`,
        params
      );

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal menambahkan no rekening baru");
      } else {
        commit("changeKlaspay", {
          loading: false,
          tarik_saldo: getResult.data.data,
          total_tarik_saldo: getResult.data.meta.total,
        });
      }
    },
    async createTarikSaldo({ commit }, dataPost = {}) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiPostRequest(
        `school/cooperative/withdraw`,
        dataPost
      );

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          error: false,
        });

        ApiNotification("success", "Data tarik saldo berhasil ditambahkan");
      }
    },
    async getChannel({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/biller/getToolbar`);
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          channel: (getResult.data.data && getResult.data.data.products) || [],
        });
      }
    },
    async topUpCheckout({ commit, state }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;

      const getResult = await ApiPostRequest(`klaspay/transaction/topup_inq`, {
        nominal: data.nominalValue,
        biller: 1,
        channel: data.chooseChannel && data.chooseChannel.payment_code,
      });

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
      } else {
        const getSubmit = await ApiPostRequest(
          `klaspay/transaction/topup_trx`,
          {
            channel: data.chooseChannel && data.chooseChannel.payment_code,
            biller: 1,
            transaction_id: getResult.data.data.transaction_id_inquiry,
          }
        );

        if (getSubmit.error) {
          commit("changeKlaspay", {
            loading: false,
            error: getResult.errorList || getResult.error,
          });

          ApiNotification(
            "error",
            "Gagal melakukan Top Up, mohon coba kembali"
          );
        } else {
          commit("changeKlaspay", {
            error: false,
            trxCheckout: getSubmit.data.data,
            loading: false,
          });
        }
      }
    },
    async getHistoryMutasi({ commit, state }, payload = null) {
      commit("changeKlaspay", {
        loading: true,
      });

      console.log(`---payload`, payload);
      if (payload) {
        commit("changeKlaspay", {
          page_history: payload || state.data.page_history,
        });
      }

      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;

      let params = {
        limit: data.limit_history,
        page: data.page_history,
        search: data.search_history,
        status: parseInt(data.filterStatus) || 0,
        tipe: data.filterTipe || "",
      };

      if (
        data.dateStart &&
        data.dateStart !== "" &&
        data.dateEnd &&
        data.dateEnd !== ""
      ) {
        params.date_from = moment(data.dateStart).format("YYYY-MM-DD");
        params.date_to = moment(data.dateEnd).format("YYYY-MM-DD");
      }

      const getResult = await ApiPostRequest(
        `klaspay/koperasi/saldo/transaction_history`,
        params
      );
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal menampilkan history kas klaspay");
        return false;
      } else {
        const dataPembukuan = getResult.data.data;
        commit("changeKlaspay", {
          loading: false,
          cashwallet_id: dataPembukuan.wallet_id || "",
          transactions: dataPembukuan.transactions || [],
          transaction_detail: dataPembukuan.transaction_detail || [],
          total_history: dataPembukuan.meta.total || 0,
        });

        return true;
      }
    },
    async getMutasiKlaspay({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/koperasi/saldo/mutasi`);
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          mutasiKlaspay:
            (getResult.data.data && getResult.data.data.mutasi) || [],
        });
      }
    },
    async getSnapShootToday({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/koperasi/snapshot/today`);
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          snapShootKlaspayToday:
            (getResult.data.data && getResult.data.data.snapshot) || {},
        });
      }
    },
    async getSnapShoot({ commit }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`klaspay/koperasi/snapshot/all`);
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          snapShootKlaspay:
            (getResult.data.data && getResult.data.data.snapshots) || [],
        });
      }
    },
    async getDetailStudent({ commit }, id = null) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`school/student/${id}`);

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          infoUser: getResult.data.data,
        });
      }
    },
    async getListStudent({ commit, state }, page = null) {
      if (page) {
        commit("changeKlaspay", {
          page_students: page,
        });
      }

      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      let params = {
        page: data.page_students,
        limit: data.limit_students,
        q: data.search_students,
      };

      if (data.filterclasses) {
        params.class_id = data.filterclasses
      }

      const getResult = await ApiGetRequest(
        `school/cooperative/user/student`,
        params
      );
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          students: getResult.data.data,
          total_students: getResult.data.meta.total,
        });
      }
    },
    async getDetailUser({ commit }, id = null) {
      commit("changeKlaspay", {
        loading: true,
      });

      const getResult = await ApiGetRequest(`school/user/${id}`);

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          infoUser: getResult.data.data,
        });
      }
    },
    async getListUser({ commit, state }, page = null) {
      if (page) {
        commit("changeKlaspay", {
          page_users: page,
        });
      }

      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      let params = {
        page: data.page_users,
        limit: data.limit_users,
        q: data.search_users,
      };

      if (data.filterroles) {
        params.roles = data.filterroles;
      }

      const getResult = await ApiGetRequest(
        `school/cooperative/user/teacher`,
        params
      );
      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
        });

        ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          users: getResult.data.data,
          total_users: getResult.data.meta.total,
        });
      }
    },
    async kirimSaldo({ commit, state }) {
      commit("changeKlaspay", {
        loading: true,
      });

      const { data } = state;
      const wallet_select = [];
      if (data.userSelect && data.userSelect.length > 0) {
        data.userSelect.map((item) => wallet_select.push(item.wallet_id));
      }

      // old url klaspay/transaction/topup_inq
      const getResult = await ApiPostRequest(`klaspay/koperasi/saldo/topup`, {
        wallet_ids: wallet_select,
        nominal: data.amountIsiSaldo,
      });

      if (getResult.error) {
        commit("changeKlaspay", {
          loading: false,
          error: getResult.errorList || getResult.error,
        });

        ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
      } else {
        commit("changeKlaspay", {
          loading: false,
          amountIsiSaldo: 0,
          userSelect: [],
        });
        console.log(
          `-----wallet`,
          getResult.data.rd.toLowerCase().indexOf("wallet")
        );

        if (getResult.data.rc === `500`) {
          if (getResult.data.rd.toLowerCase().indexOf("range") !== -1) {
            ApiNotification(
              "error",
              "Mohon hubungi Admin Onklas. Terimakasih"
            );
              //"Mohon melakukan pengisian dibawah nominal Rp.1.000.000."
          } else if (getResult.data.rd.toLowerCase().indexOf("dana") !== -1) {
            ApiNotification("error", "Mohon maaf saldo anda tidak mencukupi.");
          } else if (getResult.data.rd.toLowerCase().indexOf("wallet") !== -1) {
            ApiNotification(
              "error",
              "Mohon melakukan aktivasi klaspay pada akun tersebut."
            );
          } else {
            ApiNotification(
              "error",
              "Mohon melakukan aktivasi klaspay pada akun tersebut."
            );
          }

          data.showFormTopup = true;
        } else if (getResult.data.rc === `00`) {
          ApiNotification("success", getResult.data.rd);
          data.showFormTopup = false;
        }
      }
    },
  },
};

export default Klaspay;
