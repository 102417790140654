// import {
//   ApiNotification,
//   ApiGetRequest,
//   ApiPostRequest,
//   ApiPutRequest,
//   ApiDeleteRequest,
// } from "../../../utils/Api";
// import moment from "moment";
// import { Message } from "element-ui";

const state = {
  data: {
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    errors: [],
    columnsDaftarKantin: [
      {
        prop: "nama_kantin",
        label: "Nama Kantin",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pemilik",
        label: "Pemilik",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "no_telp",
        label: "No Telp",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "alamat",
        label: "Alamat",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tgl_mulai",
        label: "Tanggal Mulai",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tgl_selesai",
        label: "Tanggal Selesai",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
    ],
    formDaftarKantin: {
      namaKantin: "",
      pemilik: "",
      noTelp: "",
      alamat: "",
      startDate: null,
      endDate: null,
    },
  },
};

const mutations = {
  changeKoperasiKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
