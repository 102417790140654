import { ApiGetRequest } from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    cities: [],
    menu: [
      {
        name: 'Klasbisnis',
        label: 'Klasbisnis',
        lock: false,
        url: '/klasbisnis',
      },
      {
        name: 'Klasmart',
        label: 'Klasmart',
        lock: true,
        url: '/klasbisnis/klasmart',
      },
      {
        name: 'Etalase',
        label: 'Etalase',
        lock: true,
        url: '/klasbisnis/etalase',
      },
      {
        name: 'PengecualianKerjasama',
        label: 'Pengecualian Kerja Sama',
        lock: false,
        url: '/klasbisnis/pengecualian-kerjasama',
      },
    ],
    columns: [
      {
        prop: 'school',
        label: 'Nama Sekolah',
        minWidth: '120',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'addresss',
        label: 'Alamat',
        minWidth: '100',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'phone',
        label: 'No. Telpon',
        minWidth: '120',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'email',
        label: 'Email',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '100',
        'class-name': 'body-14 col-black-2',
      },
    ],
    items: [
      {
        nis_nik: '1011874144 / 34323112',
        nama: 'Vania Sisca Wulandari',
        namaToko: 'Sisca Jewelry',
        product: 'Kaos Polos Katun Size M Warna Hijau Eiger',
        stock: 100,
        price: 35000,
        category: 'Fashion Pria',
        subcategory: 'Kaos',
        classes: 'X A',
        sale: 4,
        produk: '4',
        jasa: '2',
        rating: '3.5',
        klaspay_id: '34112506',
        image: 'produk.jpg',
        status: true,
        date_transaction: '2020-05-11 12:46',
        code: 'TRX0019928SDT',
        nuptk: '827476616617',
        total: 2,
        review: 2,
        tipe: 'Produk',
        school: 'SMA DAPEDAN 2 SURABAYA',
        statusReport: 'Keluhan',
        status_delivery: 'Paket diterima',
        total_price: 185000,
        buyer: 'Vania Sisca Wulandarii',
        idtransaction: 'OKL12456',
        id: 1,
      },
      {
        nis_nik: '1011874144 / 34323112',
        nama: 'Ovilia Lindasari Agustina',
        namaToko: 'Ifar Maniacc Stores',
        product: 'Kaos Polos Katun Size M Warna Hijau Eiger',
        stock: 100,
        price: 35000,
        sale: 4,
        category: 'Fashion Pria',
        subcategory: 'Kaos',
        classes: 'X B',
        produk: '2',
        jasa: '2',
        rating: '3.5',
        klaspay_id: '34112506',
        image: 'produk.jpg',
        date_transaction: '2020-05-11 12:46',
        code: 'TRX0019928SDT',
        nuptk: '827476616617',
        status: false,
        total: 2,
        review: 2,
        tipe: 'Jasa',
        school: 'SMA Trimurti Surabaya',
        statusReport: 'Keluhan',
        status_delivery: 'Menunggu konfirmasi',
        total_price: 185000,
        buyer: 'Vania Sisca Wulandarii',
        idtransaction: 'OKL12456',
        id: 2,
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: '',
    sortType: '',
    loading: false,
    error: false,
  },
};

const mutations = {
  changeKerjaSama(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getCities({ commit }, province_id = null) {
    if (province_id && province_id !== '') {
      const result = await ApiGetRequest(
        `school/city?province_id=${province_id}`
      );
      if (result.error) {
        Message({
          type: 'error',
          message: result.error,
        });
      } else {
        commit('changeKerjaSama', {
          cities: result.data.data,
        });

        return result.data.data;
      }
    } else {
      return [];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
