const state = {
  data: {
    activeTab: "Token",
    Tab: [
      { name: "Token", active: true },
      { name: "Tamu", active: false },
      { name: "Izin", active: false },
    ],
  },
};

const mutations = {
  changeKlassign(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
