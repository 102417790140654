import { ApiGetRequestV2, ApiPostRequestV2 } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    produkOnklasList: [
      {
        name: "PAKET 1",
        showInfo: false,
        installed: false,
        monthlyPrice: "333.000",
        monthlyPriceInt: "333000",
        yearlyPrice: "3.996 jt",
        yearlyPriceInt: "3996000",
        icon: "onklas_free.svg",
        colorBadge: "#2C51B0",
        fitures: [
          {
            label: "API integration up to 250 data user",
            checkedIcon: true,
          },
          {
            label: "Dedicated Acces",
            checkedIcon: true,
          },
          {
            label: "Cloud server management services",
            checkedIcon: true,
          },
          {
            label: "storage server 5GB",
            checkedIcon: true,
          },
          {
            label: "Kelola sosial Media Sekolah dan e-book",
            checkedIcon: true,
          },
          {
            label: "Kelola data guru dan karyawan",
            checkedIcon: true,
          },
          {
            label: "Kelola data pelajar",
            checkedIcon: true,
          },
          {
            label: "Kelola mapel, jadwal dan jurusan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi siswa, guru, karyawan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi jurnal KBM",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Kantin sekolah digital",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Pembayaran Uang sekolah",
            checkedIcon: true,
          },

        ],
        product: [],
        available: true,
        pending: false,
      },
      {
        name: "PAKET 2",
        showInfo: false,
        installed: false,
        available: false,
        pending: false,
        monthlyPrice: "555.000",
        monthlyPriceInt: "555000",
        yearlyPrice: "6.660 jt",
        yearlyPriceInt: "6660000",
        icon: "onklas_free.svg",
        colorBadge: "#2C51B0",
        fitures: [
          {
            label: "API integration up to 500 data user",
            checkedIcon: true,
          },
          {
            label: "Dedicated Acces",
            checkedIcon: true,
          },
          {
            label: "Cloud server management services",
            checkedIcon: true,
          },
          {
            label: "storage server 10GB",
            checkedIcon: true,
          },
          {
            label: "Kelola sosial Media Sekolah dan e-book",
            checkedIcon: true,
          },
          {
            label: "Kelola data guru dan karyawan",
            checkedIcon: true,
          },
          {
            label: "Kelola data pelajar",
            checkedIcon: true,
          },
          {
            label: "Kelola mapel, jadwal dan jurusan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi siswa, guru, karyawan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi jurnal KBM",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Kantin sekolah digital",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Pembayaran Uang sekolah",
            checkedIcon: true,
          },

        ],
        product: [],
      },
      {
        name: "PAKET 3",
        showInfo: false,
        installed: false,
        monthlyPrice: "777.000",
        monthlyPriceInt: "777000",
        yearlyPrice: "9.324 jt",
        yearlyPriceInt: "9324000",
        icon: "onklas_free.svg",
        colorBadge: "#2C51B0",
        fitures: [
          {
            label: "API integration up to 1000 data user",
            checkedIcon: true,
          },
          {
            label: "Dedicated Acces",
            checkedIcon: true,
          },
          {
            label: "Cloud server management services",
            checkedIcon: true,
          },
          {
            label: "storage server 20GB",
            checkedIcon: true,
          },
          {
            label: "Onklas Learning Management System",
            checkedIcon: true,
          },
          {
            label: "Kelola data guru dan karyawan",
            checkedIcon: true,
          },
          {
            label: "Kelola data pelajar",
            checkedIcon: true,
          },
          {
            label: "Kelola mapel, jadwal dan jurusan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi siswa, guru, karyawan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi jurnal KBM",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Kantin sekolah digital",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Pembayaran Uang sekolah",
            checkedIcon: true,
          },
        ],
        product: [],
        available: true,
        pending: false,
      },
      {
        name: "PAKET 4",
        showInfo: false,
        installed: false,
        monthlyPrice: "1.110.000",
        monthlyPriceInt: "1110000",
        yearlyPrice: "13.320 jt",
        yearlyPriceInt: "13320000",
        icon: "onklas_free.svg",
        colorBadge: "#2C51B0",
        fitures: [
          {
            label: "API integration unlimited data user",
            checkedIcon: true,
          },
          {
            label: "Dedicated Acces",
            checkedIcon: true,
          },
          {
            label: "Cloud server management services",
            checkedIcon: true,
          },
          {
            label: "storage server 30GB",
            checkedIcon: true,
          },
          {
            label: "Onklas Learning Management System",
            checkedIcon: true,
          },
          {
            label: "Kelola data guru dan karyawan",
            checkedIcon: true,
          },
          {
            label: "Kelola data pelajar",
            checkedIcon: true,
          },
          {
            label: "Kelola mapel, jadwal dan jurusan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi siswa, guru, karyawan",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Presensi jurnal KBM",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Kantin sekolah digital",
            checkedIcon: true,
          },
          {
            label: "Aplikasi Pembayaran Uang sekolah",
            checkedIcon: true,
          },

        ],
        product: [],
        available: true,
        pending: false,
      },
    ],
    listVirtualAccount: [],
    formPayment: {
      nominal: 0,
      cycle: "bulan",
      payment_method: "",
    },
    detailPayment: null,
    errors: [],
    loading: false,
  },
};

const mutations = {
  changePayment(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListPayment({ commit }) {
    let result = await ApiGetRequestV2(
      `school/package-payment/channel`
    );

    if (result.error) {
      if ((result.error && result.error.response) ? result.error.response.status : 400 == 404) {
        Message({
          type: "error",
          message: "Metode pembayaran bermasalah, Tunggu beberapa saat lagi",
        });
        setTimeout(()=>{
          // window.location = "/onklas/produk"
        },1800)
      }else{
        Message({
          type: "error",
          message: result.error,
        });
      }

      commit("changePayment", {
        loading: false,
      });
      return false;
    }

    commit("changePayment", {
      listVirtualAccount: result.data || [],
    });
    return true;
  },

  async submitCreatePayment({ commit, state }) {
    const { data } = state;
    if (data.formPayment.nominal == 0 || data.formPayment.payment_method == ""){
      Message({
        type: "error",
        message: "Permintaan Kurang lengkap, Harap ulangi lagi!",
      });
      return false;
    }

    let result = await ApiPostRequestV2(
      `school/package-payment/create-va`,
      data.formPayment
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changePayment", {
          errors: errors,
        });
      }
      return false
    }
      Message({
        type: "success",
        message: "Form pengajuan telah terkirim.",
      });
      commit("changePayment", {
        detailPayment: result.data || [],
      });
      return true

  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
