import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Mata Pelajaran",
    columns_table: [
      {
        prop: "icon_image",
        label: "Icon",
        width: "70",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Mata Pelajaran",
        minWidth: 200,
        "class-name": "body-14 col-black-2",
        sortable: true
      },
      {
        prop: "status",
        label: "Status",
        width: "150",
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    errors: [],
    currentStatus: "all",
    loading: false,
    form: {
      id: "",
      name: "",
      icon_image: "",
      subject_global_id: null
    },
    formRules: {
      name: [
        {
          required: true,
          message: "Nama Mapel tidak boleh kosong",
          trigger: "blur"
        }
      ],
      icon_image: [
        { required: true, message: "Icon tidak boleh kosong", trigger: "blur" }
      ]
    },
    subjectList: []
  }
};

const mutations = {
  changeMapel(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async getMapel({ commit, state }, payload) {
    commit("changeMapel", {
      loading: true
    });

    const { data } = state;

    if (payload && typeof payload !== "object") {
      await commit("changeMapel", {
        page: payload || data.page
      });
    } else {
      await commit("changeMapel", {
        page: (payload && payload.page) ?? data.page
      });
    }

    const params = {
      limit: data.limit,
      page: data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter
    };
    if (payload && typeof payload == "object") {
      if (payload.wherepage) params.wherepage = "exam";
    }

    const result = await ApiGetRequest(`school/subject`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeMapel", {
        loading: false
      });

      return [];
    } else {
      await commit("changeMapel", {
        items: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false
      });
    }
  },

  async getMapelMaster({ commit }) {
    commit("changeMapel", {
      loading: true
    });

    const result = await ApiGetRequest(`school/subject/icon`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeMapel", {
        loading: false
      });

      return [];
    } else {
      await commit("changeMapel", {
        subjectList: result.data.data,
        loading: false
      });
    }
  },

  async submitMapel({ commit, state }) {
    commit("changeMapel", {
      loading: true
    });

    const { data } = state;

    let result = "";
    // let body = {
    //     name: data.form.name,
    //     icon_image: data.form.icon_image,
    // };
    let body = {
      subject_global_id: data.form.subject_global_id
    };

    if (data.form.id != "") {
      // result = await ApiPutRequest(`school/subject/${data.form.id}`, body);
      result = await ApiPutRequest(
        `school/subject/store-from-subject-global/${data.form.id}`,
        body
      );
    } else {
      // result = await ApiPostRequest(`school/subject`, body);
      result = await ApiPostRequest(
        `school/subject/store-from-subject-global`,
        body
      );
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeMapel", {
          errors: errors
        });
      }
    } else {
      commit("changeMapel", {
        errors: []
      });

      return true;
    }
  },

  async deleteMapel({ commit }, payload) {
    let endPoint = "school/subject";

    commit("changeMapel", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(`${endPoint}/${payload.id}`);

      if (result.error) {
        Message({
          type: "error",
          message: result.errorList.subject[0]
        });

        commit("changeMapel", {
          loading: false
        });
      } else {
        Message({
          type: "success",
          message: "Data telah dihapus."
        });

        commit("changeMapel", {
          loading: false
        });
      }
    }
  },

  async activeMapel({ commit }, payload) {
    commit("changeMapel", {
      loading: true
    });

    if (payload) {
      await commit("changeMapel", {
        page: payload || data.page
      });
    }

    const { data } = state;

    const result = await ApiPostRequest(`school/subject/${payload.id}/restore`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeMapel", {
        loading: false
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Mata pelajaran telah diaktifkan kembali"
      });
      commit("changeMapel", {
        loading: false
      });
      return true;
    }
  },

  async inactiveMapel({ commit, state }, payload) {
    commit("changeMapel", {
      loading: true
    });

    if (payload) {
      await commit("changeMapel", {
        page: payload || data.page
      });
    }

    const { data } = state;

    const result = await ApiDeleteRequest(
      `school/subject/${payload.id}/deactivate`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.errorList.subject[0]
      });

      commit("changeMapel", {
        loading: false
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Mata pelajaran telah dinonaktifkan"
      });
      commit("changeMapel", {
        loading: false
      });
      return true;
    }
  },

  async getGlobalMapel({ commit }) {
    commit("changeMapel", {
      loading: true
    });

    const result = await ApiGetRequest(`school/subject-global`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeMapel", {
        loading: false
      });

      return [];
    } else {
      await commit("changeMapel", {
        subjectList: result.data.data,
        loading: false
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
