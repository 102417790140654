import {
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "jenis_kelamin",
      //   label: "Jenis Kelamin",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        prop: "kelas",
        label: "Kelas",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jenis_lomba",
        label: "Lomba",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "capaian_prestasi",
        label: "Prestasi",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "jenis_penghargaan",
      //   label: "Penghargaan",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        prop: "tingkat",
        label: "Tingkat",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "penyelenggara",
      //   label: "Penyelenggara",
      //   "class-name": "body-14 col-black-2",
      // },
      // {
      //   prop: "lokasi",
      //   label: "Lokasi",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        prop: "tahun_pelaksanaan",
        label: "Tahun ",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "keterangan",
      //   label: "Keterangan",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        fixed: "right",
        label: "Aksi",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    filter: "",
    q: "",
    loading: false,
    litPrestasiSiswa: [],
    listExportSiswa: [],
    jsonData: [],
    detailImport: {},
    detailPrestasiSiswa: {},
    formData: {
      uuid: "",
      name: "",
      gender: "",
      class: "",
      type: "",
      rank: "",
      reward: "",
      level: "",
      organizer: "",
      place: "",
      year: "",
      description: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changeIntegrasiPrestasiSiswa(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getPrestasiSiswa({ commit, state }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    const { data } = state;

    if(payload){
      data.page = payload
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(
      `/web/student-achievement`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPrestasiSiswa", {
        litPrestasiSiswa: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async submitPrestasi({ commit, state }) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formData.uuid !== ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }

    formData.append("name", data.formData.name);
    formData.append("gender", data.formData.gender);
    formData.append("class", data.formData.class);
    formData.append("type", data.formData.type);
    formData.append("rank", data.formData.rank);
    formData.append("reward", data.formData.reward);
    formData.append("level", data.formData.level);
    formData.append("organizer", data.formData.organizer);
    formData.append("place", data.formData.place);
    formData.append("year", data.formData.year);
    formData.append("description", data.formData.description);

    if (data.formData.file != "string" && data.formData.file != null) {
      formData.append("mediafile", data.formData.file);
    }

    let result = "";

    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/student-achievement/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(
        `/web/student-achievement`,
        formData
      );
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiPrestasiSiswa", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },

  async deletePrestasiSiswa({ commit, state }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(
      `/web/student-achievement/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Prestasi Siswa telah dihapus",
      });
      const {data} = state;
      let page = 1;
      for (let i = 10; i <= 500; i += 10) {
        if ((data.total-1)==i){
          commit("changeIntegrasiPrestasiSiswa", {
            page: page
          });
          break;
        }
        page++;
      }
      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });

      return true;
    }
  },
  async getPrestasiSiswaById({ commit }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(
      `/web/student-achievement/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
        detailPrestasiSiswa: result.data.data,
      });
    }
  },
  async getExportDataSiswa({ commit, state }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPrestasiSiswa", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/student-achievement/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPrestasiSiswa", {
        listExportSiswa: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelPrestasiSiswa({ commit }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "student_achievements": payload.formData
    }
    type = "student-achievement"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiPrestasiSiswa", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
