import {
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from '@/utils/Api';
import { Message } from 'element-ui';
import moment from "moment";
import Vue from "vue";

const state = {
  data: {
    labelMenu: 'Jadwal Pelajaran',
    activeDay: 'Senin',
    days: [
      {
        name: 'Senin',
        active: false,
      },
      {
        name: 'Selasa',
        active: false,
      },
      {
        name: 'Rabu',
        active: false,
      },
      {
        name: 'Kamis',
        active: false,
      },
      {
        name: 'Jumat',
        active: false,
      },
      {
        name: 'Sabtu',
        active: false,
      },
      {
        name: 'Minggu',
        active: false,
      },
    ],
    columns: [
      {
        prop: "classroom",
        label: "Kelas / Jurusan",
        minWidth: "200",
        "class-name": "body-14 col-black-2 align-top",
      },
      {
        prop: "timeplot",
        label: "Plot Waktu",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi_timeplot",
        label: "",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "mapel_teacher",
        label: "Mapel / Guru",
        minWidth: "200",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi_mapel_teacher",
        label: "",
        width: "200",
        "class-name": "body-14 col-black-2",
      },
    ],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: '',
    sortType: '',
    filter: '',
    errors: false,
    loading: false,
    timeplots: [],
    schedules: [],
    totalTimeplot: 0,
    activeClassroom: '',
    formTimeplot: {
      id: '',
      start_at: {
        hour: '00',
        minute: '00',
      },
      end_at: {
        hour: '00',
        minute: '00',
      },
    },
    formTimeplotRules: {},
    formSchedule: {
      id: '',
      teacher_id: '',
      school_subject_id: '',
      timeplot: '',
    },
    errorsSchedule: [],
    formScheduleRules: {
      teacher_id: [
        { required: true, message: 'Guru tidak boleh kosong', trigger: 'blur' },
      ],
      school_subject_id: [
        {
          required: true,
          message: 'Mapel tidak boleh kosong',
          trigger: 'blur',
        },
      ],
    },
  },
};

const mutations = {
  changeJadwal(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  VueSetchangeJadwal(state, payload) {
    let index = 0;
    Vue.set(state.data.items, index, payload);
  },
};

const actions = {
  async getTimeplot({ commit, state, dispatch }, payload) {
    commit('changeJadwal', {
      loading: true,
    });

    if (payload) {
      await commit('changeJadwal', {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      'name_of_day[]': data.activeDay.toUpperCase(),
      // limit: data.limit,
      // page: data.page,
      // show: data.currentStatus,
      // sort_field: data.sortBy,
      // sort_dir: data.sortType,
      // q: data.filter,
    };

    const result = await ApiGetRequest(`school/time-plot`, params);

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeJadwal', {
        loading: false,
      });

      return [];
    } else {
      await commit('changeJadwal', {
        timeplots: result.data.data,
        // totalTimeplot: result.data.meta.total,
        loading: false,
      });
      if (data.activeClassroom != '') {
        dispatch('getSchedule');
      } else {
        dispatch('arrangeSchedule');
      }
    }
  },

  async getSchedule({ commit, state, dispatch }, payload) {
    commit('changeJadwal', {
      loading: true,
    });

    if (payload) {
      await commit('changeJadwal', {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      'class[]': data.activeClassroom,
      'name_of_day[]': data.activeDay.toUpperCase(),
    };

    const result = await ApiGetRequest(`school/subject-schedule`, params);

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeJadwal', {
        loading: false,
      });

      return [];
    } else {
      await commit('changeJadwal', {
        schedules: result.data.data,
        loading: false,
      });
      dispatch('arrangeSchedule');
    }
  },

  arrangeSchedule({ commit, state }) {
    const { data } = state;
    commit('changeJadwal', {
      items: [],
    });

    var scheduleList = [];
    for (let i = 0; i < data.timeplots.length; i++) {
      scheduleList.push({
        time_plot: data.timeplots[i],
        hasSchedule: false,
      });
    }

    if (scheduleList.length == 0) {
      scheduleList.push({
        hasSchedule: false,
      });
    }

    for (let i = 0; i < data.schedules.length; i++) {
      for (let j = 0; j < scheduleList.length; j++) {
        if (scheduleList[j].time_plot) {
          if (scheduleList[j].time_plot.id == data.schedules[i].time_plot.id) {
            scheduleList[j].hasSchedule = true;
            scheduleList[j].subject = data.schedules[i].subject;
            scheduleList[j].teacher = data.schedules[i].teacher;
            scheduleList[j].id = data.schedules[i].id;
            break;
          }
        }
      }
    }

    commit('changeJadwal', {
      items: scheduleList,
    });
  },

  async submitTimeplot({ commit, state }) {
    commit('changeJadwal', {
      loading: true,
    });

    const { data } = state;
    let result = '';
    let body = {
      name_of_day: data.activeDay.toUpperCase(),
      start_at:
        data.formTimeplot.start_at.hour +
        ':' +
        data.formTimeplot.start_at.minute,
      end_at:
        data.formTimeplot.end_at.hour + ':' + data.formTimeplot.end_at.minute,
    };

    if (data.formTimeplot.id != '') {
      result = await ApiPutRequest(
        `school/time-plot/${data.formTimeplot.id}`,
        body
      );
    } else {
      result = await ApiPostRequest(`school/time-plot`, body);
    }

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
      commit('changeJadwal', {
        loading: false,
        errors: result.errorList,
      });
    } else {
      Message({
        type: 'success',
        message: 'Data telah tersimpan.',
      });
      commit('changeJadwal', {
        errors: false,
        form: {
          id: '',
          name: '',
          grade: '',
          school_major_id: '',
        },
      });

      return true;
    }
  },

  async deleteTimePlot({ commit }, payload) {
    if (payload && payload.id) {
      commit('changeJadwal', {
        loading: true,
      });

      const result = await ApiDeleteRequest(`school/time-plot/${payload.id}`);

      if (result.error) {
        Message({
          type: 'error',
          message: result.error,
        });
        commit('changeJadwal', {
          loading: false,
          errors: result.errorList,
        });
        return false;
      } else {
        Message({
          type: 'success',
          message: 'Data telah dihapus.',
        });
        commit('changeJadwal', {
          loading: false,
          errors: false,
        });

        return true;
      }
    }
  },

  async submitSchedule({ commit, state }) {
    commit('changeJadwal', {
      loading: true,
    });

    const { data } = state;
    let result = '';
    let body = {
      teacher_id: data.formSchedule.teacher_id,
      school_subject_id: data.formSchedule.school_subject_id,
      school_time_plot_id: data.formSchedule.timeplot.id,
      school_class_id: data.activeClassroom,
    };

    if (data.formSchedule.id != '') {
      result = await ApiPutRequest(
        `school/subject-schedule/${data.formSchedule.id}`,
        body
      );
    } else {
      result = await ApiPostRequest(`school/subject-schedule`, body);
    }

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit('changeJadwal', {
          loading: false,
          errorsSchedule: errors,
          errors: result.errorList,
        });
      }
    } else {
      Message({
        type: 'success',
        message: 'Data telah tersimpan.',
      });
      commit('changeJadwal', {
        errors: false,
        errorsSchedule: [],
        formSchedule: {
          id: '',
          teacher_id: '',
          school_subject_id: '',
          timeplot: '',
        },
      });

      return true;
    }
  },

  async submitTimeplotLooping({ commit }, payload) {
    commit("changeJadwal", {
      loading: true,
    });

    let result = "";
    let body = {
      name_of_day: payload.day.toUpperCase(),
      start_at: payload.start,
      end_at: payload.end,
    };

    result = await ApiPostRequest(`school/time-plot`, body);

    if (result.error) {
      commit("changeJadwal", {
        errors: result.errorList,
      });
      return {
        status: false,
        message: result.error
      };
    }
    commit("changeJadwal", {
      errors: false,
      form: {
        id: "",
        name: "",
        grade: "",
        school_major_id: "",
      },
    });
    return {
      status: true,
      message: "OK"
    };
  },

  async deleteSchedulePlot({ commit }, payload) {
    if (payload && payload.id) {
      commit('changeJadwal', {
        loading: true,
      });

      const result = await ApiDeleteRequest(
        `school/subject-schedule/${payload.id}`
      );

      if (result.error) {
        Message({
          type: 'error',
          message: result.error,
        });
        commit('changeJadwal', {
          loading: false,
          errors: result.errorList,
        });
        return false;
      } else {
        Message({
          type: 'success',
          message: 'Data telah dihapus.',
        });
        commit('changeJadwal', {
          loading: false,
          errors: false,
        });

        return true;
      }
    }
  },
};

const getters = {
  listDays: (state) => {
    let today;
    if (state.data.activeDay) {
      today = state.data.activeDay;
    }else {
      // Mendapatkan nama hari saat ini dalam bahasa Inggris
      today = moment().format('dddd');
      console.log(today)
    }

    // Mengembalikan array baru dengan nilai active yang disesuaikan
    return state.data.days.map(day => {
      if (day.name === today) {
        return {
          ...day,
          active: true
        };
      }
      return day;
    });
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
