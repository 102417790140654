import { ApiGetRequest, ApiDeleteRequest, ApiPostMultipart } from "@/utils/Api";
import { Message } from "element-ui";
import { _ } from "vue-underscore";
import {tableOfWidth} from "@/services/InternalService";

const state = {
  data: {
    labelMenu: "Materi",
    columns: [
      {
        prop: "titleTheory",
        label: "Judul Materi",
        sortable: true,
        minWidth: "180",
      },
      {
        prop: "name",
        label: "Mata Pelajaran",
        sortable: true,
        ...tableOfWidth.subject.minWidth
      },
      {
        prop: "description",
        label: "Description",
        sortable: true,
        minWidth: "200",
      },
      {
        prop: "uri",
        label: "Format / Link",
        sortable: true,
        minWidth: "160",
      },
      {
        prop: "uploaded",
        label: "Pengupload",
        sortable: false,
        minWidth: "160",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "90",
        "class-name": "body-14 col-black-2",
      },
    ],
    filterData: "kelas",
    theory: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    errors: [],
    gradeOptions: [],
    grades: [
      {
        value: "1",
        label: "1",
        types: ["SD"],
      },
      {
        value: "2",
        label: "2",
        types: ["SD"],
      },
      {
        value: "3",
        label: "3",
        types: ["SD"],
      },
      {
        value: "4",
        label: "4",
        types: ["SD"],
      },
      {
        value: "5",
        label: "5",
        types: ["SD"],
      },
      {
        value: "6",
        label: "6",
        types: ["SD"],
      },
      {
        value: "7",
        label: "7",
        types: ["SMP"],
      },
      {
        value: "8",
        label: "8",
        types: ["SMP"],
      },
      {
        value: "9",
        label: "9",
        types: ["SMP"],
      },
      {
        value: "10",
        label: "10",
        types: ["SMA", "SMK"],
      },
      {
        value: "11",
        label: "11",
        types: ["SMA", "SMK"],
      },
      {
        value: "12",
        label: "12",
        types: ["SMA", "SMK"],
      },
      {
        value: "13",
        label: "13",
        types: ["SMK"],
      },
    ],
    form: {
      id: "",
      name: "",
      grade: "",
      school_major_id: "",
      school_classess_id: "",
      subject: null,
      teacher: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      description: "",
      link: [{ value: "" }],
    },
    formRules: {
      name: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
      subject: [
        {
          required: true,
          message: "Harus diisi",
          trigger: "blur",
        },
      ],
    },
  },
};

const mutations = {
  changeMateri(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getTheory({ commit, state }, payload) {
    commit("changeMateri", {
      loading: true,
    });

    if (payload) {
      await commit("changeMateri", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequest(`school/theory`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMateri", {
        loading: false,
      });
    } else {
      commit("changeMateri", {
        theory: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });

      return {
        data: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
      };
    }
  },
  async deleteTheory(context, payload) {
    const { id } = payload;
    const result = await ApiDeleteRequest(`school/theory/${id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data materi telah dihapus",
      });

      return true;
    }
  },
  async getTheoryById({ commit }, payload) {
    commit("changeMateri", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/theory/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeMateri", {
        loading: false,
      });

      return [];
    } else {
      let dataLink = (result.data.data.uri && result.data.data.uri.link) || [];
      let listData = dataLink.map((item) => {
        return { value: item };
      });

      if (result.data.data.class == null) {
        if (result.data.data.grade != null) {
          await commit("changeMateri", {
            form: {
              id: payload.id,
              name: result.data.data.name,
              grade: result.data.data.grade,
              subject: result.data.data.subject.id,
              file: result.data.data.file,
              file_name: result.data.data.file_name,
              file_size: result.data.data.file_size,
              file_path: result.data.data.file_path,
              file_type: result.data.data.file_type,
              link: listData.length > 0 ? listData : [{ value: "" }],
              description: result.data.data.description || "",
            },

            loading: false,
          });
        } else if (result.data.data.major != null) {
          await commit("changeMateri", {
            form: {
              id: payload.id,
              name: result.data.data.name,
              school_major_id: result.data.data.major.id,
              subject: result.data.data.subject.id,
              file: result.data.data.file,
              file_name: result.data.data.file_name,
              file_size: result.data.data.file_size,
              file_path: result.data.data.file_path,
              file_type: result.data.data.file_type,
              link: listData.length > 0 ? listData : [{ value: "" }],
              description: result.data.data.description || "",
            },

            loading: false,
          });
        }
      } else if (result.data.data.class != null) {
        await commit("changeMateri", {
          form: {
            id: payload.id,
            name: result.data.data.name,
            school_classess_id: result.data.data.class.id,
            subject: result.data.data.subject.id,
            file: result.data.data.file,
            file_name: result.data.data.file_name,
            file_size: result.data.data.file_size,
            file_path: result.data.data.file_path,
            file_type: result.data.data.file_type,
            link: listData.length > 0 ? listData : [{ value: "" }],
            description: result.data.data.description || "",
          },

          loading: false,
        });
      }
    }
  },
  getGrades({ commit, state }, payload) {
    const { data } = state;
    let grades = _.filter(data.grades, function(grade) {
      return _.contains(grade.types, payload);
    });

    commit("changeRuangKelas", {
      schoolType: payload,
      gradeOptions: grades,
    });
  },
  async submitMateri({ commit, state }) {
    commit("changeMateri", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    formData.append("_method", "post");
    formData.append("id", data.form.id);
    formData.append("name", data.form.name);
    formData.append("school_subject_id", data.form.subject);

    if (data.form.description && data.form.description !== "") {
      formData.append("description", data.form.description);
    }

    if (data.form.link && data.form.link.length > 0) {
      data.form.link.map((itemL, indexL) => {
        if (itemL.value && itemL.value !== "") {
          formData.append(`link[${indexL}]`, itemL.value);
        }
      });
    }

    if (data.form.grade != null) {
      formData.append("grade", data.form.grade);
    } else if (data.form.school_major_id != null) {
      formData.append("school_major_id", data.form.school_major_id);
    } else {
      formData.append("school_classes_id", data.form.school_classess_id);
    }

    if (typeof data.form.file != "string" && data.form.file != null) {
      formData.append("file", data.form.file);
    }

    if (data.form.id != "") {
      formData.set("id", data.form.id);
    }

    if (data.form.subject != null) {
      formData.set("school_subject_id", data.form.subject);
    }

    if (data.form.name != "") {
      formData.set("name", data.form.name);
    }

    if (data.form.grade != null) {
      formData.set("grade", data.form.grade);
    } else {
      formData.set("grade", (data.form.grade = ""));
    }

    if (data.form.school_major_id != null) {
      formData.set("school_major_id", data.form.school_major_id);
    } else {
      formData.set("school_major_id", (data.form.school_major_id = ""));
    }

    if (data.form.school_classess_id != null) {
      formData.set("school_classes_id", data.form.school_classess_id);
    } else {
      formData.set("school_classes_id", (data.form.school_classess_id = ""));
    }

    if (data.form.id) {
      formData.set("_method", "put");
    }

    let result = "";

    if (data.form.id) {
      result = await ApiPostMultipart(
        `school/theory/${data.form.id}`,
        formData
      );
    } else {
      result = await ApiPostMultipart(`school/theory`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeMateri", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeMateri", {
        filterData: "",
        errors: [],
        form: {
          id: "",
          name: "",
          grade: null,
          school_major_id: null,
          school_classess_id: null,
          subject: null,
          teacher: "",
          file: "",
          file_name: "",
          file_size: "",
          file_path: "",
          file_type: "",
        },
        loading: false,
      });

      return true;
    }
  },
  // async submitMateri({ commit, state }) {
  //   commit("changeMateri", {
  //     loading: true,
  //   });

  //   const { data } = state;
  //   if (data.form.id.length == 0 && data.form.name == "") {
  //     let errors = [];
  //     errors.push({
  //       message: "Nama materi tidak boleh kosong",
  //       path: ["id"],
  //     });

  //     commit("changeMateri", {
  //       loading: false,
  //       errors: errors,
  //     });
  //   } else {
  //     let result = "";
  //     let body = {
  //       id: data.form.id,
  //       name: data.form.name,
  //       school_subject_id: data.form.subject,
  //       grade: data.form.grade,
  //       school_major_id: data.form.school_major_id,
  //       school_classess_id: data.form.school_classess_id,
  //       file: data.form.file,
  //     };

  //     console.log(`====body`, body);

  //     if (typeof data.form.file != "string" && data.form.file != null) {
  //       body.form.file = data.form.file;
  //     }

  //     if (data.form.id != "") {
  //       result = await ApiPutRequest(`school/theory/${data.form.id}`, body);
  //     } else {
  //       result = await ApiPostRequest(`school/theory`, body);
  //     }

  //     if (result.error) {
  //       Message({
  //         type: "error",
  //         message: result.error,
  //       });
  //       if (result.errorList) {
  //         let errors = [];
  //         for (const [key, value] of Object.entries(result.errorList)) {
  //           errors.push({
  //             message: value[0],
  //             path: [key],
  //           });
  //         }
  //         commit("changeMateri", {
  //           errors: errors,
  //         });
  //       }
  //     } else {
  //       Message({
  //         type: "success",
  //         message: "Data telah tersimpan.",
  //       });
  //       commit("changeMateri", {
  //         errors: [],
  //         form: {
  //           id: "",
  //           name: "",
  //           school_subject_id: "",
  //           grade: "",
  //         },
  //       });

  //       return true;
  //     }
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
