import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    items: [],
    page: 1,
    total: 0,
    limit: 10,
    filter: "",
    sortBy: "",
    sortType: "",
    jenjang: "",
    status: "",
    formAturJadwal: {
      date: "",
      start: "",
      end: ""
    },
    rulesAturJadwal: {
      date: [{ required: true, message: "Anda harus melengkapi data ini" }],
      start: [{ required: true, message: "Anda harus melengkapi data ini" }],
      end: [{ required: true, message: "Anda harus melengkapi data ini" }]
    },
    columns: [
      {
        prop: "schedule",
        label: "Jadwal",
        width: 150,
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "grade",
        label: "Jenjang",
        width: 120,
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "student",
        label: "Peserta",
        width: 120,
        sortable: true,
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "mapel",
        label: "Quiz",
        minWidth: 80,
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "title",
        label: "Judul Quiz",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "140",
        "class-name": "body-14 col-black-2"
      }
    ],
    page_statistik: 1,
    total_statistik: 0,
    limit_statistik: 10,
    filter_statistik: "",
    columns_statistik: [
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "kelas",
        label: "Kelas",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "nilai",
        label: "Nilai",
        "class-name": "body-14 col-black-2 text-center"
      }
    ],
    items_statistik: [],
    export_excel: [],
    summary_statistik: false,
    page_peserta: 1,
    total_peserta: 0,
    limit_peserta: 10,
    filter_peserta: "",
    columns_peserta: [
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "status",
        label: "Status",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "selesai",
        label: "Selesai",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "nilai_bakat",
        label: "Nilai",
        width: 80,
        "class-name": "body-14 col-black-2 text-center p-0 column-nilai-bakat"
      },
      {
        label: "Aksi",
        width: 100,
        "class-name": "body-14 col-black-2 text-center"
      }
    ],
    items_peserta: [],
    summary_peserta: {},
    detail: false,
    loading: false,
    error: false
  }
};

const mutations = {
  changeKlasKonselingAssesment(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingAssesmentFormAturJadwal(state, payload) {
    state.data.formAturJadwal = Object.assign(
      {},
      state.data.formAturJadwal,
      payload
    );
  }
};

const actions = {
  async setJadwalMapel({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const result = await ApiPostRequest(
      `school/training/request/tryout/${payload.tryout_request}`,
      payload.form
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Set Jadwal Mapel Berhasil"
      });

      return true;
    }
  },

  async getListAssesment({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    if (payload) {
      commit("changeKlasKonselingAssesment", {
        page: payload
      });
    }

    // const { data } = state;
    // let params = {
    //   page: data.page,
    //   limit: data.limit,
    //   q: data.filter,
    //   type: "mapel",
    //   grade: data.jenjang,
    //   status: data.status,
    //   sort_field: data.sortBy,
    //   sort_dir: data.sortType
    // };

    const result = await import("@/db/assesment.json");
    // const result = await ApiGetRequest(
    //   `school/training/request/tryout`,
    //   params
    // );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    }
    commit("changeKlasKonselingAssesment", {
      loading: false,
      items: result.data.data || [],
      total: result.data && result.data.meta ? result.data.meta.total : 0
    });
    return true;
  },

  async summaryStatistik({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/tryout/review-kkm/statistic/${payload.schoolExamSchedule}/header`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      commit("changeKlasKonselingAssesment", {
        loading: false,
        summary_statistik: result.data.data || false
      });

      return true;
    }
  },

  async getListStatistik({ commit, state }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const { data } = state;
    let params = {
      page: data.page_statistik,
      limit: data.limit_statistik,
      q: data.filter_statistik
    };

    const result = await ApiGetRequest(
      `school/tryout/review-kkm/${payload.schoolExamSchedule}/student`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      commit("changeKlasKonselingAssesment", {
        loading: false,
        items_statistik: result.data.data || [],
        total_statistik:
          result.data && result.data.meta ? result.data.meta.total : 0
      });

      return true;
    }
  },

  async getExportExcel({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/tryout/review-kkm/${payload.schoolExamSchedule}/student`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      if (result.error) {
        Message({
          type: "error",
          message: "CORS Error! Ulangi lagi / Hubungi Onklas."
        });
        commit("changeKlasKonselingAssesment", {
          loading: false
        });
        return false;
      }
      commit("changeKlasKonselingAssesment", {
        loading: false,
        export_excel: result.data.data || []
      });
      return true;
    }
  },

  async getDetailMapel({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/training/request/${payload.request_id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      commit("changeKlasKonselingAssesment", {
        loading: false,
        detail: result.data.data || false
      });

      return true;
    }
  },

  async getPesertaTryOut({ commit, state }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const { data } = state;
    let params = {
      page: data.page_peserta,
      limit: data.limit_peserta,
      q: data.filter_peserta
    };

    const result = await ApiGetRequest(
      `school/tryout/review-kkm/${payload.schoolExamSchedule}/student`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      if (result.error) {
        Message({
          type: "error",
          message: "CORS Error! Ulangi lagi, Atau hubungi Onklas"
        });

        commit("changeKlasKonselingAssesment", {
          loading: false
        });

        return false;
      }
      commit("changeKlasKonselingAssesment", {
        loading: false,
        items_peserta: result.data.data || [],
        total_peserta:
          result.data && result.data.meta ? result.data.meta.total : 0
      });

      return true;
    }
  },

  async summaryPeserta({ commit }, payload = null) {
    commit("changeKlasKonselingAssesment", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/tryout/review-kkm/${payload.schoolExamSchedule}/header`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changeKlasKonselingAssesment", {
        loading: false
      });

      return false;
    } else {
      commit("changeKlasKonselingAssesment", {
        loading: false,
        summary_peserta: result.data.data || {}
      });

      return true;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
