import ApiService from "@/api/ApiService"
import axios from "axios"

class DanaPartisipasiRepository {
	constructor() {
		this.apiService = ApiService
		this.cancelTokenSource = null
		this.mock = 'http://localhost:3000/api'
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.")
		}
		this.cancelTokenSource = axios.CancelToken.source()
	}

	async getData222(id) {
		this.createCancelToken()
		return await this.apiService.get(`/dana-partisipasi/school/${id}/student`)
	}

	async getListBill(params) {
		return await this.apiService.get(`/payment/bill/list`, {params})
	}

	async getDetailBill(id) {
		return await this.apiService.get(`/payment/bill/id/${id}`)
	}

	async getBillByUserUuid(params) {
		return await this.apiService.get(`/school/dana-partisipasi/student`, {params})
	}

	async postUpdateBill(id, formData) {
		return await this.apiService.post(`/payment/bill/${id}`, formData)
	}

	async postCreateBill(formData) {
		return await this.apiService.post(`/payment/bill`, formData)
	}

	async getDetailBillHistory(id) {
		return await this.apiService.get(`/payment/bill/history/id/${id}`)
	}

	async postBillActivation(id, formData) {
		return await this.apiService.post(`/payment/bill/activation/${id}`, formData)
	}

	async getAppInProductCheck() {
		return await this.apiService.get(`/payment/external/appinnproduct/check`)
	}

	async postAppInProduct(id, formData) {
		return await this.apiService.post(`/payment/external/appinnproduct/set/id/${id}`, formData)
	}

}

export default new DanaPartisipasiRepository()
