import { ApiGetRequest } from "@/utils/Api";
import moment from "moment";
import { Message } from "element-ui";

const state = {
  data: {
    error: false,
    loading: false,
    showModalCetakBulanan: false,
    search: "",
    start_month: "",
    end_month: "",
    filter_year: "",
    itemsRecap: [],
    items: [
      {
        id: 1,
        hari: "Senin",
        tanggal: "14 Jan 2021",
        jam_masuk: "O5:58",
        jam_pulang: "15.31",
        pulang_awal: {
          in: {
            label: "Tepat Waktu",
          },
          out: {
            label: "Tepat Waktu",
          },
        },
      },
      {
        id: 2,
        hari: "Selasa",
        tanggal: "14 Jan 2021",
        jam_masuk: "06:21",
        jam_pulang: "-",
        pulang_awal: {
          in: {
            label: "Tepat Waktu",
          },
          out: {
            label: "Tepat Waktu",
          },
        },
      },
    ],
    rekapBulananStaffId: "",
    limit: 10,
    page: 1,
    total: 0,
    filterMonth: "",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      {
        id: 1,
        name: "Januari",
      },
      {
        id: 2,
        name: "Februari",
      },
      {
        id: 3,
        name: "Maret",
      },
      {
        id: 4,
        name: "April",
      },
      {
        id: 5,
        name: "Mei",
      },
      {
        id: 6,
        name: "Juni",
      },
      {
        id: 7,
        name: "Juli",
      },
      {
        id: 8,
        name: "Agustus",
      },
      {
        id: 9,
        name: "September",
      },
      {
        id: 10,
        name: "Oktober",
      },
      {
        id: 11,
        name: "November",
      },
      {
        id: 12,
        name: "Desember",
      },
    ],
    columns_rekap_staff_bulanan: [
      {
        prop: "hari",
        label: "Hari",
        sortable: true,
        minWidth: "100",
      },
      {
        prop: "tanggal",
        label: "Tanggal",
        sortable: true,
        minWidth: "100",
      },
      {
        prop: "jam_masuk",
        label: "Jam Masuk",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "jam_pulang",
        label: "Jam Pulang",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "pulang_awal",
        label: "Pulang Awal",
        sortable: false,
        minWidth: "100",
      },
    ],
  },
};

const mutations = {
  changeRekapPresensiGuruBulanan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getRecapListMonthStaff({ commit, state }, payload) {
    commit("changeRekapPresensiGuruBulanan", {
      loading: true,
    });

    if (payload) {
      await commit("changeRekapPresensiGuruBulanan", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      month: data.filterMonth,
    };

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-staff/${data.rekapBulananStaffId}/monthly`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeRekapPresensiGuruBulanan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRekapPresensiGuruBulanan", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getHeaderRecapMonthStaff({ commit, state }) {
    commit("changeRekapPresensiGuruBulanan", {
      loading: true,
      infoUser: "....",
    });

    const { data } = state;

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-staff/${data.rekapBulananStaffId}/monthly/header`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeRekapPresensiGuruBulanan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRekapPresensiGuruBulanan", {
        loading: false,
        infoUser: result.data.data,
        error: false,
      });
    }
  },

  async getListCetakMonthStaff({ commit, state }, payload) {
    commit("changeRekapPresensiGuruBulanan", {
      loading: true,
    });

    if (payload) {
      await commit("changeRekapPresensiGuruBulanan", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: 0,
      page: 1,
      start_month: data.start_month,
      end_month: data.end_month,
      year: data.filter_year,
    };

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-staff/${data.rekapBulananStaffId}/monthly`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeRekapPresensiGuruBulanan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRekapPresensiGuruBulanan", {
        itemsRecap: result.data.data,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
