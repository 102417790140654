import { ApiPutRequest, ApiPostMultipart, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    id: "",
    ruleForm: {
      name: "",
      email: "",
      phone: "",
      address: "",
      zona: "",
      type: "",
      imagePreview: false,
      selectedFile: false,
      isComplateRadius: false,
      isComplateKoordinat: false,
    },
    rules: {
      name: [
        {
          required: true,
          message: "Anda harus menginputkan nama sekolah",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Anda harus menginputkan email sekolah",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Format email salah",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          message: "Anda harus menginputkan telp sekolah",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "Anda harus menginputkan alamat sekolah",
          trigger: "blur",
        },
      ],
      zona: [
        {
          required: true,
          message: "Anda harus menginputkan zona waktu",
          trigger: "change",
        },
      ],
    },

    locationForm: {
      latitude: null,
      longitude: null,
      radius: null,
    },
    loading: false,
    errors: false,
  },
};

const mutations = {
  changeSekolah(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async updateSekolah({ commit, state, dispatch }) {
    commit("changeSekolah", {
      loading: true,
    });

    const { data } = state;
    let formData = {
      name: data.ruleForm.name,
      email: data.ruleForm.email,
      address: data.ruleForm.address,
      phone: data.ruleForm.phone,
      timezone: data.ruleForm.zona,
      type: data.ruleForm.type,
    };

    const result = await ApiPutRequest(
      `school/profile-school/${data.id || ""}`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSekolah", {
        loading: false,
        errors: result.errorList || [],
      });

      return false;
    } else {
      let resultUploadImage = await dispatch("updateProfileImage");
      if (resultUploadImage.error) {
        Message({
          type: "error",
          message: resultUploadImage.error,
        });

        commit("changeSekolah", {
          loading: false,
          errors: resultUploadImage.errorList,
        });

        return false;
      } else {
        commit("changeSekolah", {
          loading: false,
          errors: false,
        });

        Message({
          type: "success",
          message: "Profil sekolah berhasil diubah",
        });

        if (!resultUploadImage) {
          return result.data.data;
        } else {
          return resultUploadImage;
        }
      }
    }
  },

  async updateKoordinat({ commit, state }) {
    commit("changeSekolah", {
      loading: true,
    });

    const { data } = state;
    let formData = {
      latitude: data.locationForm.latitude,
      longitude: data.locationForm.longitude,
    };

    const result = await ApiPostRequest(`school/coordinate`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSekolah", {
        loading: false,
        isComplateKoordinat: false,
        errors: result.errorList || [],
      });

      return false;
    } else {
      commit("changeSekolah", {
        loading: false,
        errors: false,
        isComplateKoordinat: true,
      });
     
      Message({
        type: "success",
        message: "Koordinat sekolah berhasil diubah",
      });
    }
  },

  async updateRadius({ commit, state }) {
    commit("changeSekolah", {
      loading: true,
    });

    const { data } = state;
    let formData = {
      radius: data.locationForm.radius,
    };

    const result = await ApiPostRequest(`school/coordinate/radius`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSekolah", {
        loading: false,
        isComplateRadius: false,
        errors: result.errorList || [],
      });

      return false;
    } else {
      commit("changeSekolah", {
        loading: false,
        errors: false,
        isComplateRadius: true,
      });

      Message({
        type: "success",
        message: "Radius sekolah berhasil diubah",
      });
    }
  },

  async updateProfileImage({ state, commit }) {
    const { data } = state;
    if (data.ruleForm.selectedFile) {
      let formData = new FormData();
      formData.append("_method", "post");
      formData.append("image", data.ruleForm.selectedFile);

      const result = await ApiPostMultipart(
        `school/profile-school/image`,
        formData
      );

      if (result.error) {
        commit("changeSekolah", {
          ruleForm: {
            ...data.ruleForm,
            selectedFile: false,
            imagePreview: false,
          },
        });

        return result;
      } else {
        commit("changeSekolah", {
          ruleForm: {
            ...data.ruleForm,
            selectedFile: false,
            imagePreview: false,
          },
        });

        return result.data.data;
      }
    } else {
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
