import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest,
  ApiPostMultipart,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns: [
      // {
      //   fixed: "left",
      //   label: "No",
      //   minWidth: "100",
      //   sortable: false,
      //   "class-name": "body-14 col-black-2",
      // },
      {
        prop: "nis_nik",
        label: "NIP/NUPTK",
        minWidth: "110",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "profesi",
        label: "Profesi",
        minWidth: "100",
        sortable: true,
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role",
        label: "Peranan",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "status",
        label: "Status",
        minWidth: "150",
        "class-name": "body-14 col-black-2",
      },

      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],

    items: [],
    teachers: [],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    showModalCreate: false,
    showModalDetailHakAkses: false,
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" },
    ],
    currentStatus: "all",
    roles: [],
    rolesSelected: "",
    loading: false,
    jsonData: [],
    provinces: [],
    cities: [],
    districts: [],
    goldarah: ["A", "AB", "B", "O"],
    agama: ["Islam", "Protestan", "Katolik", "Hindu", "Budha", "Konghucu"],
    dataForm: {
      nis_nik: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      roles: "",
      // soft_deleted: false,
      date_of_birth: "",
      place_of_birth: "",
      province_id: "",
      city_id: "",
      sub_district_id: "",
      address: "",
      gender: "",
      blood_type: "",
      religion: "",
      profession: "",
    },
    formRules: {
      nis_nik: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
      name: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
      email: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
      password: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
      roles: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
      profession: [
        { required: true, message: "tidak boleh kosong", trigger: "blur" },
      ],
    },
    errors: false,
  },
};

const mutations = {
  changePengaturanHakAkses(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getGuruStaff({ commit, state }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });

    if (payload) {
      await commit("changePengaturanHakAkses", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const params = {
      limit: data.limit,
      page: data.page,
      show: data.currentStatus,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
      role: data.rolesSelected,
    };

    const result = await ApiGetRequest(`school/user`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data Guru & Staff",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
      });

      return false;
    } else {
      commit("changePengaturanHakAkses", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });

      return {
        data: result.data.data,
        total: result.data.meta.total,
      };
    }
  },

  async getGuruStaffById({ commit, state, dispatch }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });

    const { data } = state;
    const result = await ApiGetRequest(`school/user/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengambil detail data guru",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
      });
    } else {
      let newDataForm = data.dataForm;
      newDataForm = Object.assign({}, newDataForm, result.data.data);
      if (result.data.data.role && result.data.data.role.length > 0) {
        let dataRoles = [];
        result.data.data.role.map((item) => {
          dataRoles.push(item.name);
        });

        newDataForm.roles = dataRoles;
      }

      let dataCities = await dispatch(
        "cities/getCities",
        newDataForm.province_id,
        { root: true }
      );

      let dataDistricts = await dispatch(
        "districts/getDistricts",
        newDataForm.city_id,
        { root: true }
      );

      commit("changePengaturanHakAkses", {
        dataForm: newDataForm,
        cities: dataCities,
        districts: dataDistricts,
        id: payload.id,
        loading: false,
      });
    }
  },

  async getGuru({ commit, state }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });

    if (payload) {
      await commit("changePengaturanHakAkses", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const params = {
      role: 9,
    };

    const result = await ApiGetRequest(`school/user`, params);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data Guru & Staff",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
      });

      return false;
    } else {
      commit("changePengaturanHakAkses", {
        teachers: result.data.data,
        loading: false,
      });

      return {
        data: result.data.data,
        total: result.data.meta.total,
      };
    }
  },

  async resetAkunGuruStaff(context, payload) {
    const { formData, id } = payload;
    const result = await ApiPutRequest(`school/user/${id}/password`, formData);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal reset akun guru / staff",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data password berhasil di reset",
      });

      return true;
    }
  },

  async deleteAkunGuruStaff({ commit }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });
    const { id } = payload;

    const result = await ApiDeleteRequest(`school/user/${id}`);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menghapus data akun guru / staff",
      });
      commit("changePengaturanHakAkses", {
        loading: false,
      });
      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah dinonaktifkan",
      });
      commit("changePengaturanHakAkses", {
        loading: false,
      });
      return true;
    }
  },

  async restoreAkunGuruStaff({ commit }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });
    const { id } = payload;
    const result = await ApiPostRequest(`school/user/${id}/restore`, {});
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengaktifkan akun guru / staff ",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Akun telah diaktifkan kembali",
      });
      return true;
    }
  },

  async importExcelGuruStaff({ commit }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });

    const result = await ApiPostRequest(`school/teacher`, payload.formData);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal melakukan import excel Guru / Staff",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
      });
    } else {
      commit("changePengaturanHakAkses", {
        loading: false,
      });

      const items = result.data.data;

      // check kalo return respone ada error
      const errorItems = await items.filter((item) => {
        if (item.error) {
          return item;
        }
      });

      if (errorItems.length > 0) {
        commit("changePengaturanHakAkses", {
          jsonData: errorItems,
        });

        let message = "";
        if (errorItems.length != payload.formData.length) {
          message = "Beberapa data gagal ditambahkan ke server";
        } else {
          message = "Data gagal ditambahkan ke server";
        }

        Message({
          type: "error",
          message: message,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data Guru / Staff berhasil ditambahkan",
        });

        return true;
      }
    }
  },

  async createGuruStaff({ commit, state }) {
    commit("changePengaturanHakAkses", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPostRequest(`school/user`, data.dataForm);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changePengaturanHakAkses", {
        loading: false,
        error: false,
      });

      Message({
        type: "success",
        message: "Data Guru / Staff berhasil ditambahkan",
      });

      return result.data.data;
    }
  },

  async updateGuruStaff({ commit, state }) {
    commit("changePengaturanHakAkses", {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiPutRequest(`school/user/${data.id}`, data.dataForm);
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changePengaturanHakAkses", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Guru / Staff berhasil diubah",
      });

      return true;
    }
  },

  async updateImageUser({ commit }, payload) {
    commit("changePengaturanHakAkses", {
      loading: true,
    });

    const result = await ApiPostMultipart(
      `school/user/${payload.id}/profile-image`,
      payload.dataForm
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah gambar profil",
      });

      commit("changePengaturanHakAkses", {
        loading: false,
        error: result.errorList || false,
      });

      return false;
    } else {
      commit("changePengaturanHakAkses", {
        loading: false,
        error: false,
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
