const state = {
  data: {
    tabMenu: [
      {
        name: "Postingan",
        label: "Postingan",
        lock: false,
        url: "/sosial-media/postingan",
      },
      {
        name: "PostinganEBook",
        label: "Postingan E-Book",
        lock: false,
        url: "/sosial-media/postingan-ebook",
      },
      {
        name: "Hashtag",
        label: "Hashtag",
        lock: false,
        url: "/sosial-media/hashtag",
      },
      {
        name: "PeringkatPost",
        label: "Peringkat Post",
        lock: false,
        url: "/sosial-media/peringkat-post",
      },
      {
        name: "Laporan",
        label: "Laporan",
        lock: true,
        url: "/sosial-media/laporan",
      },
    ],
  },
};

const mutations = {
  changeOnklasSosialMedia(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
