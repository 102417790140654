import ApiService from "@/api/ApiService"

export class AuthRepository {

	constructor() {
		this.PATH_FIRST = "school";
		this.MOCK_API = "http://localhost:3001/api";
	}

	async getCurrent(params) {
		return ApiService.get(`/${this.PATH_FIRST}/current`, { params })
	}
}
