import { ApiNotification, ApiGetRequest } from "@/utils/Api";
import moment from "moment";

const state = {
  data: {
    error: false,
    loading: false,
    search: "",
    items: [],
    limit: 10,
    page: 1,
    pageKehadiran: 1,
    totalKehadiran: 0,
    total: 0,
    typefilter: "bulan",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    classroom: [],
    subject: [],
    classes_select: [],
    attendances: [],
    filter_matapelajaran: "",
    filter_kelas: "",
    columns_kelas: [
      {
        prop: "mata_pelajaran",
        label: "Mata Pelajaran ",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "kelas",
        label: "Kelas ",
        sortable: false,
        minWidth: "80",
      },
      {
        prop: "plot_waktu",
        label: "Plot Waktu",
        sortable: false,
        minWidth: "140",
      },
      {
        prop: "guru",
        label: "Guru",
        sortable: false,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "status_kelas",
        label: "Status Kelas",
        sortable: false,
        minWidth: "120",
      },
      {
        prop: "mulai_berakhir",
        label: "Mulai - Berakhir",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "kehadiran",
        label: "Kehadiran",
        sortable: false,
        minWidth: "110",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_detail_kehadiran: [
      {
        prop: "nama_lengkap",
        label: "Nama Lengkap ",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "nisn_nis",
        label: "NIS/NISN",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "kelas",
        label: "Kelas",
        sortable: false,
        minWidth: "90",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "start_time",
        label: "Kelas Dimulai",
        sortable: false,
        minWidth: "110",
      },
      {
        prop: "end_time",
        label: "Kelas Berakhir",
        sortable: false,
        minWidth: "110",
      },
      {
        prop: "status",
        label: "Status",
        sortable: false,
        minWidth: "90",
      },
    ],
  },
};

const mutations = {
  changePresensiKelas(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getAbsensiKelas({ commit, state }, payload = null) {
    commit("changePresensiKelas", {
      loading: true,
    });

    if (payload) {
      commit("changePresensiKelas", {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page || 1,
      limit: data.limit,
      q: data.search,
    };

    if (data.filter_kelas) {
      params.class = data.filter_kelas;
    }

    if (data.filter_matapelajaran) {
      params.subject = data.filter_matapelajaran;
    }

    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/class`,
      params
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
    } else {
      commit("changePresensiKelas", {
        loading: false,
        items: getResult.data.data,
        page: (getResult.data.meta && getResult.data.meta.current_page) || 1,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showKelas({ commit, state }, id = null) {
    commit("changePresensiKelas", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/class/${id || ""}`
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
        error: getResult.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail absensi kelas");
    } else {
      commit("changePresensiKelas", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async showAttendance({ commit, state }, id = null) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const { data } = state;

    let params = {
      page: data.pageKehadiran || 1,
      limit: data.limit,
    };

    const result = await ApiGetRequest(
      `school/klastime/attendance-class-list/${id || ""}`,
      params
    );

    if (result.error) {
      commit("changePresensiKelas", {
        loading: false,
        error: result.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail kehadiran");
    } else {
      commit("changePresensiKelas", {
        attendances: result.data.data,
        totalKehadiran: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        pageKehadiran: (result.data.meta && result.data.meta.current_page) || 1,
        loading: false,
      });
    }
  },
  async getClassRoom({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/class`);
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        classroom: getResult.data.data,
      });
    }
  },
  async getSubject({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/subject`);
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        subject: getResult.data.data,
      });
    }
  },
  async getAbsenKelas({ commit }) {
    commit("changePresensiKelas", {
      loading: true,
    });

    const getResult = await ApiGetRequest(
      `school/subject-schedule/{subject_schedule}/attendance`
    );
    if (getResult.error) {
      commit("changePresensiKelas", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiKelas", {
        loading: false,
        items: getResult.data.data,
        page: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
