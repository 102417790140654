import {
  // ApiNotification,
  ApiGetRequest,
  // ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
// import moment from "moment";
import { Message } from "element-ui";
import { _ } from "vue-underscore";

const state = {
  data: {
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    editData: false,
    errors: [],
    columnsDanaKantin: [
      {
        prop: "wallet",
        label: "Wallet",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },

      {
        prop: "nominal",
        label: "Nominal",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "created_at",
        label: "Waktu",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "145",
        "class-name": "body-14 col-black-2",
      },
    ],
    dataItem: [
      {
        id: 1,
        merchant: "Bakoel Jamoer",
        name: "Ibu Martinah",
        phone: "081216593329",
        email: "Perum.Grand Anggaswangi No 12",
        is_klaspay_activated: true,
      },
    ],
    dataCanteen: {},
    formDaftarKantin: {
      id: null,
      namaKantin: "",
      pemilik: "",
      noTelp: "",
      email: "",
    },
    rulesForm: {
      namaKantin: [
        {
          required: true,
          message: "Nama toko wajib diisi",
          trigger: "blur",
        },
      ],
      pemilik: [
        {
          required: true,
          message: "Nama pemilik toko wajib diisi",
          trigger: "blur",
        },
      ],
      noTelp: [
        {
          required: true,
          message: "Nomor telp wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email wajib diisi",
          trigger: "blur",
        },
      ],
    },
  },
};
const mutations = {
  changeKoperasiDanaKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataTableWithdraw({ commit, state }, payload) {
    commit("changeKoperasiDanaKantin", {
      loading: true,
    });

    if (payload) {
      await commit("changeKoperasiDanaKantin", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    const result = await ApiGetRequest(`school/canteen/withdraw`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDanaKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDanaKantin", {
        dataItem: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },
  async approveWithdraw({ commit }, payload) {
    commit("changeKoperasiDanaKantin", {
      loading: true,
    });

    // const { data } = state;

    const result = await ApiPutRequest(
      `school/canteen/withdraw/${payload.id_withdraw}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data",
      });

      commit("changeKoperasiDanaKantin", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeKoperasiDanaKantin", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Withdraw berhasil disetujui",
      });

      return result.data.data;
    }
  },
  async declineWithdraw({ commit }, payload) {
    commit("changeKoperasiDanaKantin", {
      loading: true,
    });

    const result = await ApiDeleteRequest(
      `school/canteen/withdraw/${payload.id_withdraw}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDanaKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDanaKantin", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Withdraw tidak disetujui",
      });
    }
  },
  async getDataDetail({ commit }, payload) {
    commit("changeKoperasiDanaKantin", {
      loading: true,
    });

    // const { data } = state;

    const params = {
      limit: 100,
    };

    const result = await ApiGetRequest(`school/canteen`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDanaKantin", {
        loading: false,
      });
    } else {
      let dataFilter = _.findWhere(result.data.data, {
        wallet_id: payload.wallet_id,
      });

      commit("changeKoperasiDanaKantin", {
        dataCanteen: {
          data: dataFilter,
        },
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
