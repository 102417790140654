import { ApiGetRequestCabdin, ApiPostMultipartCabdin,ApiPostRequestCabdin, ApiDeleteRequestCabdin,ApiPutMultipartCabdin } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_pelajar: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nis",
        label: "NISN/NIS",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama_siswa",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "kelas_siswa",
        label: "Kelas",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        "class-name": "body-14 col-black-2",
      },
    ],

    columns_guru_guru: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nip",
        label: "NIP/NUPTK",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama_guru",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role_guru",
        label: "Posisi",
        "class-name": "body-14 col-black-2",
      },

      {
        fixed: "right",
        label: "Aksi",
        "class-name": "body-14 col-black-2",
      },
    ],

    limit: 10,
    page: 1,
    total: 0,
    sort: "-created_at",
    key: "",
    q: "",
    filter: "",
    loading: false,
    jsonData: [],
    listTeacher: [],
    listStudent: [],
    listEmployee: [],
    listExportTeacher: [],
    listExportStudent: [],
    listExportEmployee: [],
    detailGuru: {},
    detailSiswa: {},
    detailImport: {
      dataValid: 0,
      dataInValid: 0
    },
    formDataTeacher: {
      uuid: "",
      nip: "",
      name: "",
      role: "",
      file: "",
      previewImage: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
    },

    formDataEmployee: {
      uuid: "",
      nip: "",
      name: "",
      role: "",
      file: "",
      previewImage: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
    },

    formDataStudent: {
      uuid: "",
      nisn: "",
      name: "",
      class: "",
      file: "",
      previewImage: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
    },
    tabsView: "",
  },
};

const mutations = {
  changeIntegrasiPenghuniSekolah(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getTeacher({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      // sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/teacher`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listTeacher: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async deleteTeacher({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/teacher/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Guru telah dihapus",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return true;
    }
  },
  async getTeacherById({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(`/api/web/teacher/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
        detailGuru: result.data.data,
      });
    }
  },
  async submitGuru({ commit, state }) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formDataTeacher.uuid != ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }

    formData.append("nip", data.formDataTeacher.nip);
    formData.append("name", data.formDataTeacher.name);
    formData.append("role", data.formDataTeacher.role);

    if (
      data.formDataTeacher.file != "string" &&
      data.formDataTeacher.file != null
    ) {
      formData.append("photo", data.formDataTeacher.file);
    }

    let result = "";

    if (data.formDataTeacher.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/teacher/${data.formDataTeacher.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/teacher`, formData);
    }
    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiPenghuniSekolah", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        filterData: "",
        errors: [],
        form: {
          id: "",
          name: "",
          grade: null,
          school_major_id: null,
          school_classess_id: null,
          subject: null,
          teacher: "",
          file: "",
          file_name: "",
          file_size: "",
          file_path: "",
          file_type: "",
        },
        loading: false,
      });

      return true;
    }
  },
  async getExportDataGuru({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/teacher/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listExportTeacher: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async getStudent({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      // sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/student`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listStudent: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async deleteStudent({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/student/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Siswa telah dihapus",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return true;
    }
  },
  async getStudentById({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(`/api/web/student/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
        detailSiswa: result.data.data,
      });
    }
  },
  async submitStudent({ commit, state }) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formDataStudent.uuid != ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }
    formData.append("nisn", data.formDataStudent.nisn);
    formData.append("name", data.formDataStudent.name);
    formData.append("class", data.formDataStudent.class);

    if (
      data.formDataStudent.file != "string" &&
      data.formDataStudent.file != null
    ) {
      formData.append("photo", data.formDataStudent.file);
    }

    let result = "";

    if (data.formDataStudent.uuid !== ``) {
      result = await ApiPutMultipartCabdin(`/web/student/${data.formDataStudent.uuid}`, formData);
    } else {
      result = await ApiPostMultipartCabdin(`/web/student`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiPenghuniSekolah", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },
  async getExportDataSiswa({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      // sort: `-created_at`,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/student/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listExportStudent: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async getEmployee({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      // sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["page"] = 1;
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/employee`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listEmployee: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async deleteEmployee({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/employee/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Karyawan telah dihapus",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return true;
    }
  },
  async getEmployeeById({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(`/api/web/employee/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
        detailGuru: result.data.data,
      });
    }
  },
  async submitEmployee({ commit, state }) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formDataEmployee.uuid != ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }
    formData.append("nip", data.formDataEmployee.nip);
    formData.append("name", data.formDataEmployee.name);
    formData.append("role", data.formDataEmployee.role);

    if (
      data.formDataEmployee.file != "string" &&
      data.formDataEmployee.file != null
    ) {
      formData.append("photo", data.formDataEmployee.file);
    }

    let result = "";

    if (data.formDataEmployee.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/employee/${data.formDataEmployee.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/employee`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiPenghuniSekolah", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        filterData: "",
        errors: [],

        loading: false,
      });

      return true;
    }
  },
  async getExportDataEmployee({ commit, state }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiPenghuniSekolah", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/employee/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiPenghuniSekolah", {
        listExportEmployee: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcel({ commit }, payload) {
    commit("changeIntegrasiPenghuniSekolah", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiPenghuniSekolah", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    if(payload.type=="teachers"){
      dataSend = {
        teachers: payload.formData
      }
      type = "teacher"
    }else if(payload.type=="employees"){
      dataSend = {
        employees: payload.formData
      }
      type = "employee"
    }else{
      dataSend = {
        students: payload.formData
      }
      type = "student"
    }

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiPenghuniSekolah", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
