import { ApiNotification, ApiGetRequest } from "@/utils/Api";
import moment from "moment";

const state = {
  data: {
    error: false,
    loading: false,
    search: "",
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    typefilter: "bulan",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    classroom: [],
    subject: [],
    classes_select: "",
    role_select: "",
    filter_matapelajaran: "",
    filter_kelas: "",
    columns_staff: [
      {
        prop: "status",
        label: "Status ",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "jam_masuk",
        label: "Jam Masuk ",
        sortable: false,
        minWidth: "90",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "jam_keluar",
        label: "Jam Keluar",
        sortable: false,
        minWidth: "90",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "ketetapan_waktu",
        label: "Ketetapan Waktu",
        sortable: false,
        minWidth: "120",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "jam_kerja",
        label: "Jam Kerja",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "nama_lengkap",
        label: "Nama Lengkap",
        sortable: false,
        minWidth: "120",
      },
      {
        prop: "profesi",
        label: "Profesi",
        sortable: false,
        minWidth: "100",
      },
      {
        fixed: "right",
        label: "Aksi",
        minWidth: "120",
        "class-name": "body-14 col-black-2",
      },
    ],
    geotag: {},
  },
};

const mutations = {
  changePresensiGuru(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getGeoTagging({ commit, state }, payload = null) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/staff/${payload.userId}/geotag`
    );

    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Gagal menampilkan data absensi staff, mohon coba kembali"
      );
    } else {
      commit("changePresensiGuru", {
        loading: false,
        geotag: getResult.data.data,
      });
    }
  },
  async getAbsensiStaff({ commit, state }, payload = null) {
    commit("changePresensiGuru", {
      loading: true,
    });

    if (payload) {
      commit("changePresensiGuru", {
        page: payload,
      });
    }

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/staff?page=${data.page}&limit=${data.limit}&q=${data.search}&role=${data.role_select}`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Gagal menampilkan data absensi staff, mohon coba kembali"
      );
    } else {
      commit("changePresensiGuru", {
        loading: false,
        infoUser: false,
        items: getResult.data.data,
        page: getResult.data.meta && getResult.data.meta.current_page,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showStaff({ commit, state }, id = null) {
    commit("changePresensiGuru", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/staff/${id || ""}`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
        error: getResult.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail data staff");
    } else {
      commit("changePresensiGuru", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async getAbsensiPelajar({ commit, state }) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const { data } = state;
    const dataClasses = [];
    if (data.classes_select && data.classes_select.length > 0) {
      data.classes_select.map((item) =>
        dataClasses.push(`classes[]=${item.id}`)
      );
    }

    let reqClasses = dataClasses.length > 0 ? `&${dataClasses.join("&")}` : "";
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/student?page=${data.page}&limit=${data.limit}&q=${data.search}${reqClasses}`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification("error", "Gagal menampilkan absensi pelajar");
    } else {
      commit("changePresensiGuru", {
        loading: false,
        infoUser: false,
        items: getResult.data.data,
        page: getResult.data.meta && getResult.data.meta.current_page,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showPelajar({ commit, state }, id = null) {
    commit("changePresensiGuru", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/student/${id || ""}`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
        error: getResult.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail data pelajar");
    } else {
      commit("changePresensiGuru", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async getAbsensiKelas({ commit, state }) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/class?page=${data.page ||
        1}&limit=${data.limit}&q=${data.search}${
        data.filter_kelas ? `&class=${data.filter_kelas}` : ""
      }${
        data.filter_matapelajaran ? `&subject=${data.filter_matapelajaran}` : ""
      }`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification("error", "Gagal menampilkan data absensi kelas");
    } else {
      commit("changePresensiGuru", {
        loading: false,
        items: getResult.data.data,
        page: (getResult.data.meta && getResult.data.meta.current_page) || 1,
        total: getResult.data.meta && getResult.data.meta.total,
      });
    }
  },
  async showKelas({ commit, state }, id = null) {
    commit("changePresensiGuru", {
      loading: true,
      infoUser: false,
    });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/attendance/${data.filter_date}/class/${id || ""}`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
        error: getResult.errorList,
      });

      ApiNotification("error", "Gagal menampilkan detail absensi kelas");
    } else {
      commit("changePresensiGuru", {
        loading: false,
        infoUser: getResult.data.data,
        error: false,
      });
    }
  },
  async getClassRoom({ commit }) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/class`);
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiGuru", {
        loading: false,
        classroom: getResult.data.data,
      });
    }
  },
  async getSubject({ commit }) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const getResult = await ApiGetRequest(`school/subject`);
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiGuru", {
        loading: false,
        subject: getResult.data.data,
      });
    }
  },
  async getAbsenKelas({ commit }) {
    commit("changePresensiGuru", {
      loading: true,
    });

    const getResult = await ApiGetRequest(
      `school/subject-schedule/{subject_schedule}/attendance`
    );
    if (getResult.error) {
      commit("changePresensiGuru", {
        loading: false,
      });

      ApiNotification(
        "error",
        "Server kami sedang mengalami kendala, mohon menunggu beberapa saat"
      );
    } else {
      commit("changePresensiGuru", {
        loading: false,
        items: getResult.data.data,
        page: getResult.data.meta.current_page,
        total: getResult.data.meta.total,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
