import {
  ApiGetRequest, //   ApiPostRequest,
  // ApiDeleteRequest,
  // ApiPutRequest,
  //   ApiGetRequestPartisipasi,
  ApiNotification
} from "@/utils/Api";
import { _ } from "vue-underscore";

const state = {
  data: {
    type: "kkm",
    tryout_schedule_id: "",
    student_id: "",
    template_id: "",
    instructions: [],
    instruction_id: "",
    header: false,
    jawaban: [],
    jawabanFormat: [],
    done: true,
    detail: false,
    templates: [],
    page: 1,
    limit: 0,
    current_index_student: 0,
    total_student: 0,
    loading: false,
    tipePenilaian: [
      {
        name: "SUDAH DINILAI",
        active: true
      },
      {
        name: "BELUM DINILAI",
        active: false
      }
    ],
    activeTabPenilaian: "SUDAH DINILAI"
  }
};

const mutations = {
  changeJawabanTryOut(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async validateQuestion({ commit, state }) {
    if (state.data.jawaban.length > 0) {
      let jawabanFormat = state.data.jawaban;
      for (let i = 0; i < jawabanFormat.length; i++) {
        let answer = jawabanFormat[i].question_real.answer;
        if (jawabanFormat[i].question_real.answer_type == "MULTIPLE CHOICE") {
          jawabanFormat[i].type = 1;
          let checkImage = _.where(answer, { image: null });
          let checkAnswer = _.where(answer, { answer: "" });

          if (checkImage.length > 0) {
            jawabanFormat[i].format = 1;
          } else {
            if (checkAnswer.length > 0) {
              jawabanFormat[i].format = 3;
            } else {
              jawabanFormat[i].format = 2;
            }
          }
        } else if (jawabanFormat[i].question_real.answer_type == "ESSAY") {
          jawabanFormat[i].type = 2;
          jawabanFormat[i].format = 1;
        } else if (
          jawabanFormat[i].question_real.answer_type == "SHORT_ESSAY"
        ) {
          jawabanFormat[i].type = 2;
          jawabanFormat[i].format = 2;
        } else if (jawabanFormat[i].question_real.answer_type == "STATEMENT") {
          jawabanFormat[i].type = 3;

          let checkImage = _.where(answer, { image: null });
          let checkShowFalse = _.where(answer, { show_false: true });

          if (checkImage.length > 0) {
            if (checkShowFalse.length > 0) {
              jawabanFormat[i].format = 2;
            } else {
              jawabanFormat[i].format = 4;
            }
          } else {
            jawabanFormat[i].format = 3;
          }

          if (answer.length == 1) {
            jawabanFormat[i].format = 1;
          }
        } else if (jawabanFormat[i].question_real.answer_type == "PAIR") {
          jawabanFormat[i].type = 4;

          let checkImage = _.where(answer, { image1: null });

          if (checkImage.length > 0) {
            jawabanFormat[i].format = 1;
          } else {
            jawabanFormat[i].format = 2;
          }
        }
      }
      commit("changeJawabanTryOut", { jawabanFormat: jawabanFormat });
    }
  },
  async getHeader({ commit, state }) {
    const { data } = state;
    let result = false;

    if (data.type === "kkm") {
      result = await ApiGetRequest(
        `school/tryout/review-kkm/${data.tryout_schedule_id}/${data.student_id}/${data.template_id}/header`
      );
    } else {
      result = await ApiGetRequest(
        `school/tryout/review-akm/${data.tryout_schedule_id}/${data.student_id}/${data.template_id}/header`
      );
    }

    if (result.error) {
      commit("changeJawabanTryOut", {
        header: false
      });
      ApiNotification("error", "Info Soal gagal ditampilkan");
      return false;
    } else {
      commit("changeJawabanTryOut", {
        header: result.data.data
      });

      return true;
    }
  },

  async getInstruction({ commit }) {
    const { data } = state;
    let result = false;

    if (data.type === "kkm") {
      result = await ApiGetRequest(
        `school/tryout/review-kkm/${data.tryout_schedule_id}/${data.student_id}/${data.template_id}/instruction`
      );
    } else {
      result = await ApiGetRequest(
        `school/tryout/review-akm/${data.tryout_schedule_id}/${data.template_id}/instruction`
      );
    }

    if (result.error) {
      commit("changeJawabanTryOut", {
        instructions: false,
        instruction_id: ""
      });
      ApiNotification("error", "Info Soal gagal ditampilkan");
      return false;
    } else {
      commit("changeJawabanTryOut", {
        instructions: result.data.data,
        instruction_id:
          result.data.data.length > 0 ? result.data.data[0].id : ""
      });

      return true;
    }
  },

  async getJawaban({ commit, state, dispatch }) {
    const { data } = state;
    let result = false;

    let params = {
      page: data.page,
      limit: data.limit,
      instruction_id: data.instruction_id,
      done: data.done ? 1 : 0
    };

    if (data.type === "kkm") {
      result = await ApiGetRequest(
        `school/tryout/review-kkm/${data.tryout_schedule_id}/${data.student_id}/${data.template_id}/answer`,
        params
      );
    } else {
      result = await ApiGetRequest(
        `school/tryout/review-akm/${data.tryout_schedule_id}/${data.student_id}/${data.template_id}/answer`,
        params
      );
    }

    if (result.error) {
      commit("changeJawabanTryOut", {
        jawaban: [],
        jawabanFormat: []
      });
      ApiNotification(
        "error",
        result.error ? result.error : "Data jawaban siswa gagal ditampilkan"
      );
      return false;
    } else {
      await commit("changeJawabanTryOut", {
        jawaban: result.data.data
      });
      await dispatch("validateQuestion");
      return true;
    }
  },

  async getDetailTryOut({ commit, state }) {
    const { data } = state;
    const result = await ApiGetRequest(
      `school/training/request/${data.tryout_request_id}`
    );

    if (result.error) {
      ApiNotification("error", "Gagal menampilkan detail try out");
      commit("changeJawabanTryOut", {
        loading: false
      });

      return false;
    } else {
      if (!result.data) {
        ApiNotification(
          "error",
          "CORS Error! Ulangi Lagi, jika masih sama Hubungi Onklas."
        );
        commit("changeJawabanTryOut", {
          loading: false
        });
        return false;
      }
      let data_detail = result.data.data || {};
      let index_student = 0;

      if (
        data_detail.details &&
        data_detail.details.student &&
        data_detail.details.student.length > 0
      ) {
        index_student = data_detail.details.student.findIndex(
          item => item.id === parseInt(data.student_id)
        );

        if (index_student !== -1) {
          index_student += 1;
        } else {
          index_student = 0;
        }
      }

      commit("changeJawabanTryOut", {
        loading: false,
        current_index_student: index_student,
        total_student:
          data_detail.details && data_detail.details.student
            ? data_detail.details.student.length
            : 0,
        detail: result.data.data || false
      });

      return true;
    }
  },

  async getTemplate({ commit }) {
    const { data } = state;
    const result = await ApiGetRequest(
      `school/tryout/review-akm/${data.tryout_schedule_id}/template`
    );

    if (result.error) {
      ApiNotification("error", "Gagal menampilkan template soal");
      commit("changeJawabanTryOut", {
        templates: []
      });

      return false;
    } else {
      commit("changeJawabanTryOut", {
        templates: result.data.data || []
      });

      return true;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
