import repository from "@/repositories/InternshipRepository"
import {HandlerMessageServerError} from "@/utils/Helpers";

export const internshipByIdGet = async (id) => {
	if (!id) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.getInternshipById(id)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}