import { ApiGetRequest, ApiPostRequest } from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    classrooms: [],
  },
};

const mutations = {
  changeClassroom(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getClassRoom({ commit }) {
    const params = {
      sort_field: 'name',
      sort_dir: 'asc',
    }
    const result = await ApiGetRequest(`school/class`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      return [];
    } else {
      // Pastikan ada data sebelum dilakukan pengurutan dan komit
      if (result.data.data && result.data.data.length > 0) {
        // Urutkan array berdasarkan properti 'name' secara ascending
        result.data.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        // Lakukan commit dengan data yang telah diurutkan
        commit('changeClassroom', {
          classrooms: result.data.data,
        });

        // Kembalikan data yang telah diurutkan
        return result.data.data;
      } else {
        // Lakukan commit dengan data yang telah diurutkan
        commit('changeClassroom', {
          classrooms: [],
        });
        // Jika data kosong, kembalikan array kosong dan tidak perlu melakukan komit
        return [];
      }

    }
  },
  async importExcelPelajarKelas({ commit }, payload) {
    commit('changeClassroom', {
      loading: true,
    });

    const result = await ApiPostRequest(
      `school/class/${payload.id}/student`,
      payload.formData
    );
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeClassroom', {
        loading: false,
      });

      return false;
    } else {
      commit('changeClassroom', {
        loading: false,
      });

      const items = result.data.data;

      // check kalo return respone ada error
      const errorItems = await items.filter((item) => {
        if (item.error) {
          return item;
        }
      });

      if (errorItems.length > 0) {
        commit('changeClassroom', {
          jsonData: errorItems,
        });

        let message = '';
        if (errorItems.length != payload.formData.length) {
          message = 'Beberapa data gagal ditambahkan ke server';
        } else {
          message = 'Data gagal ditambahkan ke server';
        }

        Message({
          type: 'error',
          message: message,
        });

        return false;
      } else {
        Message({
          type: 'success',
          message: 'Data Pelajar berhasil ditambahkan',
        });

        return true;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
