import { ApiPostRequestCabdin,ApiGetRequestCabdin, ApiPostMultipartCabdin, ApiDeleteRequestCabdin } from "@/utils/Api";
import { Message } from "element-ui";


const state = {
  data: {
    columns_table: [
      {
        prop: "nama",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tanggal",
        label: "Tanggal",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "kelas",
        label: "Kelas",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "waktu",
        label: "Waktu",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "alokasi_waktu",
        label: "Alokasi",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "mapel",
        label: "Mapel",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pokok",
        label: "Diskusi",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "keterangan",
        label: "Keterangan",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    loading: false,
    listJurnal: [],
    jsonData: [],
    listExportJurnal: [],
    detailJurnalKBM: {},
    detailImport: {},
    formData: {
      uuid:'',
      name : "",
      date : "",
      time : "",
      class : "",
      alocation : "",
      subject : "",
      discussion : "",
      description : "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changeJurnalKBM(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getJurnalKBM({ commit, state }, payload) {
    commit("changeJurnalKBM", {
      loading: true,
    });

    const { data } = state;

    if(payload){
      data.page = payload
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/teaching-journal`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJurnalKBM", {
        loading: false,
      });
    } else {
      commit("changeJurnalKBM", {
        listJurnal: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async submitJurnal({ commit, state }) {
    commit("changeJurnalKBM", {
      loading: true,
    });

    const { data } = state;

    const formDataJurnal = new FormData();
    if (data.formData.uuid != ``) {
      formDataJurnal.append("_method", "PUT");
    } else {
      formDataJurnal.append("_method", "post");
    }
    formDataJurnal.append("name", data.formData.name);
    formDataJurnal.append("date", data.formData.date);
    formDataJurnal.append("time", data.formData.time);
    formDataJurnal.append("class", data.formData.class);
    formDataJurnal.append("alocation", data.formData.alocation);
    formDataJurnal.append("subject", data.formData.subject);
    formDataJurnal.append("discussion", data.formData.discussion);
    formDataJurnal.append("description", data.formData.description);

    let result = "";
    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/teaching-journal/${data.formData.uuid}`,
        formDataJurnal
      );


    } else {
      result = await ApiPostMultipartCabdin(`/web/teaching-journal`, formDataJurnal);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeJurnalKBM", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });


    }
  },

  async deleteJurnalKBM({ commit }, payload) {
    commit("changeJurnalKBM", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(`/web/teaching-journal/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Jurnal telah dihapus",
      });
      commit("changeJurnalKBM", {
        loading: false,
      });

      return true;
    }
  },
  async getExportJurnalKbm({ commit, state }, payload) {
    commit("changeJurnalKBM", {
      loading: true,
    });

    if (payload) {
      await commit("changeJurnalKBM", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/teaching-journal/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeJurnalKBM", {
        loading: false,
      });
    } else {
      commit("changeJurnalKBM", {
        listExportJurnal: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelJurnalKbm({ commit }, payload) {
    commit("changeJurnalKBM", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeJurnalKBM", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "teaching_journals": payload.formData
    }
    type = "teaching-journal"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeJurnalKBM", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
