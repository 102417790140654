const state = {
  data: {
    tabMenu: [
      {
        name: "KamusPoin",
        label: "Kamus Poin",
        lock: false,
        url: "/klaskonseling/kamus-poin"
      },
      {
        name: "Penanganan",
        label: "Penanganan",
        lock: false,
        url: "/klaskonseling/penanganan"
      },
      {
        name: "Rapor",
        label: "Rapor",
        lock: false,
        url: "/klaskonseling/rapor"
      },
      {
        name: "Asesmen",
        label: "Assesment",
        lock: true,
        url: "/klaskonseling/asesmen"
      },
    ],
  }
};

const mutations = {
  changeKlasKonseling(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
