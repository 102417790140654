import { ApiGetRequest, ApiPostRequest, ApiPostMultipart, ApiPutRequest, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        location: {
            columns: [{
                    prop: 'name',
                    label: 'Ruang',
                    'class-name': 'body-14 col-black-2',
                    sortable: true,
                },
                {
                    label: 'Aksi',
                    minWidth: '50',
                    'class-name': 'body-14 col-black-2',
                },
            ],
            items: [],
            sortBy: '',
            sortType: '',
            filter: '',
            errors: [],
            loading: false,
        },
        archive: {
            columns: [{
                    prop: 'name',
                    label: 'Arsip',
                    'class-name': 'body-14 col-black-2',
                    sortable: true,
                },
                {
                    label: 'Aksi',
                    minWidth: '50',
                    'class-name': 'body-14 col-black-2',
                },
            ],
            items: [],
            sortBy: '',
            sortType: '',
            filter: '',
            errors: [],
            loading: false,
        },
        formType: '',
        selectedLocation: null,
        form: {
            id: '',
            name: '',
        },
        formRules: {
            name: [
                { required: true, message: 'Nama tidak boleh kosong', trigger: 'blur' },
            ],
        },
        errors: [],
        locationSelect: [],
        archiveSelect: [],
        formArchive: {
            id: '',
            book_location: '',
            book_archive: '',
            book_location_id: '',
            book_archive_id: '',
        },
        formRulesArchive: {
            book_location_id: [
                { required: true, message: 'Ruang tidak boleh kosong', trigger: 'blur' },
            ],
            book_archive_id: [
                { required: true, message: 'Arsip tidak boleh kosong', trigger: 'blur' },
            ],
        },
        errorsArsip: [],
    },
};

const mutations = {
    changePerpustakaanArsip(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getLocation({ commit, state }, payload) {
        const { data } = state;
        data.location.loading = true

        commit('changePerpustakaanArsip', {
            location: data.location,
        });

        if (payload) {
            await commit('changePerpustakaanArsip', {
                page: payload || data.page,
            });
        }

        const params = {};

        if (data.location.sortBy) {
            params.sort_field = data.location.sortBy
        }

        if (data.location.sortType) {
            params.sort_dir = data.location.sortType
        }

        if (data.location.filter) {
            params.q = data.location.filter
        }

        const result = await ApiGetRequest(`school/library/location`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            data.location.loading = false
            commit('changePerpustakaanArsip', {
                location: data.location,
            });

            return [];
        } else {
            data.location.items = result.data.data
            data.location.loading = false
            await commit('changePerpustakaanArsip', {
                location: data.location,
            });
        }
    },

    async getArchive({ commit, state }, payload) {
        const { data } = state;
        data.archive.loading = true

        commit('changePerpustakaanArsip', {
            archive: data.archive,
        });

        if (payload) {
            await commit('changePerpustakaanArsip', {
                page: payload || data.page,
            });
        }

        const params = {
            book_location_id: data.selectedLocation,
        };

        if (data.archive.sortBy) {
            params.sort_field = data.archive.sortBy
        }

        if (data.archive.sortType) {
            params.sort_dir = data.archive.sortType
        }

        if (data.archive.filter) {
            params.q = data.archive.filter
        }

        const result = await ApiGetRequest(`school/library/archive`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            data.archive.loading = false
            commit('changePerpustakaanArsip', {
                archive: data.archive,
            });

            return [];
        } else {
            data.archive.items = result.data.data
            data.archive.loading = false
            await commit('changePerpustakaanArsip', {
                archive: data.archive,
            });
        }
    },

    async submitLocation({ commit, state }) {
        const { data } = state;

        let endPoint = 'school/library/location'
        let body = {
            name: data.form.name,
        }

        if (data.formType == 'location') {
            data.location.loading = true
            commit('changePerpustakaanArsip', {
                location: data.location,
            });
        } else {
            data.archive.loading = true
            commit('changePerpustakaanArsip', {
                archive: data.archive,
            });
            endPoint = 'school/library/archive'
            body.book_location_id = data.selectedLocation
        }

        let result = ""

        if (data.form.id != '') {
            result = await ApiPutRequest(`${endPoint}/${data.form.id}`, body);
        } else {
            result = await ApiPostRequest(`${endPoint}`, body);
        }

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                data.location.loading = false
                data.archive.loading = false
                commit('changePerpustakaanArsip', {
                    errors: errors,
                    location: data.location,
                    archive: data.archive,
                });
            }
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            data.location.loading = false
            data.archive.loading = false
            commit('changePerpustakaanArsip', {
                errors: [],
                form: {
                    id: '',
                    name: '',
                },
                location: data.location,
                archive: data.archive,
            });

            return true;
        }
    },

    async deleteLocation({ commit, state }, payload) {
        const { data } = state;

        let endPoint = 'school/library/location'

        if (data.formType == 'location') {
            data.location.loading = true
            commit('changePerpustakaanArsip', {
                location: data.location,
            });
        } else {
            data.archive.loading = true
            commit('changePerpustakaanArsip', {
                archive: data.archive,
            });
            endPoint = 'school/library/archive'
        }

        if (payload && payload.id) {
            const result = await ApiDeleteRequest(`${endPoint}/${payload.id}`);

            if (result.error) {
                Message({
                    type: 'error',
                    message: result.error,
                });
                data.location.loading = true
                data.archive.loading = true
                commit('changePerpustakaanArsip', {
                    errors: result.errorList,
                    location: data.location,
                    archive: data.archive,
                });
                return false;
            } else {
                Message({
                    type: 'success',
                    message: 'Data telah dihapus.',
                });
                data.location.loading = false
                data.archive.loading = false
                commit('changePerpustakaanArsip', {
                    errors: [],
                    location: data.location,
                    archive: data.archive,
                });
                return true;
            }
        }
    },

    async getLocationSelect({ commit }) {
        const params = {};

        const result = await ApiGetRequest(`school/library/location`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            commit('changePerpustakaanArsip', {
                locationSelect: [],
            });
            return [];
        } else {
            await commit('changePerpustakaanArsip', {
                locationSelect: result.data.data,
            });
        }
    },

    async getArchiveSelect({ commit, state }) {
        const data = state.data
        const params = {
            book_location_id: data.formArchive.book_location_id,
        };

        const result = await ApiGetRequest(`school/library/archive`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            commit('changePerpustakaanArsip', {
                archiveSelect: [],
            });
            return [];
        } else {
            await commit('changePerpustakaanArsip', {
                archiveSelect: result.data.data,
            });
        }
    },

    async submitBuku({ commit, state }) {
        const { data } = state;

        let endPoint = 'school/library/school-book'
        const formData = new FormData();
        formData.append('_method', 'put');
        formData.append('book_location_id', data.formArchive.book_location_id);
        formData.append('book_archive_id', data.formArchive.book_archive_id);

        let result = await ApiPostMultipart(`${endPoint}/${data.formArchive.id}`, formData);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                commit('changePerpustakaanArsip', {
                    errorsArsip: errors,
                    loading: false,
                });
            }
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            commit('changePerpustakaanArsip', {
                errorsArsip: [],
                loading: false,
            });

            return result.data;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};