import { ApiGetRequest, ApiPostRequest, ApiPutRequest } from "@/utils/Api";
import {
  GlobalHandleErrorApi,
  HandlerNotification,
  HandlerPageStartEnd
} from "@/utils/Helpers";

const state = {
  data: {
    columns: [
      {
        prop: "color",
        label: "Warna",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "score",
        label: "Poin Minimal",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "created_at",
        label: "TglBuat",
        width: "150",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "updated_at",
        label: "TglUbah",
        width: "150",
        "class-name": "body-14 col-black-2"
      },
      {
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    formData: {
      id: "",
      color: "#000000",
      score: 0
    },
    formDataDetail: {},
    items: [],
    filter: "",
    errors: [],
    limit: 10,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    sort_field: "score",
    sort_dir: "asc",
    currentStatus: "Active",
    processAction: "read",
    isProcess: false,
    showmodalAddForm: false,
    loading: false
  }
};

const mutations = {
  changeKlasKonselingPoinWarna(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingPoinWarnaFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingPoinWarnaFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign(
      {},
      state.data.formDataDetail,
      payload
    );
  }
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload == null || payload ? true : false;
    commit("changeKlasKonselingPoinWarna", property);
  },
  async getDataPoinWarna({ commit, dispatch, state }, payload) {
    dispatch("handlerLoading");
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    if (data.filter) params.q = data.filter;
    if (data.sort_field) {
      params.sort_field = data.sort_field;
    }
    if (data.sort_dir) params.sort_dir = data.sort_dir;
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    if (data.processAction == "update") {
      resPage = data.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    // const result = await import("@/db/poinWarna.json");
    const result = await ApiGetRequest(`klaskonseling/color-poin`, params);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    if (result.data == "") {
      HandlerNotification("error", "Response Tidak ditemukan, Hubungi admin.");
      commit("changeKlasKonselingPoinWarna", {
        loading: false
      });
      return;
    }
    commit("changeKlasKonselingPoinWarna", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async postDataPoinWarna({ commit, state, dispatch }) {
    if (await dispatch("validasiFormData")) {
      return;
    }
    dispatch("handlerLoading");
    const { data } = state;
    let result;
    const formDataRequest = {
      color: data.formData.color,
      score: data.formData.score
    };
    if (data.formDataDetail.id) {
      result = await ApiPutRequest(
        `klaskonseling/color-poin/${data.formDataDetail.id}`,
        formDataRequest
      );
    } else {
      result = await ApiPostRequest(
        `klaskonseling/color-poin`,
        formDataRequest
      );
    }
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return;
      }
      if (result.errors) {
        let resError = GlobalHandleErrorApi(result.errors, "string");
        HandlerNotification("error", resError);
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    HandlerNotification();
    dispatch("getDataPoinWarna");
    dispatch("resetFormData");
    dispatch("handlerLoading", false);
    commit("changeKlasKonselingPoinWarna", {
      showmodalAddForm: false
    });
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingPoinWarna", { page: payload ?? 1 });
    dispatch("getDataPoinWarna");
  },
  handlerOnCloseModal({ commit }) {
    commit("changeKlasKonselingPoinWarna", {
      showmodalAddForm: false,
      processAction: `read`
    });
    commit("changeKlasKonselingPoinWarnaFormDataDetail", {
      id: ""
    });
  },
  resetFormData({ commit }, payload = null) {
    if (!payload) {
      commit("changeKlasKonselingPoinWarnaFormData", {
        id: "",
        color: "#000000",
        score: 0
      });
    } else {
      commit("changeKlasKonselingPoinWarnaFormDataDetail", {});
    }
  },
  validasiFormData({ state }) {
    let d = state.data.formData;
    let noValidate = 0;
    let msg = "";
    if (d.handling_score === "") {
      noValidate++;
      msg += "Minimal Score Belum Diisi, ";
    }
    if (d.handling_id === "") {
      noValidate++;
      msg += "Nama Penanganan Belum Diisi";
    }
    if (!noValidate) {
      return false;
    }
    HandlerNotification("error", msg.replace(/,\s*$/, ""));
    return true;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
