import ApiService from "@/api/ApiService"

const PATH_FIRST = "school"
//const MOCK_API = "http://localhost:3000/api"

export default {

	getUserStudent(params) {
		return ApiService.get(`${PATH_FIRST}/student`, { params })
	},
}