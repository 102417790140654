const state = {
  data: {
    tabMenu: [
      {
        name: "Jurusan",
        label: "Jurusan",
        lock: false,
        url: "/pembelajaran/jurusan",
      },
      {
        name: "RuangKelas",
        label: "Kelas",
        lock: false,
        url: "/pembelajaran/ruang-kelas",
      },
      {
        name: "MataPelajaran",
        label: "Mapel",
        lock: false,
        url: "/pembelajaran/mata-pelajaran",
      },
      {
        name: "JadwalPelajaran",
        label: "Jurnal KBM",
        lock: false,
        url: "/pembelajaran/jadwal",
      },
      {
        name: "Materi",
        label: "Materi",
        lock: false,
        url: "/pembelajaran/materi",
      },
      {
        name: "Ujian",
        label: "Ujian",
        lock: false,
        url: "/pembelajaran/ujian",
      },
      {
        name: "JadwalUjian",
        label: "Jadwal Ujian",
        lock: false,
        url: "/pembelajaran/jadwal-ujian",
      },
      {
        name: "Tugas",
        label: "Tugas",
        lock: false,
        url: "/pembelajaran/tugas",
      },
      {
        name: "Magang",
        label: "Magang",
        lock: false,
        url: "/pembelajaran/magang",
      },
    ],
  },
};

const mutations = {
  changePembelajaran(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
