import axios from "axios";
import {Message} from "element-ui";

// Api cabdindik
const ApiCabdindik = axios.create({
	baseURL: process.env.VUE_APP_API_URL_CABDIN,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

// ========= API START

const Api = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

// declare a request interceptor
Api.interceptors.request.use(
	config => {
		const LocalDataVuex = JSON.parse(
			window.localStorage.getItem("storeonklas")
		);
		// kalo url yang diakses dari koperasi ganti pake token koperasi
		if (
			config.url.indexOf("koperasi") !== -1 ||
			config.url.indexOf("klaspay/transaction/topup_inq") !== -1 ||
			config.url.indexOf("klaspay/transaction/topup_trx") !== -1 ||
			(config.url.indexOf("klaspay") !== -1 &&
				config.url.indexOf("klaspay-token") === -1 &&
				config.url.indexOf("klaspay/transaction") === -1 &&
				config.url.indexOf("klaspay/bill") === -1)
		) {
			if (config.url.indexOf("klaspay/mutation/recap") !== -1) {
				// token untuk mutasi keuangan
				config.headers = {
					...config.headers,
					Authorization: `Bearer ${
						LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
							? LocalDataVuex.tokenDanaPartisipasi.token
							: LocalDataVuex
								? LocalDataVuex.userToken
								: ""
					}`
				};
			} else {
				config.headers = {
					...config.headers,
					Authorization: `Bearer ${
						LocalDataVuex && LocalDataVuex.tokenKoperasi
							? LocalDataVuex.tokenKoperasi.token
							: LocalDataVuex
								? LocalDataVuex.userToken
								: ""
					}`
				}
			}
		} else if (
			(config.url.indexOf("klaspay") !== -1 &&
				config.url.indexOf("koperasi") === -1 &&
				config.url.indexOf("klaspay-token") === -1)
		) {
			// token untuk mutasi keuangan
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
						? LocalDataVuex.tokenDanaPartisipasi.token
						: LocalDataVuex
							? LocalDataVuex.userToken
							: ""
				}`
			};
		} else {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${(LocalDataVuex && LocalDataVuex.userToken) ||
				""}`
			};
		}

		return config;
	},
	error => {
		// handle the error
		return Promise.reject(error);
	}
);

export function ApiGetRequest(url, data = {}) {
	return Api.get(url, {
		params: data
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			if (!error.response) {
				console.log("!error.response");
				return {
					error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi"
				};
			}
			return {
				code: error.response ? error.response.status : 500,
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiPostMultipart(url, data = {}) {
	return Api.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiGetUploadFile(url, data = {}, callbackUpload = () => {
}) {
	return Api.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress: event => callbackUpload(event)
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export const ApiPostRequest = (url, data = {}) => {
	return Api.post(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			if (!error.response) {
				console.log("!error.response");
				return {
					error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi"
				};
			}
			return {
				code: error.response ? error.response.status : 500,
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiPutRequest = (url, data = {}) => {
	return Api.put(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			if (!error.response) {
				console.log("!error.response");
				return {
					error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi"
				};
			}
			return {
				code: error.response ? error.response.status : 500,
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiDeleteRequest = (url, data = {}) => {
	return Api.delete(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			if (!error.response) {
				console.log("!error.response");
				return {
					error: "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi"
				};
			}
			return {
				code: error.response ? error.response.status : 500,
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

// ========= API END

const ApiDanaPartisipasi = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

const ApiV2 = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest",
		"X-OK-Version": "2"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

// declare a request API mobile (sementara)
const ApiMobile = axios.create({
	baseURL: process.env.VUE_APP_API_URL_MOBILE,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	credentials: true,
	crossdomain: true
});

// Sementara
ApiMobile.interceptors.request.use(
	config => {
		const LocalDataVuex = JSON.parse(
			window.localStorage.getItem("storeonklas")
		);
		// kalo url yang diakses dari koperasi ganti pake token koperasi
		if (config.url.indexOf("koperasi") !== -1) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.tokenKoperasi
						? LocalDataVuex.tokenKoperasi.token
						: LocalDataVuex
							? LocalDataVuex.userToken
							: ""
				}`
			};
		} else if (
			config.url.indexOf("klaspay") !== -1 &&
			config.url.indexOf("koperasi") === -1 &&
			config.url.indexOf("klaspay-token") === -1
		) {
			// token untuk mutasi keuangan
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
						? LocalDataVuex.tokenDanaPartisipasi.token
						: LocalDataVuex
							? LocalDataVuex.userToken
							: ""
				}`
			};
		} else {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${(LocalDataVuex && LocalDataVuex.userToken) ||
				""}`
			};
		}

		return config;
	},
	error => {
		// handle the error
		return Promise.reject(error);
	}
);

// declare a request interceptor cabdin
ApiCabdindik.interceptors.request.use(
	config => {
		const LocalDataVuex = JSON.parse(
			window.localStorage.getItem("storeonklas")
		);
		// kalo url yang diakses dari koperasi ganti pake token koperasi
		if (config.url.indexOf("integrasi") !== -1) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.cabdinToken
						? LocalDataVuex.cabdinToken
						: LocalDataVuex
							? LocalDataVuex.cabdinToken
							: ""
				}`
			};
		} else {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${(LocalDataVuex && LocalDataVuex.cabdinToken) ||
				""}`
			};
		}

		return config;
	},
	error => {
		// handle the error
		return Promise.reject(error);
	}
);

ApiDanaPartisipasi.interceptors.request.use(
	config => {
		const LocalDataVuex = JSON.parse(
			window.localStorage.getItem("storeonklas")
		);
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${
				LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
					? LocalDataVuex.tokenDanaPartisipasi.token
					: ""
			}`
		};

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// declare a request interceptor
ApiV2.interceptors.request.use(
	config => {
		const LocalDataVuex = JSON.parse(
			window.localStorage.getItem("storeonklas")
		);
		// kalo url yang diakses dari koperasi ganti pake token koperasi
		if (config.url.indexOf("koperasi") !== -1) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.tokenKoperasi
						? LocalDataVuex.tokenKoperasi.token
						: LocalDataVuex
							? LocalDataVuex.userToken
							: ""
				}`
			};
		} else if (
			config.url.indexOf("klaspay") !== -1 &&
			config.url.indexOf("koperasi") === -1 &&
			config.url.indexOf("klaspay-token") === -1
		) {
			// token untuk mutasi keuangan
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${
					LocalDataVuex && LocalDataVuex.tokenDanaPartisipasi
						? LocalDataVuex.tokenDanaPartisipasi.token
						: LocalDataVuex
							? LocalDataVuex.userToken
							: ""
				}`
			};
			console.log(
				LocalDataVuex.tokenDanaPartisipasi.token,
				config.headers,
				"run heree"
			);
		} else {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${(LocalDataVuex && LocalDataVuex.userToken) ||
				""}`
			};
		}

		return config;
	},
	error => {
		// handle the error
		return Promise.reject(error);
	}
);

export function ApiGetRequestMobile(url, data = {}) {
	return ApiMobile.get(url, {
		params: data
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error: (error.response && error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export const ApiPostRequestMobile = (url, data = {}) => {
	return ApiMobile.post(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error: (error.response && error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export function ApiGetRequestV2(url, data = {}) {
	return ApiV2.get(url, {
		params: data
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error: (error.response && error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export const ApiPostRequestV2 = (url, data = {}) => {
	return ApiV2.post(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error: (error.response && error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

/** Api Khusus Dana Partisipasi */

export function ApiGetRequestPartisipasi(url, data = {}) {
	return ApiDanaPartisipasi.get(url, {
		params: data
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiPostMultipartPartisipasi(url, data = {}) {
	return ApiDanaPartisipasi.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiGetUploadFilePartisipasi(
	url,
	data = {},
	callbackUpload = () => {
	}
) {
	return ApiDanaPartisipasi.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress: event => callbackUpload(event)
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export const ApiPostRequestPartisipasi = (url, data = {}) => {
	return ApiDanaPartisipasi.post(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiPutRequestPartisipasi = (url, data = {}) => {
	return ApiDanaPartisipasi.put(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiDeleteRequestPartisipasi = (url, data = {}) => {
	return ApiDanaPartisipasi.delete(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

// Api Khusus Cabdindik

export function ApiGetRequestCabdin(url, data = {}) {
	return ApiCabdindik.get(url, {
		params: data
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiPostMultipartCabdin(url, data = {}) {
	return ApiCabdindik.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiPutMultipartCabdin(url, data = {}) {
	return ApiCabdindik.put(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export function ApiGetUploadFileCabdin(
	url,
	data = {},
	callbackUpload = () => {
	}
) {
	return ApiCabdindik.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		onUploadProgress: event => callbackUpload(event)
	})
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
}

export const ApiPostRequestCabdin = (url, data = {}) => {
	return ApiCabdindik.post(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiPutRequestCabdin = (url, data = {}) => {
	return ApiCabdindik.put(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiDeleteRequestCabdin = (url, data = {}) => {
	return ApiCabdindik.delete(url, data)
		.then(response => response)
		.then(responseJson => {
			return responseJson;
		})
		.catch(error => {
			return {
				error:
					(error.response && error.response.data.error
						? error.response.data.error
						: error.response.data.message) || error,
				errorList:
					error.response && error.response.data && error.response.data.errors
			};
		});
};

export const ApiNotification = (type = "success", message = "") => {
	Message({
		type: type,
		message: message
	});

	return true;
};

export default {
	Api: Api,
	ApiV2: ApiV2,
	ApiMobile: ApiMobile,
	ApiCabdindik: ApiCabdindik
};
