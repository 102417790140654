import {
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "foto",
        label: "Foto",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "date",
        label: "Tanggal",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama",
        label: "Nama Tamu",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "groupData",
        label: "Data",
        "min-width": 100,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "approach",
        label: "Menemui",
        minWidth: 150,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "description",
        label: "Keperluan",
        minWidth: 200,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: 100,
        "class-name": "body-14 col-black-2",
      }
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    listExport: [],
    jsonData: [],
    detailImport: {},
    loading: false,
    resProses: false,
    isUpdate: false,
    listBukuTamu: [
      {
        uuid: "12W",
        foto: "https://monitoring.onklas.id/img/Logo-Onklas.cb353568.png",
        tanggal: "20 November 2022",
        name: "Noviana Badriah, S.Kom., MSI",
        institusi: "Universitas",
        telpon: "08932xxxx",
        alamat: "Surabaya",
        menemui: "Kepala Sekolah",
        keperluan: "Diskusi, koordinasi Sistem",
      },
      {
        uuid: "21QW",
        foto: "https://monitoring.onklas.id/img/Logo-Onklas.cb353568.png",
        tanggal: "1 November 2022",
        name: "Aam Ikhya, S.Pd.",
        institusi: "SMK Global Jaya",
        telpon: "08923xxx",
        alamat: "Surabaya",
        menemui: "Wa.Ka. Sarpras",
        keperluan: "Rapat Tim Pengadaan",
      },
      {
        uuid: "214WQ",
        foto: "https://dev.assets.onklas.id/webschool/81eac1eb6bda4c039cdaa1b5cddd8cc9/E1hhZoi505nOFKJdwVZdppJq8iehpHNeqF63iJsH.jpg",
        tanggal: "2 September 2022",
        name: "Septian Hanafi, M.Kom",
        institusi: "SMK Negeri Surabyaa",
        telpon: "0893462xxxx",
        alamat: "Surabaya",
        menemui: "Kepala Sekolah",
        keperluan: "Koordinasi",
      },
    ],
    detailBukuTamu: {},
    formData: {
      uuid: '',
      address: "",
      approach: "",
      date: "",
      description: "",
      name: "",
      origin: "",
      phone: "",
      mediafile: "",
      file_name: "",
    },
  },
};

const mutations = {
  changeIntegrasiBukuTamu(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getBukuTamu({ commit, state }, payload) {
    commit("changeIntegrasiBukuTamu", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiBukuTamu", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: data.limit,
      page: data.page,
      key: data.key,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/guest-book`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiBukuTamu", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiBukuTamu", {
        listBukuTamu: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async getExport({ commit, state }, payload) {
    commit("changeIntegrasiBukuTamu", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiBukuTamu", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      page: data.page,
      key: data.key,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/guest-book/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiBukuTamu", {
        loading: false,
      });
    } else {
      if (!result.data) {
        Message({
          type: "error", message: "Cors Error, tidak terhubung dengan server",
        });
        commit("changeIntegrasiBukuTamu", {
          loading: false,
        });
        return;
      }
      commit("changeIntegrasiBukuTamu", {
        listExport: result.data.data,
        loading: false,
        resProses: true,
      });
    }
  },
  async clearForm({commit}) {
    commit("changeIntegrasiBukuTamu", {
      loading: false,
      resProses: false,
      formData: {
        uuid: "",
        name: "",
        mediafile: "",
        address: "",
        origin: "",
        phone: "",
        approach: "",
        description: "",
      }
    });
  },
  detailItem({commit},payload) {
    commit("changeIntegrasiBukuTamu", {
      isUpdate: true,
      formData: {
        uuid: payload.uuid,
        name: payload.name,
        date: payload.date,
        address: payload.address,
        origin: payload.origin,
        phone: payload.phone,
        approach: payload.approach,
        description: payload.description,
        mediafile: payload.mediafile,
      }
    });
  },
  async submitBukuTamu({ commit, state, dispatch }) {
    commit("changeIntegrasiBukuTamu", {
      loading: true,
      resProses: false,
    });
    const { data } = state;
    const formData = new FormData();
    if (data.formData.uuid !== ``) {
      formData.append("_method", "PUT");
    }
    formData.append("address", data.formData.address);
    formData.append("approach", data.formData.approach);
    formData.append("description", data.formData.description);
    formData.append("name", data.formData.name);
    formData.append("origin", data.formData.origin);
    formData.append("phone", data.formData.phone);
    let uploadIsImage = ""
    if(!data.isUpdate){
      formData.append("date", data.formData.date);
    }
    if (typeof data.formData.mediafile == "object") {
      uploadIsImage = " Beserta Gambar!"
      formData.append("mediafile", data.formData.mediafile);
    }
    let result = "";
    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/guest-book/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/guest-book`, formData);
    }

    if (result.error) {
      commit("changeIntegrasiBukuTamu", {
        loading: false,
      });
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiBukuTamu", {
          errors: errors,
        });
      }
    } else {
      commit("changeIntegrasiBukuTamu", {
        loading: false,
      });
      if (!result.data) {
        Message({
          type: "error", message: "Cors Error, tidak terhubung dengan server",
        });
        return;
      }
      Message({
        type: "success",
        message: `Data telah tersimpan. ${uploadIsImage}`,
      });
      dispatch("getBukuTamu");
      commit("changeIntegrasiBukuTamu", {
        resProses: true,
        isUpdate: false,
      });
      dispatch("clearForm");
    }
  },
  async deleteBukuTamu({ commit }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
      resProses: false,
    });
    if(!payload.uuid){
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
      });
      return false;
    }
    const result = await ApiDeleteRequestCabdin(`/web/guest-book/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
      });
      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah dihapus",
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
        resProses: true,
      });
      return true;
    }
  },
  async importExcelBukuTamu({ commit }, payload) {
    commit("changeIntegrasiPrestasiSiswa", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiPrestasiSiswa", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "guest_books": payload.formData
    }
    type = "guest-book"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiPrestasiSiswa", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
