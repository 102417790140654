import {ApiGetRequestV2, ApiPostRequest, ApiPostRequestV2} from "@/utils/Api";
import {Message} from "element-ui";
import moment from "moment";
import {AuthService} from "@/services/AuthService";
import store from "@/store";
import {DUPLICATE_CALL_SERVER_MESSAGE} from "@/utils/messageUtils";

const state = {
	data: {
		isLoadingCurrent: false,
		produkMenu: [
			{
				name: "Onklas",
				active: true,
			},
			// {
			//   name: "Produk Lainnya",
			//   active: false,
			// },
			// {
			//   name: "Add On",
			//   active: false,
			// },
			// {
			//   name: "Klasquiz",
			//   active: false,
			// },
		],
		activeProdukMenu: "Onklas",
		produkOnklasList: [
			{
				name: "PAKET 1",
				showInfo: false,
				installed: false,
				monthlyPrice: "666.000",
				monthlyPriceInt: "666000",
				yearlyPrice: "7.992 jt",
				yearlyPriceInt: "7992000",
				icon: "onklas_free.svg",
				colorBadge: "#2C51B0",
				fitures: [
					{
						label: "API integration up to 250 data user",
						checkedIcon: true,
					},
					{
						label: "Dedicated Acces",
						checkedIcon: true,
					},
					{
						label: "Cloud server management services",
						checkedIcon: true,
					},
					{
						label: "storage server 5GB",
						checkedIcon: true,
					},
					{
						label: "Kelola sosial Media Sekolah dan e-book",
						checkedIcon: true,
					},
					{
						label: "Kelola data guru dan karyawan",
						checkedIcon: true,
					},
					{
						label: "Kelola data pelajar",
						checkedIcon: true,
					},
					{
						label: "Kelola mapel, jadwal dan jurusan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi siswa, guru, karyawan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi jurnal KBM",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Kantin sekolah digital",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Pembayaran Uang sekolah",
						checkedIcon: true,
					},

				],
				product: [],
				available: true,
				pending: false,
			},
			{
				name: "PAKET 2",
				showInfo: false,
				installed: false,
				available: false,
				pending: false,
				monthlyPrice: "1.110.000",
				monthlyPriceInt: "1110000",
				yearlyPrice: "13.320 jt",
				yearlyPriceInt: "13320000",
				icon: "onklas_free.svg",
				colorBadge: "#2C51B0",
				fitures: [
					{
						label: "API integration up to 500 data user",
						checkedIcon: true,
					},
					{
						label: "Dedicated Acces",
						checkedIcon: true,
					},
					{
						label: "Cloud server management services",
						checkedIcon: true,
					},
					{
						label: "storage server 10GB",
						checkedIcon: true,
					},
					{
						label: "Kelola sosial Media Sekolah dan e-book",
						checkedIcon: true,
					},
					{
						label: "Kelola data guru dan karyawan",
						checkedIcon: true,
					},
					{
						label: "Kelola data pelajar",
						checkedIcon: true,
					},
					{
						label: "Kelola mapel, jadwal dan jurusan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi siswa, guru, karyawan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi jurnal KBM",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Kantin sekolah digital",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Pembayaran Uang sekolah",
						checkedIcon: true,
					},

				],
				product: [],
			},
			{
				name: "PAKET 3",
				showInfo: false,
				installed: false,
				monthlyPrice: "1.554.000",
				monthlyPriceInt: "1554000",
				yearlyPrice: "18.648 jt",
				yearlyPriceInt: "18648000",
				icon: "onklas_free.svg",
				colorBadge: "#2C51B0",
				fitures: [
					{
						label: "API integration up to 1000 data user",
						checkedIcon: true,
					},
					{
						label: "Dedicated Acces",
						checkedIcon: true,
					},
					{
						label: "Cloud server management services",
						checkedIcon: true,
					},
					{
						label: "storage server 20GB",
						checkedIcon: true,
					},
					{
						label: "Onklas Learning Management System",
						checkedIcon: true,
					},
					{
						label: "Kelola data guru dan karyawan",
						checkedIcon: true,
					},
					{
						label: "Kelola data pelajar",
						checkedIcon: true,
					},
					{
						label: "Kelola mapel, jadwal dan jurusan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi siswa, guru, karyawan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi jurnal KBM",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Kantin sekolah digital",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Pembayaran Uang sekolah",
						checkedIcon: true,
					},
				],
				product: [],
				available: true,
				pending: false,
			},
			{
				name: "PAKET 4",
				showInfo: false,
				installed: false,
				monthlyPrice: "2.220.000",
				monthlyPriceInt: "2220000",
				yearlyPrice: "26.640 jt",
				yearlyPriceInt: "26640000",
				icon: "onklas_free.svg",
				colorBadge: "#2C51B0",
				fitures: [
					{
						label: "API integration unlimited data user",
						checkedIcon: true,
					},
					{
						label: "Dedicated Acces",
						checkedIcon: true,
					},
					{
						label: "Cloud server management services",
						checkedIcon: true,
					},
					{
						label: "storage server 30GB",
						checkedIcon: true,
					},
					{
						label: "Onklas Learning Management System",
						checkedIcon: true,
					},
					{
						label: "Kelola data guru dan karyawan",
						checkedIcon: true,
					},
					{
						label: "Kelola data pelajar",
						checkedIcon: true,
					},
					{
						label: "Kelola mapel, jadwal dan jurusan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi siswa, guru, karyawan",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Presensi jurnal KBM",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Kantin sekolah digital",
						checkedIcon: true,
					},
					{
						label: "Aplikasi Pembayaran Uang sekolah",
						checkedIcon: true,
					},

				],
				product: [],
				available: true,
				pending: false,
			},
			// {
			//   name: "LITE",
			//   showInfo: true,
			//   installed: false,
			//   monthlyPrice: "2000",
			//   yearlyPrice: "15 jt",
			//   icon: "onklas_lite.svg",
			//   colorBadge: "#2C51B0",
			//   fitures: [
			//     {
			//       label: "Sosial media sekolah",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola data guru & staff",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola data pelajar",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola mapel, jadwal & jurusan",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Buat ujian, materi & tugas",
			//       checkedIcon: true,
			//     },
			//   ],
			//   fitureDetails: [
			//     {
			//       name: "PEMBELAJARAN",
			//       detail:
			//         "Anda akan mendapatkan aplikasi ONKLAS untuk pelajar dan tenaga pendidik, dilengkapi dengan fitur belajar mengajar, upload, pengerjaan tugas, materi belajar sesuai kelas dan mata pelajaran serta ujian bisa dilakukan secara mudah dan online untuk semua pelajar. fitur ini dilengkapi dengan kemudahan akses untuk Guru, Pelajar dan Orang Tua",
			//       type: "standart",
			//     },
			//     {
			//       name: "SISTEM MANAJEMEN (CMS)",
			//       detail:
			//         "Kebutuhan administrasi dan manajemen data bisa dilakukan dengan cms sekolah, fitur pengaturan data guru dan staff, data pelajar, penjadwalan kelas, pengaturan mapel, jadwal kelas, pengaturan kantin, tata usaha, dan banyak fitur menarik lainnya",
			//       type: "standart",
			//     },
			//     {
			//       name: "KEUANGAN",
			//       detail:
			//         "Solusi pembayaran non tunai sekolah yang bisa digunakan bayar apa saja. Melalui Klaspay anda bisa melakukan pembayaran secara cashless seperti bayar spp, dana partisipasi, kantin, koperasi, belanja barang atau bayar PPOB (Listrik, PDAM, BPJS, Voucher, dll). Klaspay dapat juga digunakan untuk layanan pembayaran dengan rekan kami melalui Offline, Online serta pembayaran dengan kartu RFID",
			//       type: "standart",
			//     },
			//     {
			//       name: "SOSIAL MEDIA",
			//       detail:
			//         "Berinteraksi di sekolah jadi lebih seru, kini pelajar dan pihak sekolah bisa selalu eksis dan terus update dengan fitur sosial media, buat postingan, upload foto, berikan caption, dan dapatkan komentar dan suka dari teman-temanmu, ingin postinganmu lebih menarik kamu juga bisa upload e-book dan bagikan kegiatan menarik lainnya untuk sekolahmu",
			//       type: "extra",
			//     },
			//   ],
			//   product: [],
			//   available: true,
			//   pending: false,
			// },
			// {
			//   name: "PRO",
			//   showInfo: true,
			//   installed: false,
			//   monthlyPrice: "3000",
			//   yearlyPrice: "22 jt",
			//   icon: "onklas_pro.svg",
			//   colorBadge: "#FF9F00",
			//   fitures: [
			//     {
			//       label: "Sosial media sekolah",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola data guru & staff",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola data pelajar",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kelola mapel, jadwal & jurusan",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Buat ujian, materi & tugas",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Kewirausahaan (Klasbisnis)",
			//       checkedIcon: true,
			//     },
			//     {
			//       label: "Klastracking",
			//       checkedIcon: true,
			//     },
			//   ],
			//   fitureDetails: [
			//     {
			//       name: "PEMBELAJARAN",
			//       detail:
			//         "Anda akan mendapatkan aplikasi ONKLAS untuk pelajar dan tenaga pendidik, dilengkapi dengan fitur belajar mengajar, upload, pengerjaan tugas, materi belajar sesuai kelas dan mata pelajaran serta ujian bisa dilakukan secara mudah dan online untuk semua pelajar. fitur ini dilengkapi dengan kemudahan akses untuk Guru, Pelajar dan Orang Tua",
			//       type: "standart",
			//     },
			//     {
			//       name: "SISTEM MANAJEMEN (CMS)",
			//       detail:
			//         "Kebutuhan administrasi dan manajemen data bisa dilakukan dengan cms sekolah, fitur pengaturan data guru dan staff, data pelajar, penjadwalan kelas, pengaturan mapel, jadwal kelas, pengaturan kantin, tata usaha, dan banyak fitur menarik lainnya",
			//       type: "standart",
			//     },
			//     {
			//       name: "KEUANGAN",
			//       detail:
			//         "Solusi pembayaran non tunai sekolah yang bisa digunakan bayar apa saja. Melalui Klaspay anda bisa melakukan pembayaran secara cashless seperti bayar spp, dana partisipasi, kantin, koperasi, belanja barang atau bayar PPOB (Listrik, PDAM, BPJS, Voucher, dll). Klaspay dapat juga digunakan untuk layanan pembayaran dengan rekan kami melalui Offline, Online serta pembayaran dengan kartu RFID",
			//       type: "standart",
			//     },
			//     {
			//       name: "SOSIAL MEDIA",
			//       detail:
			//         "Berinteraksi di sekolah jadi lebih seru, kini pelajar dan pihak sekolah bisa selalu eksis dan terus update dengan fitur sosial media, buat postingan, upload foto, berikan caption, dan dapatkan komentar dan suka dari teman-temanmu, ingin postinganmu lebih menarik kamu juga bisa upload e-book dan bagikan kegiatan menarik lainnya untuk sekolahmu",
			//       type: "extra",
			//     },
			//     {
			//       name: "KEWIRAUSAHAAN (KLASBISNIS)",
			//       detail:
			//         "Akses dan peluang besar bagi siapa saja khusus pelajar yang ingin memperoleh penghasilan dengan berjualan produk, mulai belajar berwirausaha dipandu dengan fitur e-commerce dari kami, upload produk, tentukan harga jual dan raih keuntungan dengan berjualan di market place kami di Aplikasi ONKLAS",
			//       type: "extra",
			//     },
			//     {
			//       name: "KLASTRACKING",
			//       detail:
			//         "Ingin mengetahui kemampuan, minat dan bakat pelajar Anda?, ketahui potensi siswa-siswi dengan fitur Klastracking yang bekerja sama dengan instansi dan perguruan yang berkompeten di bidangnya, dapatkan penilaian personal untuk pelajar dan kemudahan akses perolehan nilai di fitur Klastracking ",
			//       type: "extra",
			//     },
			//   ],
			//   product: [],
			//   available: true,
			//   pending: false,
			// },
		],
		paymentType: "bulanan",
		selectedProduct: null,
		selectedPaket: [],
		formPemesanan: {
			name: "",
			phone: "",
			email: "",
			date: "",
			school_name: "",
			school_address: "",
			product_school_id: "",
		},
		add_ons_links: {
			danasekolah: "/pembayaran-spp/biaya-admin",
			kewirausahaan: "/klasbisnis/biaya-admin",
			koperasi: "/koperasi/biaya-admin",
			kantin: "/koperasi/kantin",
			prokes: "/prokes/biaya-admin",
			danapartisipasi: "/dana-partisipasi/biaya-admin",
			ppob: "/ppob/biaya-admin",
			klassign: `/klassign`,
		},
		formPemesananRules: {
			name: [
				{required: true, message: "Nama tidak boleh kosong", trigger: "blur"},
			],
			phone: [
				{
					required: true,
					message: "Nomer telepon tidak boleh kosong",
					trigger: "blur",
				},
			],
			email: [
				{
					required: true,
					message: "Email tidak boleh kosong",
					trigger: "blur",
				},
				{type: "email", message: "Email harus berupa email", trigger: "blur"},
			],
		},
		errors: [],
		produkLainList: [
			{
				name: "Web School",
				display_name: "Klasite",
				showInfo: true,
				installed: false,
				activeDate: "2020-05-31",
				price: "0",
				icon: "klasite_img.svg",
				colorBadge: "#2C51B0",
				detail:
					"Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
				fitures: [
					{
						label: "Gratis pilihan tema",
						checkedIcon: true,
					},
					{
						label: "Gratis domain & hosting",
						checkedIcon: true,
					},
					{
						label: "Full cms website sekolah",
						checkedIcon: true,
					},
					{
						label:
							"Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
						checkedIcon: true,
					},
					{
						label: "Integrasi website dengan sosial media ONKLAS",
						checkedIcon: true,
					},
					{
						label: "PPDB dan PSB",
						checkedIcon: true,
					},
				],
				product: [],
				pending: false,
			},
			{
				name: "Pustaka",
				display_name: "Klas Pustaka",
				showInfo: true,
				installed: false,
				activeDate: "2020-05-31",
				price: "0",
				icon: "klaspustaka_img.svg",
				colorBadge: "#2C51B0",
				detail:
					"Ingin meningkatkan efisiensi kerja pustakawan dan meningkatan minat membaca pelajar?, dengan Klaspustaka anda bisa melakukan pengarsipan, penyimpanan database dan pencatatan stok buku perpustakaan Sekolah Anda, dengan fitur dari Klaspustaka yang terintegrasi dengan ISBN, efisiensi katalog buku menjadi lebih mudah, Fitur Klaspustaka juga mendukung pencatatan dan riwayat peminjaman buku keluar dan masuk, melihat daftar pengunjung, stok dan arsip buku, semuanya cukup mudah hanya dengan Klaspustaka.",
				fitures: [
					{
						label: "Unlimited penyimpanan arsip & list buku",
						checkedIcon: true,
					},
					{
						label: "Gratis web katalog perpustakaan",
						checkedIcon: true,
					},
					{
						label: "Integrasi ISBN",
						checkedIcon: true,
					},
					{
						label: "Fitur pencatatan pengunjung perpustakaan",
						checkedIcon: true,
					},
					{
						label: "Fitur peminjaman buku & riwayat peminjaman",
						checkedIcon: true,
					},
				],
				product: [],
				pending: false,
			},
			{
				name: "Klastime",
				display_name: "Klastime",
				showInfo: true,
				installed: false,
				activeDate: "2020-05-31",
				price: "0",
				icon: "klastime_img.svg",
				colorBadge: "#2C51B0",
				detail:
					"Kelola absensi untuk pelajar, guru dan karyawan Sekolahmu, dengan Klastime pelacakan waktu dengan mudah hanya dengan satu alat dan terintegrasi dengan mesin absensi. Bebaskan diri Anda dari tugas administrasi yang  tidak efisien dan berulang. Kelola presensi dan ketidakhadiran pelajar dan staff Anda dengan gambaran yang jelas, dapatkan rekap kehadiran dan atur waktu jadwal masuk Sekolahmu.",
				fitures: [
					{
						label: "Pengaturan presensi pelajar, guru & staff",
						checkedIcon: true,
					},
					{
						label: "Pencatatan rekap kehadiran per/kelas",
						checkedIcon: true,
					},
					{
						label: "Pencatatan rekap presensi pelajar, guru & staff",
						checkedIcon: true,
					},
					{
						label: "Pengaturan jam masuk pelajar, guru & staff",
						checkedIcon: true,
					},
					{
						label: "Integrasi mesin absensi dan fingerprint",
						checkedIcon: true,
					},
				],
				product: [],
				pending: false,
			},
			{
				name: "Monitoring",
				display_name: "Klas Monitoring",
				showInfo: true,
				installed: false,
				activeDate: "2020-05-31",
				price: "0",
				icon: "klasmonitoring_img.svg",
				colorBadge: "#2C51B0",
				detail:
					"Klasmonitoring memungkinkan Anda mengetahui data spesifik aktivitas belajar mengajar di Sekolah Anda, Dengan fitur analitik, bagan, diagram dan peringkat yang membantu Sekolah untuk membuat keputusan dan analisa yang lebih terukur. Klasmonitoring membantu Anda untuk mendapatkan analisa pengguna dan memantau kegiatan di Sekolah , statistik aktivitas, peringkat, persentase kehadiran, peringkat nilai, persentase belajar siswa, informasi kelas dengan metode diagram dan pengukur target. Buat keputusan berdasarkan data dan laporan, dan tingkatkan kesuksesan Sekolah dengan data pengukur terbaik dari Klasmonitoring.",
				fitures: [
					{
						label: "Fitur Analitik Sekolah",
						checkedIcon: true,
					},
					{
						label: "Fitur Peringkat",
						checkedIcon: true,
					},
					{
						label: "Fitur Monitoring",
						checkedIcon: true,
					},
					{
						label: "Integrasi Slide Show",
						checkedIcon: true,
					},
				],
				product: [],
				pending: false,
			},
		],
		columns: [
			{
				prop: "name",
				label: "Name",
				minWidth: "100",
			},
			{
				prop: "email",
				label: "Email",
				minWidth: "100",
			},
			{
				prop: "school",
				label: "Sekolah",
				minWidth: "100",
			},
			{
				prop: "date",
				label: "Waktu Pengajuan",
				minWidth: "100",
			},
		],
		produkTersedia: [],
		produkAddonList: [
			// {
			//     name: "SPP",
			//     display_name: "SPP Sekolah",
			//     showInfo: true,
			//     installed: false,
			//     colorBadge: "#2C51B0",
			//     icon: "spp_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: false,
			// },
			// {
			//     name: "Dana Sekolah",
			//     showInfo: true,
			//     installed: true,
			//     colorBadge: "#2C51B0",
			//     icon: "danapartisipasi_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: true,
			// },
			// {
			//     name: "Dana Partisipasi",
			//     showInfo: true,
			//     installed: false,
			//     colorBadge: "#2C51B0",
			//     icon: "danapartisipasi_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: false,
			// },
			// {
			//     name: "Kewirausahaan",
			//     showInfo: true,
			//     installed: true,
			//     colorBadge: "#2C51B0",
			//     icon: "kewirausahaan_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: true,
			//     active: false,
			// },
			// {
			//     name: "Prokes",
			//     showInfo: true,
			//     installed: true,
			//     colorBadge: "#2C51B0",
			//     icon: "prokes_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: true,
			//     active: false,
			// },
			// {
			//     name: "PPOB",
			//     showInfo: true,
			//     installed: true,
			//     colorBadge: "#2C51B0",
			//     icon: "ppob_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: false,
			// },
			// {
			//     name: "Koperasi",
			//     showInfo: true,
			//     installed: false,
			//     colorBadge: "#2C51B0",
			//     icon: "koperasi_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: false,
			// },
			// {
			//     name: "Kantin",
			//     showInfo: true,
			//     installed: false,
			//     colorBadge: "#2C51B0",
			//     icon: "kantin_img.svg",
			//     detail: "Platform pembuatan situs web yang ramah pengguna. Teknologi intuitif dan fitur bawaan kami yang kuat memberi Sekolahmu kebebasan untuk merancang situs web profesional dengan cara yang mudah. Klasite menawarkan seperangkat alat lengkap untuk membangun keberadaan online website Sekolah Anda hingga ditemukan di mesin telusur. Website Sekolah sangat dibutuhkan untuk peningkatan profil dan mengenalkan sekolah Anda secara luas, dengan Klasite cukup mudah, pilih temamu, foto kegiatan, pengumuman, informasi, profil jajaran Sekolah, testimonial dan banyak lagi semua tersedia untuk Anda.",
			//     fitures: [{
			//             label: "Gratis pilihan tema",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Gratis domain & hosting",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Full cms website sekolah",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Fitur keunggulan Sekolah, Berita, Pengumuman, Galeri, Testimoni, Ekstrakulikuler, Jurusan, Profil, dll.",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "Integrasi website dengan sosial media ONKLAS",
			//             checkedIcon: true,
			//         },
			//         {
			//             label: "PPDB dan PSB",
			//             checkedIcon: true,
			//         },
			//     ],
			//     product: [],
			//     pending: false,
			//     active: false,
			// },
		],
		loading: false,
		history_pengajuan: false,
		items_history: [],
		add_ons_detail: {
			danasekolah: {
				description:
					"Sistem Pembayaran Uang Sekolah Terkini, platform pembayaran SPP, manajemen sistem pembayaran  untuk memantau pembayaran bulanan pelajar di sekolah, dilengkapi fitur diskon untuk pelajar, sistem ini terintegrasi dengan aplikasi Onklas untuk pelajar dan Orang tua, sehingga memudahkan mendapatkan informasi tagihan dan dapat dibayarkan oleh orang tua siswa melalui beragam metode pembayaran. ",
				features: [
					"Pengaturan pembayaran SPP sekolah (per/kelas & per/pelajar)",
					"Fitur potongan / diskon pelajar",
					"Integrasi pembayaran SPP dengan aplikasi Onklas untuk Pelajar & Orang tua/Wali",
					"Integrasi pembayaran dengan Klaspay ataupun menggunakan channel bank lain / minimarket",
					"Monitoring pembayaran SPP pelajar dengan fitur cetak/rekap riwayat pembayaran SPP ",
				],
			},
			danapartisipasi: {
				description:
					"Sistem Pembayaran Patungan Terkini, manajemen sistem pembayaran untuk memantau pembayaran bulanan pelajar di sekolah, dilengkapi fitur diskon untuk pelajar, sistem ini terintegrasi dengan aplikasi Onklas untuk pelajar dan Orang tua, sehingga memudahkan mendapatkan informasi tagihan dan dapat dibayarkan oleh orang tua siswa melalui beragam metode pembayaran. ",
				features: [
					"Pengaturan pembayaran SPP sekolah (per/kelas & per/pelajar)",
					"Fitur potongan / diskon pelajar",
					"Integrasi pembayaran SPP dengan aplikasi Onklas untuk Pelajar & Orang tua/Wali",
					"Integrasi pembayaran dengan Klaspay ataupun menggunakan channel bank lain / minimarket",
					"Monitoring pembayaran SPP pelajar dengan fitur cetak/rekap riwayat pembayaran SPP ",
				],
			},
			kewirausahaan: {
				description:
					"Sistem Pembayaran Patungan Terkini, manajemen sistem pembayaran untuk memantau pembayaran bulanan pelajar di sekolah, dilengkapi fitur diskon untuk pelajar, sistem ini terintegrasi dengan aplikasi Onklas untuk pelajar dan Orang tua, sehingga memudahkan mendapatkan informasi tagihan dan dapat dibayarkan oleh orang tua siswa melalui beragam metode pembayaran. ",
				features: [
					"Pengaturan pembayaran SPP sekolah (per/kelas & per/pelajar)",
					"Fitur potongan / diskon pelajar",
					"Integrasi pembayaran SPP dengan aplikasi Onklas untuk Pelajar & Orang tua/Wali",
					"Integrasi pembayaran dengan Klaspay ataupun menggunakan channel bank lain / minimarket",
					"Monitoring pembayaran SPP pelajar dengan fitur cetak/rekap riwayat pembayaran SPP ",
				],
			},
			koperasi: {
				description:
					"Sistem Pembayaran Patungan Terkini, manajemen sistem pembayaran untuk memantau pembayaran bulanan pelajar di sekolah, dilengkapi fitur diskon untuk pelajar, sistem ini terintegrasi dengan aplikasi Onklas untuk pelajar dan Orang tua, sehingga memudahkan mendapatkan informasi tagihan dan dapat dibayarkan oleh orang tua siswa melalui beragam metode pembayaran. ",
				features: [
					"Pengaturan pembayaran SPP sekolah (per/kelas & per/pelajar)",
					"Fitur potongan / diskon pelajar",
					"Integrasi pembayaran SPP dengan aplikasi Onklas untuk Pelajar & Orang tua/Wali",
					"Integrasi pembayaran dengan Klaspay ataupun menggunakan channel bank lain / minimarket",
					"Monitoring pembayaran SPP pelajar dengan fitur cetak/rekap riwayat pembayaran SPP ",
				],
			},
		},
	},
};

const mutations = {
	changeBerandaProduk(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {
	async getHistoryPengajuan({commit}, payload = null) {
		let result = await ApiGetRequestV2(
			`school/product/${payload.id}/apply-history`
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});

			commit("changeBerandaProduk", {
				history_pengajuan: false,
				items_history: [],
			});

			return false;
		} else {
			let data_pengajuan = result.data.data || [];
			let items_history = data_pengajuan.map((item) => {
				let school_request = item.school_request || {};
				return {
					...school_request,
					school: school_request.school_name,
					date: item.created_at || "",
				};
			});

			commit("changeBerandaProduk", {
				history_pengajuan: result.data.data,
				items_history: items_history,
			});
			return true;
		}
	},

	async submitpengajuan({commit, state}) {
		const {data} = state;
		data.formPemesanan.date = Date.now();
		commit("changeBerandaProduk", {
			formPemesanan: data.formPemesanan,
		});

		let result = await ApiPostRequest(`school-request`, data.formPemesanan);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key],
					});
				}
				commit("changeBerandaProduk", {
					errors: errors,
				});
			}
		} else {
			Message({
				type: "success",
				message: "Form pengajuan telah terkirim.",
			});
			return true;
		}
	},

	async submitProdukRequest({commit, state}) {
		const {data} = state;
		let formData = {
			payment_type: data.paymentType == "bulanan" ? "monthly" : "anually",
		};
		let result = await ApiPostRequestV2(
			`school/product/${data.formPemesanan.product_school_id}/apply`,
			formData
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key],
					});
				}
				commit("changeBerandaProduk", {
					errors: errors,
				});
			}
		} else {
			Message({
				type: "success",
				message: "Produk telah diajukan.",
			});
			return result.data.data;
		}
	},

	async getProdukOnklas({commit}) {
		commit("changeBerandaProduk", {
			produkTersedia: [],
		});

		const result = await ApiGetRequestV2(`school/product/item`);

		if (!result.error) {
			await commit("changeBerandaProduk", {
				produkTersedia: result.data.data,
			});
		}
	},

	async getProdukUpgrade({commit, state}, payload) {
		let produkTersedia = state.data.produkTersedia;

		const params = {
			product_id: payload.id,
		};
		const result = await ApiGetRequestV2(`school/product-upgrade/item`, params);

		if (!result.error) {
			for (let i = 0; i < result.data.data.length; i++) {
				produkTersedia.push(result.data.data[i]);
			}

			commit("changeBerandaProduk", {
				produkTersedia: produkTersedia,
			});
		}
	},

	async getProdukAddon({commit}) {
		commit("changeBerandaProduk", {
			produkAddonList: [],
			loading: true,
		});

		const result = await ApiGetRequestV2(`school/addons`);

		if (!result.error) {
			let addonData = [];
			for (let i = 0; i < result.data.data.length; i++) {
				let icon = "spp_img.svg";
				let tempAddon = {
					name: result.data.data[i].name,
					display_name: result.data.data[i].display_name
						? result.data.data[i].display_name
						: result.data.data[i].name,
					showInfo: true,
					installed: false,
					colorBadge: "#2C51B0",
					icon: icon,
					detail: result.data.data[i].description,
					fitures: [],
					product: result.data.data[i],
					pending: false,
					active: result.data.data[i].status == "Activated" ? true : false,
					is_show: result.data.data[i].is_show,
				};
				addonData.push(tempAddon);
			}

			await commit("changeBerandaProduk", {
				produkAddonList: addonData,
				loading: false,
			});

			await commit(
				"updateState",
				{
					menuAddOns: {
						menu: result.data.data,
						last_check: moment().format("YYYY-MM-DD HH:mm"),
					},
				},
				{root: true}
			);
		} else {
			commit("changeBerandaProduk", {
				loading: false,
			});
		}
	},

	async getProfileSchool({state, commit}) {
		if (state.data.isLoadingCurrent) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		commit("changeBerandaProduk", {
			isLoadingCurrent: true
		})
		try {
			const authService = new AuthService()
			const result = await authService.currentGet()
			//const result = await ApiGetRequestV2(`school/current`);

			store.commit("updateState", {
				user: result.data.data
			})

			return result.data.data;
		} catch (e) {
			return false
		} finally {
			commit("changeBerandaProduk", {
				isLoadingCurrent: false
			})
		}
	},

	async submitProdukAddonRequest({commit, state}) {
		const {data} = state;
		let formData = {};
		let result = await ApiPostRequestV2(
			`school/addons/${data.formPemesanan.product_school_id}/activate`,
			formData
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key],
					});
				}
				commit("changeBerandaProduk", {
					errors: errors,
				});
			}

			return false;
		} else {
			Message({
				type: "success",
				message: "Add On telah diajukan.",
			});
			return result.data.data;
		}
	},

	async activeAddon({commit}, payload) {
		const result = await ApiPostRequest(`school/addons/${payload.id}/activate`);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key],
					});
				}
				commit("changeBerandaProduk", {
					errors: errors,
				});
			}

			return [];
		} else {
			Message({
				type: "success",
				message: "Add On telah diaktifkan kembali",
			});
			return true;
		}
	},

	async deactivateAddon({commit}, payload) {
		const result = await ApiPostRequest(
			`school/addons/${payload.id}/deactivate`
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			if (result.errorList) {
				let errors = [];
				for (const [key, value] of Object.entries(result.errorList)) {
					errors.push({
						message: value[0],
						path: [key],
					});
				}
				commit("changeBerandaProduk", {
					errors: errors,
				});
			}

			return [];
		} else {
			Message({
				type: "success",
				message: "Add On telah dinonaktifkan",
			});
			return true;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
