import repository from "@/repositories/PresensiRepository"
import {HandlerMessageServerError} from "@/utils/Helpers";

// NVR START ============================
export const nvrProxyGet = async (payload) => {
	const {type, page, limit, q} = payload
	if (type && !['GROUP','SCHOOL'].includes(type)) {
		throw new Error("type must GROUP | SCHOOL.")
	}

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		type: type || undefined
	}

	try {
		return await repository.getNvrProxy(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const nvrProxyByIdGet = async (nvrId) => {
	if (!nvrId) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.getNvrProxyById(nvrId)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const nvrProxyPost = async (payload) => {
	const {name, secret, type} = payload
	if (!name || !secret || !type) {
		throw new Error("Client Request not complete.")
	}
	if (!["SCHOOL", "GROUP"].includes(type)) {
		throw new Error("Client Request type not same.")
	}

	const formData = {
		name: name,
		secret: secret,
		type: type,
	}

	try {
		return await repository.postNvrProxy(formData)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const nvrProxyPut = async (nvrId, payload) => {
	const {name, type} = payload
	if (!name || !type || !nvrId) {
		throw new Error("Client Request not complete.")
	}
	if (!["SCHOOL", "GROUP"].includes(type)) {
		throw new Error("Client Request type not same.")
	}

	const formData = {
		name: name,
		type: type,
	}

	try {
		return await repository.putNvrProxy(nvrId, formData)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const nvrProxyDelete = async (nvrId) => {
	if (!nvrId) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.deleteNvrProxy(nvrId)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}
// NVR END ============================

// DEVICE START ============================
export const deviceGet = async (payload) => {
	const {device_type, page, limit, q, school_nvr_device_id} = payload
	if (!device_type) {
		throw new Error("Client Request not complete.")
	}

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		device_type: device_type || undefined,
		school_nvr_device_id: school_nvr_device_id || undefined,
	}

	try {
		return await repository.getDevice(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const deviceByIdGet = async (deviceId) => {
	if (!deviceId) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.getDeviceById(deviceId)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const devicePost = async (payload) => {
	const {
		name,
		sn,
		type,
		secret,
		school_nvr_device_id,
		tag,
		device_type
	} = payload
	if (!name || !secret || !type || !sn || !school_nvr_device_id || !device_type) {
		throw new Error("Client Request not complete.")
	}
	if (!["IN", "OUT", "CLASS"].includes(type)) {
		throw new Error("Client Request type not same.")
	}

	const formData = {
		device_type: device_type,
		name: name,
		secret: secret,
		sn: sn,
		type: type || undefined,
		tag: tag || 'SCHOOL',
		school_nvr_device_id: school_nvr_device_id
	}

	try {
		return await repository.postDevice(formData)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const devicePut = async (deviceId, payload) => {
	const {
		name,
		sn,
		type,
		secret,
		school_nvr_device_id,
		tag,
		device_type
	} = payload
	console.log(payload)
	if (!deviceId || !name || !secret || !type || !sn || !school_nvr_device_id || !device_type) {
		throw new Error("Client Request not complete.")
	}
	if (!["IN", "OUT", "CLASS"].includes(type)) {
		throw new Error("Client Request type not same.")
	}

	const formData = {
		device_type: device_type,
		name: name,
		secret: secret,
		sn: sn,
		type: type || undefined,
		tag: tag || '',
		school_nvr_device_id: school_nvr_device_id
	}

	try {
		return await repository.putDevice(deviceId, formData)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const deviceDelete = async (deviceId) => {
	if (!deviceId) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.deleteDevice(deviceId)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}
// DEVICE END ============================

// DEVICE START ==========================

export const klastimeCctvUserSummary = async (payload) => {
	const {include, page, limit, q, majors, classes} = payload
	if (typeof include !== 'object' || !include.length) {
		throw new Error("include must array ['avatar', 'photo']")
	}
	if (typeof majors !== 'object') {
		throw new Error("majors must array")
	}
	if (typeof classes !== 'object') {
		throw new Error("classes must array")
	}

	const handleInclude = []
	for (let value of include) {
		if (['photo','avatar'].includes(value)) {
			handleInclude.push(value)
		}
	}

	const params = {
		q: q || undefined,
		limit: parseInt(limit) || undefined,
		page: parseInt(page) || undefined,
		include: handleInclude,
	}

	if (majors.length && majors[0]) {
		params.majors = majors
	}

	if (classes.length && classes[0]) {
		params.classes = classes
	}

	try {
		return await repository.getUserSumaryKlastimeCctv(params)
	} catch (error) {
		console.error("call server error:", error)
		throw error
	}
}

// DEVICE END ============================

export const klastimeUserGet = async (payload) => {
	const {type, page, limit, q, classId, grade} = payload
	if (!type) {
		throw new Error("Client Request not complete.")
	}

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		type: type || undefined,
		class: classId || undefined,
		grade: grade || undefined
	}

	try {
		return await repository.getUserInKlastime(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const klastimeUserPost = async (payload) => {
	const {type, page, limit, q, classId, grade} = payload
	if (!type) {
		throw new Error("Client Request not complete.")
	}

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		type: type || undefined,
		class: classId || undefined,
		grade: grade || undefined
	}

	try {
		return await repository.getUserInKlastime(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const klastimeUserPhotosCctvByUuidGet = async (uuid) => {
	if (!uuid) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.getUserPhotosCctv(uuid)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const klastimeUserPhotosCctvByUuidPost = async (uuid, payload) => {
	if (!uuid) {
		throw new Error("Client Request not complete.")
	}
	if (typeof payload !== 'object') {
		throw new Error("payload Client Request not complete.")
	}

	const formData = new FormData();
	payload.forEach(file => {
		formData.append('photos[]', file);
	});

	try {
		return await repository.postUserPhotosCctv(uuid, formData)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const klastimeUserPhotosCctvByUuidDelete = async (uuid, id) => {
	if (!uuid || !id) {
		throw new Error("Client Request not complete.")
	}

	try {
		return await repository.deleteUserPhotosCctv(uuid, id)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}
