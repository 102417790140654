const state = {
    data: {
        columns: [{
                prop: 'display_name',
                label: 'Produk',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'periode',
                label: 'Periode',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'approved_at',
                label: 'Aktifasi',
                'class-name': 'body-14 col-black-2',
                sortable: true,
            },
            {
                prop: 'expired_at',
                label: 'Hingga',
                'class-name': 'body-14 col-black-2',
            },
        ],
        items: [],
        sortBy: '',
        sortType: '',
        filter: '',
        errors: [],
        loading: false,
        page: 1,
        total: 1,
        limit: 1,
    },
};

const mutations = {
    changeBerandaPembelian(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    getBerandaPembelian() {

    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};