import {
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    columns_table: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "nama",
        label: "Nama",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "jenis_kegiatan",
        label: "Jenis Kegiatan",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tahun",
        label: "Tahun",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "penyelenggara",
        label: "Penyelenggara",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "no_sertifikat",
        label: "No Sertifikat",
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "keterangan",
      //   label: "Keterangan",
      //   "class-name": "body-14 col-black-2",
      // },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],

    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    q: "",
    loading: false,
    listPengembanganSDM: [],
    jsonData: [],
    listExportPengembanganSDM: [],
    detailPengembanganSDM: {},
    detailImport: {},
    formData: {
      uuid: "",
      name: "",
      activity: "",
      year: "",
      organizer: "",
      description: "",
      certificate_number: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changePengembanganSDM(state, objUpdate) {
    state.data = Object.assign({}, state.data, objUpdate);
  },
};

const actions = {
  async getPengembanganSDM({ commit, state }, payload) {
    commit("changePengembanganSDM", {
      loading: true,
    });

    const { data } = state;

    if(payload){
      data.page = payload;
      commit("changePengembanganSDM", {
        page: payload,
      });
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/human-resource`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePengembanganSDM", {
        loading: false,
      });
    } else {
      commit("changePengembanganSDM", {
        listPengembanganSDM: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async submitPengembangan({ commit, state }) {
    commit("changePengembanganSDM", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formData.uuid !== ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }
    formData.append("name", data.formData.name);
    formData.append("activity", data.formData.activity);
    formData.append("year", data.formData.year);
    formData.append("organizer", data.formData.organizer);
    formData.append("certificate_number", data.formData.certificate_number);

    if (
      data.formData.file != "string" &&
      data.formData.file != null
    ) {
      formData.append("mediafile", data.formData.file);
    }

    let result = "";

    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/human-resource/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/human-resource`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changePengembanganSDM", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },

  async deletePengembanganSDM({ commit }, payload) {
    commit("changePengembanganSDM", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(
      `/web/human-resource/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data telah dihapus",
      });
      commit("changePengembanganSDM", {
        loading: false,
      });

      return true;
    }
  },
  async getExportPengembanganSDM({ commit, state }, payload) {
    commit("changePengembanganSDM", {
      loading: true,
    });

    if (payload) {
      await commit("changePengembanganSDM", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/human-resource/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changePengembanganSDM", {
        loading: false,
      });
    } else {
      commit("changePengembanganSDM", {
        listExportPengembanganSDM: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelPengembanganSDM({ commit }, payload) {
    commit("changePengembanganSDM", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changePengembanganSDM", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      "human_resources": payload.formData
    }
    type = "human-resource"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changePengembanganSDM", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
