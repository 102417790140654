import { ApiGetRequest, ApiPostMultipart, ApiPutRequest, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        token: '',
        banner: [],
        settings: [],
        showcase: [],
        loading: false,
        errors: [],
    },
};

const mutations = {
    changeKlaspustakaWebKatalog(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getWebKonten({ commit }) {
        commit('changeKlaspustakaWebKatalog', {
            loading: true,
        });

        const result = await ApiGetRequest(`/school/library/content`);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: result.error,
            });

            return [];
        } else {
            let banner = result.data.data.banner
            for (let i = 0; i < banner.length; i++) {
                if (banner[i].image) {
                    banner[i].imageName = banner[i].image
                    banner[i].imagePreview = banner[i].image
                }
                banner[i].subjectList = []
                banner[i].categoryList = []
                banner[i].type = 'banner'
                banner[i].is_show = true
            }

            let showcase = result.data.data.showcase
            for (let i = 0; i < showcase.length; i++) {
                showcase[i].image = null
                showcase[i].imageName = null
                showcase[i].imagePreview = null
                showcase[i].subjectList = []
                showcase[i].categoryList = []
                showcase[i].type = 'showcase'
                showcase[i].is_show = showcase[i].is_active
            }

            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: [],
                token: result.data.data.token,
                banner: result.data.data.banner,
                settings: result.data.data.settings,
                showcase: result.data.data.showcase,
            });
        }
    },

    async generateToken({ commit }) {
        commit('changeKlaspustakaWebKatalog', {
            loading: true,
        });

        const result = await ApiGetRequest(`/school/library/generate-token`);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: result.error,
            });

            return [];
        } else {
            Message({
                type: 'success',
                message: 'Token berhasil digenerate',
            });
            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: [],
                token: result.data.data.token,
            });
        }
    },

    async submitSetting({ commit }, payload) {
        commit('changeKlaspustakaWebKatalog', {
            loading: true,
        });

        let body = {
            is_active: payload.is_active
        }

        const result = await ApiPutRequest(`/school/library/setting/${payload.id}`, body);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: result.error,
            });

            return [];
        } else {
            Message({
                type: 'success',
                message: 'Pengaturan berhasil dirubah',
            });
            commit('changeKlaspustakaWebKatalog', {
                loading: false,
                errors: [],
            });
            return true
        }
    },

    async submitWebKonten({ commit }, payload) {
        commit('changeKlaspustakaWebKatalog', {
            loading: true,
        });

        let endPoint = 'school/library/content'
        const formData = new FormData();
        formData.append('_method', 'post');
        if (payload.content_id) {
            formData.set('_method', 'put');
        }
        formData.append('type', payload.type);
        formData.append('book_subject_id', payload.book_subject_id);
        formData.append('book_category_id', payload.book_category_id);
        formData.append('is_show', (payload.is_show) ? 1 : 0);
        if ((typeof payload.image) != 'string' && payload.image != null) {
            formData.append('image', payload.image);
        }
        if (payload.type == 'showcase') {
            formData.append('label', payload.label);
        }

        let result = ""

        if (payload.content_id) {
            result = await ApiPostMultipart(`${endPoint}/${payload.content_id}`, formData);
        } else {
            result = await ApiPostMultipart(`${endPoint}`, formData);
        }

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                commit('changeKlaspustakaWebKatalog', {
                    errors: errors,
                    loading: false,
                });
            }
        } else {
            commit('changeKlaspustakaWebKatalog', {
                errors: [],
            });

            return true;
        }
    },

    async deletWebKonten({ commit }, payload) {
        commit('changeKlaspustakaWebKatalog', {
            loading: true,
        });

        if (payload && payload.id) {
            const result = await ApiDeleteRequest(`school/library/content/${payload.id}`);

            if (result.error) {
                Message({
                    type: 'error',
                    message: result.error,
                });
                commit('changeKlaspustakaWebKatalog', {
                    errors: result.errorList,
                    loading: false,
                });
                return false;
            } else {
                Message({
                    type: 'success',
                    message: 'Data telah dihapus.',
                });
                commit('changeKlaspustakaWebKatalog', {
                    errors: [],
                    loading: false,
                });
                return true;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};