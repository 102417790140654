import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    columns: [
      {
        prop: "name",
        label: "Nama",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "meet_with",
        label: "Bertemu Dengan",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "waiting_on",
        label: "Tempat Tunggu",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "purpose",
        label: "Tujuan",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "date",
        label: "Tanggal",
        minWidth: "140",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "desc",
    tglawal: moment().clone().format("YYYY-MM-DD"),
    tglakhir: moment().clone().format("YYYY-MM-DD"),
    search: "",
    loading: false,
    errors: [],
    items: [],
    id_data: null,
    detail: null,
    periode: "1",
  },
};

const mutations = {
  changeKlassignTamu(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataTable({ commit, state }, payload) {
    commit("changeKlassignTamu", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlassignTamu", {
        page: payload || data.page,
      });
    }
    const { data } = state;

    let params = {
      q: data.search,
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    if (
      data.periode !== "1" &&
      data.tglawal &&
      data.tglawal !== "" &&
      data.tglakhir &&
      data.tglakhir !== ""
    ) {
      params.start_date = moment(data.tglawal).format("YYYY-MM-DD");
      params.end_date = moment(data.tglakhir).format("YYYY-MM-DD");
    }

    const result = await ApiGetRequest(`school/klasign/visitor`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignTamu", {
        loading: false,
      });
    } else {
      commit("changeKlassignTamu", {
        items: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },

  async getDataDetail({ commit }, payload) {
    commit("changeKlassignTamu", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/klasign/visitor/${payload.id_detail}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignTamu", {
        loading: false,
      });
    } else {
      commit("changeKlassignTamu", {
        detail: result.data.data,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
