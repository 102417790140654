import { ApiGetRequest } from "@/utils/Api";
import moment from "moment";
import { Message } from "element-ui";

const state = {
  data: {
    error: false,
    loading: false,
    search: "",
    limit: 10,
    page: 1,
    total: 0,
    typefilter: "bulan",
    rekapTahunanStudentId: "",
    filterYear: "",
    filter_date: moment().format("YYYY-MM-DD"),
    current_year: moment().format("YYYY"),
    current_month: moment().format("MM"),
    current_date: moment().format("DD"),
    currentMonth: [],
    tglActive: "",
    month: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    items: [
      {
        id: 1,
        nik_nuptk: 112233,
        nama_lengkap: "Miqbal Aulia Rafi",
        profesi: "Software Engineer",
      },
    ],
    columns_rekap_student_tahunan: [
      {
        prop: "bulan",
        label: "Bulan",
        sortable: true,
        minWidth: "100",
      },
      {
        prop: "tepat_Waktu",
        label: "Tepat Waktu",
        sortable: true,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "terlambat",
        label: "Terlambat",
        sortable: false,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "lupa_absen",
        label: "Lupa Absen",
        sortable: false,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "pulang_awal",
        label: "Pulang Awal",
        sortable: false,
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changeRekapPresensiPelajarTahunan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListYearStudent({ commit, state }, payload) {
    commit("changeRekapPresensiPelajarTahunan", {
      loading: true,
    });

    if (payload) {
      await commit("changeRekapPresensiPelajarTahunan", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      year: data.filterYear,
      // month: data.month,
      // start_month:,
      // end_month
    };

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-student/${data.rekapTahunanStudentId}/yearly`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeRekapPresensiPelajarTahunan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRekapPresensiPelajarTahunan", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getHeaderRecapYearStudent({ commit, state }) {
    commit("changeRekapPresensiPelajarTahunan", {
      loading: true,
      infoUser: "....",
    });

    const { data } = state;

    const result = await ApiGetRequest(
      `school/klastime/attendance-recap-student/${data.rekapTahunanStudentId}/yearly/header`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      commit("changeRekapPresensiPelajarTahunan", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeRekapPresensiPelajarTahunan", {
        loading: false,
        infoUser: result.data.data,
        error: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
