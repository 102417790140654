import {
	klastimeCctvUserSummary,
	klastimeUserPhotosCctvByUuidDelete,
	klastimeUserPhotosCctvByUuidGet,
	klastimeUserPhotosCctvByUuidPost,
} from "@/services/PresensiService";
import {userStudentActiveGet} from "@/services/UserService";
import {DUPLICATE_CALL_SERVER_MESSAGE} from "@/utils/messageUtils";
import store from "@/store";
import {Message} from "element-ui";

const state = {
	data: {
		student: {
			items: [],
			page: 1,
			limit: 10,
			total: 0,
			isServerError: false,
			loading: false,
		},
		userPhotos: {
			data: {
				user_photo: []
			},
			formData: [],
			error: false,
			loading: false,
			isServerError: false,
		},
		items: [],
		summary: {
			"user": 0,
			"avatar": 0,
			"photo": 0
		},
		isSummaryServerError: false,
		page: 1,
		limit: 10,
		total: 0,
		loading: false,
		filter: "",
		filter_class: "",
		filter_jurusan: "",
		isDetailNotFound: false,
		listPengguna: [
			{
				name: "Pelajar",
				active: true,
			},
		],
		columns_pelajar: [
			{
				prop: "avatar",
				label: "Foto",
				width: "140",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "200",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "photos",
				label: "Foto Cctv",
				minWidth: "160",
				"class-name": "body-14 col-black-2"
			}
		],
		columns_guru: [
			{
				prop: "avatar",
				label: "Foto",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "nis_nik",
				label: "NIP/NUPTK",
				minWidth: "120",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: "80",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "role",
				label: "Profesi",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "newtimes",
				label: "Jam Kerja",
				minWidth: "100",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "ketepatan_waktu",
				label: "Ketepatan Waktu",
				minWidth: "140",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "rfid",
				label: "RFID",
				minWidth: "80",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "synced_fingerprint_device_at",
				label: "Update",
				minWidth: "120",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "sinkron",
				label: "Tersinkron",
				minWidth: "120",
				"class-name": "body-14 col-black-2 text-center"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "135",
				"class-name": "body-14 col-black-2"
			}
		],
	}
};

const mutations = {
	changePenggunaCctv(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changePenggunaCctvLoading(state, payload) {
		state.data.loading = payload
	},
	changePenggunaCctvStudent(state, payload) {
		state.data.student = Object.assign({}, state.data.student, payload);
	},
	changePenggunaCctvStudentLoading(state, payload) {
		state.data.student.loading = payload
	},
	changePenggunaCctvUserPhotos(state, payload) {
		state.data.userPhotos = Object.assign({}, state.data.userPhotos, payload);
	},
	changePenggunaCctvUserPhotosLoading(state, payload) {
		state.data.userPhotos.loading = payload
	}
};

const actions = {

	async deleteUserPhotosCctvByUuidAndId({commit, state, dispatch}, payload) {

		const {loading} = state.data.userPhotos
		const {uuid, id} = payload
		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		try {
			commit("changePenggunaCctvUserPhotosLoading", true);
			await klastimeUserPhotosCctvByUuidDelete(uuid, id)
			commit("changePenggunaCctvUserPhotosLoading", false);

			Message({
				type: "success",
				message: "Foto Cctv Berhasil dihapus"
			});
			dispatch("getUserPhotosCctv", uuid)
			return true;
		} catch (error) {
			commit("changePenggunaCctvUserPhotosLoading", false);
			Message({
				type: "error",
				message: "Foto Cctv GAGAL dihapus"
			});

			if (error.code >= 500 || !error) {
				return false;
			}

			return false;
		}
	},

	async postUserPhotosCctv({commit, state, dispatch}, uuid) {

		const {formData, loading} = state.data.userPhotos
		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}
		console.log('formData', formData)

		try {
			commit("changePenggunaCctvUserPhotosLoading", true);
			await klastimeUserPhotosCctvByUuidPost(uuid, formData)
			commit("changePenggunaCctvUserPhotosLoading", false);

			commit("changePenggunaCctvUserPhotos", {
				formData: []
			});
			Message({
				type: "success",
				message: "Foto Cctv Berhasil ditambahkan"
			});
			dispatch("getUserPhotosCctv", uuid)
			return true;
		} catch (error) {
			commit("changePenggunaCctvUserPhotosLoading", false);
			Message({
				type: "error",
				message: "Foto Cctv GAGAL ditambahkan"
			});

			if (error.code >= 500 || !error) {
				return false;
			}

			return false;
		}
	},

	async getUserPhotosCctv({commit}, uuid) {

		try {
			commit("changePenggunaCctvUserPhotosLoading", true);
			const result = await klastimeUserPhotosCctvByUuidGet(uuid)
			commit("changePenggunaCctvUserPhotosLoading", false);

			commit("changePenggunaCctvUserPhotos", {
				isServerError: false,
				data: result.data.data
			});

			store.commit("updateState", {
				userPhotosCctv: {
					...store.state.userPhotosCctv,
					[uuid]: result.data.data.user_photo
				}
			});
			return true;
		} catch (error) {
			commit("changePenggunaCctvUserPhotosLoading", false);

			commit("changePenggunaCctvUserPhotos", {
				isServerError: false,
				data: {
					user_photo: []
				}
			});

			if (error.code >= 500 || !error) {

				commit("changePenggunaCctvUserPhotos", {
					isServerError: true,
				});
				return false;
			}

			return false;
		}
	},

	async getUserStudentActive({commit, state}, payload) {

		if (typeof payload === "number") {
			commit("changePenggunaCctvStudent", {
				page: payload || 1
			});
		}

		const {filter_jurusan, filter_class, filter} = state.data
		const {limit,page} = state.data.student

		const params = {
			majorId: filter_jurusan,
			classId: filter_class,
			q: filter,
			limit: limit,
			page: page
		}

		try {
			commit("changePenggunaCctvStudentLoading", true);
			const result = await userStudentActiveGet(params)
			commit("changePenggunaCctvStudentLoading", false);

			commit("changePenggunaCctvStudent", {
				isServerError: false,
				items: result.data.data,
				total: result.data?.meta?.total || 0
			});
			return true;
		} catch (error) {
			commit("changePenggunaCctvStudentLoading", false);

			commit("changePenggunaCctvStudent", {
				isServerError: false,
				items: []
			});

			if (error.code >= 500 || !error) {

				commit("changePenggunaCctvStudent", {
					isServerError: true,
				});
				return false;
			}

			return false;
		}
	},

	async getUserSummary({commit, state}) {

		const {filter_jurusan, filter_class, loading} = state.data
		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		const params = {
			include: ['avatar','photo'],
			majors: [filter_jurusan],
			classes: [filter_class]
		}
		console.log(params)

		try {
			commit("changePenggunaCctvLoading", true);
			const result = await klastimeCctvUserSummary(params)
			commit("changePenggunaCctvLoading", false);

			commit("changePenggunaCctv", {
				isSummaryServerError: false,
				summary: result.data.data
			});
			return true;
		} catch (error) {
			commit("changePenggunaCctvLoading", false);

			commit("changePenggunaCctv", {
				isSummaryServerError: false,
				summary: {
					"user": 0,
					"avatar": 0,
					"photo": 0
				}
			});

			if (error.code >= 500 || !error) {

				commit("changePenggunaCctv", {
					isSummaryServerError: true,
				});
				return false;
			}

			return false;
		}
	}

};

const getters = {
	summaryUserGetter: state => {
		try {
			return state.data.summary.user
		} catch (e) {
			return 0
		}
	},
	summaryAvatarGetter: state => {
		try {
			return state.data.summary.avatar
		} catch (e) {
			return 0
		}
	},
	summaryPhotoCctvGetter: state => {
		try {
			return state.data.summary.photo
		} catch (e) {
			return 0
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
