import ApiService from "@/api/ApiService"

const PATH_FIRST = "school/klastime"
//const MOCK_API = "http://localhost:3000/api"

export default {

	getNvrProxy(params) {
		return ApiService.get(`${PATH_FIRST}/nvr`, { params })
	},
	getNvrProxyById(nvrId) {
		return ApiService.get(`${PATH_FIRST}/nvr/${nvrId}`, { params: {} })
	},
	postNvrProxy(formData) {
		return ApiService.post(`${PATH_FIRST}/nvr`, formData)
	},
	putNvrProxy(nvrId, formData) {
		return ApiService.put(`${PATH_FIRST}/nvr/${nvrId}`, formData)
	},
	deleteNvrProxy(nvrId) {
		return ApiService.delete(`${PATH_FIRST}/nvr/${nvrId}`)
	},

	getDevice(params) {
		return ApiService.get(`${PATH_FIRST}/device`, { params })
	},
	getDeviceById(deviceId) {
		return ApiService.get(`${PATH_FIRST}/device/${deviceId}`, { params: {} })
	},
	postDevice(formData) {
		return ApiService.post(`${PATH_FIRST}/device`, formData)
	},
	putDevice(deviceId, formData) {
		return ApiService.put(`${PATH_FIRST}/device/${deviceId}`, formData)
	},
	deleteDevice(deviceId) {
		return ApiService.delete(`${PATH_FIRST}/device/${deviceId}`)
	},

	getUserInKlastime(params) {
		return ApiService.get(`${PATH_FIRST}/user`, { params })
	},

	getUserSumaryKlastimeCctv(params) {
		return ApiService.get(`${PATH_FIRST}/user/photo/summary`, { params })
	},

	getUserPhotosCctv(uuid) {
		return ApiService.get(`${PATH_FIRST}/user/${uuid}/photo`, { params: {} })
	},

	postUserPhotosCctv(uuid, formData) {
		const config = {
				headers: {
					"Content-Type": "multipart/form-data"
				}
		}
		return ApiService.post(`${PATH_FIRST}/user/${uuid}/photo`, formData, config)
	},

	updateUserPhotosCctv(uuid, id, formData) {
		const config = {
				headers: {
					"Content-Type": "multipart/form-data"
				}
		}
		return ApiService.post(`${PATH_FIRST}/user/${uuid}/photo/${id}`, formData, config)
	},

	getUserPhotosCctvDetail(uuid, id) {
		return ApiService.get(`${PATH_FIRST}/user/${uuid}/photo/${id}`)
	},

	deleteUserPhotosCctv(uuid, id) {
		return ApiService.delete(`${PATH_FIRST}/user/${uuid}/photo/${id}`)
	},
}