import { ApiGetRequest } from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    items: [
      {
        id: 1,
        nis_nik: '43125413 / 34323112',
        name: 'Anggun Puspitasari',
        class: 'X IPA 2',
        start_date: '13 April 2021, 16:30',
        end_date: '13 April 2021, 18:55',
        status: 3,
        nilai: 0,
      },
      {
        id: 2,
        nis_nik: '43125413 / 34323112',
        name: 'Ovilia Lindasari Agustina',
        class: 'X IPA 1',
        start_date: null,
        end_date: null,
        status: 4,
        nilai: 0,
      },
      {
        id: 3,
        nis_nik: '43125413 / 34323112',
        name: 'Surya Rakhmat Fadhillah',
        class: 'X IPA 2',
        start_date: null,
        end_date: null,
        status: 4,
        nilai: 0,
      },
    ],
    page: 1,
    total: 0,
    limit: 10,
    filter: '',
    sortBy: '',
    sortType: '',
    jenjang: '',
    status: '',
    columns: [
      {
        prop: 'nis_nik',
        label: 'NIS / NISN',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'name',
        label: 'Nama',
        sortable: true,
        width: 120,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'class',
        label: 'Kelas',
        width: 100,
        'class-name': 'body-14 col-black-2 text-center',
      },
      {
        prop: 'start_date',
        label: 'Mulai',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'end_date',
        label: 'Selesai',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'status',
        label: 'status',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'nilai',
        label: 'Nilai',
        width: 80,
        'class-name': 'body-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '90',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_filter_akm: [
      {
        prop: 'title',
        label: 'Judul',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'schedule',
        label: 'Jadwal',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'grade',
        label: 'Jenjang',
        width: 120,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'student',
        label: 'Pelajar',
        width: 120,
        'class-name': 'body-14 col-black-2 text-center',
      },
      {
        prop: 'level',
        label: 'Level',
        width: 120,
        'class-name': 'body-14 col-black-2 text-center',
      },
    ],
    columns_filter_mapel: [
      {
        prop: 'title',
        label: 'Judul',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'schedule',
        label: 'Jadwal',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'grade',
        label: 'Jenjang',
        width: 120,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'student',
        label: 'Pelajar',
        width: 120,
        'class-name': 'body-14 col-black-2 text-center',
      },
      {
        prop: 'mapel',
        label: 'Mapel',
        'class-name': 'body-14 col-black-2 text-center',
      },
    ],
    items_filter_akm: [
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
      },
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
      },
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
      },
    ],
    items_filter_mapel: [
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
        mapel: 'Bahasa Indonesia',
      },
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
        mapel: 'Bahasa Indonesia',
      },
      {
        title: 'Try Out AKM 3',
        schedule: '14 Jan 2021, 11:00 - 12:00',
        grade: 'Kelas 10',
        student: '100',
        level: 5,
        mapel: 'Bahasa Indonesia',
      },
    ],
    loading: false,
    error: false,
  },
};

const mutations = {
  changeTryOutAssesment(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListAssesment({ commit, state }, payload = null) {
    commit('changeTryOutAssesment', {
      loading: true,
    });

    if (payload) {
      commit('changeTryOutAssesment', {
        page: false,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.search,
    };

    const result = await ApiGetRequest(`school/try-out/assesment`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeTryOutAssesment', {
        loading: false,
      });

      return false;
    } else {
      commit('changeTryOutAssesment', {
        loading: false,
        items: result.data.data || [],
        total: result.data && result.data.meta ? result.data.meta.total : 0,
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
