import {AuthRepository} from "@/repositories/AuthRepository"
import {HandlerAlertUnauthorized, HandlerMessageServerError} from "@/utils/Helpers";

export class AuthService {

	async currentGet() {
		try {
			const canteenRepository = new AuthRepository()
			return await canteenRepository.getCurrent({})
		}catch (e) {
			const status = e.code;

			if (status === 401) {
				HandlerAlertUnauthorized();
			} else if (status >= 500) {
				HandlerMessageServerError();
			}
			throw e
		}
	}
}
