import ApiServiceWithTimeOut from "@/api/ApiServiceWithTimeout"

const PATH_FIRST = "school/canteen"
//const MOCK_API = "http://localhost:3000/api"

export class CanteenRepository {

	async reportMonthly(params) {
		return ApiServiceWithTimeOut.get(`/${PATH_FIRST}/profit/report`, { params })
	}
	async reportRangeV2(params) {
		return ApiServiceWithTimeOut.get(`/${PATH_FIRST}/profit/report/v2`, { params })
	}
}
