import { _ } from "vue-underscore";
import store from "@/store";
import router from "@/router/index";
import { MessageBox } from "element-ui";

const state = {
  data: {
    tabMenu: [
      {
        name: "surveys",
        label: "Survei",
        lock: false,
        url: "/survey/kuesioner"
      },
      {
        name: "surveySchedules",
        label: "Jadwalkan",
        lock: false,
        url: "/survey/survey-schedules"
      },
      {
        name: "surveyResults",
        label: "Hasil Survei",
        lock: false,
        url: "/survey/survey-results"
      }
    ],
    menuActive: "surveys"
  }
};

const mutations = {
  changeSurvey(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  hakAkses({ state, commit }, payload) {
    let rolesUser = (store.state && store.state.user && store.state.user.role) || [];
    //let rolesUser = [{ id: 2, name: "admin" }];
    let arrayRoles = _.pluck(rolesUser, "name");
    if (payload && payload.allowedRole) {
      const dt = _.intersection(arrayRoles, payload.allowedRole);
      if (dt.length === 0) {
        MessageBox.alert(
          `Peranan Akun anda tidak memiliki Hak Akses.`,
          "PEMBERITAHUAN",
          {
            closeOnClickModal: true,
            closeOnPressEscape: true,
            confirmButtonText: "OK",
            dangerouslyUseHTMLString: true,
            callback: function() {
              router.push(`/onklas/produk`);
            }
          }
        );
        return false;
      }
    }
    // kalo gak ada role admin dan head master didisabled submenu
    if (_.difference(arrayRoles, payload.allowedRole)) {
      let tabMenu = state.data.tabMenu || [];
      tabMenu.map(item => {
        if (
          !["surveys", "surveySchedules", "surveyResults"].includes(item.name)
        ) {
          item.lock = true;
        }
      });
      commit("changeSurvey", { tabMenu });
    }
  }
};

const getters = {
  getTabMenuById: state => id => {
    return state.data.tabMenu.find(item => item.id === id);
  },
  filterUserByRole: state => id => {
    return state.data.tabMenu.find(item => item.id === id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
