/**
 * Format a number using a localized number formatter.
 * @param num The number to format
 * @param locale The locale to pass to the Intl.NumberFormat constructor
 * @param options Number format options
 */
export function formatNumberKoma(num, locale, options) {
  return new Intl.NumberFormat(locale, options).format(num);
}

export function checkVw(b = 100) {
  const a = window.innerWidth;
  console.log(a)
  if (a < 768) {
    return 90
  }
  return b
}

export const tableOfWidth = {
  major: {
    width: {
      width: 200
    },
    minWidth: {
      minWidth: 160
    },
  },
  class: {
    width: {
      width: 200
    },
    minWidth: {
      minWidth: 160
    },
  },
  grade: {
    width: {
      width: 90
    },
    minWidth: {
      minWidth: 90
    },
  },
  status: {
    width: {
      width: 90
    },
    minWidth: {
      minWidth: 90
    },
  },
  subject: {
    width: {
      width: 200
    },
    minWidth: {
      minWidth: 200
    },
  },
}