import {ApiGetRequest} from "@/utils/Api";
import {Message} from "element-ui";
import moment from "moment/moment";
// import moment from "moment";

const localStorageInternshipGroupReportKey = "onklasInternship_internshipReport";
const expirationTime = 10 * 60 * 1000; // 10 menit in milliseconds

const state = {
	data: {
		groupId: 0,
		internshipId: 0,
		editdata: false,
		usertimes: [],
		internshipGroupSelectReport: [],
		limit: 31,
		page: 1,
		total: 0,
		sortBy: "",
		sortType: "",
		filter: "",
		loading: false,
		reportAllLoading: false,
		search: "",
		detailReport: {},
		errors: [],
		items: [],
		itemsReportAll: [],
		itemsTableGroup: [],
		attendance_id: null,
		rowuser: {
			name: "",
			custom_users: [],
			times: [],
		},
		columns_report: [
			{
				prop: "name_student",
				label: "Nama",
				minWidth: "180",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "class",
				label: "Kelas",
				minWidth: "100",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Tanggal",
				width: "120",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "checkin_at",
				label: "Jam",
				width: "120",
				sortable: false,
				"class-name": "body-14 col-black-2 text-left",
			},
			{
				prop: "status",
				label: "Status",
				width: "150",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "note",
				label: "Keterangan",
				minWidth: "140",
				sortable: false,
				"class-name": "body-11 col-black-2",
			},
			{
				fixed: "right",
				label: "Aksi",
				width: "90",
				"class-name": "body-14 col-black-2",
			},
		],
		columns_group_magang: [
			{
				prop: "select",
				label: "Check",
				type: "selection",
				minWidth: "100",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "teacher",
				label: "Nama Guru",
				minWidth: "160",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "nip",
				label: "NIP",
				minWidth: "140",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "start_date",
				label: "Tanggal Mulai",
				minWidth: "140",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "end_date",
				label: "Tanggal Berakhir",
				minWidth: "140",
				sortable: false,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				label: "Aksi",
				width: 90,
				"class-name": "body-14 col-black-2",
			},
		],
		timesDefault: [
			{
				day: 1,
				check: false,
				label: "Senin",
				start_at: "",
				end_at: "",
			},
			{
				day: 2,
				check: false,
				label: "Selasa",
				start_at: "",
				end_at: "",
			},
			{
				day: 3,
				check: false,
				label: "Rabu",
				start_at: "",
				end_at: "",
			},
			{
				day: 4,
				check: false,
				label: "Kamis",
				start_at: "",
				end_at: "",
			},
			{
				day: 5,
				check: false,
				label: "Jumat",
				start_at: "",
				end_at: "",
			},
			{
				day: 6,
				check: false,
				label: "Sabtu",
				start_at: "",
				end_at: "",
			},
			{
				day: 7,
				check: false,
				label: "Minggu",
				start_at: "",
				end_at: "",
			},
		],
	},
};

const mutations = {
	changeMagangReport(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeMagangReportLoading(state, payload) {
		state.data.loading = payload
	},
	changeMagangReportAllLoading(state, payload) {
		state.data.reportAllLoading = payload
	},
	setDataInternshipGroupReport(state, payload) {
		const {data, internshipId, internshipGroupId, lastUpdated} = payload
		const storedData = JSON.parse(localStorage.getItem(localStorageInternshipGroupReportKey));
		localStorage.setItem(localStorageInternshipGroupReportKey, JSON.stringify({
			...storedData,
			[internshipId+''+internshipGroupId]: {
				data: data,
				lastUpdated: lastUpdated,
			}
		}));
	},
};

const actions = {
	/*
	* payload {
	* 	id: required
	* 	group_id: required
	* }
	* */
	async getDataReportAll({commit}, payload) {
		const {id, group_id} = payload
		const now = moment().valueOf();
		const storedData = JSON.parse(localStorage.getItem(localStorageInternshipGroupReportKey));

		if (storedData && typeof storedData === "object") {
			// apakah ada key sesuai ID
			if (storedData[id+''+group_id]) {
				if ((now - storedData[id+''+group_id].lastUpdated < expirationTime)) {
					commit("changeMagangReport", {
						itemsReportAll: storedData[id+''+group_id].data,
					})
					return true;
				}
			}
		}

		commit("changeMagangReportAllLoading", true);

		const params = {
			limit: 10000,
			page: 1,
			sort_field: "created_at",
			sort_dir: "asc"
		}

		const result = await ApiGetRequest(`school/internship/${id}/group/${group_id}/attendance`, params);
		commit("changeMagangReportAllLoading", false);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});

			commit("changeMagangReport", {
				itemsReportAll: []
			});
			return false
		}

		commit("setDataInternshipGroupReport", {
			internshipId: id,
			internshipGroupId: group_id,
			data: result.data?.data || [], lastUpdated: now});
		commit("changeMagangReport", {
			itemsReportAll: result.data?.data || []
		});
		return true
	},

	async getDataReport({commit}, payload) {
		commit("changeMagangReportLoading", true);
		const {data} = state;

		if (payload) {
			await commit("changeMagangReport", {
				page: data.page,
			});
		}

		const params = {
			limit: data.limit,
			page: data.page,
			sort_field: "created_at",
			sort_dir: "desc"
		}

		if (data.filter) {
			params.q = data.filter;
		}

		const result = await ApiGetRequest(`school/internship/${payload.id}/group/${payload.group_id}/attendance`, params);
		commit("changeMagangReportLoading", false);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
		} else {
			commit("changeMagangReport", {
				items: result.data.data,
				total: result.data.meta
					? result.data.meta.total
					: result.data.data.length,
				loading: false,
			});
		}
	},
	async getDataDetailReport({commit}, payload) {
		commit("changeMagangReport", {
			loading: true,
		});

		const result = await ApiGetRequest(
			`school/internship/${payload.id}/group/${payload.group_id}/attendance/${payload.attendance_id}`
		);

		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});

			commit("changeMagangReport", {
				loading: false,
			});
		} else {
			commit("changeMagangReport", {
				detailReport: result.data.data,
				loading: false,
			});
		}
	},
	async getDataSettingInternship({commit, state}, payload) {
		commit("changeMagangReport", {
			loading: true,
		});
		const {data} = state;
		if (payload) {
			await commit("changeMagangReport", {
				page: data.page,
			});
		}
		let params = {
			q: data.search,
			limit: data.limit,
			page: data.page,
			sort_field: data.sortBy,
			sort_dir: data.sortType,
		};
		const result = await ApiGetRequest(
			`school/internship/${payload.id}/group`,
			params
		);
		if (result.error) {
			Message({
				type: "error",
				message: result.error,
			});
			commit("changeMagangReport", {
				loading: false,
			});
			return false
		}
		commit("changeMagangReport", {
			itemsTableGroup: result.data.data,
			total: result.data.meta
				? result.data.meta.total
				: result.data.data.length,
			loading: false,
		});
		return true
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
