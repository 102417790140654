import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest
} from "@/utils/Api";

import {Message} from "element-ui";
import {_} from "vue-underscore";

const state = {
  data: {
    labelMenu: "Ujian",
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    currentStatusData: "all",
    currentCreatorLogin: true,
    currentArchive: false,
    loading: false,
    errors: [],
    exams: [],
    form: {
      questionType: 1,
      choices: []
    },
    dataFormListInstruction: {
      listInstruction: [],
      modalDataName: "",
      templateScore: null,
      templateId: null,
      subject: "",
      name: "",
      id: null
    },
    dataInstruction: {
      instruction: "",
      description: "",
      is_random: ""
    },
    ruleForm: {
      exam_name: "",
      school_subject_id: "",
      is_shared: true,
      teacher_id: ""
    },
    rules: {
      exam_name: [
        {
          required: true,
          message: "Nama ujian tidak boleh kosong",
          trigger: "blur"
        }
      ],
      school_subject_id: [
        {
          required: true,
          message: "Mapel tidak boleh kosong",
          trigger: "blur"
        }
      ],
      teacher_id: [
        {
          required: true,
          message: "Guru tidak boleh kosong",
          trigger: "blur"
        }
      ]
    },
    columns_ujian: [
      {
        prop: "exam_name",
        label: "Ujian",
        minWidth: "200",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "exam_create",
        label: "Dibuat",
        minWidth: "130",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "used_count",
        label: "Digunakan",
        minWidth: "120",
        sortable: false,
        "class-name": "text-center body-14 col-black-2"
      },
      {
        prop: "exam_subject",
        label: "Mapel",
        widtth: "150",
        minWidth: "150",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "exam_teacher",
        label: "Guru",
        widtth: "150",
        minWidth: "150",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "publication",
        label: "Publikasi ",
        width: "120",
        sortable: false,
        "class-name": "text-center body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: 90,
        "class-name": "body-14 col-black-2"
      }
    ],
    examListStudent: [
      {
        id: "1",
        student_name: "Ujian Tengah Semester",
        student_nisn: "9 Apr 2021",
        student_valid: true
      },
      {
        id: "2",
        student_name: "Ujian Tengah Semester",
        student_nisn: "9 Apr 2021",
        student_valid: false
      }
    ]
  }
};

const mutations = {
  changeUjian(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async getListUjian({commit}, payload = null) {
    commit("changeUjian", {
      loading: true
    });

    const {data} = state;
    if (payload) {
      await commit("changeUjian", {
        page: payload || data.page
      });
    }

    const params = {
      page: state.data.page,
      limit: data.limit,
      q: data.filter
    };
    if (data.filter) {
      params.q = data.filter;
    }
    if (data.currentCreatorLogin) {
      params.creator_login = "show";
    }
    if (data.currentArchive) {
      params.archive = "show";
    }
    if (data.currentStatusData && data.currentStatusData !== "all") {
      params.filter = data.currentStatusData;
    }

    const result = await ApiGetRequest(`school/exam/kkm/exam-template`, params);
    // const result = await ApiGetRequest(`school/exam/template`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data list ujian"
      });

      commit("changeUjian", {
        loading: false
      });

      return null;
    } else {
      commit("changeUjian", {
        exams: result.data.data,
        total: result.data.meta.total,
        loading: false
      });
    }
  },

  async createUjian({commit}, payload = null) {
    // const { data } = state;
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template`,
      payload.formData
    );

    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }

      Message({
        type: "error",
        message: message ? message : "Gagal menambahkan data ujian"
      });

      commit("changeUjian", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian baru berhasil ditambahkan"
      });

      window.location.href = `ujian/${result.data.data.id}/soal`;

      commit("changeUjian", {
        error: false
      });

      return true;
    }
  },

  async updateUjian({commit}, payload = null) {
    const result = await ApiPutRequest(
      `school/exam/kkm/exam-template/${payload.id}`,
      payload.formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengubah data ujian"
      });

      commit("changeUjian", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diubah"
      });

      commit("changeUjian", {
        error: false
      });

      return true;
    }
  },

  async deleteUjian({commit}, payload = null) {
    // const { data } = state;
    commit("changeUjian", {
      loading: true
    });
    const result = await ApiDeleteRequest(
      `school/exam/kkm/exam-template/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal dihapus"
      });

      commit("changeUjian", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeUjian", {
        loading: false
      });

      return true;
    }
  },

  async duplicateUjian({commit}, payload = null) {
    // const { data } = state;
    commit("changeUjian", {
      loading: true
    });

    let formData = {
      exam_template_id: payload.exam_template_id
    };
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template/duplicate`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal diduplikat"
      });

      commit("changeUjian", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diduplikat"
      });

      commit("changeUjian", {
        loading: false
      });

      return true;
    }
  },

  async listDataInstruction({commit, state}, payload) {
    const {data} = state;

    commit("changeUjian", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-template/${payload}`
    );

    if (result.error) {
      Message({type: "error", message: result.error});

      commit("changeUjian", {
        loading: false
      });

      return [];
    } else {
      data.dataFormListInstruction.modalDataName = result.data.data.name;
      data.dataFormListInstruction.templateId = result.data.data.id;
      data.dataFormListInstruction.templateScore = result.data.data.score_total;
      data.dataFormListInstruction.subject = result.data.data.subject;
      data.dataFormListInstruction.name = result.data.data.name;
      data.dataFormListInstruction.id = result.data.data.id;

      // if (
      //     result.data.data.instruction &&
      //     result.data.data.instruction.length > 0
      // ) {
      //     // result.data.data.instruction.map((instruction) => {
      //     //   data.dataFormListInstruction.listInstruction = instruction;
      //     //   this.validationScore(instruction.score_total);
      //     // });

      //     data.dataFormListInstruction.listInstruction =
      //         result.data.data.instruction;
      //     // result.data.data.instruction.map((instruction) => {
      //     //   this.validationScore(instruction.score_total);
      //     // });
      // }

      let resultInstruction = result.data.data.instruction;
      let instruction = [];
      for (let i = 0; i < resultInstruction.length; i++) {
        let tempList = {
          id: resultInstruction[i].id,
          exam_template_id: resultInstruction[i].exam_template_id,
          is_check_description: resultInstruction[i].description ? true : false,
          description: resultInstruction[i].description,
          instruction: resultInstruction[i].instruction,
          is_random: resultInstruction[i].is_random,
          question: resultInstruction[i].question,
          score_total: resultInstruction[i].score_total,
          sequence: resultInstruction[i].sequence,
          is_hide: true
        };
        instruction.push(tempList);
      }
      data.dataFormListInstruction.listInstruction = instruction;
    }
  },

  validationScore(score) {
    let self = this;
    if (score > 100) {
      self.$confirm(
        `Mohon maaf terdapat perintah yang memiliki skor lebih dari 100.`,
        {
          cancelButtonText: "Tutup",
          confirmButtonClass: "bg-red-1 col-black-9 border-0",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      );
    }
  },

  async resetSoal({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(
        `school/exam/kkm/exam-template/${payload.id}/reset`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeUjian", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah berhasil direset"
        });

        commit("changeUjian", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async dataRemoveSoal({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(`${payload.urldelete}`);
      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeUjian", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah dihapus"
        });

        commit("changeUjian", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async createInstruction({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload) {
      const result = await ApiPostRequest(
        `school/exam/akm/exam-instruction`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeUjian", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data perintah berhasil tersimpan"
        });

        commit("changeUjian", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },

  async editInstruction({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/akm/exam-instruction/${payload.id}`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeUjian", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      }
      Message({
        type: "success",
        message: "Data perintah berhasil tersimpan"
      });

      commit("changeUjian", {
        error: [],
        loading: false
      });

      return result.data.data;
    }
  },

  async deleteInstruction({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    const result = await ApiDeleteRequest(
      `school/exam/akm/exam-instruction/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal menghapus data ujian"
      });

      commit("changeUjian", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeUjian", {
        error: []
      });

      return true;
    }
  },
  async activationTemplateExam({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/kkm/exam-template/${payload.id}/activate`
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan data"
        });

        commit("changeUjian", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data template berhasil tersimpan"
        });

        commit("changeUjian", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },
  async archiveTemplateExam({commit}, payload) {
    commit("changeUjian", {
      loading: true
    });

    if (payload && payload.id) {
      let result;
      if (payload.archive) {
        result = await ApiPostRequest(
          `school/exam/kkm/exam-template/${payload.id}/unarchive`
        );
      } else {
        result = await ApiPostRequest(
          `school/exam/kkm/exam-template/${payload.id}/archive`
        );
      }

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }
        Message({
          type: "error",
          message: message ? message : "Gagal proses data"
        });
        commit("changeUjian", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });
        return false;
      }
      Message({
        type: "success",
        message: "Proses Berhasil."
      });
      commit("changeUjian", {
        error: [],
        loading: false
      });
      return true;
    }
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
