import repository from "@/repositories/GlobalRepository";
import ServiceError from "@/services/ServiceError";
import StrukturErrorService from "@/services/StrukturErrorService";
import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers";

export default class GlobalService {
	constructor() {
		this.dataRepository = repository;
	}

	/**
	 * @param payload = {
	 * 			role,
	 * 			filterQ,
	 * 			filterStatus,
	 * 			limit, page, sortField, sortDir, showAlumnus,
	 * 			filterMajor,
	 * 			filterClass
	 * }
	 * */
	async userStudentGet(payload) {
		const {
			filterQ,
			filterStatus,
			limit, page, sortField, sortDir, showAlumnus,
			filterMajor,
			filterClass
		} = payload;

		const params = {
			q: filterQ,
			limit: limit ?? undefined,
			page: page || undefined,
			show: filterStatus || undefined,
			sort_field: sortField,
			sort_dir: sortDir,
			hide_alumnus: showAlumnus ?? undefined,
			school_major_id: filterMajor || undefined,
			school_class_id: filterClass || undefined,
		};

		try {
			return await this.dataRepository.getUserStudent(params);
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async presenceSummaryGet(payload) {
		const { role, filter, start_date, end_date } = payload;
		if (!role && !["teacher", "student"].includes(role)) {
			throw new Error("role must teacher | student");
		}

		const params = {
			role: role,
			start_date: start_date || undefined,
			end_date: end_date || undefined,
		};

		if (filter) {
			params.filter = 'range_date'
		}

		try {
			return await this.dataRepository.getPresenceSummary(params);
		} catch (error) {
			console.log(error)
		}
	}

	async schoolClassAddStudentArrayInClassPost(payload) {
		if (typeof payload !== 'object') {
			throw new Error("payload not same");
		}

		const { classId, nisn } = payload;
		if (!nisn) {
			throw new Error("payload not same");
		}

		let formData = Array.isArray(payload.formData) ? payload.formData : [{ nisn }];

		try {
			return await this.dataRepository.schoolClassAddStudentArrayInClass(classId, formData);
		} catch (error) {
			console.log(error)
			throw new ServiceError(`Failed to add student to class: ${error.message}`, "ADD_STUDENT_IN_CLASS_ERROR");
		}
	}

	async schoolClassRemoveStudentInClassDelete(classId, studentId) {
		if (!classId || !studentId) {
			throw new Error("request not complete");
		}

		try {
			return await this.dataRepository.schoolClassStudentRemoveInClass(classId, studentId);
		} catch (error) {
			throw new ServiceError(`Failed to remove student from class: ${error.message}`, "REMOVE_STUDENT_IN_CALASS_ERROR");
		}
	}





	async bankGet() {
		try {
			return await this.dataRepository.getBank();
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async bankAccountGet() {
		try {
			return await this.dataRepository.getBankAccount();
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async bankAccountPost(payload) {
		const {selectedBank,norek, atasnama} = payload
		try {
			const formData = {
				bank: selectedBank,
				account_number: norek,
				account_holder: atasnama,
			}
			return await this.dataRepository.postBankAccount(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async bankAccountDelete(id) {
		if (typeof id !== 'object') {
			throw new Error("ID required");
		}

		try {
			return await this.dataRepository.deleteBankAccount(id);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async balancePaymentGet() {
		try {
			return await this.dataRepository.getBalancePayment();
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async withdrawGet() {
		try {
			return await this.dataRepository.getWithdraw();
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async withdrawPost(payload) {
		const {atasNama, nominal, keterangan} = payload

		try {
			const formData = {
				bank_account: atasNama,
				amount: nominal,
				note: keterangan,
			}
			return await this.dataRepository.postWithdraw(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async deleteWithdraw(id) {
		if (typeof id !== 'object') {
			throw new Error("ID required");
		}

		try {
			return await this.dataRepository.deleteWithdraw(id);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}
}
