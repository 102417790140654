// import { ApiGetRequest } from '@/utils/Api';
// import { Message } from 'element-ui';

const state = {
  data: {
    menu: [
      {
        label: 'Presensi Guru & Staff',
        name: 'PresensiGuruStaff',
        url: '/klastime/presensi-guru-staff',
        lock: false,
      },
      {
        label: 'Presensi Pelajar',
        name: 'PresensiPelajar',
        url: '/klastime/presensi-pelajar',
        lock: false,
      },
      {
        label: 'Jurnal KBM',
        name: 'PresensiKelas',
        url: '/klastime/presensi-kelas',
        lock: false,
      },
      {
        label: 'Pengaturan Jam',
        name: 'PengaturanJam',
        url: '/klastime/pengaturan-jam',
        lock: false,
      },
      {
        label: 'Mesin Absensi',
        name: 'MesinAbsensi',
        url: '/klastime/mesin-absensi',
        lock: false,
      },
      {
        label: 'Absensi CCTV',
        name: 'AbsensiCctv',
        url: '/klastime/absensi-cctv',
        lock: false,
      },
    ],
    subMenuCctv: [
      {
        label: 'Data Alat',
        name: 'AbsensiCctv',
        url: '/klastime/absensi-cctv',
        lock: false,
      },
      {
        label: 'Pengguna',
        name: 'AbsensiCctvPengguna',
        url: '/klastime/pengguna-absensi-cctv',
        lock: false,
      },
    ],
    manualActiveMenu: '',
		subMenuCctvActive: '',
  },
};

const mutations = {
  changeKlastime(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
