import {
  ApiGetRequest,
  ApiPostRequest,
  ApiGetRequestPartisipasi,
  ApiPostRequestPartisipasi,
} from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    cities: [],
    subMenu: [
      {
        name: 'Klasbisnis',
        label: 'Klasbisnis',
        lock: false,
        url: '/klasbisnis',
      },
      {
        name: 'Klasmart',
        label: 'Klasmart',
        lock: true,
        url: '/klasbisnis/klasmart',
      },
      {
        name: 'Etalase',
        label: 'Etalase',
        lock: true,
        url: '/klasbisnis/etalase',
      },
      {
        name: 'PengecualianKerjaSama',
        label: 'Pengecualian Kerja Sama',
        lock: true,
        url: '/klasbisnis/pengecualian-kerjasama',
      },
      {
        name: 'KlasbisnisBiayaAdmin',
        label: 'Biaya Admin',
        lock: false,
        url: '/klasbisnis/biaya-admin',
      },
    ],
    activeTab: 'DAFTAR PENJUAL',
    Tab: [
      { name: 'DAFTAR PENJUAL', active: true },
      { name: 'LIST PRODUK', active: false },
      // { name: 'LIST JASA', active: false },
      { name: 'TRANSAKSI PRODUK', active: false },
      // { name: 'TRANSAKSI JASA', active: false },
      // { name: 'LAPORAN', active: false },
    ],
    columns: [
      {
        prop: 'nis_nisn',
        label: 'NISN / NIS',
        minWidth: '120',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'name',
        label: 'Nama',
        minWidth: '100',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'merchant_name',
        label: 'Nama Toko',
        minWidth: '120',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'class',
        label: 'Kelas',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'product_count',
        label: 'Produk',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'jasa',
        label: 'Jasa',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'rating',
        label: 'Rating',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'klaspay_id',
        label: 'ID Klaspay',
        minWidth: '100',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'status',
        label: 'Status',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '100',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_riwayat_trans: [
      {
        prop: 'history',
        label: 'Riwayat Transaksi',
        minWidth: '120',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'code',
        label: 'Kode',
        minWidth: '100',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'nisn_nik',
        label: 'NIK / NUPTK',
        minWidth: '100',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'buyer_name',
        label: 'Nama',
        minWidth: '80',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'quantity',
        label: 'Jumlah',
        minWidth: '80',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'school_name',
        label: 'Sekolah',
        minWidth: '110',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
    ],
    product_review: false,
    product_merchant: false,
    data_riwayat: false,
    filter_riwayat: '',
    items_riwayat: [],
    page_riwayat: 1,
    limit_riwayat: 10,
    total_riwayat: 0,
    sortBy_riwayat: '',
    sortType_riwayat: '',
    info_transaction: false,
    review_transaction: false,
    history_transaction: [],
    detail_transaction: false,
    page_transaction: 1,
    limit_transaction: 10,
    total_transaction: 0,
    sortBy_transaction: '',
    sortType_transaction: '',
    columns_trans_produk: [
      {
        prop: 'date',
        label: 'Tanggal',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'time',
        label: 'Pukul',
        minWidth: '70',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'status',
        label: 'Status',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'information',
        label: 'Keterangan',
        minWidth: '80',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_produk: [
      {
        prop: 'image',
        label: 'Gambar',
        width: '75',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'name',
        label: 'Produk',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'merchant_name',
        label: 'Toko',
        minWidth: '85',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'stock',
        label: 'Stok',
        sortable: true,
        width: '80',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'price',
        label: 'Harga',
        sortable: true,
        minWidth: '90',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'category',
        label: 'Kategori',
        minWidth: '140',
        sortable: true,
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'sold',
        label: 'Terjual',
        sortable: true,
        minWidth: '95',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'rating',
        label: 'Rating',
        sortable: true,
        minWidth: '95',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'status',
        label: 'Tayang',
        width: '70',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '55',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_produk_transaksi: [
      {
        prop: 'id',
        label: 'ID Trx',
        minWidth: '70',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'updated_at',
        label: 'Waktu Trx',
        minWidth: '100',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'merchant_name',
        label: 'Nama Toko',
        minWidth: '120',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'buyer_name',
        label: 'Pembeli',
        minWidth: '100',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'product_sum',
        label: 'Jumlah',
        minWidth: '100',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'money_paid',
        label: 'Dibayarkan',
        minWidth: '125',
        sortable: true,
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'shipping_status',
        label: 'Status',
        minWidth: '70',
        'class-name': 'body-14 col-black-2',
      },
      {
        prop: 'shipping_information',
        label: 'Keterangan',
        minWidth: '110',
        'class-name': 'body-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '100',
        'class-name': 'body-14 col-black-2',
      },
    ],
    columns_laporan: [
      {
        prop: 'idtransaction',
        label: 'ID Trx',
        width: '75',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'date_transaction',
        label: 'Waktu Trx',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'tipe',
        label: 'Tipe',
        sortable: true,
        minWidth: '75',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'statusReport',
        label: 'Status',
        sortable: true,
        width: '100',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'keterangan',
        label: 'Keterangan',
        minWidth: '90',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'komplain',
        label: 'Komplain Dari',
        minWidth: '105',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'namaToko',
        label: 'Nama Toko',
        minWidth: '100',
        sortable: true,
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        prop: 'keputusan',
        label: 'Keputusan',
        minWidth: '95',
        'class-name': 'body-14 col-black-2',
        'label-class-name': 'subheading-14 col-black-2',
      },
      {
        fixed: 'right',
        label: 'Aksi',
        width: '100',
        'class-name': 'body-14 col-black-2',
      },
    ],
    category: [],
    subCategory: [],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    filter: '',
    sortBy: '',
    sortType: '',
    classesSelected: '',
    categorySelected: '',
    subCategorySelected: '',
    status: '',
    filterRating: '',
    detailId: false,
    detail: false,
    detailProduct: false,
    infoProduct: false,
    reviewProduct: false,
    error: false,
    loading: false,
    product_klaspay: {},
  },
};

const mutations = {
  changeBisnis(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async checkProductIdKwu({ commit }) {
    const result = await ApiGetRequestPartisipasi(
      `klaspay/external/onklasproduct/check`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: result.error ? result.error : 'Gagal mengambil data pelajar',
      });

      commit('changeBisnis', {
        product_klaspay: {},
      });

      return false;
    } else {
      let data_product = result.data.data || [];
      let product_spp = data_product.filter((item) => {
        if (item.product_id.toLowerCase().indexOf('kwu') !== -1) {
          return item;
        }
      });

      if (product_spp && product_spp.length > 0) {
        if (parseFloat(product_spp[0].merchant_admin_charge_percent) > 0) {
          product_spp[0].merchant_admin_charge_percent =
            parseFloat(product_spp[0].merchant_admin_charge_percent) -
            parseFloat(product_spp[0].admin_charge_percent_min);
        }
      }

      commit('changeBisnis', {
        product_klaspay:
          product_spp && product_spp.length > 0 ? product_spp[0] : {},
      });

      return result.data.data || {};
    }
  },

  async setAdminKwu(context, payload = null) {
    const result = await ApiPostRequestPartisipasi(
      `klaspay/external/onklasproduct/set/id/${payload.id}`,
      payload.data
    );

    if (result.error) {
      Message({
        type: 'error',
        message: result.error ? result.error : 'Gagal setting biaya admin',
      });

      return false;
    } else {
      Message({
        type: 'success',
        message: 'Setting Biaya Admin berhasil',
      });

      return true;
    }
  },

  async getListMerchant({ commit, state }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter || '',
      class: data.classesSelected || '',
      status: data.status || '',
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    const result = await ApiGetRequest(`school/enterpreneur/merchant`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan daftar penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        items: result.data.data,
        total: result.data && result.data.meta && result.data.meta.total,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getDetailMerchant({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const result = await ApiGetRequest(
      `school/enterpreneur/merchant/${payload.id}`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan detail penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        detail: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getMerchantProduct({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      rating: data.filterRating || '',
    };

    if (data.urutkanProduk && data.urutkanProduk !== '') {
      params.sort_field =
        data.urutkanProduk.indexOf('price') !== -1 ? 'price' : 'rating';
      params.sort_type =
        data.urutkanProduk.indexOf('high') !== -1 ? 'desc' : 'asc';
    }

    const result = await ApiGetRequest(
      `school/enterpreneur/merchant/${data.detailId || ''}/goodies`,
      params
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan produk penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        detailProduct: result.data.data,
        total: result.data && result.data.meta && result.data.meta.total,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getDetailProductMerchant({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/merchant/${data.detailId || ''}/goodies/${
        payload.id
      }`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan informasi produk penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        infoProduct: result.data.data
          ? result.data.data.length > 0
            ? result.data.data[0]
            : result.data.data
          : false,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getDetailProductReview({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/merchant/${data.detailId || ''}/goodies/reviews`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan informasi produk penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        reviewProduct: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async updateStatusMerchant({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const result = await ApiPostRequest(
      `school/enterpreneur/merchant/${payload.id || ''}/${payload.type}`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal mengubah status penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        loading: false,
      });

      return true;
    }
  },

  async getCategoryProduct({ commit }) {
    commit('changeBisnis', {
      error: false,
    });

    const result = await ApiGetRequest(`school/enterpreneur/category`);
    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan daftar penjual',
      });

      return false;
    } else {
      commit('changeBisnis', {
        category: result.data.data || [],
      });

      return result.data.data;
    }
  },

  async getSubCategoryProduct({ commit }, payload = null) {
    commit('changeBisnis', {
      error: false,
    });

    const result = await ApiGetRequest(
      `school/enterpreneur/category/${payload.category_id}/detail`
    );
    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan daftar penjual',
      });

      return false;
    } else {
      commit('changeBisnis', {
        subCategory: result.data.data || [],
      });

      return result.data.data;
    }
  },

  async getProduct({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter || '',
      category: data.categorySelected || '',
      sub_category: data.subCategorySelected || '',
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    const result = await ApiGetRequest(`school/enterpreneur/goodies`, params);
    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan daftar penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        items: result.data.data,
        total: result.data && result.data.meta && result.data.meta.total,
        loading: false,
      });

      return result.data.data;
    }
  },

  async updateStatusProduct({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const result = await ApiPostRequest(
      `school/enterpreneur/goodies/${payload.product_id}/status`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan daftar penjual',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        loading: false,
      });

      return result.data.data;
    }
  },

  async getProductTransaction({ commit, state }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page_riwayat,
      limit: data.limit_riwayat,
      q: data.filter_riwayat || '',
      sort_field: data.sortBy_riwayat,
      sort_dir: data.sortType_riwayat,
    };

    const result = await ApiGetRequest(
      `school/enterpreneur/goodies/${data.detailId}/transactions`,
      params
    );
    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan transaksi produk',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        data_riwayat: result.data.data || false,
        items_riwayat: (result.data.data && []) || [],
        total_riwayat:
          (result.data.data &&
            result.data.data.meta &&
            result.data.data.meta.total) ||
          0,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getProductMerchant({ commit, state }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/goodies/${data.detailId}/merchant`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan data penjual produk',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        product_merchant: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getProductReview({ commit }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/goodies/${data.detailId}/reviews`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan review produk',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        product_review: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getTransactionProduct({ commit }, payload = null) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    if (payload) {
      commit('changeBisnis', {
        page: payload,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter || '',
      class: data.classesSelected || '',
      status: data.status || '',
      sort_field: data.sortBy,
      sort_dir: data.sortType,
    };

    const result = await ApiGetRequest(
      `school/enterpreneur/transactions`,
      params
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan transaksi produk',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        items: result.data.data,
        total: result.data && result.data.meta && result.data.meta.total,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getInfoTransaction({ commit, state }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/transactions/${data.detailId}/info`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan review produk',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        info_transaction: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getReviewTransaction({ commit, state }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/transactions/${data.detailId}/reviews`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan review transaksi',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        review_transaction: result.data.data,
        total_transaction:
          result.data.data &&
          result.data.data.transactions &&
          result.data.data.transactions.meta.total,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getHistoryTransaction({ commit }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/transactions/${data.detailId}/history`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan riwayat transaksi',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        history_transaction: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },

  async getDetailTransaction({ commit }) {
    commit('changeBisnis', {
      loading: true,
      error: false,
    });

    const { data } = state;
    const result = await ApiGetRequest(
      `school/enterpreneur/transactions/${data.detailId}/detail`
    );

    if (result.error) {
      Message({
        type: 'error',
        message: 'Gagal menampilkan riwayat transaksi',
      });

      commit('changeBisnis', {
        loading: false,
      });

      return false;
    } else {
      commit('changeBisnis', {
        detail_transaction: result.data.data,
        loading: false,
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
