import GlobalService from "@/services/GlobalService";
import ServiceError from "@/services/ServiceError";
import {MessageBox} from "element-ui";


const state = {
	data: {
		loading: false,
		isServerError: false,
		isSuccessRemoveStudentInClass: false,
		isSuccessAddStudentInClass: false,
		isSuccessRollbackAddStudentInOldClass: false,
		newClassId: ""
	},
};

const mutations = {
	setFirstRunning(state) {
		state.data = {
			isSuccessRemoveStudentInClass: false,
			loading: false
		};
	},
	changeStudentMoveClassLoading(state, payload) {
		state.data.loading = payload;
	},
	changeStudentMoveClass(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {

	/**
	 * payload {
	 *   nisn: required,
	 *   studentId: required,
	 *   oldClassId: required
	 * }
	 * */
	async proccessStudentMoveClass({commit, state}, payload) {
		const globalService = new GlobalService()
		commit("changeStudentMoveClassLoading", true)

		try {
			await globalService.schoolClassRemoveStudentInClassDelete(payload.oldClassId, payload.studentId)
			const params = {
				classId: state.data.newClassId,
				nisn: payload.nisn,
			}
			await globalService.schoolClassAddStudentArrayInClassPost(params)
		} catch (error) {
			console.log(error)

			if (error instanceof ServiceError && error.code === "ADD_STUDENT_IN_CLASS_ERROR") {
				try {
					const rollbackParams = {
						classId: payload.oldClassId,
						nisn: payload.nisn,
					};
					await globalService.schoolClassAddStudentArrayInClassPost(rollbackParams);
				} catch (rollbackError) {
					console.error("Error during rollback:", rollbackError);
				}
			}

			MessageBox.alert("Proses Pindah Kelas Gagal", "Informasi", {
				autofocus: true,
				dangerouslyUseHTMLString: true,
				confirmButtonText: "OK",
				callback: (action) => console.log(action),
			});
		} finally {
			commit("changeStudentMoveClassLoading", false)
		}
	},

	/**
	 * payload {
	 *   nisn: required,
	 *   studentId: required,
	 * }
	 * */
	async proccessStudentAddToClass({commit, state}, payload) {
		const globalService = new GlobalService()
		commit("changeStudentMoveClassLoading", true)

		const params = {
			classId: state.data.newClassId,
			nisn: payload.nisn,
		}

		try {
			await globalService.schoolClassAddStudentArrayInClassPost(params)
		} catch (error) {
			console.log(error)

			MessageBox.alert("Proses Memasukan Kelas Gagal", "Informasi", {
				autofocus: true,
				dangerouslyUseHTMLString: true,
				confirmButtonText: "OK",
				callback: (action) => console.log(action),
			});
		} finally {
			commit("changeStudentMoveClassLoading", false)
		}
	}
}

const getters = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
