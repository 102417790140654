import {
  // ApiPostRequest,
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    field_inovasi: [
      {
        prop: "title",
        label: "Judul",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "position",
        label: "Posisi",
        minWidth: "70",
        "class-name": "text-center body-14 col-black-2",
        sortable: true,
      },
      {
        prop: "creator",
        label: "Karya",
        minWidth: "100",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "benefit",
        label: "Manfaat",
        "class-name": "text-center body-14 col-black-2",
        sortable: false,
      },
      {
        prop: "description",
        label: "Keterangan",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
        sortable: false,
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    filter: "",
    q:"",
    loading: false,
    listInovasi: [],
    listExportInovasi: [],
    detailInovasi: {},
    jsonData: [],
    detailImport: {},
    tabsView: "Guru",
    formData: {
      uuid: "",
      creator: "",
      judul: "",
      name: "",
      creator_type: "",
      school: "",
      benefit: "",
      informasi: "",
      file: "",
      file_name: "",
      file_size: "",
      file_path: "",
      file_type: "",
    },
  },
};

const mutations = {
  changeIntegrasiInovasi(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getInovasi({ commit, state }, payload) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });
    const { data } = state;
    if(payload){
      data.page= payload
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }
    params[`filter[creator_type]`] = data.tabsView.toLowerCase()

    const result = await ApiGetRequestCabdin(`/web/innovation`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiInovasi", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiInovasi", {
        listInovasi: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async deleteInovasi({ commit }, payload) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(
      `/web/innovation/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Inovasi telah dihapus",
      });
      commit("changeIntegrasiInovasi", {
        loading: false,
      });

      return true;
    }
  },
  async getInovasiById({ commit }, payload) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });

    const result = await ApiGetRequestCabdin(`/teacher/${payload.uuid}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiInovasi", {
        loading: false,
      });

      return [];
    } else {
      commit("changeIntegrasiInovasi", {
        loading: false,
        detailGuru: result.data.data,
      });
    }
  },
  async submitInovasi({ commit, state }) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formData.uuid != ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }
    formData.append("name", data.formData.judul);
    formData.append("creator", data.formData.name);
    formData.append("creator_type", data.formData.creator_type);
    formData.append("benefit", data.formData.benefit);
    formData.append("description", data.formData.informasi);

    let result = "";

    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/innovation/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/innovation`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiInovasi", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },
  async getExportInovasi({ commit, state }, payload) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });

    const { data } = state;
    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };
    if (payload) {
      await commit("changeIntegrasiInovasi", {
        page: payload.page || data.page,
      });
      params["filter[creator_type]"] = payload.section;
    }
    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/innovation/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiInovasi", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiInovasi", {
        listExportInovasi: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelInovasi({ commit }, payload) {
    commit("changeIntegrasiInovasi", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiInovasi", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      innovations: payload.formData
    }
    type = "innovation"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiInovasi", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
