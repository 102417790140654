import repository from "@/repositories/UserRepository"
import {HandlerMessageServerError} from "@/utils/Helpers";

export const userStudentGet = async (payload) => {
	const {type, page, limit, q, classId, grade} = payload
	if (!type) {
		throw new Error("Client Request not complete.")
	}

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		type: type || undefined,
		class: classId || undefined,
		grade: grade || undefined
	}

	try {
		return await repository.getUserStudent(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}

export const userStudentActiveGet = async (payload) => {
	const {page, limit, q, classId, majorId} = payload

	const params = {
		q: q || undefined,
		limit: limit || undefined,
		page: page || undefined,
		school_major_id: majorId || undefined,
		school_class_id: classId || undefined,
		sort_field: 'name',
		sort_dir: 'asc',
		hide_alumnus: 1,
		show: 'active'
	}

	try {
		return await repository.getUserStudent(params)
	} catch (error) {
		console.error("call server error:", error)
		if (error.code >= 500) {
			HandlerMessageServerError()
		}
		throw error
	}
}