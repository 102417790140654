import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import PembayaranSppService from "@/services/PembayaranSppService";
import moment from "moment";
import DanaPartisipasiService from "@/services/DanaPartisipasiService";

const state = {
	data: {
		showModalSearchStudent: false,
		showModalHistoryProgram: false,
		loading: false,
		filterStatus: "Aktif",
		filterMajor: null,
		filterClasses: null,
		filtertype: "1",
		filterQ: '',
		filterYearMonth: moment().format("YYYY-MM"),
		dataPrint: false,
		itemsMessageTable: null,
		errorDataItems: null,
		limit: 10,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		riwayat_bayar: [],
		columns: [
			{
				prop: "name",
				label: "Program",
				minWidth: 200,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "partisipasi",
				label: "Biaya Program",
				width: 140,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "terbayar",
				label: "Terbayar",
				width: 140,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "status",
				label: "Status",
				width: 140,
				"class-name": "body-14 col-black-2"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: 90,
				"class-name": "body-14 col-black-2"
			}
		],
		rekapTunggakan: {
			serverError: false,
			messageError: null
		}
	}
};

const mutations = {
	changeDanaPartisipasiByPelajar(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeDanaPartisipasiByPelajarLoading(state, payload) {
		state.data.loading = payload
	},
	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {

	onShowModalSearchStudemt({commit}, isOpen) {
		commit("changeDanaPartisipasiByPelajar", {showModalSearchStudent: isOpen})
	},
	onShowModalHistoryProgram({commit}, isOpen) {
		commit("changeDanaPartisipasiByPelajar", {showModalHistoryProgram: isOpen})
	},

	async fetchListProgramByUserUuid({commit, state}, userUuid) {
		if (!userUuid) {
			console.log("uuid is required")
			return
		}

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeDanaPartisipasiByPelajarLoading", true)

		const params = {
			userUuids: [userUuid]
		}

		try {
			const service = new DanaPartisipasiService()
			const result = await service.billByUserUuidGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Tunggakan"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeDanaPartisipasiByPelajar", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeDanaPartisipasiByPelajar", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeDanaPartisipasiByPelajarLoading", false)
		}
	},

	async fetchStudentFeeInvoice({commit, state}, payload) {

		const {data} = state
		const {status} = payload

		const params = {
			sort_field: "name",
			sort_dir: "asc",
			filterStatus: status,
			filtertype: 1,
			issued_start: data.filterYearMonth,
			issued_end: data.filterYearMonth
		};

		if (data.filterClasses) {
			params.filterClasses = data.filterClasses;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.sppStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				commit("changeDanaPartisipasiByPelajar", {
					riwayat_bayar: result.data.data
				})
			}
			commit("changeDanaPartisipasiByPelajar", {
				rekapTunggakan: {
					serverError: false,
					messageError: null
				}
			})

			return true
		} catch (e) {

			commit("changeDanaPartisipasiByPelajar", {
				riwayat_bayar: [],
			})

			if (e.status >= 500) {
				Message({
					type: "error",
					message: SERVER_ERROR_MESSAGE
				});
				commit("changeDanaPartisipasiByPelajar", {
					rekapTunggakan: {
						serverError: true,
						messageError: SERVER_ERROR_MESSAGE
					}
				})
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data REKAP"
			Message({
				type: "error",
				message: m
			});
			commit("changeDanaPartisipasiByPelajar", {
				rekapTunggakan: {
					serverError: true,
					messageError: m
				}
			})

			return false;
		}
	},
};

const getters = {
	listBillByWalletUserGetter: (state) => {
		if (!state.data.items.length) {
			return [];
		}

		const { filterStatus, filterQ, items } = state.data;

		return items
			.filter(item => {
				const now = moment();
				const expirationDate = moment(item.expired_date);

				// Filter by status ("Aktif" or others)
				if (filterStatus === "Aktif") {
					if (!expirationDate.isAfter(now)) {
						return false; // Exclude expired items when filterStatus is "Aktif"
					}
				} else {
					if (expirationDate.isAfter(now)) {
						return false; // Exclude active items when filterStatus is not "Aktif"
					}
				}

				// Optional: Filter by filterQ (e.g., searching by name)
				if (filterQ && filterQ.trim() !== "") {
					return item.bill_name.toLowerCase().includes(filterQ.toLowerCase());
				}

				return true; // If no filterQ or matches found, return true
			});
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
