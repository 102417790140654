import {
  ApiGetRequest,
  ApiPostRequest,
  ApiDeleteRequest,
  ApiPutRequest
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    items: [],
    page: 1,
    limit: 10,
    total: 0,
    filter: "",
    filter_role: "",
    filter_peranan: "",
    filter_class: "",
    filter_jurusan: "",
    filter_angkatan: "",
    detail: {},
    formMesin: {
      name: "",
      serial: "",
      password: ""
    },
    rules: {
      name: [
        {
          required: true,
          message: "Anda wajib mengisi nama mesin",
          trigger: "blur"
        }
      ],
      serial: [
        {
          required: true,
          message: "Anda wajib mengisi ID serial",
          trigger: "blur"
        }
      ],
      password: [
        { min: 7, message: "Min password 7 karakter", trigger: "blur" }
      ]
    },
    columns_guru: [
      {
        prop: "nis_nik",
        label: "NIP/NUPTK",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "80",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "newtimes",
        label: "Jam Kerja",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "ketepatan_waktu",
        label: "Ketepatan Waktu",
        minWidth: "140",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "rfid",
        label: "RFID",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "synced_fingerprint_device_at",
        label: "Update",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "sinkron",
        label: "Tersinkron",
        minWidth: "120",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "135",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_tambah_guru: [
      {
        prop: "select",
        label: "Check",
        type: "selection",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "nis_nik",
        label: "NIP/NUPTK",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "peranan",
        label: "Peranan",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_tambah_pelajar: [
      {
        prop: "select",
        label: "Check",
        type: "selection",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "grade",
        label: "Angkatan",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_pelajar: [
      {
        prop: "nis_nik",
        label: "NIS/NISN",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "80",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "newtimes",
        label: "Jam Sekolah",
        minWidth: "110",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "ketepatan_waktu",
        label: "Ketepatan Waktu",
        minWidth: "140",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "rfid",
        label: "RFID",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "synced_fingerprint_device_at",
        label: "Update",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "sinkron",
        label: "Tersinkron",
        minWidth: "120",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "140",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_topup: [],
    dataTimezone: "",
    indexRowSpan: [],
    detailId: false,
    selectRoles: [],
    itemRoles: [],
    selectClass: [],
    itemClass: [],
    userSelected: [],
    column_detail_guru: [
      {
        prop: "nis_nik",
        label: "NIK / NUPTK",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "90",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "times",
        label: "Jam Kerja & Ketepatan Waktu",
        minWidth: "170",
        "class-name": "body-14 col-black-2"
      }
    ],
    column_detail_pelajar: [
      {
        prop: "nis_nik",
        label: "NIK / NUPTK",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        minWidth: "80",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "class",
        label: "Kelas",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "times",
        label: "Jam Kerja & Ketepatan Waktu",
        minWidth: "170",
        "class-name": "body-14 col-black-2"
      }
    ],
    column_pengaturan_guru: [
      {
        prop: "nis_nik",
        label: "NIK / NUPTK",
        minWidth: "130",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "times",
        label: "Jam Kerja",
        minWidth: "200",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "ketepatan_waktu",
        label: "Ketepatan Waktu",
        minWidth: "170",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "rfid",
        label: "RFID",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "synced_fingerprint_device_at",
        label: "Update Terakhir",
        minWidth: "170",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_scan: [
      {
        prop: "used",
        label: "Aktif",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "jenis",
        label: "Tangan",
        minWidth: "70",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "jari",
        label: "Jari",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "250",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "update",
        label: "Update",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "60",
        "class-name": "body-14 col-black-2"
      }
    ],
    dataSidikJari: [
      {
        key: 0,
        used: false,
        jenis: "kiri",
        jari: "(0) Jari kelingking",
        status: "",
        update: ""
      },
      {
        key: 1,
        used: false,
        jenis: "kiri",
        jari: "(1) Jari manis",
        status: "",
        update: ""
      },
      {
        key: 2,
        used: false,
        jenis: "kiri",
        jari: "(2) Jari tengah",
        status: "",
        update: ""
      },
      {
        key: 3,
        used: false,
        jenis: "kiri",
        jari: "(3) Jari telunjuk",
        status: "",
        update: ""
      },
      {
        key: 4,
        used: false,
        jenis: "kiri",
        jari: "(4) Ibu jari",
        status: "",
        update: ""
      },
      {
        key: 5,
        used: false,
        jenis: "kanan",
        jari: "(5) Ibu jari",
        status: "",
        update: ""
      },
      {
        key: 6,
        used: false,
        jenis: "kanan",
        jari: "(6) Jari telunjuk",
        status: "",
        update: ""
      },
      {
        key: 7,
        used: false,
        jenis: "kanan",
        jari: "(7) Jari tengah",
        status: "",
        update: ""
      },
      {
        key: 8,
        used: false,
        jenis: "kanan",
        jari: "(8) Jari manis",
        status: "",
        update: ""
      },
      {
        key: 9,
        used: false,
        jenis: "kanan",
        jari: "(9) Jari kelingking",
        status: "",
        update: ""
      }
    ],
    userSidikJari: [],
    fingerSelect: false,
    formFinger: {
      type: "finger",
      sig_id: false,
      used: false
    },
    formRfid: {
      type: "rfid",
      signature: ""
    }
  }
};

const mutations = {
  changeMesin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async getListMesin({ commit }) {
    commit("changeMesin", { loading: true });

		const params = {
			device_type: 'FINGERPRINT'
		}

    const getResult = await ApiGetRequest(`school/klastime/device`, params);
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data mesin absensi"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        items: getResult.data.data
      });
    }
  },

  async updateTimezone({ commit, state }) {
    commit("chageMesin", {
      loading: true,
      error: false
    });

    const { data } = state;
    const getResult = await ApiPostRequest(`school/timezone`, {
      timezone: data.dataTimezone
    });

    if (getResult.error) {
      commit("chageMesin", {
        loading: false
      });

      Message({
        type: "error",
        message: "Gagal mengubah zona waktu, mohon mencoba kembali"
      });

      return false;
    } else {
      commit("chageMesin", {
        loading: false
      });

      Message({
        type: "success",
        message: "Data zona waktu berhasil diubah"
      });

      return true;
    }
  },

  async createMesin({ commit }, payload = {}) {
    commit("changeMesin", {
      loading: true,
      error: false
    });

    const getResult = await ApiPostRequest("school/klastime/device", payload);
    if (getResult.error) {
      Message({
        type: "error",
        message: getResult.error
          ? getResult.error
          : "Gagal menambahkan data mesin"
      });

      commit("changeMesin", {
        error: getResult.errorList || false,
        loading: false
      });
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil ditambahkan"
      });

      return true;
    }
  },

  async updateMesin({ commit, state }, payload = {}) {
    commit("changeMesin", {
      loading: true,
      error: false
    });

    const { data } = state;
    console.log(payload, "data payload");
    const getResult = await ApiPutRequest(
      `school/klastime/device/${data.detailId || ""}`,
      payload
    );
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal update data mesin"
      });

      commit("changeMesin", {
        error: getResult.errorList || false,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil diubah"
      });

      return true;
    }
  },

  async hapusMesin({ commit, state }) {
    commit("changeMesin", {
      loading: true,
      error: false
    });

    const { data } = state;
    const getResult = await ApiDeleteRequest(
      `school/klastime/device/${data.detailId || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Data mesin gagal dihapus"
      });

      commit("changeMesin", {
        error: getResult.errorList,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil dihapus"
      });

      return true;
    }
  },

  async sinkronasiMesin({ commit, state }) {
    commit("changeMesin", {
      loading: true,
      error: false
    });

    const { data } = state;
    const getResult = await ApiPutRequest(
      `school/klastime/device/${data.detailId || ""}/resync`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Sinkronasi mesin gagal"
      });

      commit("changeMesin", {
        error: getResult.errorList,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Sinkronasi fingerprint berhasil"
      });

      return true;
    }
  },

  async searchUserGuruStaff({ commit, state }) {
    commit("changeMesin", {
      loading: true
    });

    const { data } = state;
    let params = {
      type: "staff"
    };

    let url = `/school/klastime/user/grouped?`;
    if (data.selectRoles && data.selectRoles.length > 0) {
      url += `&roles[]=${data.selectRoles.join("&roles[]=")}`;
    }

    const getResult = await ApiGetRequest(url, params);

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      let responseItems = getResult.data.data || [];
      let allItems = [];

      await responseItems.map(dataItem => {
        if (dataItem.users && dataItem.users.length > 0) {
          allItems.push(...dataItem.users);
        }
      });

      commit("changeMesin", {
        userSelected: allItems || [],
        itemRoles: getResult.data.data || [],
        loading: false
      });

      return true;
    }
  },

  async getListGuruStaff({ commit, state }, payload = null) {
    if (payload) {
      commit("changeMesin", {
        page: payload
      });
    }

    commit("changeMesin", { loading: true });
    const { data } = state;
    let params = {
      limit: data.limit,
      page: data.page,
      type: "employee",
      q: data.filter || "",
      role: data.filter_role || "",
      peranan: data.filter_peranan || ""
    };

    const getResult = await ApiGetRequest(`/school/klastime/user`, params);

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });
    } else {
      commit("changeMesin", {
        loading: false,
        items: getResult.data.data || [],
        total: getResult.data.meta.total
      });
    }
  },

  async searchUserPelajar({ commit, state }) {
    commit("changeMesin", {
      loading: true
    });

    const { data } = state;
    let params = {
      type: "student"
    };

    let url = `/school/klastime/user/grouped?`;
    if (data.selectClass && data.selectClass.length > 0) {
      url += `&classes[]=${data.selectClass.join("&classes[]=")}`;
    }

    const getResult = await ApiGetRequest(url, params);

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      let responseItems = getResult.data.data || [];
      let allItems = [];

      await responseItems.map(dataItem => {
        if (dataItem.users && dataItem.users.length > 0) {
          allItems.push(...dataItem.users);
        }
      });

      commit("changeMesin", {
        userSelected: allItems || [],
        itemRoles: getResult.data.data || [],
        loading: false
      });

      return true;
    }
  },

  async getListPelajar({ commit, state }, payload = null) {
    if (payload) {
      commit("changeMesin", {
        page: payload
      });
    }

    commit("changeMesin", { loading: true });
    const { data } = state;
    let params = {
      limit: data.limit,
      page: data.page,
      type: "student",
      q: data.filter || "",
      class: data.filter_class || "",
      grade: data.filter_angkatan || ""
    };

    const getResult = await ApiGetRequest(`/school/klastime/user`, params);

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });
    } else {
      commit("changeMesin", {
        loading: false,
        items: getResult.data.data || [],
        total: getResult.data.meta.total
      });
    }
  },

  async registerUserToDevice({ commit, state }) {
    commit("changeMesin", { loading: true, error: false });
    const { data } = state;

    const getUserIdSelected = [];
    data.userSelected.map(item => getUserIdSelected.push(item.id));
    const payload = {
      users: getUserIdSelected
    };

    if (
      !getUserIdSelected ||
      (getUserIdSelected && getUserIdSelected.length === 0)
    ) {
      Message({
        type: "error",
        message: "Anda harus memilih user yang akan ditambahkan"
      });

      return false;
    }

    const getResult = await ApiPostRequest(
      `school/klastime/device/${data.detailId || ""}/user`,
      payload
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mendaftarkan user ke perangkat absensi"
      });

      commit("changeMesin", {
        error: getResult.errorList || false,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil ditambahkan"
      });

      return true;
    }
  },

  async removeUserFromDevice({ commit, state }, payload = null) {
    commit("changeMesin", {
      loading: true,
      error: false
    });

    const { data } = state;
    let result = await ApiDeleteRequest(
      `/school/klastime/device/${data.detailId}/user/${payload.id}`
    );
    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menghapus user dari perangkat"
      });

      commit("changeMesin", {
        loading: false,
        error: result.errorList || false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        error: false
      });

      Message({
        type: "success",
        message: "Data user berhasil dihapus dari perangkat"
      });

      return true;
    }
  },

  async getDetailUserDevice({ commit, state, dispatch }, payload = null) {
    commit("changeMesin", { loading: true });
    const { data } = state;
    const getResult = await ApiGetRequest(
      `/school/klastime/device/${data.detailId}/user/${payload.id}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan detail user, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        detail: getResult.data.data
      });

      await dispatch("getInfoStatusFinger", payload);
      await dispatch("getInfoStatusRfid", payload);
      return true;
    }
  },

  async createNewFingerPrint({ commit }, payload = null) {
    commit("changeMesin", { loading: true, error: false });
    const { data } = state;
    const getResult = await ApiPostRequest(
      `school/klastime/user/${payload.id || ""}/signature`,
      data.formFinger
    );
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data sidik jari baru"
      });

      commit("changeMesin", {
        error: getResult.errorList || false,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        fingerSelect: false,
        formFinger: {
          type: "finger",
          sig_id: false,
          used: false
        },
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil ditambahkan"
      });

      return true;
    }
  },

  async createNewRfid({ commit }, payload = null) {
    commit("changeMesin", { loading: true, error: false });
    const { data } = state;
    const getResult = await ApiPostRequest(
      `school/klastime/user/${payload.id || ""}/signature`,
      data.formRfid
    );
    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data sidik jari baru"
      });

      commit("changeMesin", {
        error: getResult.errorList || false,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        fingerSelect: false,
        formRfid: {
          type: "rfid",
          signature: ""
        },
        error: false
      });

      Message({
        type: "success",
        message: "Data fingerprint berhasil ditambahkan"
      });

      return true;
    }
  },

  async updateFingerPrint({ commit }, payload = null) {
    commit("changeMesin", { loading: true, error: false });
    const getResult = await ApiPutRequest(
      `school/klastime/user/${payload.user || ""}/signature/${payload.id ||
        ""}`,
      payload.data || {}
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal mengubah status aktif finger print"
      });

      commit("changeMesin", {
        error: getResult.errorList,
        loading: false
      });
    } else {
      commit("changeMesin", {
        error: false,
        loading: false
      });

      return true;
    }
  },

  async deleteFingerPrint({ commit }, payload = null) {
    commit("changeMesin", { loading: true, error: false });
    const getResult = await ApiDeleteRequest(
      `school/klastime/user/${payload.user || ""}/signature/${payload.id || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menghapus data sidik jari"
      });

      commit("changeMesin", {
        error: getResult.errorList,
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        error: false,
        loading: false
      });

      Message({
        type: "success",
        message: "Data sidik jari berhasil dihapus"
      });

      return true;
    }
  },

  async getInfoStatusFinger({ commit, state }, payload = null) {
    commit("changeMesin", { loading: true });
    const { data } = state;
    const getResult = await ApiGetRequest(
      `/school/klastime/user/${payload.id}/signature?type[]=finger`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan detail user, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        detail: {
          ...data.detail,
          list_fingerprint: getResult.data.data
        }
      });

      return true;
    }
  },

  async getInfoStatusRfid({ commit, state }, payload = null) {
    commit("changeMesin", { loading: true });
    const { data } = state;
    const getResult = await ApiGetRequest(
      `/school/klastime/user/${payload.id}/signature?type[]=rfid`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan detail user, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });

      return false;
    } else {
      commit("changeMesin", {
        loading: false,
        detail: {
          ...data.detail,
          list_rfid: getResult.data.data
        }
      });

      return true;
    }
  },

  async getListGuruStaffDevice({ commit, state }, payload = null) {
    if (payload) {
      commit("changeMesin", {
        page: payload
      });
    }

    commit("changeMesin", {
      loading: true,
      items: []
    });
    const { data } = state;
    let params = {
      limit: data.limit,
      page: data.page,
      type: "staff",
    };
    if(data.filter) params.q = data.filter;
    if(data.filter_role) params.role = data.filter_role;

    const getResult = await ApiGetRequest(
      `school/klastime/device/${data.detailId || ""}/user`,
      params
    );

    if (getResult && getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });
    } else {
      let dataItems = getResult.data.data || [];
      let newItems = [];
      let indexRowSpan = [];
      let rowItem = 0;
      if (dataItems && dataItems.length > 0) {
        await dataItems.map(item => {
          if (item.times && Object.keys(item.times).length > 0) {
            indexRowSpan.push(rowItem);
            Object.keys(item.times).map(index => {
              let dataItem = JSON.parse(JSON.stringify(item));
              rowItem += 1;
              dataItem.newtimes = dataItem.times[index];
              newItems.push(dataItem);
            });
          } else {
            item.newtimes = item.times;
            newItems.push(item);
            rowItem += 1;
          }
        });
      }

      commit("changeMesin", {
        loading: false,
        items: newItems,
        indexRowSpan: indexRowSpan,
        total: getResult.data.meta.total
      });
    }
  },

  async getListPelajarDevice({ commit, state }, payload = null) {
    if (payload) {
      commit("changeMesin", {
        page: payload
      });
    }

    commit("changeMesin", { loading: true });
    const { data } = state;
    let params = {
      limit: data.limit,
      page: data.page,
      type: "student",
      role: data.filter_role || "",
      class: data.filter_class || "",
      major: data.filter_jurusan || "",
      q: data.filter || ""
    };

    const getResult = await ApiGetRequest(
      `school/klastime/device/${data.detailId || ""}/user`,
      params
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });
    } else {
      let dataItems = getResult.data.data || [];
      let newItems = [];
      let indexRowSpan = [];
      let rowItem = 0;
      if (dataItems && dataItems.length > 0) {
        await dataItems.map(item => {
          if (item.times && Object.keys(item.times).length > 0) {
            indexRowSpan.push(rowItem);
            Object.keys(item.times).map(index => {
              let dataItem = JSON.parse(JSON.stringify(item));
              rowItem += 1;
              dataItem.newtimes = dataItem.times[index];
              newItems.push(dataItem);
            });
          } else {
            item.newtimes = item.times;
            newItems.push(item);
            rowItem += 1;
          }
        });
      }

      commit("changeMesin", {
        loading: false,
        items: newItems,
        indexRowSpan: indexRowSpan,
        total: getResult.data.meta.total
      });
    }
  },

  async getDetailMesin({ commit }) {
    commit("changeMesin", { loading: true });

    const { data } = state;
    const getResult = await ApiGetRequest(
      `school/klastime/device/${data.detailId || ""}`
    );

    if (getResult.error) {
      Message({
        type: "error",
        message: "Gagal menampilkan data, mohon mencoba kembali"
      });

      commit("changeMesin", {
        loading: false
      });
    } else {
      commit("changeMesin", {
        loading: false,
        detail: getResult.data.data || {}
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
