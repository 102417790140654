import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";

const state = {
  data: {
    preview: {
      training_request: {
        details: {
          type: ""
        },
        training: {}
      }
    },
    soal_id: "",
    statusSoal: [],
    selected_soal: {},
    perintah_id: "",
    perintah_soal: {
      question: []
    },
    perintah_soal2: {
      question: []
    }
  }
};

const mutations = {
  changePreview(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async getStatusSoalStore({ commit }, payload = null) {
    if (!payload.date || !payload.start || !payload.end) {
      commit("changePreview", {
        statusSoal: []
      });
      return;
    }
    let data_status = [];
    let today = moment();
    let finish = moment(payload.date + " " + payload.end);
    let duration = moment.duration(finish.diff(today));

    if (duration.asMinutes() > 0) {
      let start = moment(payload.date + " " + payload.start);
      duration = moment.duration(start.diff(today));

      if (duration.asMinutes() < 0) {
        data_status.push({
          label: "Sedang Berlangsung",
          type: "info"
        });
      } else {
        data_status.push({
          label: "Belum Mulai",
          type: "warning"
        });
      }
    } else {
      data_status.push({
        label: "Selesai",
        type: "default"
      });
    }
    await commit("changePreview", {
      statusSoal: data_status
    });
  },
  async getPreview({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `school/tryout/${payload.tryout_id}/preview?p=0`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changePreview", {
        preview: {
          training_request: {
            details: {
              type: ""
            },
            training: {}
          }
        },
        soal_id: "",
        selected_soal: {},
        perintah_id: "",
        perintah_soal: {
          question: []
        }
      });

      return false;
    }
    let resData = result.data.data || {};
    let soal = resData.exam_template || null;
    commit("changePreview", {
      preview: resData,
      soal_id: soal ? soal.id : "",
      selected_soal: soal,
      perintah_id:
        soal.instruction && soal.instruction.length > 0
          ? soal.instruction[0].id
          : "",
      perintah_soal:
        soal.instruction && soal.instruction.length > 0
          ? soal.instruction[0]
          : {}
    });
    commit("changePreview", {
      perintah_soal2:
        soal.instruction && soal.instruction.length > 0
          ? soal.instruction[0]
          : {}
    });
    return resData;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
