import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Klassign Token",
    tokenAnjungan: null,
    tokenControl: null,
    errors: false,
    loading: false,
  },
};

const mutations = {
  changeKlassignToken(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getTokenAnjungan({ commit }, payload) {
    commit("changeKlassignToken", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlassignToken", {});
    }

    const result = await ApiGetRequest("school/klasign/token/input");
    // const { data } = state;

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignToken", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeKlassignToken", {
        tokenAnjungan: result.data.data.token,
        loading: false,
      });
    }
  },

  async generateTokenAnjungan({ commit }, payload) {
    commit("changeKlassignToken", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlassignToken", {});
    }

    const result = await ApiPostRequest(`school/klasign/token/input`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignToken", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeKlassignToken", {
        tokenAnjungan: result.data.data.token,
        loading: false,
      });
    }
  },

  async getTokenControl({ commit }, payload) {
    commit("changeKlassignToken", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlassignToken", {});
    }

    const result = await ApiGetRequest("school/klasign/token/control");
    // const { data } = state;

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignToken", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeKlassignToken", {
        tokenControl: result.data.data.token,
        loading: false,
      });
    }
  },

  async generateTokenControl({ commit }, payload) {
    commit("changeKlassignToken", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlassignToken", {});
    }

    const result = await ApiPostRequest(`school/klasign/token/control`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlassignToken", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeKlassignToken", {
        tokenControl: result.data.data.token,
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
