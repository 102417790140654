import {
  // ApiPostRequest,
  ApiPostRequestCabdin,
  ApiGetRequestCabdin,
  ApiPostMultipartCabdin,
  ApiDeleteRequestCabdin,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";

const state = {
  data: {
    field_literasi: [
      {
        prop: "foto",
        label: "Foto",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "judul_buku",
        label: "Judul Buku",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "terbit",
        label: "Tahun Terbit",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "penulis",
        label: "Penulis",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "penerbit",
        label: "Penerbit",
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "keterangan",
        label: "Keterangan",
        "class-name": "body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    limit: 10,
    page: 1,
    total: 0,
    sort: "",
    key: "",
    filter: "",
    q: "",
    loading: false,
    listLiterasi: [],
    listExportInovasi: [],
    detailInovasi: {},
    jsonData: [],
    detailImport: {},
    tabsView: "",
    formData: {
      uuid: "",
      creator: "",
      title: "",
      writer: "",
      publisher: "",
      publication_year: "",
      description: "",
      file: "",
      file_name: "",
      previewImage: "",
    },
  },
};

const mutations = {
  changeIntegrasiLiterasi(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getLiterasi({ commit, state }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    const { data } = state;

    if(payload){
      data.page = payload
    }
    let params = {
      limit: data.limit,
      sort: `-created_at`,
      key: data.key,
      page: data.page,
    };

    if (data.q && data.q.length > 0) {
      params["q"] = data.q;
    }

    const result = await ApiGetRequestCabdin(`/web/literature`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiLiterasi", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiLiterasi", {
        listLiterasi: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async deleteLiterasi({ commit }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    const result = await ApiDeleteRequestCabdin(
      `/web/literature/${payload.uuid}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Literasi telah dihapus",
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
      });

      return true;
    }
  },
  async submitLiterasi({ commit, state }) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    const { data } = state;

    const formData = new FormData();

    if (data.formData.uuid != ``) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "post");
    }

    formData.append("title", data.formData.title);
    formData.append("writer", data.formData.writer);
    formData.append("publisher", data.formData.publisher);
    formData.append("publication_year", data.formData.publication_year);
    formData.append("description", data.formData.description);
    formData.append("creator", data.formData.creator);
    if(data.formData.file){
      formData.append("mediafile", data.formData.file);
    }

    let result = "";

    if (data.formData.uuid != ``) {
      result = await ApiPostMultipartCabdin(
        `/web/literature/${data.formData.uuid}`,
        formData
      );
    } else {
      result = await ApiPostMultipartCabdin(`/web/literature`, formData);
    }

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeIntegrasiLiterasi", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      Message({
        type: "success",
        message: "Data telah tersimpan.",
      });
    }
  },
  async getExportLiterasi({ commit, state }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    if (payload) {
      await commit("changeIntegrasiLiterasi", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    let params = {
      limit: 0,
      sort: data.sort,
      key: data.key,
    };

    if (data.filter && data.filter.length > 0) {
      params["q"] = data.filter;
    }

    const result = await ApiGetRequestCabdin(`/web/literature/export`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeIntegrasiLiterasi", {
        loading: false,
      });
    } else {
      commit("changeIntegrasiLiterasi", {
        listExportInovasi: result.data.data,
        total: result.data.meta
          ? result.data.meta.total
          : result.data.data.length,
        loading: false,
      });
    }
  },
  async importExcelLiterasi({ commit }, payload) {
    commit("changeIntegrasiLiterasi", {
      loading: true,
    });

    if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
      Message({
        type: "error",
        message: "Data yang dikirim kosong",
      });
      commit("changeIntegrasiLiterasi", {
        loading: false,
      });
      return;
    }
    //remove id editTable error
    for (let i = 0; i < payload.formData.length; i++) {
      delete payload.formData[i].id;
      delete payload.formData[i].editable;
      delete payload.formData[i].error;
    }
    let dataSend = {}
    let type = null
    dataSend = {
      literatures: payload.formData
    }
    type = "literature"

    const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

    commit("changeIntegrasiLiterasi", {
      loading: false,
    });
    if (result.error) {
      Message({
        type: "error",
        message: "Proses Import: "+result.error,
      });
    }else if(result.errors){
      Message({
        type: "error",
        message: "Proses Import: "+result.message,
      });
    }else{
      Message({
        type: "success",
        message: "Proses Import Berhasil",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
