import {
  ApiGetRequest,
  // ApiPostRequest,
  // ApiDeleteRequest,
  // ApiPutRequest,
  // ApiGetRequestPartisipasi,
  ApiNotification,
  ApiPostRequestPartisipasi,
  ApiGetRequestPartisipasi,
  // ApiNotification,
} from "@/utils/Api";
import { Message } from "element-ui";
// import moment from "moment";
// import { _ } from "vue-underscore";
const state = {
  data: {
    channel_payment: {},
    loading: false,
    trxCheckout: {},
    nominalValue: 0,
    chooseChannel: {},
    nominal: [
      {
        label: "Rp. 25.000,-",
        value: 25000,
      },
      {
        label: "Rp. 50.000,-",
        value: 50000,
      },
      {
        label: "Rp. 100.000,-",
        value: 100000,
      },
      {
        label: "Rp. 500.000,-",
        value: 500000,
      },
    ],
    columnsDetail: [
      {
        prop: "reff_id",
        label: "Kode",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "transaction_type",
        label: "Produk",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at",
        label: "Periode",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "installment_fee",
        label: "Instalasi",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "fee",
        label: "Biaya",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "total_fee",
        label: "Sub Total",
        "class-name": "body-14 col-black-2",
      },
    ],
    itemsDetail: [],
    invoice_training: [],
    reff_id: 0,
  },
};

const mutations = {
  changeTagihanTryout(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getChannelPayment({ commit }) {
    commit("changeTagihanTryout", {
      loading: true,
    });

    let result = await ApiGetRequest(`klaspay/biller/getToolbarSpp`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeTagihanTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeTagihanTryout", {
        loading: false,
        channel_payment: (result.data.data && result.data.data.products) || [],
      });
    }
  },
  async getCheckout({ commit, state }) {
    commit("changeTagihanTryout", {
      loading: true,
    });

    const { data } = state;

    const getResult = await ApiPostRequestPartisipasi(
      `klaspay/transaction/channel_trx`,
      {
        biller: 1,
        channel: data.chooseChannel && data.chooseChannel.payment_code,
        transaction_id: data.reff_id,
      }
    );
    if (getResult.error) {
      commit("changeTagihanTryout", {
        loading: false,
        error: getResult.errorList || getResult.error,
      });

      ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
    } else {
      commit("changeTagihanTryout", {
        error: false,
        trxCheckout: getResult.data.data,
        loading: false,
      });
    }
    // if (getResult.error) {
    //   commit("changeTagihanTryout", {
    //     loading: false,
    //     error: getResult.errorList || getResult.error,
    //   });

    //   ApiNotification(
    //     "error",
    //     "Gagal melakukan pembayaran, mohon coba kembali"
    //   );
    // } else {
    //   const getSubmit = await ApiPostRequestPartisipasi(
    //     `klaspay/transaction/channel_trx`,
    //     {
    //       channel: data.chooseChannel && data.chooseChannel.payment_code,
    //       biller: 1,
    //       transaction_id: data.reff_id,
    //     }
    //   );

    //   if (getSubmit.error) {
    //     commit("changeTagihanTryout", {
    //       loading: false,
    //       error: getResult.errorList || getResult.error,
    //     });

    //     ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
    //   } else {
    //     commit("changeTagihanTryout", {
    //       error: false,
    //       trxCheckout: getSubmit.data.data,
    //       loading: false,
    //     });
    //   }
    // }
  },
  async getDetailHistoryPayment({ commit }, payload) {
    commit("changeTagihanTryout", {
      itemsDetail: [],
    });
    // const { data } = state;
    if (payload) {
      const result = await ApiGetRequestPartisipasi(
        `klaspay/transaction/history/id/${payload}`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });
        if (result.errorList) {
          let errors = [];
          for (const [key, value] of Object.entries(result.errorList)) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
          commit("changeTagihanTryout", {
            errors: errors,
          });
        }
      } else {
        let dataDetail = [];
        dataDetail.push(result.data.data.transaction_detail);
        commit("changeTagihanTryout", {
          errors: [],
          itemsDetailTagihan: dataDetail,
        });
        console.log(`data`, dataDetail);
      }
    }
  },
  async getInvoice({ commit }) {
    commit("changeTagihanTryout", {
      loading: true,
    });

    let result = await ApiGetRequestPartisipasi(`klaspay/transaction/invoice`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key],
          });
        }
        commit("changeTagihanTryout", {
          errors: errors,
          loading: false,
        });
      }
    } else {
      commit("changeTagihanTryout", {
        loading: false,
        invoice_training:
          (result.data.data && result.data.data.transaction_invoice) || [],
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
