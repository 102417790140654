import ApiService from "@/api/ApiService"

const PATH_FIRST = "school/internship"
//const MOCK_API = "http://localhost:3000/api"

export default {

	getInternshipById(id) {
		return ApiService.get(`${PATH_FIRST}/${id}`)
	},
}