import { ApiGetRequest, ApiDeleteRequest, ApiPutRequest, ApiPostRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Klasmonitoring",
    token: null,
    errors: false,
    errorResponse: false,
    loading: false,
    newItem: false,
    bannerInformasi: [
      {
        id: 1,
        content: "Keterangan"
      }
    ],
  },
};

const mutations = {
  changeKlasmonitoring(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getToken({ commit }, payload) {
    commit("changeKlasmonitoring", {
      loading: true,
      errorResponse: false,
    });

    if (payload) {
      await commit("changeKlasmonitoring", {});
    }

    const result = await ApiGetRequest("school/klasmonitoring/get-token");
    // const { data } = state;

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlasmonitoring", {
        loading: false,
        errorResponse: true,
      });

      return [];
    } else {
      if(!result.data){
        await commit("changeKlasmonitoring", {
          loading: false,
          errors: ["Cors Error, tidak terhubung dengan server"]
        });
        return;
      }
      await commit("changeKlasmonitoring", {
        token: result.data.data.token,
        loading: false,
        errorResponse: false,
      });
    }
  },
  async generateTokenMonitoring({ commit }, payload) {
    commit("changeKlasmonitoring", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlasmonitoring", {});
    }

    const result = await ApiGetRequest(`school/klasmonitoring/generate-token`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlasmonitoring", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeKlasmonitoring", {
        token: result.data.data.klasmonitoring_token,
        loading: false,
      });
    }
  },
  async getBannerInformasi({ commit }, payload) {
    commit("changeKlasmonitoring", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlasmonitoring", {});
    }

    const result = await ApiGetRequest("school/klasmonitoring/banner");
    // const { data } = state;

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlasmonitoring", {
        loading: false,
      });

      return [];
    } else {
      if(!result.data){
        await commit("changeKlasmonitoring", {
          loading: false,
          errors: ["Cors Error, tidak terhubung dengan server"]
        });
        return;
      }
      await commit("changeKlasmonitoring", {
        bannerInformasi: result.data.data,
        loading: false,
      });
    }
  },
  async deleteContent({ commit }, payload) {
    commit("changeKlasmonitoring", {
      loading: true,
      errorResponse: false,
    });

    const result = await ApiDeleteRequest(`school/klasmonitoring/banner/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKlasmonitoring", {
        loading: false,
        errorResponse: true,
      });

      return [];
    } else {
      await commit("changeKlasmonitoring", {
        loading: false,
        errorResponse: false,
      });
      Message({
        type: "success",
        message: "Data Berhasil di Hapus",
      });

    }
  },
  async updateContent({ state, commit }) {
    commit("changeKlasmonitoring", {
      loading: true,
    });
    let no = 1;
    let messageInfo = "";
    for (const stateKey in state.data.bannerInformasi) {
      if (state.data.bannerInformasi[stateKey].id){
        const result = await ApiPutRequest(`school/klasmonitoring/banner/${state.data.bannerInformasi[stateKey].id}`, {
          content: state.data.bannerInformasi[stateKey].content||"",
        });
        if (result.error) {
          messageInfo += `Gagal Update #${no++} <br>`;
        } else {
          messageInfo += `Berhasil Update #${no++} <br>`;
        }
      }
    }
    commit("changeKlasmonitoring", {
      loading: false,
    });
    Message({
      dangerouslyUseHTMLString: true,
      type: "info",
      message: messageInfo,
    });
  },
  async submitContent({ state, commit }) {
    commit("changeKlasmonitoring", {
      loading: true,
    });
    let messageInfo = "";
    let no = 1;
    for (const stateKey in state.data.bannerInformasi) {
      if (!state.data.bannerInformasi[stateKey].id){
        const result = await ApiPostRequest(`school/klasmonitoring/banner`, {
          content: state.data.bannerInformasi[stateKey].content||"",
        });
        if (result.error) {
          messageInfo += `Gagal Disimpan #${no++} <br>`;
        } else {
          messageInfo += `Berhasil Disimpan #${no++} <br>`;
        }
      }
    }
    commit("changeKlasmonitoring", {
      loading: false,
    });
    Message({
      dangerouslyUseHTMLString: true,
      type: "info",
      message: messageInfo,
    });
  },
  filterContent({ state, commit }) {
    commit("changeKlasmonitoring", {
      newItem: false,
    });
    for (const stateKey in state.data.bannerInformasi) {
      if (state.data.bannerInformasi[stateKey].newContent){
        commit("changeKlasmonitoring", {
          newItem: true,
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
