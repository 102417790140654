import {CanteenRepository} from "@/repositories/CanteenRepository"

export class CanteenService {

	async reportMonthlyGet(payload = {}) {

		const {walletCanteen, year, month} = payload
		if (!walletCanteen || !year ||  !month ) {
			throw new Error("Canteen services reportMonthly not complete")
		}

		const params = {
			walletCanteen,
			year,
			month
		}

		try {
			const canteenRepository = new CanteenRepository()
			return await canteenRepository.reportMonthly(params)
		}catch (e) {
			console.log(e)
			throw e
		}
	}

	async reportRangeV2Get(payload = {}) {

		const {walletCanteen, year, month} = payload
		if (!walletCanteen || !year ||  !month ) {
			throw new Error("Canteen services reportMonthly not complete")
		}

		const params = {
			walletCanteen,
			type: 'month',
			year,
			month
		}

		try {
			const canteenRepository = new CanteenRepository()
			return await canteenRepository.reportRangeV2(params)
		}catch (e) {
			console.log(e)
			throw e
		}
	}
}
