import { ApiGetRequest } from "@/utils/Api";

import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Sosial Media - Peringkat Post",
    dataDetailId: "",
    sortBy: "",
    sortType: "",
    filter: "",
    periode: "1",
    tglawal: "",
    tglakhir: "",
    filterType: "most_post",
    filterTypeMostPost: "",
    page: 1,
    total: 10,
    perPage: 10,
    limit: 10,
    loading: false,
    showModalDetail: false,
    itemsRangkingFeed: [],
    itemsRangkingFeedMostLike: [],
    itemsMostCommented: [],
    errors: [],
    columns_feed: [
      // {
      //   type: "index",
      //   label: "No",
      //   sortable: false,
      //   minWidth: "80",
      // },

      {
        prop: "nis_nisn",
        label: "NIS / NISN / NIK / NUPTK",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "name_student",
        label: "Nama",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "profesi",
        label: "Profesi",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "count_post",
        label: "Total Postingan",
        sortable: false,
        minWidth: "100",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_table_modal: [
      {
        prop: "first_post",
        label: "Postingan Awal",
        minWidth: "70",
        sortable: false,
        "class-name": " text-center body-14 col-black-2",
      },
      {
        prop: "last_post",
        label: "Postingan Terakhir",
        minWidth: "80",
        sortable: false,
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "like",
        label: "Peraihan Suka",
        minWidth: "100",
        sortable: false,
        "class-name": "text-center body-14 col-black-2",
      },
      {
        prop: "comment",
        label: "Peraihan Komentar",
        minWidth: "100",
        sortable: false,
        "class-name": "text-center body-14 col-black-2",
      },
      // {
      //   prop: "view",
      //   label: "Peraihan View",
      //   minWidth: "100",
      //   sortable: false,
      //   "class-name": "text-center body-14 col-black-2",
      // },
    ],
    columns_most_like: [
      // {
      //   prop: "no",
      //   label: "No",
      //   sortable: false,
      //   minWidth: "60",
      // },

      {
        prop: "nis_nisn",
        label: "NIS / NISN / NIK / NUPTK",
        sortable: false,
        minWidth: "180",
      },
      {
        prop: "name_student",
        label: "Nama",
        sortable: false,
        minWidth: "130",
      },
      {
        prop: "caption",
        label: "Caption",
        sortable: false,
        minWidth: "200",
      },
      {
        prop: "profesi",
        label: "Profesi",
        sortable: false,
        minWidth: "100",
      },

      {
        prop: "like",
        label: "Suka",
        sortable: false,
        minWidth: "80",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],

    columns_table_modal_most_like: [
      {
        prop: "image",
        label: "Foto",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "caption",
        label: "Caption",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "date_post",
        label: "Tgl Posting",
        minWidth: "190",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "like",
        label: "Suka",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "comment",
        label: "Komentar",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "view",
      //   label: "View",
      //   minWidth: "90",
      //   sortable: false,
      //   "class-name": "body-14 col-black-2",
      // },
    ],

    columns_most_comment: [
      // {
      //   prop: "no",
      //   label: "No",
      //   sortable: false,
      //   minWidth: "60",
      // },

      {
        prop: "nis_nisn",
        label: "NIS / NISN / NIK / NUPTK",
        sortable: false,
        minWidth: "180",
      },
      {
        prop: "name_student",
        label: "Nama",
        sortable: false,
        minWidth: "130",
      },
      {
        prop: "caption",
        label: "Caption",
        sortable: false,
        minWidth: "200",
      },
      {
        prop: "profesi",
        label: "Profesi",
        sortable: false,
        minWidth: "100",
      },

      {
        prop: "comment",
        label: "Komentar",
        sortable: false,
        minWidth: "80",
        "class-name": " text-center body-14 col-black-2",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_table_modal_most_comment: [
      {
        prop: "image",
        label: "Foto",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "caption",
        label: "Caption",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "date_post",
        label: "Tgl Posting",
        minWidth: "190",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "like",
        label: "Suka",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "comment",
        label: "Komentar",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "view",
        label: "View",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
    ],
    detail_feed: {
      name: "",
      avatar: "",
      nisn_nik: "",
      role: "",
      total_posting: null,
    },
    table_modal_feed: [],
  },
};

const mutations = {
  changeSosmedPeringkat(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getRangkingFeed({ commit, state }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedPeringkat", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      start_date: data.tglawal,
      end_date: data.tglakhir,
    };

    const result = await ApiGetRequest(`school/sosmed/feed-ranking`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });
    } else {
      await commit("changeSosmedPeringkat", {
        itemsRangkingFeed: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getRangkingFeedById({ commit }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/sosmed/feed-ranking/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedPeringkat", {
        detail_feed: {
          name: result.data.data.name,
          avatar: result.data.data.avatar,
          nisn_nik: result.data.data.nisn_nik,
          role: result.data.data.role,
          total_posting: result.data.data.count_feeds,
        },
        table_modal_feed: [
          {
            id: result.data.data.id,
            name: result.data.data.name,
            nisn_nik: result.data.data.nisn_nik,
            feeds_start_at: result.data.data.feeds_start_at,
            feeds_end_at: result.data.data.feeds_end_at,
            count_feeds: result.data.data.count_feeds,
            count_like: result.data.data.count_like,
            count_comment: result.data.data.count_comment,
          },
        ],
        loading: false,
      });
    }
  },

  async getRangkingFeedMostLike({ commit, state }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedPeringkat", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      start_date: data.tglawal,
      end_date: data.tglakhir,
    };

    const result = await ApiGetRequest(`school/sosmed/feed-most-liked`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });
    } else {
      await commit("changeSosmedPeringkat", {
        itemsRangkingFeedMostLike: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getRangkingFeedMostLikeById({ commit }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/sosmed/feed-most-liked/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedPeringkat", {
        detail_feed: {
          name: result.data.data.user.name,
          avatar: result.data.data.user.avatar,
          nisn_nik: result.data.data.user.nisn_nik,
          role: result.data.data.role,
          total_posting: result.data.data.count_feeds,
        },
        table_modal_feed: [
          {
            feed_thumbnail_image: result.data.data.feed_thumbnail_image,
            caption: result.data.data.feed_body,
            like: result.data.data.total_like,
            comment: result.data.data.total_comment,
          },
        ],
        loading: false,
      });
    }
  },

  async getRangkingFeedMostComment({ commit, state }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedPeringkat", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      start_date: data.tglawal,
      end_date: data.tglakhir,
    };

    const result = await ApiGetRequest(
      `school/sosmed/feed-most-commented`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });
    } else {
      await commit("changeSosmedPeringkat", {
        itemsMostCommented: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getRangkingFeedMostCommentById({ commit }, payload) {
    commit("changeSosmedPeringkat", {
      loading: true,
    });

    const result = await ApiGetRequest(
      `school/sosmed/feed-most-commented/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedPeringkat", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedPeringkat", {
        detail_feed: {
          name: result.data.data.user.name,
          avatar: result.data.data.user.avatar,
          nisn_nik: result.data.data.user.nisn_nik,
          role: result.data.data.role,
          total_posting: result.data.data.count_feeds,
        },
        table_modal_feed: [
          {
            feed_thumbnail_image: result.data.data.feed_thumbnail_image,
            caption: result.data.data.feed_body,
            like: result.data.data.total_like,
            comment: result.data.data.total_comment,
          },
        ],
        loading: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
