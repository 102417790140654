import {
  ApiPostRequestCabdin,
    ApiGetRequestCabdin,
    ApiPostMultipartCabdin,
    ApiDeleteRequestCabdin,
  } from "@/utils/Api";
  import { Message } from "element-ui";

  const state = {
    data: {
      columns_table: [
        {
          prop: "foto",
          label: "Foto",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "nama",
          label: "Nama",
          "class-name": "body-14 col-black-2",
        },
        // {
        //   prop: "jenis_kelamin",
        //   label: "Jenis Kelamin",
        //   "class-name": "body-14 col-black-2",
        // },
        {
          prop: "nip",
          label: "NIP",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "jenis_lomba",
          label: "Lomba",
          "class-name": "body-14 col-black-2",
        },
        {
          prop: "capaian_prestasi",
          label: "Prestasi",
          "class-name": "body-14 col-black-2",
        },
        // {
        //   prop: "jenis_penghargaan",
        //   label: "Penghargaan",
        //   "class-name": "body-14 col-black-2",
        // },
        {
          prop: "tingkat",
          label: "Tingkat",
          "class-name": "body-14 col-black-2",
        },
        // {
        //   prop: "penyelenggara",
        //   label: "Penyelenggara",
        //   "class-name": "body-14 col-black-2",
        // },
        // {
        //   prop: "lokasi",
        //   label: "Lokasi",
        //   "class-name": "body-14 col-black-2",
        // },
        {
          prop: "tahun_pelaksanaan",
          label: "Tahun ",
          "class-name": "body-14 col-black-2",
        },
        // {
        //   prop: "keterangan",
        //   label: "Keterangan",
        //   "class-name": "body-14 col-black-2",
        // },
        {
          fixed: "right",
          label: "Aksi",
          "class-name": "body-14 col-black-2",
        },
      ],

      limit: 10,
      page: 1,
      total: 0,
      sort: "",
      key: "",
      filter: "",
      q: "",
      loading: false,
      listPrestasiGuru: [],
      jsonData: [],
      listExportGuru: [],
      detailPrestasiGuru: {},
      detailImport: {},
      formData: {
        uuid: "",
        name: "",
        gender: "",
        nip: "",
        type: "",
        rank: "",
        reward: "",
        level: "",
        organizer: "",
        place: "",
        year: "",
        description: "",
        file: "",
        file_name: "",
        file_size: "",
        file_path: "",
        file_type: "",
        previewImage: "",
      },
    },
  };

  const mutations = {
    changeIntegrasiPrestasiGuru(state, objUpdate) {
      state.data = Object.assign({}, state.data, objUpdate);
    },
  };

  const actions = {
    async getPrestasiGuru({ commit, state }, payload) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      const { data } = state;

      if(payload){
        data.page = payload
      }
      let params = {
        limit: data.limit,
        sort: `-created_at`,
        key: data.key,
        page: data.page,
      };

      if (data.q && data.q.length > 0) {
        params["q"] = data.q;
      }

      const result = await ApiGetRequestCabdin(
        `/web/teacher-achievement`,
        params
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
        });
      } else {
        commit("changeIntegrasiPrestasiGuru", {
          listPrestasiGuru: result.data.data,
          total: result.data.meta
            ? result.data.meta.total
            : result.data.data.length,
          loading: false,
        });
      }
    },
    async submitPrestasi({ commit, state }) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      const { data } = state;

      const formData = new FormData();

      if (data.formData.uuid !== ``) {
        formData.append("_method", "PUT");
      } else {
        formData.append("_method", "post");
      }

      formData.append("name", data.formData.name);
      formData.append("gender", data.formData.gender);
      formData.append("nip", data.formData.nip);
      formData.append("type", data.formData.type);
      formData.append("rank", data.formData.rank);
      formData.append("reward", data.formData.reward);
      formData.append("level", data.formData.level);
      formData.append("organizer", data.formData.organizer);
      formData.append("place", data.formData.place);
      formData.append("year", data.formData.year);
      formData.append("description", data.formData.description);

      if (data.formData.file != "string" && data.formData.file != null) {
        formData.append("mediafile", data.formData.file);
      }

      let result = "";

      if (data.formData.uuid != ``) {
        result = await ApiPostMultipartCabdin(
          `/web/teacher-achievement/${data.formData.uuid}`,
          formData
        );
      } else {
        result = await ApiPostMultipartCabdin(
          `/web/teacher-achievement`,
          formData
        );
      }

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });
        if (result.errorList) {
          let errors = [];
          for (const [key, value] of Object.entries(result.errorList)) {
            errors.push({
              message: value[0],
              path: [key],
            });
          }
          commit("changeIntegrasiPrestasiGuru", {
            errors: errors,
            loading: false,
          });
        }
      } else {
        Message({
          type: "success",
          message: "Data telah tersimpan.",
        });
      }
    },
    async deletePrestasiGuru({ commit }, payload) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      const result = await ApiDeleteRequestCabdin(
        `/web/teacher-achievement/${payload.uuid}`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data Prestasi Guru telah dihapus",
        });
        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
        });

        return true;
      }
    },
    async getAlumnusPTNById({ commit }, payload) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      const result = await ApiGetRequestCabdin(
        `/web/teacher-achievement/${payload.uuid}`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
        });

        return [];
      } else {
        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
          detailPrestasiGuru: result.data.data,
        });
      }
    },
    async getExportDataGuru({ commit, state }, payload) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      if (payload) {
        await commit("changeIntegrasiPrestasiGuru", {
          page: payload || data.page,
        });
      }

      const { data } = state;

      let params = {
        limit: 0,
        sort: data.sort,
        key: data.key,
      };

      if (data.filter && data.filter.length > 0) {
        params["q"] = data.filter;
      }

      const result = await ApiGetRequestCabdin(`/web/teacher-achievement/export`, params);

      if (result.error) {
        Message({
          type: "error",
          message: result.error,
        });

        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
        });
      } else {
        commit("changeIntegrasiPrestasiGuru", {
          listExportGuru: result.data.data,
          total: result.data.meta
            ? result.data.meta.total
            : result.data.data.length,
          loading: false,
        });
      }
    },
    async importExcelPrestasiGuru({ commit }, payload) {
      commit("changeIntegrasiPrestasiGuru", {
        loading: true,
      });

      if (typeof payload != "object" || typeof payload.formData == "undefined" ) {
        Message({
          type: "error",
          message: "Data yang dikirim kosong",
        });
        commit("changeIntegrasiPrestasiGuru", {
          loading: false,
        });
        return;
      }
      //remove id editTable error
      for (let i = 0; i < payload.formData.length; i++) {
        delete payload.formData[i].id;
        delete payload.formData[i].editable;
        delete payload.formData[i].error;
      }
      let dataSend = {}
      let type = null
      dataSend = {
        "teacher_achievements": payload.formData
      }
      type = "teacher-achievement"

      const result = await ApiPostRequestCabdin(`/web/${type}/import`, dataSend);

      commit("changeIntegrasiPrestasiGuru", {
        loading: false,
      });
      if (result.error) {
        Message({
          type: "error",
          message: "Proses Import: "+result.error,
        });
      }else if(result.errors){
        Message({
          type: "error",
          message: "Proses Import: "+result.message,
        });
      }else{
        Message({
          type: "success",
          message: "Proses Import Berhasil",
        });
      }
    },
  };

  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
