import { ApiGetRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        columns: [{
                prop: 'nisn_nik',
                label: 'NIS/NISN',
                'class-name': 'body-14 col-black-2',
                sortable: true,
            },
            {
                prop: 'name',
                label: 'Nama Peminjam',
                'class-name': 'body-14 col-black-2',
                sortable: true,
                minWidth: '125',
            },
            {
                prop: 'book_code',
                label: 'QR Code',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'book',
                label: 'Judul/Penulis',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'rent_at',
                label: 'Tgl Pinjam',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'return_at',
                label: 'Tgl Kembali',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'status',
                label: 'Status',
                'class-name': 'body-14 col-black-2',
            },
        ],
        items: [],
        sortBy: '',
        sortType: '',
        filter: '',
        errors: [],
        loading: false,
    },
};

const mutations = {
    changePerpustakaanPeminjaman(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getPeminjaman({ commit, state }, payload) {
        commit('changePerpustakaanPeminjaman', {
            loading: true,
        });

        if (payload) {
            await commit('changePerpustakaanPeminjaman', {
                page: payload || data.page,
            });
        }

        const { data } = state;
        const params = {
            limit: data.limit,
        };

        if (data.page) {
            params.page = data.page
        }

        if (data.sortBy) {
            params.sort_field = data.sortBy
        }

        if (data.sortType) {
            params.sort_dir = data.sortType
        }

        if (data.filter) {
            params.q = data.filter
        }

        const result = await ApiGetRequest(`school/library/rent`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanPeminjaman', {
                loading: false,
            });

            return [];
        } else {
            await commit('changePerpustakaanPeminjaman', {
                items: result.data.data,
                total: result.data.meta.total,
                loading: false,
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};