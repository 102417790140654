import { HandlerNotification, HandlerPageStartEnd } from "@/utils/Helpers";
import { ApiDeleteRequest, ApiGetRequest, ApiPostRequest, ApiPutRequest } from "@/utils/Api";
import { _ } from "vue-underscore";

const state = {
  data: {
    detailPenanganan: {
      columns: [
        {
          prop: "handling_at",
          label: "Tanggal",
          width: "150",
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "handling_name",
          label: "Jenis Pemanggilan",
          width: "200",
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "keterangan",
          label: "Keterangan",
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "handled",
          label: "Status",
          width: "140",
          "class-name": "body-14 col-black-2 text-left"
        },
        {
          label: "Aksi",
          width: "150",
          "class-name": "body-14 col-black-2"
        }
      ],
      items: [],
      limit: 10,
      page: 1,
      total: 0,
      loading: false
    },
    columns: [
      {
        prop: "avatar",
        label: "Profile",
        width: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Nama",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "nis/nisn",
        label: "NIS/NISN",
        width: "180",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "kelas",
        label: "Kelas",
        width: "200",
        sortable: true,
        "class-name": "body-14 col-black-2 text-left"
      },
      {
        prop: "violation_score",
        label: "Poin Pelanggaran",
        width: "100",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "achievement_score",
        label: "Poin Prestasi",
        width: "100",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "status",
        label: "Status",
        width: "100",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "handling",
        label: "Penanganan",
        width: "100",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    detailProfilSiswa: {},
    formData: {
      score_student_id: "",
      school_handling_id: "",
      calling_at: "",
      calling_message: ""
    },
    formDataDetail: {},
    formDataTangani: {
      handling_at: "",
      handling_message: ""
    },
    formDataTanganiDetail: {},
    formRules: {
      school_handling_id: [
        {
          required: true,
          message: "Harus DiPilih",
          trigger: "blur"
        }
      ],
      calling_at: [
        {
          required: true,
          message: "Harus Diisi",
          trigger: "blur"
        }
      ],
      calling_message: [
        {
          required: true,
          message: "Harus Diisi, Maksimal 150huruf",
          trigger: "blur"
        }
      ]
    },
    formTanganiRules: {
      handling_message: [
        {
          required: true,
          message: "Harus Diisi",
          trigger: "blur"
        }
      ],
      handling_at: [
        {
          required: true,
          message: "Harus Diisi",
          trigger: "blur"
        }
      ]
    },
    filter: "",
    currentKelas: [],
    majorSelected: [],
    errors: [],
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    creator: null,
    updater: null,
    sort_field: "name",
    sort_dir: "asc",
    currentStatus: null,
    filterHandling: null,
    processAction: "read",
    isProcess: false,
    showmodalAddFormPemanggilan: false,
    showmodalAddFormPenanganan: false,
    showmodalListPenanganan: false,
    loading: false
  }
};

const mutations = {
  changeKlasKonselingPenanganan(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeKlasKonselingPenangananFormDataTangani(state, payload) {
    state.data.formDataTangani = Object.assign({}, state.data.formDataTangani, payload);
  },
  changeKlasKonselingPenangananFormDataTanganiDetail(state, payload) {
    state.data.formDataTanganiDetail = Object.assign({}, state.data.formDataTanganiDetail, payload);
  },
  changeKlasKonselingPenangananFormData(state, payload) {
    state.data.formData = Object.assign({}, state.data.formData, payload);
  },
  changeKlasKonselingPenangananFormDataDetail(state, payload) {
    state.data.formDataDetail = Object.assign({}, state.data.formDataDetail, payload);
  },
  changeKlasKonselingPenangananDetailPenanganan(state, payload) {
    state.data.detailPenanganan = Object.assign({}, state.data.detailPenanganan, payload);
  }
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload == null || payload ? true : false;
    commit("changeKlasKonselingPenanganan", property);
  },
  async getDataPenanganan({ state, commit, dispatch }, payload) {
    dispatch("handlerLoading");
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.limit : data.limit;
    if (data.filter) {
      params.q = data.filter;
    }
    if (data.currentStatus) {
      params.color_poin = data.currentStatus;
    }
    if (data.sort_field) {
      params.sort_field = data.sort_field;
    }
    if (data.sort_dir) {
      params.sort_dir = data.sort_dir;
    }
    if (data.filterHandling) {
      params.handled = data.filterHandling;
    }
    let resPage = HandlerPageStartEnd(data.page, data.items.length, data.limit);
    if (data.processAction == "update") {
      resPage = data.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    let url = ``;
    if (data.currentKelas.length) {
      url += `&classes[]=${data.currentKelas.join("&classes[]=")}`;
    }
    if (data.majorSelected.length) {
      url += `&majors[]=${data.majorSelected.join("&majors[]=")}`;
    }
    // const result = await import(`@/db/penanganan.json`);
    const result = await ApiGetRequest(`/klaskonseling/handling-student?${url.replace(/^&/, "")}`, params);
    if (result.error) {
      commit("changeKlasKonselingPenanganan", { loading: false });
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    await commit("changeKlasKonselingPenanganan", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total: (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async getDataListPenangananDetail({ state, commit }, payload) {
    commit("changeKlasKonselingPenangananDetailPenanganan", { loading: true });
    const params = {};
    const { data } = state;
    params.limit = payload ? payload.limit ?? data.detailPenanganan.limit : data.detailPenanganan.limit;
    let resPage = HandlerPageStartEnd(data.detailPenanganan.page, data.detailPenanganan.items.length, data.detailPenanganan.limit);
    if (data.processAction == "update") {
      resPage = data.detailPenanganan.page;
    }
    params.page = payload ? payload.page || resPage : resPage;
    const score_student_id = data.detailProfilSiswa.score_student_id;
    // const result = await import(`@/db/penanganan.json`);
    const result = await ApiGetRequest(`/klaskonseling/handling-student/${score_student_id}`, params);
    if (result.error) {
      commit("changeKlasKonselingPenangananDetailPenanganan", {
        loading: false
      });
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    await commit("changeKlasKonselingPenangananDetailPenanganan", {
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total: (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async getDataPenangananDetailAll({ commit, dispatch }, payload) {
    if (!payload.score_student_id) {
      HandlerNotification("error", "Data Penanganan bagian score_student_id Tidak ditemukan.");
      return false;
    }
    dispatch("handlerLoading");
    // const result = await import(`@/db/penanganan.json`);
    const result = await ApiGetRequest(`/klaskonseling/handling-student/${payload.score_student_id}`);
    commit("changeKlasKonselingPenanganan", { loading: false });
    if (result.error) {
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return;
      }
      HandlerNotification("error", result.error);
      return;
    }
    let dtFilterHandle = result.data.data.filter(item => {
      if (!item.handled) return item;
    });
    if (dtFilterHandle.length > 1) {
      await commit("changeKlasKonselingPenanganan", {
        showmodalListPenanganan: true
      });
      return true;
    }
    await commit("changeKlasKonselingPenanganan", {
      formDataTangani: dtFilterHandle[0],
      formDataTanganiDetail: dtFilterHandle[0],
      showmodalAddFormPenanganan: true
    });
    return true;
  },
  async postDataPemanggilan({ commit, state, dispatch }) {
    const { data } = state;
    dispatch("handlerLoading");
    let result = "";
    const formData = {
      score_student_id: data.formData.score_student_id,
      school_handling_id: data.formData.school_handling_id,
      calling_at: data.formData.calling_at,
      calling_message: data.formData.calling_message.replace(/"/g, '\\"')
    };
    if (data.formData && data.formData.id) {
      delete formData.score_student_id;
      result = await ApiPutRequest(`klaskonseling/calling-student/${data.formData.id}`, formData);
    } else {
      result = await ApiPostRequest("klaskonseling/calling-student", formData);
    }

    commit("changeKlasKonselingPenanganan", {
      loading: false
    });
    if (!result) {
      HandlerNotification("error", "Terjadi kesalahan, Coba Ulangi lagi!");
      return false;
    }
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeKlasKonselingPenanganan", {
          errors: errors
        });
      }
      return false;
    }
    HandlerNotification();
    dispatch("resetFormData");
    dispatch("getDataPenanganan");
    if (data.formDataTangani.where == `list penanganan` || data.formData.where == `list penanganan`) {
      dispatch("getDataListPenangananDetail");
    }
    return true;
  },
  async postDataPenanganan({ commit, state, dispatch }) {
    const { data } = state;
    dispatch("handlerLoading");
    let result = "";
    if (data.formDataTangani.id) {
      const formData2 = {
        handling_at: data.formDataTangani.handling_at,
        handling_message: data.formDataTangani.handling_message.replace(/"/g, '\\"')
      };
      result = await ApiPutRequest(`klaskonseling/handling-student/${data.formDataTangani.id}`, formData2);
    } else {
      const formData = {
        score_student_id: data.formData.score_student_id,
        school_handling_id: data.formData.school_handling_id,
        calling_at: data.formData.calling_at,
        calling_message: data.formData.calling_message.replace(/"/g, '\\"')
      };
      result = await ApiPostRequest("klaskonseling/calling-student", formData);
    }

    commit("changeKlasKonselingPenanganan", {
      loading: false
    });
    if (!result) {
      HandlerNotification("error", "Terjadi kesalahan, Coba Ulangi lagi!");
      return false;
    }
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      if (result.errorList) {
        let errors = [];
        for (const [key, value] of Object.entries(result.errorList)) {
          errors.push({
            message: value[0],
            path: [key]
          });
        }
        commit("changeKlasKonselingPenanganan", {
          errors: errors
        });
      }
      return false;
    }
    HandlerNotification();
    dispatch("resetFormData");
    dispatch("getDataPenanganan");
    if (data.formDataTangani.where) {
      dispatch("getDataListPenangananDetail");
    }
    return true;
  },
  async deleteDataPemanggilan({ state, dispatch }) {
    const { data } = state;
    if (!data.formDataDetail.id) {
      HandlerNotification("id");
      return false;
    }
    dispatch("handlerLoading");
    let result;
    result = await ApiDeleteRequest(`klaskonseling/calling-student/${data.formDataDetail.id}`);
    if (result.error) {
      dispatch("handlerLoading", false);
      if (result.code == 403) {
        HandlerNotification("notAkses");
        return false;
      }
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerNotification();
    dispatch("getDataPenanganan");
    if (data.formData.where == `list penanganan`) {
      dispatch("getDataListPenangananDetail");
    }
    dispatch("handlerLoading", false);
    return true;
  },
  resetFormData({ commit } = null) {
    commit("changeKlasKonselingPenanganan", {
      formDataTangani: {
        score_student_id: "",
        loading: false,
        handling_at: "",
        handling_message: ""
      },
      formDataTanganiDetail: {}
    });
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeKlasKonselingPenanganan", { page: payload ?? 1 });
    dispatch("getDataPenanganan");
    return true;
  },
  changePageDetailPenanganan({ commit, dispatch }, payload) {
    commit("changeKlasKonselingPenangananDetailPenanganan", {
      page: (payload && payload.page) ?? 1
    });
    dispatch("getDataPenanganan");
  },
  handlerOnCloseModal({ commit }, payload) {
    if (payload == "penanganan") {
      commit("changeKlasKonselingPenanganan", {
        showmodalAddFormPenanganan: false,
        processAction: `read`
      });
      return;
    }
    commit("changeKlasKonselingPenanganan", {
      showmodalAddFormPemanggilan: false,
      processAction: `read`
    });
    commit("changeKlasKonselingPenangananFormDataDetail", {
      id: ""
    });
  },
  async penangananFormData({ state, commit, dispatch }, payload) {
    const { data } = state;
    if (payload.formDataTangani) {
      commit("changeKlasKonselingPenanganan", {
        formDataTangani: payload.formDataTangani,
        showmodalAddFormPenanganan: true
      });
      if (payload.where) {
        commit("changeKlasKonselingPenanganan", {
          formDataTangani: {
            ...state.data.formDataTangani,
            where: payload.where
          }
        });
      } else {
        if (state.data.formDataTangani.where) delete state.data.formDataTangani.where;
      }
    }
    if (
      payload &&
      payload.detailSiswa &&
      payload.detailSiswa.score_student_id !== data.formDataTangani.score_student_id
    ) {
      dispatch("getDataPenangananDetailAll", {
        score_student_id: payload.detailSiswa.score_student_id
      });
    } else {
      commit("changeKlasKonselingPenanganan", {
        showmodalAddFormPenanganan: true
      });
    }
    commit("changeKlasKonselingPenanganan", {
      detailProfilSiswa: (payload && payload.detailSiswa) || data.detailProfilSiswa
    });
  },
  pemanggilanFormData({ commit, dispatch }, payload) {
    dispatch("resetFormData");
    commit("changeKlasKonselingPenanganan", {
      processAction: "create",
      showmodalAddFormPemanggilan: true,
      formData: {
        score_student_id: 0,
        school_handling_id: "",
        calling_at: "",
        loading: "",
        calling_message: ""
      }
    });
    let objectFormData = {
      score_student_id: (payload && payload.item && payload.item.score_student_id) || 0
    };
    if (payload.where && payload.where == `list penanganan`) {
      objectFormData.where = payload.where;
    }
    commit("changeKlasKonselingPenangananFormData", objectFormData);
    if (payload.where && payload.where !== `list penanganan` && payload.item) {
      commit("changeKlasKonselingPenanganan", { detailProfilSiswa: payload.item });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
