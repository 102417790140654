import {
  // ApiNotification,
  ApiGetRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
// import moment from "moment";
import { Message } from "element-ui";

const state = {
  data: {
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    loading: false,
    editData: false,
    errors: [],
    columnsDaftarKantin: [
      {
        prop: "nama_kantin",
        label: "Nama Kantin",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "pemilik",
        label: "Pemilik",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "wallet",
        label: "Wallet",
        minWidth: "80",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "no_telp",
        label: "No Telp",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "email",
        label: "Email",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "sharing_profit",
        label: "Sharing Profit",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2 text-center",
      },
      {
        prop: "klaspay",
        label: "Klaspay",
        minWidth: "120",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        fixed: "right",
        label: "Aksi",
        width: "145",
        "class-name": "body-14 col-black-2",
      },
    ],
    dataItem: [
      {
        id: 1,
        nama_kantin: "Bakoel Jamoer",
        pemilik: "Ibu Martinah",
        no_telp: "081216593329",
        alamat: "Perum.Grand Anggaswangi No 12",
        start_date: "12 April 2022",
        end_date: "12 April 2023",
      },
      {
        id: 1,
        nama_kantin: "Susu Murni Nasional",
        pemilik: "Ibu Soemarsih",
        no_telp: "081216593321",
        alamat: "Perum.Grand Anggaswangi No 11",
        start_date: "12 April 2022",
        end_date: "12 April 2023",
      },
    ],
    formDaftarKantin: {
      id: null,
      namaKantin: "",
      pemilik: "",
      noTelp: "",
      email: "",
      profit_share: null,
    },
    rulesForm: {
      namaKantin: [
        {
          required: true,
          message: "Nama toko wajib diisi",
          trigger: "blur",
        },
      ],
      pemilik: [
        {
          required: true,
          message: "Nama pemilik toko wajib diisi",
          trigger: "blur",
        },
      ],
      noTelp: [
        {
          required: true,
          message: "Nomor telp wajib diisi",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email wajib diisi",
          trigger: "blur",
        },
      ],
      // profit_share: [
      //   {
      //     required: true,
      //     message: "Sharing Profit wajib diisi",
      //     trigger: "blur",
      //   },
      // ],
    },
  },
};

const mutations = {
  changeKoperasiDaftarKantin(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getDataTable({ commit, state }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    if (payload) {
      await commit("changeKoperasiDaftarKantin", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      limit: data.limit,
      page: data.page,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      q: data.filter,
    };

    const result = await ApiGetRequest(`school/canteen`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDaftarKantin", {
        dataItem: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },
  async createCanteen({ commit, state }) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const { data } = state;

    let formData = {
      name: data.formDaftarKantin.pemilik,
      merchant: data.formDaftarKantin.namaKantin,
      phone: data.formDaftarKantin.noTelp,
      email: data.formDaftarKantin.email,
      profit_share: data.formDaftarKantin.profit_share,
    };

    const result = await ApiPostRequest(`school/canteen`, formData);

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal menambahkan data, periksa kembali data anda",
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Kantin berhasil ditambahkan",
      });

      return result.data.data;
    }
  },
  async showDataCanteen({ commit }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/canteen/${payload.id_canteen}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDaftarKantin", {
        formDaftarKantin: {
          id: result.data.data.id,
          namaKantin: result.data.data.merchant,
          pemilik: result.data.data.name,
          noTelp: result.data.data.phone,
          email: result.data.data.email,
          profit_share: result.data.meta.profit_share,
        },
        loading: false,
      });
    }
  },
  async editCanteen({ commit, state }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const { data } = state;

    let formData = {
      name: data.formDaftarKantin.pemilik,
      merchant: data.formDaftarKantin.namaKantin,
      phone: data.formDaftarKantin.noTelp,
      email: data.formDaftarKantin.email,
      profit_share: data.formDaftarKantin.profit_share,
    };

    const result = await ApiPutRequest(
      `school/canteen/${payload.id_canteen}`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: "Gagal mengubah data, periksa kembali data anda",
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
        error: result.errorList ? result.errorList : false,
      });

      return false;
    } else {
      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Data Kantin berhasil diubah",
      });

      return result.data.data;
    }
  },
  async deleteCanteen({ commit }, payload) {
    commit("changeKoperasiDaftarKantin", {
      loading: true,
    });

    const result = await ApiDeleteRequest(
      `school/canteen/${payload.id_canteen}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
    } else {
      commit("changeKoperasiDaftarKantin", {
        loading: false,
      });
      Message({
        type: "success",
        message: "Data Kantin berhasil dihapus",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
