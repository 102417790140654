import ApiService from "@/api/ApiService"
import axios from "axios"

class PembayaranSppRepository {
	constructor() {
		this.apiService = ApiService
		this.cancelTokenSource = null
		this.mock = 'http://localhost:3000/api'
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.")
		}
		this.cancelTokenSource = axios.CancelToken.source()
	}

	async getStudentInvoice(params) {
		this.createCancelToken()
		return await this.apiService.get(`/school/student-invoice`, {params})
	}

	async getDiscount(id, params) {
		return await this.apiService.get(`/school/student/${id}/discount`, {params})
	}

	async getInvoice(id, params) {
		return await this.apiService.get(`/school/student/${id}/invoice`, {params})
	}

	async getStudentDebt(params) {
		return await this.apiService.get(`/school/student-debt`, {params})
	}

	async getSppStudentFeeInvoice(params) {
		return await this.apiService.get(`/school/school-fee-invoice`, {params})
	}

	async getTagihanSppPengaturan(params) {
		return await this.apiService.get(`/school/school-fee-setting`, {params})
	}

	async postTagihanSppPengaturanById(id, formData) {
		return await this.apiService.post(`/school/school-fee-setting/${id}`, formData)
	}

	async deleteTagihanSppPengaturanByIdAndProductId(id, idProductSpp) {
		return await this.apiService.delete(`/school/product/${idProductSpp}/attach/${id}`)
	}

	async updateTagihanSppPengaturanByIdAndProductId(id, idProductSpp, formData) {
		return await this.apiService.put(`/school/product/${idProductSpp}/attach/${id}`, formData)
	}

	//doc
	async postTagihanSppPengaturan(formData) {
		return await this.apiService.post(`/school/school-fee-setting`, formData)
	}

	async postCreateTagihanSpp(id, formData) {
		return await this.apiService.post(`/school/school-fee-invoice`, formData)
	}

	//doc
	async getOnklasProductCheck() {
		return await this.apiService.get(`/klaspay/external/onklasproduct/check`)
	}

	//doc
	async postSetAdminSpp(id, formData) {
		return await this.apiService.post(`/klaspay/external/onklasproduct/set/id/${id}`, formData)
	}

}

export default new PembayaranSppRepository()
