import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/method';
import * as VueGoogleMaps from "vue2-google-maps";
import "bootstrap/dist/css/bootstrap.min.css";

/* Initial master style */
// Import BootstrapVue
import BootstrapVue from 'bootstrap-vue'
import { PaginationPlugin, DropdownPlugin, NavPlugin } from 'bootstrap-vue';
Vue.use(DropdownPlugin);
Vue.use(PaginationPlugin);
Vue.use(NavPlugin);
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_MAPS_KEY, // Gantilah dengan API Key Anda
		libraries: "places", // Jika Anda memerlukan libraries tambahan, tambahkan di sini
	},
});
		//async: true,
Vue.use(BootstrapVue);

import UUID from 'vue-uuid';
Vue.use(UUID);

/** Initial master component*/
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
Vue.use(ElementUI, { locale });

/** Initial Vuejs Tour */
// import VueIntro from 'vue-introjs';
// import 'intro.js/introjs.css';
// Vue.use(VueIntro);

/** Initial Calender - Klastime */
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L',
  },
});
/* Global base css */
import '../src/assets/scss/base/index.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
