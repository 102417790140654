import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";

import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Sosial Media - Postingan  E-Book",
    dataDetailId: "",
    sortBy: "",
    sortType: "",
    filter: "",
    rolesSelected: "",
    periode: "1",
    tglawal: "",
    tglakhir: "",
    feed_id: null,
    page: 1,
    total: 10,
    perPage: 10,
    limit: 10,
    loading: false,
    showModalDetail: false,
    DetailPage: {
      name: "",
      avatar: "",
      nisn_nik: "",
      role: "",
      total_posting: null,
      ebook_author: "",
      ebook_title: "",
    },
    items: [],
    itemsModal: [],
    errors: [],
    columns: [
      {
        prop: "cover",
        label: "Cover ",
        sortable: false,
        minWidth: "50",
      },

      {
        prop: "name",
        label: "Nama Lengkap ",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "title",
        label: "Judul/Pengarang ",
        sortable: false,
        minWidth: "150",
      },
      {
        prop: "profesi",
        label: "Profesi",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "date_post",
        label: "Tgl Posting",
        sortable: false,
        minWidth: "100",
      },
      {
        prop: "status",
        label: "Status",
        sortable: false,
        minWidth: "100",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_modal: [
      {
        prop: "image",
        label: "Cover",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "file_name",
        label: "Nama File",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "date_post",
        label: "Tgl Posting",
        minWidth: "190",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "like",
        label: "Suka",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "comment",
        label: "Komentar",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      // {
      //   prop: "download",
      //   label: "Download",
      //   minWidth: "90",
      //   sortable: false,
      //   "class-name": "body-14 col-black-2",
      // },
      // {
      //   prop: "view",
      //   label: "View",
      //   minWidth: "90",
      //   sortable: false,
      //   "class-name": "body-14 col-black-2",
      // },
    ],
  },
};

const mutations = {
  changeSosmedEbook(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListFeedEbook({ commit, state }, payload) {
    commit("changeSosmedEbook", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedEbook", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      role: data.rolesSelected,
      start_date: data.tglawal,
      end_date: data.tglakhir,
    };

    const result = await ApiGetRequest(`school/sosmed/feed-ebooks`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedEbook", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedEbook", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getFeedEbookById({ commit }, payload) {
    commit("changeSosmedEbook", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/sosmed/feed/${payload.id}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedEbook", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedEbook", {
        DetailPage: {
          name: result.data.data.user.name,
          avatar: result.data.data.user.avatar,
          nisn_nik: result.data.data.user.nisn_nik,
          role: result.data.data.user.role,
          total_posting: result.data.data.count_feed,
          ebook_author: result.data.data.feed_author,
          ebook_title: result.data.data.feed_title,
        },
        itemsModal: [
          {
            id: result.data.data.id,
            file_name: result.data.data.feed_title,
            caption: result.data.data.feed_body,
            date_post: result.data.data.created_at_label,
            like: result.data.data.count_likes,
            comment: result.data.data.count_comments,
            view: result.data.data.count_view,
            download: result.data.data.count_download,
            image: result.data.data.feed_thumbnail_image,
          },
        ],
        loading: false,
      });
    }
  },
  async inactiveFeed({ commit }, payload) {
    commit("changeSosmedEbook", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedEbook", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const formData = {
      feed_id: payload.id,
    };
    const result = await ApiPostRequest(
      `school/sosmed/feed-posts/inactive`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedEbook", {
        loading: false,
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Postingan Ebook telah dinonaktifkan",
      });
      commit("changeSosmedEbook", {
        loading: false,
      });
      return true;
    }
  },
  async activeFeed({ commit, state }, payload) {
    commit("changeSosmedEbook", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedEbook", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const formData = {
      feed_id: payload.id,
    };

    const result = await ApiPostRequest(
      `school/sosmed/feed-posts/active`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedEbook", {
        loading: false,
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Postingan Ebook telah diaktifkan kembali",
      });
      commit("changeSosmedEbook", {
        loading: false,
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
