import { ApiGetRequest, ApiPostMultipart, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        columns: [{
                prop: 'cover_image_url',
                label: 'Gambar',
                minWidth: '50',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'title_author',
                label: 'Judul/Penulis',
                minWidth: '120',
                'class-name': 'body-14 col-black-2',
                sortable: true,
            },
            {
                prop: 'isbn',
                label: 'ISBN',
                minWidth: '70',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'publisher',
                label: 'Penerbit',
                minWidth: '80',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'category',
                label: 'Kategori',
                minWidth: '80',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'published_at',
                label: 'Thn Terbit',
                minWidth: '50',
                'class-name': 'body-14 col-black-2',
            },
            {
                label: 'Aksi',
                minWidth: '50',
                'class-name': 'body-14 col-black-2',
            },
        ],
        items: [],
        limit: 10,
        page: 1,
        total: 0,
        sortBy: '',
        sortType: '',
        filter: '',
        errors: [],
        loading: false,
        loadingIsbn: false,
        form: {
            editMode: false,
            isbnSearch: false,
            id: '',
            isbn: '',
            title: '',
            author: '',
            publisher: '',
            published_at: '',
            number_of_page: '',
            number_of_pageIsbn: '',
            language: '',
            description: '',
            descriptionIsbn: '',
            image: '',
            imgPreview: '',
            imgIsbnPreview: '',
            book_subject_id: '',
            book_category_id: '',
        },
        formRules: {
            title: [
                { required: true, message: 'Judul tidak boleh kosong', trigger: 'blur' },
            ],
            author: [
                { required: true, message: 'Penulis tidak boleh kosong', trigger: 'blur' },
            ],
            publisher: [
                { required: true, message: 'Penerbit tidak boleh kosong', trigger: 'blur' },
            ],
            published_at: [
                { required: true, message: 'Tahun Terbit tidak boleh kosong', trigger: 'blur' },
            ],
            book_subject_id: [
                { required: true, message: 'Kategori tidak boleh kosong', trigger: 'blur' },
            ],
            book_category_id: [
                { required: true, message: 'Sub Kategori tidak boleh kosong', trigger: 'blur' },
            ],
            language: [
                { required: true, message: 'Bahasa tidak boleh kosong', trigger: 'blur' },
            ],
            number_of_page: [
                { required: true, message: 'Jumlah Halaman tidak boleh kosong', trigger: 'blur' },
            ],
        },
        itemsSearch: [],
        filterSearch: '',
        selectedSearch: '',
    },
};

const mutations = {
    changePerpustakaanBuku(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getBuku({ commit, state }, payload) {
        commit('changePerpustakaanBuku', {
            loading: true,
        });

        if (payload) {
            await commit('changePerpustakaanBuku', {
                page: payload || data.page,
            });
        }

        const { data } = state;
        const params = {
            limit: data.limit,
        };

        if (data.page) {
            params.page = data.page
        }

        if (data.sortBy) {
            params.sort_field = (data.sortBy == "title_author") ? "title" : data.sortBy
        }

        if (data.sortType) {
            params.sort_dir = data.sortType
        }

        if (data.filter) {
            params.q = data.filter
        }

        const result = await ApiGetRequest(`school/library/school-book`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanBuku', {
                loading: false,
            });

            return [];
        } else {
            await commit('changePerpustakaanBuku', {
                items: result.data.data,
                total: result.data.meta.total,
                loading: false,
            });
        }
    },

    async getBukuById({ commit, state }, payload) {
        const { data } = state;

        commit('changePerpustakaanBuku', {
            loading: true,
        });

        const result = await ApiGetRequest(`school/library/school-book/${payload}`);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanBuku', {
                loading: false,
            });

            return [];
        } else {
            let form = data.form
            form.isbnSearch = (result.data.data.book.isbn) ? true : false
            form.id = result.data.data.school_book_id
            form.isbn = result.data.data.book.isbn
            form.title = result.data.data.book.title
            form.author = result.data.data.book.author
            form.publisher = result.data.data.book.publisher
            form.published_at = result.data.data.book.published_at
            form.number_of_page = result.data.data.book.number_of_page
            form.language = result.data.data.book.language
            form.description = result.data.data.book.description
            form.book_category_id = result.data.data.category.id
            form.book_subject_id = result.data.data.subject.id
            if (result.data.data.book.cover_url) {
                form.imgPreview = result.data.data.book.cover_url
            }
            await commit('changePerpustakaanBuku', {
                loading: false,
                form: form,
            });
        }
    },

    async getIsbn({ commit, state }) {
        const { data } = state;

        commit('changePerpustakaanBuku', {
            loadingIsbn: true,
        });

        const result = await ApiGetRequest(`school/library/check-isbn/${data.form.isbn}`);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanBuku', {
                loadingIsbn: false,
            });

            return [];
        } else {
            let form = data.form
            form.isbnSearch = true
            form.isbn = result.data.isbn
            form.title = result.data.title
            form.author = result.data.author
            form.publisher = result.data.publisher
            form.published_at = result.data.published_at
            form.number_of_page = result.data.number_of_page
            form.language = result.data.language
            form.description = result.data.description
            form.number_of_pageIsbn = result.data.number_of_page
            form.descriptionIsbn = result.data.description
            form.imgIsbnPreview = result.data.image
            await commit('changePerpustakaanBuku', {
                loadingIsbn: false,
                form: form,
            });
        }
    },

    async submitBuku({ commit, state }) {
        const { data } = state;

        commit('changePerpustakaanBuku', {
            loading: true,
        });

        let endPoint = 'school/library/school-book'
        const formData = new FormData();
        formData.append('_method', 'post');
        formData.append('title', data.form.title);
        formData.append('author', data.form.author);
        formData.append('publisher', data.form.publisher);
        formData.append('published_at', data.form.published_at);
        formData.append('language', data.form.language);
        formData.append('number_of_page', data.form.number_of_page);
        formData.append('book_subject_id', data.form.book_subject_id);
        formData.append('book_category_id', data.form.book_category_id);
        if (data.form.isbn) {
            formData.append('isbn', data.form.isbn);
        }
        if (data.form.description) {
            formData.append('description', data.form.description);
        }
        if ((typeof data.form.image) != 'string' && data.form.image != null) {
            formData.append('image', data.form.image);
        }
        if (data.form.id) {
            formData.set('_method', 'put');
        }

        let result = ""

        if (data.form.id) {
            result = await ApiPostMultipart(`${endPoint}/${data.form.id}`, formData);
        } else {
            result = await ApiPostMultipart(`${endPoint}`, formData);
        }

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                commit('changePerpustakaanBuku', {
                    errors: errors,
                    loading: false,
                });
            }
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            commit('changePerpustakaanBuku', {
                errors: [],
                form: {
                    editMode: false,
                    isbnSearch: false,
                    id: '',
                    isbn: '',
                    title: '',
                    author: '',
                    publisher: '',
                    published_at: '',
                    number_of_page: '',
                    number_of_pageIsbn: '',
                    language: '',
                    description: '',
                    descriptionIsbn: '',
                    image: '',
                    imgPreview: '',
                    imgIsbnPreview: '',
                    book_subject_id: '',
                    book_category_id: '',
                },
                loading: false,
            });

            return true;
        }
    },

    async deleteBuku({ commit }, payload) {
        commit('changePerpustakaanBuku', {
            loading: true,
        });

        if (payload && payload.id) {
            const result = await ApiDeleteRequest(`school/library/school-book/${payload.id}`);

            if (result.error) {
                Message({
                    type: 'error',
                    message: result.error,
                });
                commit('changePerpustakaanBuku', {
                    errors: result.errorList,
                    loading: false,
                });
                return false;
            } else {
                Message({
                    type: 'success',
                    message: 'Data telah dihapus.',
                });
                commit('changePerpustakaanBuku', {
                    errors: [],
                    loading: false,
                });
                return true;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};