import store from "@/store";
import {
  ApiDeleteRequest,
  ApiGetRequest,
  ApiPostMultipart,
  ApiPostRequest,
  ApiPutRequest
} from "@/utils/Api";
import { HandlerNotification, HandlerResult } from "@/utils/Helpers";

const state = {
  data: {
    stafGuru: {
      items: [],
      limit: 10,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false
    },
    pelajar: {
      items: [],
      limit: 10,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false
    },
    bin: {
      columns: [
        {
          prop: "klasdrive_user",
          label: "Nama Pelajar",
          minWidth: 180,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "klasdrive_name",
          sortable: true,
          label: "Nama Dokumen",
          minWidth: 220,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "category",
          label: "Kategori",
          width: 120,
          sortable: true,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "type",
          label: "Tipe",
          sortable: true,
          width: "100",
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "size",
          label: "Ukuran",
          sortable: true,
          width: "120",
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          prop: "updated_at",
          label: "Tgl Hapus",
          minWidth: 130,
          width: "170",
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          fixed: "right",
          label: "Aksi",
          width: "130",
          "class-name": "body-14 col-black-2"
        }
      ],
      itemAll: [],
      items: [],
      limit: 100,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      loading: false,
      errors: false
    },
    byClass: {
      subColumns: [
        {
          prop: "no",
          label: "No",
          w: 7,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "name",
          label: "Nama Berkas",
          w: 23,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "kategori",
          label: "Kategori",
          w: 15,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "tipe",
          label: "Tipe",
          w: 10,
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          prop: "size",
          label: "Ukuran",
          w: 10,
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          prop: "tglBuat",
          label: "Tgl Buat",
          w: 15,
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          prop: "Aksi",
          label: "Aksi",
          w: 20,
          "class-name": "body-14 col-black-2"
        }
      ],
      columns: [
        {
          prop: "name",
          label: "Nama Pelajar",
          minWidth: 180,
          sortable: true,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "nisn",
          label: "NISN/NIS",
          width: "200",
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "classes",
          label: "Kelas",
          minWidth: 160,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "major",
          label: "Jurusan",
          minWidth: 120,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "dokumen",
          label: "Dokumen",
          width: 100,
          "class-name": "body-14 col-black-2"
        },
        {
          fixed: "right",
          label: "Aksi",
          width: 80,
          "class-name": "body-14 col-black-2"
        }
      ],
      itemAll: [],
      items: [],
      limit: 35,
      page: 1,
      total: 0,
      sortBy: "",
      sortType: "",
      filter: "",
      currentClass: null,
      loading: false,
      errors: false
    },
    columns: [
      {
        prop: "klasdrive_name",
        label: "Nama Dokumen",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "type",
        label: "Tipe",
        sortable: true,
        width: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "size",
        label: "Ukuran",
        sortable: true,
        width: "120",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "created_at",
        label: "Tanggal",
        sortable: true,
        width: "170",
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "180",
        "class-name": "body-14 col-black-2"
      }
    ],
    status: [
      { value: "all", label: "Semua status" },
      { value: "active", label: "Aktif" },
      { value: "inactive", label: "Non Aktif" }
    ],
    listKategori: [
      {
        active: false,
        name: "umum",
        label: "UMUM",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: false,
        name: "semester 1",
        label: "SEMESTER 1",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 2",
        label: "SEMESTER 2",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 3",
        label: "SEMESTER 3",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 4",
        label: "SEMESTER 4",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 5",
        label: "SEMESTER 5",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 6",
        label: "SEMESTER 6",
        type: ["SD", "SMP", "SMA", "SMK"]
      },
      {
        active: false,
        name: "semester 7",
        label: "SEMESTER 7",
        type: ["SD", "SMK"]
      },
      {
        active: false,
        name: "semester 8",
        label: "SEMESTER 8",
        type: ["SD", "SMK"]
      },
      { active: false, name: "semester 9", label: "SEMESTER 9", type: ["SD"] },
      {
        active: false,
        name: "semester 10",
        label: "SEMESTER 10",
        type: ["SD"]
      },
      {
        active: false,
        name: "semester 11",
        label: "SEMESTER 11",
        type: ["SD"]
      },
      { active: false, name: "semester 12", label: "SEMESTER 12", type: ["SD"] }
    ],
    fileInputs: [
      {
        id: "",
        nameSelect: "",
        name: "",
        currentNameFile: "",
        image: null,
        imgPreview: null,
        type: null,
        size: null,
        status: "published"
      }
    ],
    resultFilterListKategori: [
      {
        active: true,
        name: "umum",
        label: "UMUM",
        type: ["SMA", "SMK", "SD", "SMP"]
      }
    ],
    jenisDokumen: [
      {
        active: true,
        name: "KARTU KELUARGA",
        label: "KARTU KELUARGA",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "KTP",
        label: "KTP",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "AKTE",
        label: "AKTE",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "IJAZAH SD",
        label: "IJAZAH SD",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "IJAZAH SMP",
        label: "IJAZAH SMP",
        type: ["SMA", "SMK", "SMP"]
      },
      {
        active: true,
        name: "IJAZAH SMA",
        label: "IJAZAH SMA",
        type: ["SMA"]
      },
      {
        active: true,
        name: "IJAZAH SMK",
        label: "IJAZAH SMK",
        type: ["SMK"]
      },
      {
        active: true,
        name: "FOTO",
        label: "FOTO",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "SKL",
        label: "SKL",
        type: ["SMA", "SMK", "SD", "SMP"]
      },
      {
        active: true,
        name: "LAINNYA",
        label: "LAINNYA",
        type: ["SMA", "SMK", "SD", "SMP"]
      }
    ],
    listPaket: [
      {
        id: "",
        label: "5 GB",
        harga: 500000,
        hargaFormat: `500.000`,
        type: ""
      },
      {
        id: "",
        label: "10 GB",
        harga: 900000,
        hargaFormat: `900.000`,
        type: ""
      },
      {
        id: "",
        label: "15 GB",
        harga: 1300000,
        hargaFormat: `1.300.000`,
        type: ""
      },
      {
        id: "",
        label: "20 GB",
        harga: 1600000,
        hargaFormat: `1.600.000`,
        type: ""
      }
    ],
    itemPreviewImages: [],
    items: [],
    itemAll: [],
    limit: 100,
    page: 1,
    total: 0,
    maxFiles: 10,
    totalFileStorage: 10,
    loading: false,
    loadingDownload: false,
    showModalAdd: false,
    showModalDetail: false,
    showModalUpdateName: false,
    focus: {
      selectNameDokumen: false,
      inputNameDokumen: false
    },
    viewMode: "table",
    prosesHandleFile: "click",
    formData: {},
    formDataDetail: {},
    imageDetail: {},
    sort_dir: null,
    sort_field: null,
    uuid: 0,
    currentTab: "umum"
  }
};

const mutations = {
  changeFileStorage(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeFileStorageFileInputsIndex(state, payload) {
    state.data.fileInputs[payload.index] = Object.assign(
      {},
      state.data.fileInputs[payload.index],
      payload.payload
    );
  },
  changeFileStorageBin(state, payload) {
    state.data.bin = Object.assign({}, state.data.bin, payload);
  },
  changeFileStorageByClass(state, payload) {
    state.data.byClass = Object.assign({}, state.data.byClass, payload);
  },
  mergeFileInputs(state, newArray) {
    state.data.fileInputs = newArray;
  },
  changeFileStorageStafGuru(state, payload) {
    state.data.stafGuru = Object.assign({}, state.data.stafGuru, payload);
  },
  changeFileStoragePelajar(state, payload) {
    state.data.pelajar = Object.assign({}, state.data.pelajar, payload);
  }
};

const actions = {
  handlerLoading({ commit }, payload = null) {
    const property = {};
    property.loading = payload == null || payload ? true : false;
    if (payload && payload.jenis == `guru`) {
      commit("changeFileStorageStafGuru", property);
    } else if (payload && payload.jenis == `pelajar`) {
      commit("changeFileStoragePelajar", property);
    } else {
      commit("changeFileStorage", property);
    }
  },
  filterListKategori({ state, commit }) {
    let typeSchoolLS =
      (store.state &&
        store.state.user &&
        store.state.user.school &&
        store.state.user.school.type) ||
      "0";
    const res = state.data.listKategori.filter(kategori =>
      kategori.type.includes(typeSchoolLS)
    );
    const results = res.map(kategori => ({
      ...kategori,
      active: kategori.name == state.data.currentTab
    }));
    commit("changeFileStorage", { resultFilterListKategori: results });
    return true;
  },
  async filterDokumenKategori({ state, commit }, payload) {
    let dt = state.data.itemAll;
    if (payload && payload.items) {
      dt = payload.items;
    }
    if (!dt.length) return [];

    const res = await dt.filter(item =>
      item.category.includes(state.data.currentTab.toUpperCase())
    );
    //const results = res.map(kategori => ({
    //  ...kategori,
    //  active: kategori.name == state.data.currentTab
    //}));
    if (!payload) {
      commit("changeFileStorage", { items: res });
    }
    return res;
  },
  removeFileInput({ state, commit }, payload) {
    if (state.data.fileInputs.length > 1 && payload && payload.index >= 0) {
      if (!state.data.fileInputs[payload.index]) {
        return false;
      }
      state.data.fileInputs.splice(payload.index, 1);
      commit("changeFileStorage", { fileInputs: state.data.fileInputs });
      return true;
    }
    return false;
  },
  async postDataFileStorage({ dispatch, state }, payload) {
    if (!payload && !payload.uuid) {
      return `, Ulangi Lagi / Refresh Browser`;
    }
    let formData = new FormData();
    formData.append("_method", "post");
    formData.append("file", state.data.fileInputs[payload.index].image);
    formData.append("name", payload.name);
    formData.append("uuid", payload.uuid);
    formData.append("category", payload.category.toUpperCase());
    dispatch("handlerLoading");
    const result = await ApiPostMultipart(
      `school/data/klasdrive/${payload.uuid}`,
      formData
    );
    if (result.error) {
      return ", " + result.error || `, Ulangi Lagi.`;
    }
    return true;
  },
  async getDataFileStorage({ state, dispatch, commit }, payload) {
    dispatch("handlerLoading");
    const { data } = state;
    let params = {};
    let uuid = data.uuid;
    if (data.sort_field) params.sort_field = data.sort_field;
    if (data.sort_dir) params.sort_dir = data.sort_dir;
    if (payload) {
      if (payload.sort_field) params.sort_field = payload.sort_field;
      if (payload.sort_dir) params.sort_dir = payload.sort_dir;
      uuid = payload.uuid || data.uuid;
    }
    params.category = data.currentTab;
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    //const result = await import("@/db/klasdrive.json");
    const result = await ApiGetRequest(
      `school/data/klasdrive/dataStudent/${uuid}`,
      params
    );
    dispatch("handlerLoading", false);
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerResult(result);
    const filterItems = await dispatch("filterDokumenKategori", {
      items: result.data.data
    });
    commit("changeFileStorage", {
      loading: false,
      itemAll: result.data.data,
      items: filterItems,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async getFileStorageUsage({ dispatch, commit }) {
    dispatch("handlerLoading");
    const result = await ApiGetRequest(`school/data/klasdrive/usage`);
    dispatch("handlerLoading", false);
    if (result.error) {
      commit("changeFileStorage", {
        totalFileStorage: null
      });
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerResult(result);
    commit("changeFileStorage", {
      loading: false,
      totalFileStorage: (result.data && result.data.usage) || 0
    });
    return true;
  },
  async getDataFileStorageBin({ state, commit }, payload) {
    commit("changeFileStorageBin", { loading: true });
    const { data } = state;
    let params = {};
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    const result = await ApiGetRequest(
      `school/data/klasdrive/recyclebin`,
      params
    );
    commit("changeFileStorageBin", { loading: false });
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerResult(result);
    commit("changeFileStorageBin", {
      itemAll: result.data.data,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async getDataFileStorageByClass({ state, commit }, payload) {
    const { data } = state;
    let params = {};
    params.limit = (payload && payload.limit) || data.limit;
    params.page = 1;
    let idClass = data.byClass.currentClass;
    if (!idClass) {
      commit("changeFileStorageByClass", {
        itemAll: [],
        items: [],
        page: 1,
        total: 0
      });
      return false;
    }
    commit("changeFileStorageByClass", { loading: true });
    const result = await ApiGetRequest(
      `school/data/klasdrive/dataClass/${idClass}`,
      params
    );
    commit("changeFileStorageByClass", { loading: false });
    if (result.error) {
      HandlerNotification("error", result.error);
      commit("changeFileStorageByClass", {
        itemAll: [],
        items: [],
        page: 1,
        total: 0
      });
      return false;
    }
    HandlerResult(result);
    // Membuat array baru dengan transformasi menggunakan map()
    const itemsFormat = await result.data.data.map(obj => {
      let resDetailDrive = {
        sum: 0,
        total: 0
      };
      if (obj.details_klasdrive.length) {
        // Menghitung total ukuran untuk setiap objek
        resDetailDrive.sum = obj.details_klasdrive.reduce(
          (total, driveObj) => total + parseInt(driveObj.size),
          0
        );
        resDetailDrive.total = obj.details_klasdrive.length;
      }

      // Mengembalikan objek dengan penambahan kunci baru 'detailDrive'
      return {
        ...obj,
        detailDrive: resDetailDrive
      };
    });

    commit("changeFileStorageByClass", {
      itemAll: result.data.data,
      items: itemsFormat,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total:
        (result.data.meta && result.data.meta.total) || result.data.data.length
    });
  },
  async putDataFileStorage({ state, dispatch, commit }, payload) {
    dispatch("handlerLoading");
    const { data } = state;
    let params = {};
    let idKlasDrive = data.formData.id;
    if (payload) {
      idKlasDrive = payload.id || data.formData.id;
    }
    params.name =
      data.formData.name == `LAINNYA`
        ? data.formData.name
        : data.formData.nameSelect;
    params.category = data.formData.category;
    const result = await ApiPutRequest(
      `school/data/klasdrive/${idKlasDrive}`,
      params
    );
    dispatch("handlerLoading", false);
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerResult(result);
    HandlerNotification();
    dispatch("getDataFileStorage");
    commit("changeFileStorage", {
      loading: false,
      formDataDetail: {
        name: data.formData.name,
        category: data.formData.category,
        id: data.formData.id
      }
    });
    return true;
  },
  async deleteDataFileStorage({ dispatch, commit }, payload) {
    dispatch("handlerLoading");
    let id = 0;
    if (payload) {
      if (payload.id) {
        id = payload.id;
      } else {
        HandlerNotification("warning", "ID Tidak ditemukan.");
        return false;
      }
    }
    let result;
    if (payload && payload.typeProccess == `trash`) {
      result = await ApiDeleteRequest(`school/data/klasdrive/${id}`);
    } else if (payload && payload.typeProccess == `restore`) {
      result = await ApiPostRequest(`school/data/klasdrive/restore/${id}`);
    } else {
      result = await ApiDeleteRequest(
        `school/data/klasdrive/forcedelete/${id}`
      );
    }
    dispatch("handlerLoading", false);
    if (result.error) {
      HandlerNotification("error", result.error);
      return false;
    }
    HandlerNotification();
    if (payload && payload.typeProccess == `trash`) {
      dispatch("getDataFileStorage");
    } else if (payload && payload.typeProccess == `restore`) {
      dispatch("getDataFileStorageBin");
    }
    commit("changeFileStorage", {
      showModalDetail: false
    });
    return true;
  },
  downloadFile({ state, dispatch, commit }, payload) {
    if (state.data.loadingDownload) return false;
    dispatch("handlerLoading", false);
    commit("changeFileStorage", {
      loadingDownload: true
    });
    if (!payload) {
      HandlerNotification("info", "Dokumen lokasi tidak ditemukan.");
      return false;
    }
    payload.path = `https://cdn.pixabay.com/photo/2023/05/11/03/34/white-cockatoo-7985434_1280.jpg`;
    fetch(payload.path)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${payload.klasdrive_name ||
          "berkas siswa"} - ${(payload.klasdrive_user &&
          payload.klasdrive_user.name) ||
          "nama siswa"}`;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        commit("changeFileStorage", {
          loadingDownload: false
        });
      })
      .catch(error => {
        commit("changeFileStorage", {
          loadingDownload: false
        });
        HandlerNotification(
          "info",
          "Gambar Tidak bisa di download Otomatis. Lakukan menggunakan Simpan Gambar/Save Image dengan cara lihat gambar lalu klik kanan."
        );
        console.error(error); // Log the specific error message for debugging
      });
  },
  updateNameDokumen({ state, commit }, payload) {
    // payload -> nilai row
    if (!payload.id) {
      HandlerNotification("info", "Id Dokumen Tidak ditemukan");
      return false;
    }
    if (!payload.klasdrive_name) {
      HandlerNotification("info", "Nama Dokumen Tidak ditemukan");
      return false;
    }

    const foundItem = state.data.jenisDokumen.find(
      item => item.name === payload.klasdrive_name
    );
    commit("changeFileStorage", {
      showModalUpdateName: true,
      formData: {
        id: payload.id,
        nameSelect: foundItem ? foundItem.name : `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category
      },
      formDataDetail: {
        id: payload.id,
        nameSelect: foundItem ? foundItem.name : `LAINNYA`,
        name: payload.klasdrive_name,
        category: payload.category
      }
    });
  },
  infoPageDetailDokumen({ commit }, payload) {
    commit("changeFileStorage", {
      showModalDetail: true,
      formDataDetail: payload
    });
  },
  changePage({ commit, dispatch }, payload) {
    commit("changeFileStorage", { page: payload ?? 1 });
    dispatch("getDataFileStorage");
    return true;
  },
  changePageBin({ commit, dispatch }, payload) {
    commit("changeFileStorageBin", { page: payload ?? 1 });
    dispatch("getDataFileStorageBin");
    return true;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
