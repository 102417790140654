import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

const dataState = createPersistedState({
	key: "storeonklas",
	paths: [
		"userPhotosCctv",
		"user",
		"userToken",
		"tokenKoperasi",
		"tokenDanaPartisipasi",
		"isDefaultPass",
		"introState",
		"tutorial",
		"menuAddOns",
		"cabdinToken",
		"dataCabdin",
	],
});

import moment from "moment";
import {_} from "vue-underscore";
import {ApiGetRequest, ApiDeleteRequest} from "../utils/Api";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

import Intro from "./Intro";
import Roles from "./Roles";
import Provinces from "./Provinces";
import Cities from "./Cities";
import Districts from "./Districts";
import Pengumuman from "./Pengumuman";
import Sekolah from "./Sekolah";

// ==================Koperasi=====================
import KoperasiKlaspay from "./Koperasi/Klaspay";
import KoperasiKas from "./Koperasi/Kas";
import KoperasiDaftarKantin from "./Koperasi/Kantin/DaftarKantin";
import KoperasiManagementKantin from "./Koperasi/Kantin/ManagementKantin";
import KoperasiDanaKantin from "./Koperasi/Kantin/DanaKantin";
import KoperasiPenjualanKantin from "./Koperasi/Kantin/Penjualan";
// ==================Koperasi=====================

// ==================Pembelajaran=====================
import Jurusan from "./Pembelajaran/Jurusan";
import Materi from "./Pembelajaran/Materi";
import GuruStaff from "./Pengguna/GuruStaff";
import Pelajar from "./Pengguna/Pelajar";
import PenggunaSearchStudent from "./Pengguna/SearchStudent";
import PenggunaMoveClass from "./Pengguna/Pelajar/StudentMoveClass";
import FileStorage from "./Pengguna/FileStorage";
import KartuPelajar from "./Pengguna/KartuPelajar";
import RuangKelas from "./Pembelajaran/RuangKelas";
import Ujian from "./Pembelajaran/Ujian";
import JadwalUjian from "./Pembelajaran/JadwalUjian";
import Tugas from "./Pembelajaran/Tugas";
import Mapel from "./Pembelajaran/Mapel";
import JadwalPelajaran from "./Pembelajaran/Jadwal";
import Pembelajaran from "./Pembelajaran";
import Classrooms from "./Classrooms";
import Majors from "./Major";
// ==================Pembelajaran=====================

// ==================Pembelajaran Magang=====================
import Magang from "./Pembelajaran/Magang";
import MagangSettingGroup from "./Pembelajaran/Magang/SettingGroup";
import MagangReport from "./Pembelajaran/Magang/ReportMagang";
import MagangJadwal from "./Pembelajaran/Magang/JadwalMagang";
// ==================Pembelajaran Magang=====================

// ==================Pembelajaran Ujian=====================
import UjianSoal from "./Pembelajaran/Ujian/Soal";
import PenilaianSoal from "./Pembelajaran/Ujian/Penilaian";
import CekJawabanSoal from "./Pembelajaran/Ujian/CekJawaban";
// ==================Pembelajaran Ujian=====================

// ==================Klaspustaka=====================
import Klaspustaka from "./Klaspustaka";
import KlaspustakaPerpustakaanBuku from "./Klaspustaka/Perpustakaan/Buku";
import KlaspustakaPerpustakaanKategori from "./Klaspustaka/Perpustakaan/Kategori";
import KlaspustakaPerpustakaanStokArsip from "./Klaspustaka/Perpustakaan/StokArsip";
import KlaspustakaPerpustakaanArsip from "./Klaspustaka/Perpustakaan/Arsip";
import KlaspustakaPerpustakaanPeminjaman from "./Klaspustaka/Perpustakaan/Peminjaman";
import KlaspustakaWebKatalog from "./Klaspustaka/WebKatalog";
// ==================Klaspustaka=====================

// ==================Klastime=====================
import Klastime from "./Klastime";
import MesinAbsensi from "./Klastime/MesinAbsensi";
import AbsensiCctv from "./Klastime/MesinAbsensiCctv";
import PenggunaAbsensiCctv from "./Klastime/PenggunaAbsensiCctv";
import PengaturanJam from "./Klastime/PengaturanJam";
import KalenderSekolah from "./Klastime/KalenderSekolah";
import PresensiGuru from "./Klastime/PresensiGuru";
import RekapPresensiGuru from "./Klastime/PresensiGuru/RekapPresensi";
import RekapPresensiGuruBulanan from "./Klastime/PresensiGuru/RekapPresensi/Bulanan";
import RekapPresensiGuruTahunan from "./Klastime/PresensiGuru/RekapPresensi/Tahunan";
import PresensiKelas from "./Klastime/PresensiKelas";
// import RekapPresensiGuru from "./Klastime/PresensiGuru/RekapPresensi";
import PresensiPelajar from "./Klastime/PresensiPelajar";
import RekapPresensiPelajar from "./Klastime/PresensiPelajar/RekapPresensi";
import RekapPresensiPelajarBulanan from "./Klastime/PresensiPelajar/RekapPresensi/Bulanan";
import RekapPresensiPelajarTahunan from "./Klastime/PresensiPelajar/RekapPresensi/Tahunan";
// ==================Klastime=====================

// ==================SosialMedia=====================
import SosialMedia from "./Onklas";
import SosialMediaPostingan from "./Onklas/Postingan";
import SosialMediaPostinganEbook from "./Onklas/Ebook";
import SosialMediaHashtag from "./Onklas/Hastag";
import SosialMediaPeringkat from "./Onklas/PeringkatPost";
// ==================SosialMedia=====================

// ==================Klasite=====================
import Klasite from "./Klasite";
// ==================Klasite=====================

// ==================Keuangan=====================
import Keuangan from "./Keuangan";
// ==================Keuangan=====================

// ==================Dana Sekolah=====================
import PembayaranSpp from "./PembayaranSpp";
import PembayaranSppTunggakan from "./PembayaranSpp/Tunggakan";
import PembayaranSppDibayar from "./PembayaranSpp/Dibayar";
// ==================Dana Sekolah=====================

import Klasbisnis from "./Klasbisnis/Bisnis";
import KlasbisnisEtalase from "./Klasbisnis/Etalase";
import KlasbisnisKlasmart from "./Klasbisnis/Klasmart";
import KlasbisnisPengecualianKerjaSama from "./Klasbisnis/PengecualianKerjaSama";

// ==================Klasmonitoring=====================
import Klasmonitoring from "./Klasmonitoring";
// ==================Klasmonitoring=====================

// ==================Beranda=====================
import Beranda from "./Beranda";
import BerandaProduk from "./Beranda/Produk";
import BerandaPembelian from "./Beranda/Pembelian";
import BerandaTagihan from "./Beranda/Tagihan";
import TagihanTryout from "./Beranda/Tagihan/tagihanTryOut";
import BerandaTryout from "./Beranda/Tryout";
import JawabanTryOut from "./Beranda/Tryout/jawabanTryOut";

import DanaPartisipasi from "./DanaPartisipasi";
import DanaPartisipasiByPelajar from "./DanaPartisipasi/ByPelajar";

import VerificationEmail from "./VerificationEmail";
import Payment from "./Payment";
// ==================Beranda=====================

// ==================Profile Sekolah=====================
import PengaturanHakAkses from "./PengaturanHakAkses";
import Profile from "./Profile";
// ==================Profile Sekolah=====================

// ==================KlasQuiz=====================
import TryOutAkm from "./TryOut/Akm";
import TryOutMapel from "./TryOut/Mapel";
import TryOutPreview from "./TryOut/Preview";
import TryOutAssesment from "./TryOut/Assesment";
// ==================KlasQuiz=====================

// ==================Klassign=====================
import KlassignToken from "./Klassign/Token";
import KlassignIzin from "./Klassign/Izin";
import KlassignTamu from "./Klassign/Tamu";
import Klassign from "./Klassign";
// ==================Klassign=====================

// ==================Integrasi=====================
import Integrasi from "./Integrasi";
import IntegrasiInovasi from "./Integrasi/Inovasi";
import IntegrasiLiterasi from "./Integrasi/Literasi";
import IntegrasiPenghuniSekolah from "./Integrasi/PenghuniSekolah";
import IntegrasiSuratTugas from "./Integrasi/SuratTugas";
import IntegrasiPengembanganSDM from "./Integrasi/PengembanganSDM";
import IntegrasiJurnalKBM from "./Integrasi/JurnalKBM";
import IntegrasiAlumniPTN from "./Integrasi/Alumni/PTN";
import IntegrasiAlumniBekerja from "./Integrasi/Alumni/Bekerja";
import IntegrasiPrestasiSiswa from "./Integrasi/Prestasi/Siswa";
import IntegrasiPrestasiGuru from "./Integrasi/Prestasi/Guru";
import IntegrasiBukuTamu from "./Integrasi/BukuTamu";
import IntegrasiBimbinganKonseling from "./Integrasi/BimbinganKonseling";

import KlasKonseling from "./KlasKonseling";
import KlasKonselingKamusPoin from "./KlasKonseling/KamusPoin";
import KlasKonselingPenanganan from "./KlasKonseling/Penanganan";
import KlasKonselingPengaturan from "./KlasKonseling/Pengaturan";
import KlasKonselingPoinWarna from "./KlasKonseling/PoinWarna";
import KlasKonselingRapor from "./KlasKonseling/Rapor";
import KlasKonselingAssesment from "./KlasKonseling/Asesmen";
import KlasKonselingTutupBuku from "./KlasKonseling/TutupBuku";
import KlasKonselingAutoGenerate from "./KlasKonseling/AutoGenerate";

import Survey from "./Survey";
import SurveyKuesioner from "./Survey/Kuesioner";
import SurveySchedules from "./Survey/Schedules";

// ==================Integrasi=====================

export default new Vuex.Store({
	modules: {
		login: Login,
		forgotPwd: ForgotPassword,
		profile: Profile,
		jurusan: Jurusan,
		materi: Materi,
		gurustaff: GuruStaff,
		pelajar: Pelajar,

		penggunaSearchStudent: PenggunaSearchStudent,

		studentMoveClass: PenggunaMoveClass,
		fileStorage: FileStorage,
		kartuPelajar: KartuPelajar,
		ruangkelas: RuangKelas,
		intro: Intro,
		roles: Roles,
		provinces: Provinces,
		cities: Cities,
		districts: Districts,
		majors: Majors,
		classrooms: Classrooms,
		pengumuman: Pengumuman,
		verificationEmail: VerificationEmail,
		sekolah: Sekolah,
		keuangan: Keuangan,

		// ==================Koperasi=====================
		klaspay: KoperasiKlaspay,
		kasKoperasi: KoperasiKas,
		koperasiDaftarKantin: KoperasiDaftarKantin,
		koperasiManagementKantin: KoperasiManagementKantin,
		koperasiDanaKantin: KoperasiDanaKantin,
		koperasiPenjualanKantin: KoperasiPenjualanKantin,
		// ==================Koperasi=====================

		klasbisnis: Klasbisnis,
		klasbisnisEtalase: KlasbisnisEtalase,
		klasbisnisKlasmart: KlasbisnisKlasmart,
		klasbisnisPengecualianKerjaSama: KlasbisnisPengecualianKerjaSama,
		klasmonitoring: Klasmonitoring,
		mapel: Mapel,
		jadwalPelajaran: JadwalPelajaran,
		pembelajaran: Pembelajaran,
		sosialmedia: SosialMedia,
		sosialmediaPostingan: SosialMediaPostingan,
		sosialmediaPostinganEbook: SosialMediaPostinganEbook,
		sosialmediaHashtag: SosialMediaHashtag,
		sosialMediaPeringkat: SosialMediaPeringkat,
		klasite: Klasite,
		pembayaranSpp: PembayaranSpp,
		pembayaranSppTunggakan: PembayaranSppTunggakan,
		pembayaranSppDibayar: PembayaranSppDibayar,
		ujian: Ujian,
		jadwalUjian: JadwalUjian,
		tugas: Tugas,

		danaPartisipasi: DanaPartisipasi,
		danaPartisipasiByPelajar: DanaPartisipasiByPelajar,

		klaspustaka: Klaspustaka,
		klaspustakaPerpustakaanBuku: KlaspustakaPerpustakaanBuku,
		klaspustakaPerpustakaanKategori: KlaspustakaPerpustakaanKategori,
		klaspustakaPerpustakaanStokArsip: KlaspustakaPerpustakaanStokArsip,
		klaspustakaPerpustakaanArsip: KlaspustakaPerpustakaanArsip,
		klaspustakaPerpustakaanPeminjaman: KlaspustakaPerpustakaanPeminjaman,
		klaspustakaWebKatalog: KlaspustakaWebKatalog,
		beranda: Beranda,
		berandaProduk: BerandaProduk,
		payment: Payment,
		berandaPembelian: BerandaPembelian,
		berandaTagihan: BerandaTagihan,
		tagihanTryout: TagihanTryout,
		berandaTryout: BerandaTryout,
		jawabanTryOut: JawabanTryOut,
		ujianSoal: UjianSoal,
		PenilaianSoal: PenilaianSoal,
		cekJawabanSoal: CekJawabanSoal,
		klastime: Klastime,
		mesinAbsensi: MesinAbsensi,
		absensiCctv: AbsensiCctv,
		penggunaAbsensiCctv: PenggunaAbsensiCctv,
		pengaturanJam: PengaturanJam,
		kalenderSekolah: KalenderSekolah,
		presensiGuru: PresensiGuru,
		rekapPresensiGuru: RekapPresensiGuru,
		rekapPresensiGuruBulanan: RekapPresensiGuruBulanan,
		rekapPresensiGuruTahunan: RekapPresensiGuruTahunan,
		presensiKelas: PresensiKelas,
		presensiPelajar: PresensiPelajar,
		rekapPresensiPelajar: RekapPresensiPelajar,
		rekapPresensiPelajarBulanan: RekapPresensiPelajarBulanan,
		rekapPresensiPelajarTahunan: RekapPresensiPelajarTahunan,
		pengaturanHakAkses: PengaturanHakAkses,
		tryOutAkm: TryOutAkm,
		tryOutAssesment: TryOutAssesment,
		tryOutMapel: TryOutMapel,
		tryOutPreview: TryOutPreview,
		magang: Magang,
		magangSettingGroup: MagangSettingGroup,
		magangReport: MagangReport,
		magangJadwal: MagangJadwal,

		klassignToken: KlassignToken,
		klassignTamu: KlassignTamu,
		klassignIzin: KlassignIzin,
		klassign: Klassign,

		// Integrasi
		integrasi: Integrasi,
		integrasiInovasi: IntegrasiInovasi,
		integrasiLiterasi: IntegrasiLiterasi,
		integrasiPenghuniSekolah: IntegrasiPenghuniSekolah,
		integrasiSuratTugas: IntegrasiSuratTugas,
		integrasiPengembanganSDM: IntegrasiPengembanganSDM,
		integrasiJurnalKBM: IntegrasiJurnalKBM,
		integrasiAlumniPTN: IntegrasiAlumniPTN,
		integrasiAlumniBekerja: IntegrasiAlumniBekerja,
		integrasiPrestasiSiswa: IntegrasiPrestasiSiswa,
		integrasiPrestasiGuru: IntegrasiPrestasiGuru,
		integrasiBukuTamu: IntegrasiBukuTamu,
		integrasiBimbinganKonseling: IntegrasiBimbinganKonseling,

		klaskonseling: KlasKonseling,
		klaskonselingKamusPoin: KlasKonselingKamusPoin,
		klaskonselingPenanganan: KlasKonselingPenanganan,
		klaskonselingPengaturan: KlasKonselingPengaturan,
		klasKonselingPoinWarna: KlasKonselingPoinWarna,
		klasKonselingRapor: KlasKonselingRapor,
		klasKonselingAssesment: KlasKonselingAssesment,
		klasKonselingTutupBuku: KlasKonselingTutupBuku,
		klasKonselingAutoGenerate: KlasKonselingAutoGenerate,

		survey: Survey,
		surveyKuesioner: SurveyKuesioner,
		surveySchedules: SurveySchedules,
	},
	state: {
		userPhotosCctv: {},
		user: {},
		widthlayout: "",
		userToken: "",
		isLoadingTokenKoperasi: false,
		tokenKoperasi: {
			token: "",
			timeout: "",
		},
		isLoadingTokenDanaPartisipasi: false,
		tokenDanaPartisipasi: {
			token: "",
			timeout: "",
		},
		menuAddOns: {
			menu: [],
			last_check: false,
		},
		isDefaultPass: "",
		showsidebar: true,
		introState: {
			state1: [],
			state2: [],
			state1Done: false,
		},
		tutorial: {
			step_1: false,
			step_2: false,
			step_3: false,
			step_4: false,
			step_5: false,
			step_6: false,
		},
	},
	mutations: {
		updateState(state, payload) {
			Object.keys(payload).map((item) => {
				state[item] = payload[item];
			});
		},
		logoutState(state) {
			state.user = {};
			state.userToken = "";
			state.tokenKoperasi = {
				token: "",
				timeout: "",
			};
			state.tokenDanaPartisipasi = {
				token: "",
				timeout: "",
			};
			state.introState = {
				state1: [],
				state2: [],
				state1Done: false,
			};
			state.tutorial = {
				step_1: false,
				step_2: false,
				step_3: false,
				step_4: false,
				step_5: false,
				step_6: false,
			};
			state.menuAddOns = {
				menu: [],
				last_check: "",
			};
		},
	},
	actions: {
		async checkMenuAddOns({commit, state}) {
			// fungsi untuk cek menu add ons koperasi, dana partisipasi, dana sekolah, kewirausahaan
			// dicek setiap 60 menit atau setiap ada di menu beranda

			const {menuAddOns} = state;
			let timeRequest = 60;
			let checkAddOns = false;

			if (!menuAddOns.last_check || menuAddOns.last_check === "") {
				checkAddOns = true;
			} else {
				let timeNow = moment(new Date());
				let timeout = menuAddOns.last_check;
				if (timeNow.diff(timeout, "minutes") >= timeRequest) {
					checkAddOns = true;
				}
			}
			if (checkAddOns) {
				const result = await ApiGetRequest(`school/addons`);
				if (!result.error) {
					let dataMenuAddOns = _.where(result.data.data, {
						is_show: true,
					});

					commit("updateState", {
						menuAddOns: {
							menu: dataMenuAddOns,
							last_check: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
				} else {
					commit("updateState", {
						menuAddOns: {
							menu: [],
							last_check: "",
						},
					});
				}
			}
		},

		async checkTokenKoperasi({commit, state}) {
			let endPoint = `school/cooperative/klaspay-token`;
			const {tokenKoperasi} = state;
			let {token, timeout} = tokenKoperasi;
			let timeNow = moment(new Date());
			let available = true;

			timeout = moment(timeout);
			if (!token || token === "" || !tokenKoperasi || tokenKoperasi === "") {
				available = false;
			} else {
				// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
				if (timeNow.diff(timeout, "minutes") > 10) {
					available = false;
				}
			}

			if (!available) {
				commit("updateState", {
					isLoadingTokenKoperasi: true,
				});
				const result = await ApiGetRequest(endPoint);
				commit("updateState", {
					isLoadingTokenKoperasi: false,
				});

				if (!result.error) {
					commit("updateState", {
						tokenKoperasi: {
							token: result.data.data.token,
							timeout: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
				} else {
					commit("updateState", {
						tokenKoperasi: {
							token: "",
							timeout: "",
						},
					});
				}
			}

			return true;
		},

		async checkTokenDanaPartisipasi({commit, state}) {
			let endPoint = `school/klaspay-token`;
			const {tokenDanaPartisipasi} = state;
			let {token, timeout} = tokenDanaPartisipasi;
			let timeNow = moment(new Date());
			let available = true;

			timeout = moment(timeout);
			if (
				!token ||
				token === "" ||
				!tokenDanaPartisipasi ||
				tokenDanaPartisipasi === ""
			) {
				available = false;
			} else {
				// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
				if (timeNow.diff(timeout, "minutes") > 10) {
					available = false;
				}
			}

			if (!available) {
				await commit("updateState", {
					isLoadingTokenDanaPartisipasi: true,
				});
				const result = await ApiGetRequest(endPoint);
				await commit("updateState", {
					isLoadingTokenDanaPartisipasi: false,
				});

				if (!result.error) {
					commit("updateState", {
						tokenDanaPartisipasi: {
							token: result.data.data.token,
							timeout: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
				} else {
					commit("updateState", {
						tokenDanaPartisipasi: {
							token: "",
							timeout: "",
						},
					});
				}
			}

			return true;
		},

		async checkIntroState({commit, state}) {
			let endPoint = `school/cms-init`;
			const {introState} = state;

			let flag = 0;
			if (introState.state1.length > 0) {
				flag++;
			} else {
				if (introState.state1Done) {
					flag++;
				}
			}

			let valIntro = _.values(introState.state2);
			valIntro = _.without(valIntro, false);

			flag += valIntro.length;

			if (flag < 7) {
				// STATE 1
				const resultState1 = await ApiGetRequest(endPoint, {
					state: 1,
				});
				if (!resultState1.error) {
					introState.state1 = resultState1.data.data[0].SuperAdmin;
				} else {
					introState.state1 = [];
				}

				// STATE 2
				const resultState2 = await ApiGetRequest(endPoint, {
					state: 2,
				});
				if (!resultState2.error) {
					introState.state2 = resultState2.data.data[0];
				} else {
					// auto logout supaya many request
					commit("logoutState");

					introState.state2 = [];
				}

				// SKIPPED STATE 1
				let statusState1 = false;
				if (introState.state1Done) {
					statusState1 = true;
				}

				let valIntroState2 = _.values(introState.state2);
				valIntroState2 = _.without(valIntroState2, false);
				if (valIntroState2.length > 0) {
					statusState1 = true;
				}

				commit("updateState", {
					introState: {
						state1: introState.state1,
						state2: introState.state2,
						state1Done: statusState1,
					},
				});
			}

			return true;
		},

		async logoutSystem({commit}) {
			await ApiDeleteRequest(`logout`);
			commit("logoutState");
		},

		// async checkBaseProduct() {
		//   let endPoint = `klaspay/external/onklasproduct/check`;
		//   const result = await ApiGetRequest(endPoint);
		//   if (result.data.data.length == 0) {
		//     const Formdata = {
		//       sp_create_school_product_initial: 54,
		//     };

		//     await ApiGetRequest(`klaspay/external/generatebaseproduct`, Formdata);
		//   }
		// },
	},
	getters: {
		schoolAvatar: (state) => {
			try {
				return state.user.school.image;
			} catch (e) {
				return false;
			}
		},
		schoolName: (state) => {
			try {
				return state.user.school.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolId: (state) => {
			try {
				return state.user.school.row_id;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolAddress: (state) => {
			try {
				return state.user.school.address;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolCity: (state) => {
			try {
				return state.user.school.city_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolType: (state) => {
			return state.user?.school?.type || null;
		},
		isShowMajor: (state) => {
			const type = state.user?.school?.type || null;
			return ["SMA", "SMK", "SLB"].includes(type);
		},
		schoolProvince: (state) => {
			try {
				return state.user.school.province_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolSubDistrict: (state) => {
			try {
				return state.user.school.sub_district_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolTotalStudent: (state) => {
			try {
				return state.user.school.total_student;
			} catch (e) {
				return 0;
			}
		},
		headMasterName: (state) => {
			try {
				return state.user.school.headmaster.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		headMasterAddress: (state) => {
			try {
				return state.user.school.headmaster.address;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		headMasterAvatar: (state) => {
			try {
				return state.user.school.headmaster.user_avatar_image;
			} catch (e) {
				return null;
			}
		},
		headMasterPhone: (state) => {
			try {
				return state.user.school.headmaster.phone;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		rootProductsSchool: (state) => {
			try {
				return state.user.school.products;
			} catch (e) {
				return [];
			}
		},
		schoolCoordinateIsCompleteLatLng: (state) => {
			try {
				return !!(
					state.user.school.coordinate.longitude &&
					state.user.school.coordinate.latitude
				);
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userPhoneIsVerified: (state) => {
			try {
				return state.user.is_phone_verified;
			} catch (e) {
				return false;
			}
		},
		userUuid: (state) => {
			try {
				return state.user.id;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userName: (state) => {
			try {
				return state.user.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userPhone: (state) => {
			try {
				return state.user.phone;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userRoleToArray: (state) => {
			try {
				return state.user.role.map((item) => item.name);
			} catch (e) {
				return [];
			}
		},
		haveTokenCooperative: (state) => {
			try {
				const {tokenKoperasi} = state;
				let {token, timeout} = tokenKoperasi;
				let timeNow = moment(new Date());

				timeout = moment(timeout);
				if (!token || token === "" || !tokenKoperasi || tokenKoperasi === "") {
					return false;
				} else {
					// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
					if (timeNow.diff(timeout, "minutes") > 10) {
						return false;
					}
				}
				return true;
			} catch (e) {
				return false;
			}
		},
	},
	plugins: [dataState],
});
