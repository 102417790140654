import {
  ApiGetRequest,
  ApiPostMultipart,
  ApiPutRequest,
  ApiPostRequest,
  ApiDeleteRequest,
} from "@/utils/Api";
import { Message } from "element-ui";

const state = {
  data: {
    domainSekolah: "https://www.klaskita.com/",
    config: {},
    contentType: [],
    contentActive: {
      KontenWebsiteBeranda: [
        "banner",
        "excellence",
        "foreword",
        "organization",
        "about",
        "testimonial",
      ],
      KontenWebsiteProfil: [
        "vision",
        "mission",
        "purpose",
        "greeting",
        "history",
        "footer_desc",
      ],
      KontenWebsiteBerita: ["news"],
      KontenWebsitePengumuman: ["information"],
      KontenWebsiteGaleri: ["gallery"],
      KontenWebsiteJurusan: ["majors"],
      KontenWebsiteEksKul: ["extracurricular"],
      KontenWebsiteKontak: [
        "email",
        "phone",
        "social_facebook",
        "social_instagram",
        "social_twitter",
        "social_linkedin",
        "social_youtube",
        "address",
        "logo",
        "maps",
      ],
    },
    editData: {},
    limit: 0,
    page: 1,
    items: [],
    sortBy: "",
    sortType: "",
    total: 0,
    filter: "",
    tipe_postingan: "",
    postingan_items: [],
    columns_info_postingan: [
      {
        prop: "file",
        label: "Foto",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "body",
        label: "Caption",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "created_at",
        label: "Tgl Posting",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "type",
        label: "Tipe Post",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "likes",
        label: "Suka",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "comments_count",
        label: "Komentar",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_kategori: [
      {
        prop: "name",
        label: "Kategori",
        width: "450",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_postingan: [
      {
        prop: "file",
        label: "File",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "body",
        label: "Caption",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "name",
        label: "Nama Lengkap",
        minWidth: "120",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "role",
        label: "Profesi",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "tgl_posting",
        label: "Tgl Posting",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "type",
        label: "Tipe Post",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "published",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "50",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_ekskul: [
      {
        prop: "title",
        label: "Nama Eks Kulikuler",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "author",
        label: "Penulis",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "Update Terakhir",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "70",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_jurusan: [
      {
        prop: "title",
        label: "Nama Jurusan",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "body",
        label: "Deskripsi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "author",
        label: "Penulis",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "Update Terakhir",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_galeri: [
      {
        prop: "image",
        label: "Gambar",
        minWidth: "80",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "title",
        label: "Judul",
        minWidth: "150",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "70",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_berita: [
      {
        prop: "title",
        label: "Judul Berita",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "author",
        label: "Penulis",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "category",
        label: "Kategori",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "Update Terakhir",
        minWidth: "110",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "100",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_pengumuman: [
      {
        prop: "title",
        label: "Judul Pengumuman",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "author",
        label: "Penulis",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "updated_at",
        label: "Update Terakhir",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "80",
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "aksi",
        label: "Aksi",
        minWidth: "70",
        "class-name": "body-14 col-black-2",
      },
    ],
    typeTable: ["news", "information", "gallery", "majors", "extracurricular"],
    tabActive: "Beranda",
    tabItems: [
      {
        name: "Beranda",
        active: false,
      },
      {
        name: "Profil",
        active: false,
      },
      {
        name: "Berita",
        active: false,
      },
      {
        name: "Pengumuman",
        active: false,
      },
      {
        name: "Galeri",
        active: false,
      },
      {
        name: "Jurusan",
        active: false,
      },
      {
        name: "Eks Kulikuler",
        active: false,
      },
      {
        name: "Kontak",
        active: false,
      },
      {
        name: "Postingan",
        active: false,
      },
    ],
    iconList: {
      template1: [
        {
          id: 1,
          title: "e-learning",
          icon: "e-learning_icon_1.png",
        },
        {
          id: 2,
          title: "medali",
          icon: "medali_icon_1.png",
        },
        {
          id: 3,
          title: "mengajar",
          icon: "mengajar_icon_1.png",
        },
        {
          id: 4,
          title: "olahraga",
          icon: "olahraga_icon_1.png",
        },
        {
          id: 5,
          title: "papantulis",
          icon: "papantulis_icon_1.png",
        },
        {
          id: 6,
          title: "pelajar",
          icon: "pelajar_icon_1.png",
        },
        {
          id: 7,
          title: "perpustakaan",
          icon: "perpustakaan_icon_1.png",
        },
        {
          id: 8,
          title: "piala",
          icon: "piala_icon_1.png",
        },
        {
          id: 9,
          title: "pidato",
          icon: "pidato_icon_1.png",
        },
        {
          id: 10,
          title: "ranking",
          icon: "ranking_icon_1.png",
        },
        {
          id: 11,
          title: "sains",
          icon: "sains_icon_1.png",
        },
        {
          id: 12,
          title: "sekolah",
          icon: "sekolah_icon_1.png",
        },
        {
          id: 13,
          title: "seni",
          icon: "seni_icon_1.png",
        },
        {
          id: 14,
          title: "sertifikasi",
          icon: "sertifikasi_icon_1.png",
        },
        {
          id: 15,
          title: "teknik",
          icon: "teknik_icon_1.png",
        },
        {
          id: 16,
          title: "waktu",
          icon: "waktu_icon_1.png",
        },
      ],
      template2: [
        {
          id: 1,
          title: "e-learning",
          icon: "e-learning_icon_2.png",
        },
        {
          id: 2,
          title: "medali",
          icon: "medali_icon_2.png",
        },
        {
          id: 3,
          title: "mengajar",
          icon: "mengajar_icon_2.png",
        },
        {
          id: 4,
          title: "olahraga",
          icon: "olahraga_icon_2.png",
        },
        {
          id: 5,
          title: "papantulis",
          icon: "papantulis_icon_2.png",
        },
        {
          id: 6,
          title: "pelajar",
          icon: "pelajar_icon_2.png",
        },
        {
          id: 7,
          title: "perpustakaan",
          icon: "perpustakaan_icon_2.png",
        },
        {
          id: 8,
          title: "piala",
          icon: "piala_icon_2.png",
        },
        {
          id: 9,
          title: "pidato",
          icon: "pidato_icon_2.png",
        },
        {
          id: 10,
          title: "ranking",
          icon: "ranking_icon_2.png",
        },
        {
          id: 11,
          title: "sains",
          icon: "sains_icon_2.png",
        },
        {
          id: 12,
          title: "sekolah",
          icon: "sekolah_icon_2.png",
        },
        {
          id: 13,
          title: "seni",
          icon: "seni_icon_2.png",
        },
        {
          id: 14,
          title: "sertifikasi",
          icon: "sertifikasi_icon_1.png",
        },
        {
          id: 15,
          title: "teknik",
          icon: "teknik_icon_2.png",
        },
        {
          id: 16,
          title: "waktu",
          icon: "waktu_icon_2.png",
        },
      ],
      template3: [
        {
          id: 1,
          title: "e-learning",
          icon: "e-learning_icon_3.png",
        },
        {
          id: 2,
          title: "medali3",
          icon: "medali_icon_3.png",
        },
        {
          id: 3,
          title: "mengajar",
          icon: "mengajar_icon_3.png",
        },
        {
          id: 4,
          title: "olahraga",
          icon: "olahraga_icon_3.png",
        },
        {
          id: 5,
          title: "papantulis",
          icon: "papantulis_icon_3.png",
        },
        {
          id: 6,
          title: "pelajar",
          icon: "pelajar_icon_3.png",
        },
        {
          id: 7,
          title: "perpustakaan",
          icon: "perpustakaan_icon_3.png",
        },
        {
          id: 8,
          title: "piala",
          icon: "piala_icon_3.png",
        },
        {
          id: 9,
          title: "pidato",
          icon: "pidato_icon_3.png",
        },
        {
          id: 10,
          title: "ranking",
          icon: "ranking_icon_3.png",
        },
        {
          id: 11,
          title: "sains",
          icon: "sains_icon_3.png",
        },
        {
          id: 12,
          title: "sekolah",
          icon: "sekolah_icon_3.png",
        },
        {
          id: 13,
          title: "seni",
          icon: "seni_icon_3.png",
        },
        {
          id: 14,
          title: "sertifikasi",
          icon: "sertifikasi_icon_3.png",
        },
        {
          id: 15,
          title: "teknik",
          icon: "teknik_icon_3.png",
        },
        {
          id: 16,
          title: "waktu",
          icon: "waktu_icon_3.png",
        },
      ],
    },
    theme: "",
    listThemes: [],
    loading: false,
    errors: false,
  },
};

const mutations = {
  changeKlasite(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getConfigSite({ commit }) {
    commit("changeKlasite", {
      loading: true,
    });

    const result = await ApiGetRequest(`school/web/config`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil konfigurasi website",
      });
    } else {
      let config = result.data.data;
      let domainSekolah = config && config.domain_request;
      let domainFullSekolah = config && config.domain_request;
      let theme = "";

      if (!config.domain_request && config.domain) {
        domainSekolah = config.domain;
      }

      if (domainFullSekolah === null) {
        domainFullSekolah = "https://" + domainSekolah;
      } else {
        if (
          !domainFullSekolah.includes("http://") &&
          !domainFullSekolah.includes("https://")
        ) {
          domainFullSekolah = "https://" + domainSekolah;
        }
      }
      if (config.theme && config.theme.name) {
        theme = config.theme.name;
      }

      commit("changeKlasite", {
        config: {
          ...config,
          domain_request: domainSekolah,
          domain_full: domainFullSekolah,
        },
        domainSekolah,
        domainFullSekolah,
        theme,
      });
    }
  },

  async getTheme({ commit, state }) {
    const { data } = state;
    const result = await ApiGetRequest(`school/web/theme`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data tema",
      });

      return false;
    } else {
      let listThemes = [];
      await result.data.data.forEach((item) => {
        let tempTheme = [];
        if (item.previews.length > 0) {
          tempTheme = item;
          tempTheme.selected = false;

          if (item.name == "template1") {
            tempTheme.url =
              (data.domainSekolah
                ? data.domainSekolah
                : "https://www.klaskita.com/") + "?preview=template1";
          } else if (item.name == "template2") {
            tempTheme.url =
              (data.domainSekolah
                ? data.domainSekolah
                : "https://www.klaskita.com/") + "?preview=template2";
          } else if (item.name == "template3") {
            tempTheme.url =
              (data.domainSekolah
                ? data.domainSekolah
                : "https://www.klaskita.com/") + "?preview=template3";
          } else {
            tempTheme.url = data.domainSekolah
              ? data.domainSekolah
              : "https://www.klaskita.com/";
          }

          if (
            data.config &&
            data.config.theme &&
            data.config.theme.id == item.id
          ) {
            tempTheme.selected = true;
          }

          listThemes.push(tempTheme);
        }
      });

      commit("changeKlasite", {
        listThemes,
      });
    }
  },

  async changeTheme({ state }) {
    const { data } = state;
    const formData = {
      domain: data.config.domain_request,
      pic_email: data.config.domain_request_pic_email
        ? data.config.domain_request_pic_email
        : "dummy@onklas.id",
      theme: data.config && data.config.theme && data.config.theme.id,
    };

    const result = await ApiPostRequest(`school/web/config`, formData);
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengubah data tema",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Domain & Tema berhasil diubah",
      });

      return true;
    }
  },

  async getContentType({ state, commit, dispatch }, routeName = "") {
    const { data } = state;
    const result = await ApiGetRequest(`school/web/content-type`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil tipe konten",
      });

      return [];
    } else {
      await result.data.data.map(async (item) => {
        if (data.contentActive[routeName].includes(item.name)) {
          await dispatch("getContentByType", item);
        }
      });

      commit("changeKlasite", {
        contentType: result.data.data,
        loading: false,
      });
    }
  },

  async getContentByType({ commit, state }, type = null, payload) {
    const { data } = state;
    // let contentUrl = `school/web/content?content_type=${(type && type.id) ||
    //   ""}`;
    // if (type && type.name && data.typeTable.includes(type.name)) {
    //   contentUrl += `&page=${data.page}&limit=${data.limit}&q=${data.filter}`;
    // }

    // let params = {};
    // if (data.sortBy !== "" && data.sortType !== "") {
    //   params = {
    //     sort_field: data.sortBy,
    //     sort_dir: data.sortType,
    //   };
    // }

    // const result = await ApiGetRequest(contentUrl, params);

    if (payload) {
      commit("changeKlasite", {
        page: payload || data.page,
      });
    }

    const params = {
      limit: data.limit,
      page: data.page,
      q: data.filter,
    };

    const result = await ApiGetRequest(
      `school/web/content?content_type=${(type && type.id) || ""}`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : ("Gagal mengambil tipe konten by type " + type && type.name) || "",
      });

      return [];
    } else {
      if (type && type.name && data.typeTable.includes(type.name)) {
        commit("changeKlasite", {
          ["content_" + ((type && type.name) || "")]: result.data.data || [],
          total: result.data.meta.total,
        });
      } else {
        const resultData = result.data.data;

        const resultContent = [];
        await resultData.map((item) => {
          if (item.status === "published") {
            let contentTemp = item;
            contentTemp.imgPreview = item.image;
            if (!contentTemp.additional_data) {
              contentTemp.additional_data = {
                socmed: {},
                profession: "",
              };
            } else {
              if (!contentTemp.additional_data.socmed) {
                contentTemp.additional_data.socmed = {};
              }

              if (!contentTemp.additional_data.profession) {
                contentTemp.additional_data.profession = "";
              }
            }
            resultContent.push(contentTemp);
          }
        });

        // kalau contentnya kosong otomati bikin default 1 data kosong
        if (["Beranda", "Profil"].includes(data.tabActive)) {
          console.log(`---resultContent`, resultContent);

          if (resultContent.length === 0) {
            let contentTemp = {
              id: "",
              type: (type && type.id) || "",
              title: (type && type.name) || "",
              body: "",
              imgPreview: "",
              status: "published",
              additional_data: {
                socmed: "",
                profession: "",
              },
            };

            resultContent.push(contentTemp);
          }
        }

        commit("changeKlasite", {
          ["content_" + ((type && type.name) || "")]: resultContent,
        });
      }
    }
  },

  async submitContent({ commit, state }, type = null) {
    commit("changeKlasite", {
      loading: true,
      error: false,
    });

    const { data } = state;

    if (data["content_" + type]) {
      data["content_" + type].map(async (item, index) => {
        if (item.title && item.title !== "") {
          const formData = new FormData();
          formData.append("_method", "post");
          formData.append("id", "");
          formData.append("status", "published");
          formData.append("title", "");
          formData.append("body", "");
          formData.append("type", "");

          formData.set("id", item.id);
          formData.set("title", item.title);
          formData.set("body", item.body);
          formData.set(
            "type",
            item.type && item.type.id ? item.type.id : item.type
          );

          if (item.additional_data && item.additional_data.profession) {
            formData.append("profession", item.additional_data.profession);
          }

          if (item.additional_data && item.additional_data.socmed) {
            for (const [key, value] of Object.entries(
              item.additional_data.socmed
            )) {
              if (value && value !== "") {
                formData.append(`socmed[${key}]`, value);
              }
            }
          }

          if (item.image && typeof item.image != "string") {
            formData.append("image", item.image);
          }

          let defaultUrl = `school/web/content`;
          if (item.id && item.id > 0) {
            formData.append("_method", "put");
            defaultUrl += `/${item.id}`;
          }

          const resultSubmit = await ApiPostMultipart(defaultUrl, formData);

          if (resultSubmit.error) {
            Message({
              type: "error",
              message: resultSubmit.error
                ? resultSubmit.error
                : "Gagal menyimpan data",
            });

            commit("changeKlasite", {
              errors: resultSubmit.errorList ? resultSubmit.errorList : false,
              loading: false,
            });
          } else {
            const dataresult = resultSubmit.data.data;
            const allDataContent = data["content_" + type];
            allDataContent[index].id = (dataresult && dataresult.id) || "";
            allDataContent[index].image =
              (dataresult && dataresult.image) || allDataContent[index].image;
            allDataContent[index].imgPreview =
              (dataresult && dataresult.imgPreview) ||
              allDataContent[index].imgPreview;

            commit("changeKlasite", {
              [`content_${type}`]: allDataContent,
            });

            Message({
              type: "success",
              message: `Data ${type} berhasil disimpan`,
            });
          }
        }
      });

      commit("changeKlasite", {
        loading: false,
      });
    } else {
      commit("changeKlasite", {
        loading: false,
      });
    }
  },

  async deleteContent({ commit, state }, payload = null) {
    commit("changeKlasite", {
      loading: true,
      error: false,
    });

    const { data } = state;
    let dataContent = data["content_" + payload.type];
    if (dataContent && dataContent[payload.index]) {
      const formData = new FormData();
      formData.append("_method", "put");
      formData.append("id", "");
      formData.append("status", "unpublished");
      formData.append("title", "");
      formData.append("body", "");
      formData.append("type", "");

      formData.set("id", dataContent[payload.index].id);
      formData.set("title", dataContent[payload.index].title);
      formData.set("body", dataContent[payload.index].body);
      formData.set(
        "type",
        dataContent[payload.index].type && dataContent[payload.index].type.id
          ? dataContent[payload.index].type.id
          : dataContent[payload.index].type
      );

      if (
        dataContent[payload.index].image &&
        typeof dataContent[payload.index].image != "string"
      ) {
        formData.append("image", dataContent[payload.index].image);
      }

      let defaultUrl = `school/web/content`;
      if (dataContent[payload.index].id && dataContent[payload.index].id > 0) {
        defaultUrl += `/${dataContent[payload.index].id}`;
      }

      const resultSubmit = await ApiPostMultipart(defaultUrl, formData);

      if (resultSubmit.error) {
        Message({
          type: "error",
          message: resultSubmit.error
            ? resultSubmit.error
            : "Gagal menyimpan data",
        });

        commit("changeKlasite", {
          errors: resultSubmit.errorList ? resultSubmit.errorList : false,
          loading: false,
        });
      } else {
        dataContent.splice(payload.index, 1);
        commit("changeKlasite", {
          [`content_${payload.type}`]: dataContent,
          loading: false,
        });

        Message({
          type: "success",
          message: "Data berhasil dihapus",
        });
      }
    } else {
      commit("changeKlasite", {
        loading: false,
      });
    }
  },

  async getPostingan({ commit, state }, payload = null) {
    commit("changeKlasite", {
      loading: true,
    });

    if (payload) {
      await commit("changeKlasite", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    let params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      type: data.tipe_postingan,
    };

    const result = await ApiGetRequest(`school/web/socmed`, params);
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data postingan",
      });
    } else {
      commit("changeKlasite", {
        loading: false,
        postingan_items: result.data.data,
        total: result.data.meta.total,
      });
    }
  },

  async updatePostingan({ commit }, payload) {
    commit("changeKlasite", {
      loading: true,
      error: false,
    });

    const result = await ApiPutRequest(
      `school/web/socmed/${(payload && payload.id) || ""}`,
      payload.data || {}
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengubah status postingan",
      });

      commit("changeKlasite", {
        loading: false,
        errors: result.errorList || false,
      });
      return false;
    } else {
      commit("changeKlasite", {
        loading: false,
      });

      Message({
        type: "success",
        message: "Status postingan berhasil diubah",
      });

      return true;
    }
  },

  async getWebCategory({ commit }) {
    const result = await ApiGetRequest(`school/web/content-category`);
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data kategori",
      });
    } else {
      commit("changeKlasite", {
        category: result.data.data || [],
      });
    }
  },

  async createWebCategory(context, formData = null) {
    if (formData && formData.name !== "") {
      const result = await ApiPostRequest(
        `school/web/content-category`,
        formData
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error
            ? result.error
            : "Gagal menambahkan kategori baru",
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Kategori baru berhasil diambahkan",
        });

        return true;
      }
    } else {
      Message({
        type: "error",
        message: "Anda harus mengisi nama kategori",
      });
    }
  },

  async updateWebCategory(context, payload = null) {
    if (payload && payload.formData && payload.id) {
      const result = await ApiPutRequest(
        `school/web/content-category/${payload.id}`,
        payload.formData
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error ? result.error : "Gagal mengubah data kategori",
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data kategori berhasil diubah",
        });

        return true;
      }
    } else {
      Message({
        type: "error",
        message: "Data tidak valid",
      });
    }
  },

  async deleteWebCategory(context, id = null) {
    const result = await ApiDeleteRequest(
      `school/web/content-category/${id || ""}`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal menghapus data kategori",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data kategori berhasil dihapus",
      });

      return true;
    }
  },

  async submitSingleContent({ commit }, payload = null) {
    commit("changeKlasite", {
      error: false,
      errors: false,
    });

    const formData = new FormData();

    formData.append("id", "");
    formData.append("title", "");
    formData.append("body", "");
    formData.append("type", "");

    formData.set("title", payload.title);
    formData.set("body", payload.body);

    if (payload.status && payload.status !== "") {
      formData.append("status", payload.status);
    } else {
      formData.append("status", "draft");
    }

    if (payload.category && payload.category !== "") {
      formData.append("category", "");
      formData.set("category", payload.category);
    }

    formData.set(
      "type",
      payload.type && payload.type.id ? payload.type.id : payload.type
    );

    if (payload.image && typeof payload.image != "string") {
      formData.append("image", payload.image);
    }

    let defaultUrl = `school/web/content`;
    if (payload.id && payload.id > 0) {
      formData.append("_method", "put");
      defaultUrl += `/${payload.id}`;
      formData.set("id", payload.id);
    } else {
      formData.append("_method", "post");
    }

    const resultSubmit = await ApiPostMultipart(defaultUrl, formData);

    if (resultSubmit.error) {
      Message({
        type: "error",
        message: resultSubmit.error
          ? resultSubmit.error
          : "Gagal menyimpan data",
      });

      commit("changeKlasite", {
        errors: resultSubmit.errorList ? resultSubmit.errorList : false,
        loading: false,
      });

      return false;
    } else {
      Message({
        type: "success",
        message:
          payload.id && payload.id > 0
            ? "Data berhasil diubah"
            : "Data baru berhasil ditambahkan",
      });

      return true;
    }
  },

  async deleteBerita(context, id = null) {
    const result = await ApiDeleteRequest(`school/web/content/${id || ""}`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal menghapus data",
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data berhasil dihapus",
      });

      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
