import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest
} from "@/utils/Api";

import { Message } from "element-ui";
import { _ } from "vue-underscore";

const state = {
  data: {
    labelMenu: "Jadwalkan Survei",
    detail: {
      columns: [
        {
          prop: "teacher_name",
          label: "Nama Guru",
          minWidth: 200,
          sortable: false,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "data",
          label: "Mapel/Kelas",
          width: 200,
          sortable: false,
          "class-name": "body-14 col-black-2"
        },
        {
          prop: "status",
          label: "Status",
          width: 120,
          sortable: false,
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          prop: "total",
          label: "Total",
          width: 100,
          sortable: false,
          "class-name": "body-14 col-black-2 text-center"
        },
        {
          fixed: "right",
          label: "Aksi",
          width: 100,
          "class-name": "body-14 col-black-2"
        }
      ],
      items: [
        {
          id: 1,
          teacher_name: "zuz3",
          mapel: "Bhs.Indo ()",
          is_download: 0,
          total: 123,
          total_partisipan_done: 0,
          template_name: "zuz3",
          template_creator: "zuz3",
          status: true
        }
      ],
      limit: 10,
      page: 1,
      total: 0,
      loading: false,
      sort_field: null,
      sort_dir: null,
      filter: "",
      errors: [],
      showModalListPartisipan: false,
      showModalAdd: false,
    },
    teachers: [
      {
        id: 1,
        name: "Mohammad Zuz Ubaidillah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 112,
              name: "XI-RPL",
              total_student: 20,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          }
        ]
      },
      {
        id: 2,
        name: "Moh Ali Ridlo, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 3,
        name: "Fadillah Abdullah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 4,
        name: "Luthfi, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 11,
        name: "Mohammad Zuz Ubaidillah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 112,
              name: "XI-RPL",
              total_student: 20,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          }
        ]
      },
      {
        id: 21,
        name: "Moh Ali Ridlo, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 31,
        name: "Fadillah Abdullah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 41,
        name: "Luthfi, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 111,
        name: "Mohammad Zuz Ubaidillah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 112,
              name: "XI-RPL",
              total_student: 20,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          }
        ]
      },
      {
        id: 211,
        name: "Moh Ali Ridlo, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 311,
        name: "Fadillah Abdullah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 411,
        name: "Luthfi, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 1111,
        name: "Mohammad Zuz Ubaidillah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 112,
              name: "XI-RPL",
              total_student: 20,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          }
        ]
      },
      {
        id: 2111,
        name: "Moh Ali Ridlo, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 3111,
        name: "Fadillah Abdullah, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
      {
        id: 4111,
        name: "Luthfi, S.Kom.",
        mapel: [
          {
            id: 1,
            name: "Pemrograman Web",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 2,
            name: "Basis Data",
            class: {
              id: 122,
              name: "XII-RPL",
              total_student: 15,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
          {
            id: 3,
            name: "Pemodelan Perangkat Lunak (PPL)",
            class: {
              id: 102,
              name: "X-RPL",
              total_student: 35,
              major: {
                id: 1,
                name: "Rekayasa Perangkat Lunak"
              }
            }
          },
        ]
      },
    ],
    items: [
      {
        id: 1,
        date: "2023-06-09",
        date_end: "2023-06-10",
        start: "14:00:00",
        end: "13:52:00",
        created_at: "2023-01-01",
        total: 1,
        template_name: "zuz3",
        template_creator: "zuz3",
        creator_name: "zuz3",
        status: true
      }
    ],
    columns: [
      {
        prop: "id",
        label: "ID",
        width: 80,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "date",
        label: "Waktu",
        width: 200,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "count",
        label: "Total",
        width: 100,
        sortable: false,
        "class-name": "text-center body-14 col-black-2"
      },
      {
        prop: "template_name",
        label: "Nama Survei",
        minWidth: 300,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "status",
        label: "Status",
        width: 80,
        sortable: false,
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        prop: "creator",
        label: "Dibuat",
        minWidth: 150,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: 100,
        "class-name": "body-14 col-black-2"
      }
    ],
    limit: 10,
    page: 1,
    total: 0,
    loading: false,
    sort_field: "",
    sort_dir: "",
    filter: "",
    currentClass: null,
    currentStatus: "active",
    currentArchive: false,
    errors: [],
    exams: [],
    showModalAddModel: "new",
    showModalAdd: false,
    formData: {
      id: null,
      name: "",
      teacher_id: [],
      date: [],
      start_time_picker: "",
      end_time_picker: "",
      is_shared: true
    },
    rules: {
      name: [
        {
          required: true,
          message: "tidak boleh kosong",
          trigger: "blur"
        }
      ]
    },
    dataInstruction: {
      instruction: "",
      description: "",
      is_random: ""
    }
  }
};

const mutations = {
  changeSurveySchedules(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeSurveySchedulesDetail(state, payload) {
    state.data.detail = Object.assign({}, state.data.detail, payload);
  },
};

const actions = {
  removePertanyaanInput({ state, commit }, payload) {
    if (
      state.data.detailKuesioners.formDataArray.length > 1 &&
      payload &&
      payload.index >= 0
    ) {
      if (!state.data.detailKuesioners.formDataArray[payload.index]) {
        return false;
      }
      state.data.detailKuesioners.formDataArray.splice(payload.index, 1);
      commit("changeSurveyDetailKuesioner", {
        formDataArray: state.data.detailKuesioners.formDataArray
      });
      return true;
    }
    return false;
  },
  async editLabelMenu({ commit, state }) {
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },
  changePage({ commit, state }) {
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },
  prosesDuplicate({ commit, state }, payload) {
    console.log(payload);
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },
  async getListUjian({ commit }, payload = null) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload) {
      await commit("changeSurveyKuesioner", {
        page: payload || data.page
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter
    };

    const result = await ApiGetRequest(`school/exam/kkm/exam-template`, params);
    // const result = await ApiGetRequest(`school/exam/template`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data list ujian"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return null;
    } else {
      commit("changeSurveyKuesioner", {
        exams: result.data.data,
        total: result.data.meta.total,
        loading: false
      });
    }
  },

  async createUjian({ commit }, payload = null) {
    // const { data } = state;
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template`,
      payload.formData
    );

    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }

      Message({
        type: "error",
        message: message ? message : "Gagal menambahkan data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian baru berhasil ditambahkan"
      });

      window.location.href = `ujian/${result.data.data.id}/soal`;

      commit("changeSurveyKuesioner", {
        error: false
      });

      return true;
    }
  },

  async updateUjian({ commit }, payload = null) {
    const result = await ApiPutRequest(
      `school/exam/kkm/exam-template/${payload.id}`,
      payload.formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengubah data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diubah"
      });

      commit("changeSurveyKuesioner", {
        error: false
      });

      return true;
    }
  },

  async deleteUjian({ commit }, payload = null) {
    // const { data } = state;
    commit("changeSurveyKuesioner", {
      loading: true
    });
    const result = await ApiDeleteRequest(
      `school/exam/kkm/exam-template/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal dihapus"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return true;
    }
  },

  async duplicateUjian({ commit }, payload = null) {
    // const { data } = state;
    commit("changeSurveyKuesioner", {
      loading: true
    });

    let formData = {
      exam_template_id: payload.exam_template_id
    };
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template/duplicate`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal diduplikat"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diduplikat"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return true;
    }
  },

  async listDataInstruction({ commit, state }, payload) {
    const { data } = state;

    commit("changeSurveyKuesioner", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-template/${payload}`
    );

    if (result.error) {
      Message({ type: "error", message: result.error });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return [];
    } else {
      data.dataFormListInstruction.modalDataName = result.data.data.name;
      data.dataFormListInstruction.templateId = result.data.data.id;
      data.dataFormListInstruction.templateScore = result.data.data.score_total;
      data.dataFormListInstruction.subject = result.data.data.subject;
      data.dataFormListInstruction.name = result.data.data.name;
      data.dataFormListInstruction.id = result.data.data.id;

      // if (
      //     result.data.data.instruction &&
      //     result.data.data.instruction.length > 0
      // ) {
      //     // result.data.data.instruction.map((instruction) => {
      //     //   data.dataFormListInstruction.listInstruction = instruction;
      //     //   this.validationScore(instruction.score_total);
      //     // });

      //     data.dataFormListInstruction.listInstruction =
      //         result.data.data.instruction;
      //     // result.data.data.instruction.map((instruction) => {
      //     //   this.validationScore(instruction.score_total);
      //     // });
      // }

      let resultInstruction = result.data.data.instruction;
      let instruction = [];
      for (let i = 0; i < resultInstruction.length; i++) {
        let tempList = {
          id: resultInstruction[i].id,
          exam_template_id: resultInstruction[i].exam_template_id,
          is_check_description: resultInstruction[i].description ? true : false,
          description: resultInstruction[i].description,
          instruction: resultInstruction[i].instruction,
          is_random: resultInstruction[i].is_random,
          question: resultInstruction[i].question,
          score_total: resultInstruction[i].score_total,
          sequence: resultInstruction[i].sequence,
          is_hide: true
        };
        instruction.push(tempList);
      }
      data.dataFormListInstruction.listInstruction = instruction;
    }
  },

  validationScore(score) {
    let self = this;
    if (score > 100) {
      self.$confirm(
        `Mohon maaf terdapat perintah yang memiliki skor lebih dari 100.`,
        {
          cancelButtonText: "Tutup",
          confirmButtonClass: "bg-red-1 col-black-9 border-0",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      );
    }
  },

  async resetSoal({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(
        `school/exam/kkm/exam-template/${payload.id}/reset`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeSurveyKuesioner", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah berhasil direset"
        });

        commit("changeSurveyKuesioner", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async dataRemoveSoal({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(`${payload.urldelete}`);
      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeSurveyKuesioner", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah dihapus"
        });

        commit("changeSurveyKuesioner", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async createInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload) {
      const result = await ApiPostRequest(
        `school/exam/akm/exam-instruction`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data perintah berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },

  async editInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/akm/exam-instruction/${payload.id}`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data perintah berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },

  async deleteInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    const result = await ApiDeleteRequest(
      `school/exam/akm/exam-instruction/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal menghapus data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeSurveyKuesioner", {
        error: []
      });

      return true;
    }
  },
  async activationTemplateExam({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/kkm/exam-template/${payload.id}/activate`
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan data"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data template berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  }
};

const getters = {
  searchKuesionerById: state => id => {
    const dataKuesioner = state.data.items;
    return dataKuesioner.find(item => item.id === parseInt(id));
  },
  searchIndexInstructionById: state => id => {
    const dataInstruction = state.data.detailKuesioners.listInstruction;
    return dataInstruction.findIndex(item => item.id === parseInt(id));
  },
  selectedTeachersDetails: state => {
    return state.data.teachers.filter(teacher => state.data.formData.teacher_id.includes(teacher.id));
  },
  totalSelectedStudents: (state, getters) => {
    /*let total = 0;getters.selectedTeachersDetails.forEach(teacher => {teacher.mapel.forEach(subject => {total += subject.class.total_student;});});
    return total;*/
    return getters.selectedTeachersDetails.reduce((total, teacher) => {
      return total + teacher.mapel.reduce((subtotal, subject) => {
        return subtotal + subject.class.total_student;
      }, 0);
    }, 0);
  },
  selectedTeachersClasses: (state) => {
    //return state.data.teachers.map(teacher => {
    //  return teacher.mapel.map(subject => subject.class);
    //}).flat();
    const uniqueClasses = state.data.teachers.reduce((accumulator, teacher) => {
      teacher.mapel.forEach(subject => {
        const classId = subject.class.id;
        if (!accumulator[classId]) {
          accumulator[classId] = subject.class;
        }
      });
      return accumulator;
    }, {});

    return Object.values(uniqueClasses);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
