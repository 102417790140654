import { ApiPostRequest, ApiPutRequest } from '@/utils/Api';
import { Message } from 'element-ui';

// var validateEmail = (rule, value, callback) => {
//     var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     if (value === '') {
//         callback(new Error('Email tidak boleh kosong'));
//     }
//     if (!re.test(value)) {
//         callback(new Error('Email harus berupa email'));
//     }
// };

const state = {
    data: {
        state: null,
        roles: [{
                value: 'School Admin',
                label: 'Tata Usaha',
            },
            {
                value: 'School Finance',
                label: 'Keuangan',
            },
            {
                value: 'Teacher',
                label: 'Guru',
            },
            {
                value: 'Librarian',
                label: 'Pustakawan',
            },
        ],
        form: {
            id: '',
            name: '',
            nis_nik: '',
            email: '',
            password: 'onklas2020',
            roles: '',
        },
        errors: [],
        rules: {
            //   name: [
            //     {
            //       required: true,
            //       message: 'Nama Lengkap tidak boleh kosong',
            //       trigger: 'blur',
            //     },
            //   ],
            //   nis_nik: [
            //     {
            //       required: true,
            //       message: 'NIK/NUPTK tidak boleh kosong',
            //       trigger: 'blur',
            //     },
            //     // { type: 'number', message: 'NIK/NUPTK harus berupa angka', trigger: 'blur' },
            //   ],
            //   email: [
            //     {
            //       required: true,
            //       message: 'Email tidak boleh kosong',
            //       trigger: 'blur',
            //     },
            //     { type: 'email', message: 'Email harus berupa email', trigger: 'blur' },
            //   ],
            //   roles: [
            //     {
            //       required: true,
            //       message: 'Profesi tidak boleh kosong',
            //       trigger: 'blur',
            //     },
            //   ],
        },
        step: [{
                label: 'Data Guru & Staff',
                description: 'Pelajari cara menambahkan dan pengaturan data guru & staff',
                status: 0,
                link: 'pengguna/guru-staff',
            },
            {
                label: 'Data Jurusan',
                description: 'Pelajari cara menambahkan data jurusan',
                status: 0,
                link: 'pembelajaran/jurusan',
            },
            {
                label: 'Data Pelajar',
                description: 'Pelajari cara menambahkan data pelajar',
                status: 0,
                link: 'pengguna/pelajar',
            },
            {
                label: 'Data Ruang Kelas',
                description: 'Pelajari cara menambahkan data ruang kelas',
                status: 0,
                link: 'pembelajaran/ruang-kelas',
            },
            {
                label: 'Data Mata Pelajaran',
                description: 'Pelajari cara menambahkan data mata pelajaran',
                status: 0,
                link: 'pembelajaran/mata-pelajaran',
            },
            {
                label: 'Data Jadwal Belajar',
                description: 'Pelajari cara menambahkan data jadwal kegiatan belajar mengajar',
                status: 0,
                link: 'pembelajaran/jadwal',
            },
        ],
        stepState: 2,
    },
};

const mutations = {
    changeIntro(state, objUpdate) {
        state.data = Object.assign({}, state.data, objUpdate);
    },
};

const actions = {
    async submitSuperadmin({ commit, state }) {
        const { data } = state;

        let roles = ['SuperAdmin'];
        roles.push(data.form.roles);
        let result = '';

        if (data.form.id != '') {
            result = await ApiPutRequest(`school/user/${data.form.id}`, {
                name: data.form.name,
                nis_nik: data.form.nis_nik,
                email: data.form.email,
                password: data.form.password,
                roles: roles,
            });
        } else {
            result = await ApiPostRequest(`school/user`, {
                name: data.form.name,
                nis_nik: data.form.nis_nik,
                email: data.form.email,
                password: data.form.password,
                roles: roles,
            });
        }

        if (result.error) {
            let message = result.error
            if (result.errorList) {
                let errors = [];
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        message: value[0],
                        path: [(key.toLowerCase() == 'nik') ? 'nis_nik' : key.toLowerCase()],
                    });
                }
                if (errors.length > 0) {
                    if (errors[0].message) {
                        message = errors[0].message
                    }
                }
                commit('changeIntro', {
                    errors: errors,
                });
            }
            Message({
                type: 'error',
                message: message,
            });
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            commit('changeIntro', {
                state: 1,
                errors: [],
                form: {
                    id: result.data.data.id,
                    name: data.form.name,
                    nis_nik: data.form.nis_nik,
                    email: data.form.email,
                    password: data.form.password,
                    roles: data.form.roles,
                },
            });
            return result;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};