const state = {
  data: {
    tabMenu: [
      {
        name: "BerandaProduk",
        label: "Produk",
        lock: false,
        url: "/onklas/produk",
      },
      {
        name: "BerandaPembelian",
        label: "Pembelian",
        lock: false,
        url: "/onklas/pembelian",
      },
      {
        name: "BerandaTagihan",
        label: "Tagihan",
        lock: false,
        url: "/onklas/tagihan",
      },
      // {
      //   name: "BerandaPromo",
      //   label: "Promo",
      //   lock: true,
      //   url: "/onklas/promo",
      // },
      // {
      //   name: "BerandaKotakMasuk",
      //   label: "Kotak Masuk",
      //   lock: true,
      //   url: "/onklas/kotak-masuk",
      // },
      // {
      //   name: "BerandaBantuan",
      //   label: "Bantuan",
      //   lock: true,
      //   url: "/onklas/bantuan",
      // },
    ],
  },
};

const mutations = {
  changeBeranda(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
