import { ApiGetRequest } from '@/utils/Api';
import { Message } from 'element-ui';

const state = {
  data: {
    status: '',
  },
};

const mutations = {
  changeVerificationEmail(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async verifikasiEmailKlaspay({ commit }, payload = null) {
    if (!payload) return false;
    const result = await ApiGetRequest(payload.url);

    if (result.error) {
      Message({
        type: 'error',
        message: result.error,
      });

      commit('changeVerificationEmail', {
        status: 'error',
      });

      return false;
    } else {
      commit('changeVerificationEmail', {
        status: 'sukses',
      });

      return result.data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
