import { ApiGetRequest, ApiPostRequest, ApiDeleteRequest } from "@/utils/Api";
import { Message } from "element-ui";

const state = {
    data: {
        columns: [{
                prop: 'cover_image_url',
                label: 'Gambar',
                minWidth: '50',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'title_author',
                label: 'Judul/Penulis',
                minWidth: '120',
                'class-name': 'body-14 col-black-2',
                sortable: true,
            },
            {
                prop: 'isbn',
                label: 'ISBN',
                minWidth: '70',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'stock_total',
                label: 'Stok',
                minWidth: '80',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'location',
                label: 'Ruang',
                minWidth: '80',
                'class-name': 'body-14 col-black-2',
            },
            {
                prop: 'archive',
                label: 'Arsip',
                minWidth: '80',
                'class-name': 'body-14 col-black-2',
            },
            {
                label: 'Aksi',
                minWidth: '50',
                'class-name': 'body-14 col-black-2',
            },
        ],
        items: [],
        limit: 10,
        page: 1,
        total: 0,
        sortBy: '',
        sortType: '',
        filter: '',
        errors: [],
        loading: false,
        loadingIsbn: false,
        formStok: {
            editMode: false,
            stock: '',
        },
        formRulesStock: {
            stock: [
                { required: true, message: 'Stok tidak boleh kosong', trigger: 'blur' },
            ],
        },
        formStokLocation: {
            location: '',
            archive: '',
        },
        selectedItemId: null,
        selectedItem: null,
        loadingSearch: false,
        itemStocks: [],
        selectedQr: "",
        selectedPrint: [],
    },
};

const mutations = {
    changePerpustakaanStokArsip(state, payload) {
        state.data = Object.assign({}, state.data, payload);
    },
};

const actions = {
    async getBuku({ commit, state }, payload) {
        commit('changePerpustakaanStokArsip', {
            loading: true,
        });

        if (payload) {
            await commit('changePerpustakaanStokArsip', {
                page: payload || data.page,
            });
        }

        const { data } = state;
        const params = {
            limit: data.limit,
        };

        if (data.page) {
            params.page = data.page
        }

        if (data.sortBy) {
            params.sort_field = (data.sortBy == "title_author") ? "title" : data.sortBy
        }

        if (data.sortType) {
            params.sort_dir = data.sortType
        }

        if (data.filter) {
            params.q = data.filter
        }

        const result = await ApiGetRequest(`school/library/school-book`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanStokArsip', {
                loading: false,
            });

            return [];
        } else {
            await commit('changePerpustakaanStokArsip', {
                items: result.data.data,
                total: result.data.meta.total,
                loading: false,
            });
        }
    },

    async getBukuById({ commit }, payload) {
        commit('changePerpustakaanStokArsip', {
            loadingSearch: true,
        });

        const result = await ApiGetRequest(`school/library/school-book/${payload}`);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanStokArsip', {
                loadingSearch: false,
            });

            return [];
        } else {
            await commit('changePerpustakaanStokArsip', {
                loadingSearch: false,
                selectedItem: result.data.data,
            });
        }
    },

    async getStokById({ commit }, payload) {
        commit('changePerpustakaanStokArsip', {
            loadingSearch: true,
            itemStocks: [],
        });

        let params = {
            school_book_id: payload
        }
        const result = await ApiGetRequest(`school/library/stock`, params);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });

            commit('changePerpustakaanStokArsip', {
                loadingSearch: false,
            });

            return [];
        } else {
            await commit('changePerpustakaanStokArsip', {
                loadingSearch: false,
                itemStocks: result.data.data,
            });
        }
    },

    async submitStok({ commit, state }) {
        const { data } = state;
        commit('changePerpustakaanStokArsip', {
            loading: true,
        });

        let endPoint = 'school/library/stock'
        let body = {
            stock: data.formStok.stock,
            school_book_id: data.selectedItemId,
        }

        let result = await ApiPostRequest(`${endPoint}`, body);

        if (result.error) {
            Message({
                type: 'error',
                message: result.error,
            });
            if (result.errorList) {
                let errors = []
                for (const [key, value] of Object.entries(result.errorList)) {
                    errors.push({
                        "message": value[0],
                        "path": [key]
                    })
                }
                commit('changePerpustakaanStokArsip', {
                    loading: false,
                });
            }
        } else {
            Message({
                type: 'success',
                message: 'Data telah tersimpan.',
            });
            commit('changePerpustakaanStokArsip', {
                loading: false,
                formStok: {
                    stock: '',
                },
            });

            return true;
        }
    },

    async deleteStok({ commit }, payload) {
        commit('changePerpustakaanStokArsip', {
            loading: true,
        });

        if (payload && payload.id) {
            const result = await ApiDeleteRequest(`school/library/stock/${payload.id}`);

            if (result.error) {
                Message({
                    type: 'error',
                    message: result.error,
                });
                commit('changePerpustakaanStokArsip', {
                    errors: result.errorList,
                    loading: false,
                });
                return false;
            } else {
                Message({
                    type: 'success',
                    message: 'Data telah dihapus.',
                });
                commit('changePerpustakaanStokArsip', {
                    errors: [],
                    loading: false,
                });
                return true;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};