import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, NO_INTERNET_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import GlobalService from "@/services/GlobalService";

const state = {
	data: {
		showModalSelectMonth: false,
		loading: false,
		filterStatus: "active",
		filterClasses: null,
		filterMajor: null,
		filterQ: null,
		itemsMessageTable: null,
		errorDataItems: null,
		limit: 50,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		columns: [
			{
				prop: "nis_nik",
				label: "NISN/NIS",
				width: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "name",
				label: "Nama",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "major",
				label: "Jurusan",
				width: 150,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "soft_deleted",
				label: "Status",
				width: 90,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				label: "Aksi",
				width: 90,
				"class-name": "body-14 col-black-2",
			},
		],
	}
};

const mutations = {
	changePenggunaSearchStudent(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changePenggunaSearchStudentLoading(state, payload) {
		state.data.loading = payload
	},
	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {

	onShowModalSearchStudemt({commit}, isOpen) {
		commit("changePenggunaSearchStudent", {showModalSearchStudent: isOpen})
	},

	async fetchListUserStudent({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changePenggunaSearchStudentLoading", true)

		const params = {
			sortField: 'name',
			sortDir: 'asc',
			filterStatus: 'all',
			page: data.page,
			limit: data.limit,
			filterQ: data.filterQ,
			filterClass: data.filterClasses,
			filterMajor: data.filterMajor,
		};

		try {
			const service = new GlobalService()
			const result = await service.userStudentGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Tunggakan"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changePenggunaSearchStudent", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {
			console.log(e)

			commit("changePenggunaSearchStudent", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			}else if (e.code === 'ERR_NETWORK') {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}

			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changePenggunaSearchStudentLoading", false)
		}
	},

};

const getters = {
	listStudentGetter: state => {
		const { filterQ, items } = state.data;
		return !filterQ
			? items
			: items.filter(item => item.name.toLowerCase().includes(filterQ.toLowerCase()));
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
