import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import {_} from "vue-underscore";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dashboard",
		redirect: "/onklas/produk",
		component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
		meta: {
			title: "Dashboard | ",
			allowedRole: ["all"]
		}
	},
	{
		path: "/profile",
		name: "Profile",
		component: () =>
			import(/* webpackChunkName: "profile" */ "../views/Profile"),
		meta: {
			title: "Profile | ",
			allowedRole: ["all"]
		},
		redirect: "/profile/my-profile",
		children: [
			{
				path: "my-profile",
				name: "MyProfile",
				component: () =>
					import(
						/* webpackChunkName: "profile-myprofile" */ "../views/Profile/MyProfile"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"]
				}
			},
			{
				path: "my-role",
				name: "MyRole",
				component: () =>
					import(
						/* webpackChunkName: "profile-myrole" */ "../views/Profile/MyRole"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"]
				}
			},
			{
				path: "my-password",
				name: "MyPassword",
				component: () =>
					import(
						/* webpackChunkName: "profile-myrole" */ "../views/Profile/MyPassword"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"]
				}
			}
		]
	},
	{
		path: "/verify-email",
		name: "VerifikasiEmail",
		component: () =>
			import(
				/* webpackChunkName: "verifikasi-email" */
				"../views/VerificationEmail"
				),
		meta: {
			title: "Verifikasi Email | "
		}
	},
	{
		path: "/docs",
		name: "Dokumentasi",
		component: () => import(/* webpackChunkName: "docs" */ "../views/Home"),
		meta: {
			title: "Dokumentasi | "
		}
	},
	{
		path: "/darurat-spp",
		name: "DaruratSPP",
		component: () =>
			import(/* webpackChunkName: "darurat-spp" */ "../views/DaruratSPP"),
		meta: {
			title: "Darurat | "
		}
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import(
				/* webpackPreload: true */
				/* webpackChunkName: "login" */
				"../views/Login"
				),
		meta: {
			title: "Login | "
		}
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () =>
			import(
				/* webpackChunkName: "forgot-pwd" */
				"../views/ForgotPassword"
				),
		meta: {
			title: "Forgot Password | "
		}
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () =>
			import(
				/* webpackChunkName: "reset-pwd" */
				"../views/ResetPassword"
				),
		meta: {
			title: "Reset Password | "
		}
	},
	{
		path: "/kebijakan-privasi",
		name: "KebijakanPrivasi",
		component: () =>
			import(
				/* webpackChunkName: "kebijakan-privasi" */
				"../views/KebijakanPrivasi"
				),
		meta: {
			title: "Forgot Password | "
		}
	},
	{
		path: "/pengumuman",
		name: "Pengumuman",
		component: () =>
			import(/* webpackChunkName: "pengumuman" */ "../views/Pengumuman"),
		meta: {
			title: "Pengumuman | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/sekolah",
		name: "Sekolah",
		redirect: "/sekolah/profile", // default call first page

		component: () =>
			import(/* webpackChunkName: "profilsekolah" */ "../views/Sekolah"),
		meta: {
			title: "Sekolah | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		beforeEnter: requirePermission,
		children: [
			{
				path: "profile",
				name: "SekolahProfile",
				component: () =>
					import(
						/* webpackChunkName: "pengaturanjarak" */ "../views/Sekolah/Profile"
						),
				meta: {
					title: "Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "setting-koordinat",
				name: "SettingKoordinat",
				component: () =>
					import(
						/* webpackChunkName: "pengaturanjarak" */ "../views/Sekolah/PengaturanJarak"
						),
				meta: {
					title: "Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

	{
		path: "/presensi-pelajar/rekap-presensi-bulanan/cetak/:id",
		name: "RekapPresensiPelajarBulanan",
		component: () =>
			import(
				/* webpackChunkName: "klastime-rekap-presensi-pelajar-cetak" */
				"../views/Klastime/PresensiPelajar/RekapPresensi/ModalBulananTahunan/RekapDokumenBulanan"
				),
		meta: {
			title: "Onklas Produk | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/presensi-guru/rekap-presensi-bulanan/cetak/:id",
		name: "CetakRekapPresensiPelajar",
		component: () =>
			import(
				/* webpackChunkName: "klastime-rekap-presensi-guru-cetak" */
				"../views/Klastime/PresensiGuru/RekapPresensi/ModalBulananTahunan/RekapDokumenBulanan"
				),
		meta: {
			title: "Onklas Produk | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},

	/**
	 * SPP
	 * */
	{
		path: "/invoice-recap",
		name: "InvoiceRecap",
		component: () =>
			import(
				/* webpackChunkName: "invoice-recap" */
				"../views/PembayaranSpp/InvoiceRecap"
				),
		meta: {
			title: "Rekap Invoice | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/recap-tunggakan",
		name: "RecapTunggakan",
		component: () =>
			import(
				/* webpackChunkName: "recap-tunggakan" */
				"../views/PembayaranSpp/RecapTunggakan"
				),
		meta: {
			title: "Rekap Tunggakan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/print-mutasi-keuangan",
		name: "PrintMutasiRecap",
		component: () =>
			import(
				/* webpackChunkName: "print-mutasi-recap" */
				"../views/Keuangan/Mutasi/PrintRekapMutasi"
				),
		meta: {
			title: "Mutasi Keuangan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/print-mutasi-keuangan/:date",
		name: "PrintMutasiRecapDay",
		component: () =>
			import(
				/* webpackChunkName: "print-mutasi-recap-day" */
				"../views/Keuangan/Mutasi/PrintRekapMutasiDay"
				),
		meta: {
			title: "Mutasi Keuangan Perhari | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/invoice-student/:id",
		name: "InvoiceStudent",
		component: () =>
			import(
				/* webpackChunkName: "invoice-student" */
				"../views/PembayaranSpp/TunggakanPelajar/CetakInvoice"
				),
		meta: {
			title: "Invoice Student | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/invoice-bayar-student/:id",
		name: "InvoiceBayarStudent",
		component: () =>
			import(
				/* webpackChunkName: "invoice-bayar-student" */
				"../views/PembayaranSpp/RiwayatPembayaran/CetakInvoice"
				),
		meta: {
			title: "Invoice Student | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/dana-partisipasi",
		name: "DanaPartisipasi",
		component: () =>
			import(
				/* webpackChunkName: "dana-partisipasi" */
				"../views/DanaPartisipasi"
				),
		meta: {
			title: "Dana Partisipasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		redirect: "/dana-partisipasi/program",
		children: [
			{
				path: "program",
				name: "DanaPartisipasiProgram",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-program" */
						"../views/DanaPartisipasi/Program"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi",
				name: "FormProgramPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi-siswa",
				name: "FormProgramPartisipasiPerSiswa",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi/PerSiswa.vue"
						),
				meta: {
					title: "Dana Partisipasi Program Per Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "form-program-partisipasi/:id",
				name: "EditFormProgramPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-form-program" */
						"../views/DanaPartisipasi/Program/FormProgramPartisipasi"
						),
				meta: {
					title: "Dana Partisipasi Program | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran",
				name: "DanaPartisipasiPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-pembayaran" */
						"../views/DanaPartisipasi/Pembayaran"
						),
				meta: {
					title: "Dana Partisipasi Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran-by-program",
				name: "DanaPartisipasiPembayaranByProgram",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-pembayaran-by-program" */
						"../views/DanaPartisipasi/Pembayaran"
						),
				meta: {
					title: "Dana Partisipasi Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran-by-pelajar",
				name: "DanaPartisipasiPembayaranByPelajar",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-pembayaran-by-pelajar" */
						"../views/DanaPartisipasi/ByPelajar"
						),
				meta: {
					title: "Dana Partisipasi Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "biaya-admin",
				name: "BiayaAdminDanaPartisipasi",
				component: () =>
					import(
						/* webpackChunkName: "dana-partisipasi-admin" */
						"../views/DanaPartisipasi/BiayaAdmin"
						),
				meta: {
					title: "Dana Partisipasi Biaya Admin | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

	{
		path: "/koperasi",
		name: "Koperasi",
		component: () =>
			import(/* webpackChunkName: "koperasi" */ "../views/Koperasi"),
		meta: {
			title: "Koperasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance",
				"Cooperative"
			]
		},
		redirect: "/koperasi/dashboard",
		children: [
			{
				path: "biaya-admin",
				name: "KoperasiBiayaAdmin",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-biaya-admin" */
						"../views/Koperasi/BiayaAdmin"
						),
				meta: {
					title: "Koperasi Biaya Admin | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},

			// ================== KANTIN ==================
			{
				path: "kantin",
				name: "KoperasiKantin",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/Koperasi/KoperasiKantin"
						),
				meta: {
					title: "Koperasi Kantin | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "kantin/penjualan/:id",
				name: "KoperasiKantinPenjualan",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/Koperasi/KoperasiKantinPenjualan"
						),
				meta: {
					title: "Koperasi Kantin Penjualan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "kantin/penjualan/:id/transaksi/:date",
				name: "KoperasiKantinPenjualanByDate",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-kantin" */
						"../views/Koperasi/KoperasiKantinPenjualan"
						),
				meta: {
					title: "Penjualan Sesuai Tanggal | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "detail-transaksi-kantin/:id",
				name: "DetailKoperasiKantin",
				component: () =>
					import(
						/* webpackChunkName: "kantin-tarik-saldo" */
						"../views/Koperasi/KoperasiKantin/DaftarKantin/DetailTransaksi"
						),
				meta: {
					title: "Koperasi Kantin | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			// =============== KANTIN ==================

			{
				path: "dashboard",
				name: "koperasiDashboard",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-dashboard" */
						"../views/Koperasi/KoperasiDashboard"
						),
				meta: {
					title: "Koperasi Dashboard | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "klaspay-topup",
				name: "koperasiKlasPayTopUp",
				component: () =>
					import(
						/* webpackChunkName: "klaspay-topup" */
						"../views/Koperasi/KoperasiKlaspay/TopUp"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "klaspay-tarik-saldo",
				name: "koperasiKlasPayTarikSaldo",
				component: () =>
					import(
						/* webpackChunkName: "klaspay-tarik-saldo" */
						"../views/Koperasi/KoperasiKlaspay/TarikSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "klaspay-isi-saldo",
				name: "koperasiKlasPayIsiSaldo",
				component: () =>
					import(
						/* webpackChunkName: "klaspay-isisaldo" */
						"../views/Koperasi/KoperasiKlaspay/IsiSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "atur-pembukuan",
				name: "KoperasiAturPembukuan",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-atur-pembukuan" */
						"../views/Koperasi/KoperasiKas/AturPembukuan"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "input-barang",
				name: "KoperasiInputBarang",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-input-barang" */
						"../views/Koperasi/KoperasiKas/InputBarang"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "table-saldo-klaspay",
				name: "KoperasiKlasPayTableSaldo",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-klaspay-tablesaldo" */
						"../views/Koperasi/KoperasiKlaspay/TableSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "table-saldo-koperasi",
				name: "KoperasiTableSaldo",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-tablesaldo" */
						"../views/Koperasi/KoperasiKas/TableSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "mutasi-saldo-klaspay",
				name: "KoperasiMutasiSaldoKlaspay",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-mutasisaldo-klaspay" */
						"../views/Koperasi/KoperasiKlaspay/MutasiSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "mutasi-saldo-kas",
				name: "KoperasiMutasiSaldoKas",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-mutasisaldo-kas" */
						"../views/Koperasi/KoperasiKas/MutasiSaldo"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "atur-rekening",
				name: "KoperasiAturRekening",
				component: () =>
					import(
						/* webpackChunkName: "koperasi-atur-rekening" */
						"../views/Koperasi/KoperasiKlaspay/AturRekening"
						),
				meta: {
					title: "Koperasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Cooperative"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/pembelajaran",
		name: "Pembelajaran",
		redirect: "/pembelajaran/jurusan", // default call first page
		// default call first page
		component: () =>
			import(
				/* webpackChunkName: "pembelajaran" */
				"../views/Pembelajaran/"
				),
		meta: {
			title: "Pembelajaran | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		children: [
			{
				path: "jurusan",
				name: "Jurusan",
				component: () =>
					import(
						/* webpackChunkName: "jurusan" */
						"../views/Pembelajaran/Jurusan"
						),
				meta: {
					title: "Jurusan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ruang-kelas",
				name: "RuangKelas",
				component: () =>
					import(
						/* webpackChunkName: "ruangkelas" */
						"../views/Pembelajaran/RuangKelas"
						),
				meta: {
					title: "Ruang Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ruang-kelas/data-kelas/:id",
				name: "RuangKelasDataKelas",
				component: () =>
					import(
						/* webpackChunkName: "ruangkelas-tambahsiswa" */
						"../views/Pembelajaran/RuangKelas/FormTambahSiswa"
						),
				meta: {
					title: "Tambah Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ruang-kelas/import-excel-pelajar/:id",
				name: "ImportExcelPelajar",
				component: () =>
					import(
						/* webpackChunkName: "importexcelpelajar" */
						"../views/Pembelajaran/RuangKelas/FormTambahSiswa/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "mata-pelajaran",
				name: "MataPelajaran",
				component: () =>
					import(
						/* webpackChunkName: "matapelajaran" */
						"../views/Pembelajaran/Mapel"
						),
				meta: {
					title: "Mata Pelajaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "jadwal",
				name: "JadwalPelajaran",
				component: () =>
					import(
						/* webpackChunkName: "jadwal" */
						"../views/Pembelajaran/Jadwal"
						),
				meta: {
					title: "Jadwal | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "materi",
				name: "Materi",
				component: () =>
					import(
						/* webpackChunkName: "materi" */
						"../views/Pembelajaran/Materi"
						),
				meta: {
					title: "Materi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian",
				name: "Ujian",
				component: () =>
					import(
						/* webpackChunkName: "ujian" */
						"../views/Pembelajaran/Ujian"
						),
				meta: {
					title: "Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian/penilaian",
				name: "UjianPenilaian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian" */
						"../views/Pembelajaran/Ujian/Penilaian"
						),
				meta: {
					title: "Penilaian Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian/penilaian/:id",
				name: "EditUjianPenilaian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian" */
						"../views/Pembelajaran/Ujian/Penilaian/FormDetailPenilaian"
						),
				meta: {
					title: "FormIpCctv Penilaian Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian/penilaian/:ujianId/detail-siswa/:id",
				name: "DetailSiswaUjian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian detail siswa" */
						"../views/Pembelajaran/Ujian/Penilaian/DetailSiswa"
						),
				meta: {
					title: "FormIpCctv Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian/penilaian/cek-jawaban/:id/siswa/:siswaId",
				name: "DetailJawabanUjian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian cek jawaban" */
						"../views/Pembelajaran/Ujian/Penilaian/FormDetailPenilaian/CekJawaban"
						),
				meta: {
					title: "Cek Jawaban Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ujian/:id/soal",
				name: "SoalUjian",
				component: () =>
					import(
						/* webpackChunkName: "soal-ujian" */
						"../views/Pembelajaran/Ujian/FormHalamanCreateSoal"
						),
				// component: () =>
				//     import (
				//         /* webpackChunkName: "Soal ujian" */
				//         '../views/Pembelajaran/Ujian/HalamanLama/ExamQuestionAKM.vue'
				//     ),
				meta: {
					title: "Soal Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "jadwal-ujian",
				name: "JadwalUjian",
				component: () =>
					import(
						/* webpackChunkName: "Jadwal ujian" */
						"../views/Pembelajaran/JadwalUjian"
						),
				meta: {
					title: "Jadwal Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "tugas",
				name: "Tugas",
				component: () =>
					import(
						/* webpackChunkName: "tugas" */
						"../views/Pembelajaran/Tugas"
						),
				meta: {
					title: "Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "tugas/penilaian/:id",
				name: "PenilaianTugas",
				component: () =>
					import(
						/* webpackChunkName: "penilaian tugas" */
						"../views/Pembelajaran/Tugas/HalamanPenilaian"
						),
				meta: {
					title: "Penilaian Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "tugas/penilaian/:id_penilaian/detail-peserta/:id",
				name: "PenilaianTugasDetailPeserta",
				component: () =>
					import(
						/* webpackChunkName: "penilaian tugas detail siswa" */
						"../views/Pembelajaran/Tugas/HalamanPenilaian/DetailSiswa"
						),
				meta: {
					title: "Penilaian Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "magang",
				name: "Magang",
				component: () =>
					import(
						/* webpackChunkName: "magang" */
						"../views/Pembelajaran/Magang"
						),
				meta: {
					title: "Magang | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "magang/detail-magang/:id",
				name: "MagangDetail",
				component: () =>
					import(
						/* webpackChunkName: "magang-detail" */
						"../views/Pembelajaran/Magang/DetailMagang"
						),
				meta: {
					title: "Magang | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "magang/detail-magang/:id/group/:group_id/atur-anggota",
				name: "MagangAturAnggota",
				component: () =>
					import(
						/* webpackChunkName: "magang-detail-atur-anggota" */
						"../views/Pembelajaran/Magang/DetailMagang/Group/AturAnggota"
						),
				meta: {
					title: "Magang | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "magang/detail-magang/:id/group/:group_id/jadwal",
				name: "MagangJadwal",
				component: () =>
					import(
						/* webpackChunkName: "magang-detail-atur-jadwal" */
						"../views/Pembelajaran/Magang/DetailMagang/Jadwal/DetailJadwal"
						),
				meta: {
					title: "Magang | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			// {
			//   path: "magang/setting-group/:id",
			//   name: "Magang",
			//   component: () =>
			//     import(
			//       /* webpackChunkName: "magang" */
			//       "../views/Pembelajaran/Magang/Group/SettingGroup"
			//     ),
			//   meta: {
			//     title: "Magang | ",
			//     allowedRole: [
			//       "Head Master",
			//       "SuperAdmin",
			//       "Vice Principal",
			//       "School Admin",
			//       "Teacher",
			//     ],
			//   },
			//   beforeEnter: requirePermission,
			// },
			// {
			//   path: "magang/report/:id",
			//   name: "Magang",
			//   component: () =>
			//     import(
			//       /* webpackChunkName: "magang" */
			//       "../views/Pembelajaran/Magang/Report/DetailReport"
			//     ),
			//   meta: {
			//     title: "Magang | ",
			//     allowedRole: [
			//       "Head Master",
			//       "SuperAdmin",
			//       "Vice Principal",
			//       "School Admin",
			//       "Teacher",
			//     ],
			//   },
			//   beforeEnter: requirePermission,
			// },
			{
				path: "/pembelajaran/magang/detail-magang/report/:id/info/:group_id",
				name: "MagangReport",
				component: () =>
					import(
						/* webpackChunkName: "magang" */
						"../views/Pembelajaran/Magang/DetailMagang/Report/DetailReport"
						),
				meta: {
					title: "Magang | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/sosial-media",
		name: "SosialMedia",
		redirect: "/sosial-media/postingan",
		component: () =>
			import(/* webpackChunkName: "sosial-media" */ "../views/Onklas/"),
		meta: {
			title: "Sosial Media |",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		children: [
			{
				path: "postingan",
				name: "Postingan",
				component: () =>
					import(
						/* webpackChunkName: "Postingan" */
						"../views/Onklas/Postingan"
						),
				meta: {
					title: "Postingan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "postingan-ebook",
				name: "PostinganEBook",
				component: () =>
					import(
						/* webpackChunkName: "Postingan E-Book" */
						"../views/Onklas/Ebook"
						),
				meta: {
					title: "Postingan E-Book | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "hashtag",
				name: "Hashtag",
				component: () =>
					import(
						/* webpackChunkName: "Hashtag" */
						"../views/Onklas/Hashtag"
						),
				meta: {
					title: "Hashtag | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "peringkat-post",
				name: "PeringkatPost",
				component: () =>
					import(
						/* webpackChunkName: "Peringkat post" */
						"../views/Onklas/PeringkatPost"
						),
				meta: {
					title: "Peringkat Post | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "laporan",
				name: "Laporan",
				component: () =>
					import(
						/* webpackChunkName: "laporan" */
						"../views/Onklas/Laporan"
						),
				meta: {
					title: "Laporan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

	{
		path: "/pembayaran-spp",
		name: "PembayaranSPP",
		redirect: "/pembayaran-spp/tunggakan-pelajar",
		component: () =>
			import(
				/* webpackChunkName: "pembayaran-spp" */
				"../views/PembayaranSpp"
				),
		meta: {
			title: "Pembayaran SPP | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		children: [
			{
				path: "tunggakan-pelajar",
				name: "TunggakanPelajar",
				component: () =>
					import(
						/* webpackChunkName: "spp-tunggakan-pelajar" */
						"../views/PembayaranSpp/TunggakanPelajar"
						),
				meta: {
					title: "Tunggakan Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-tunggakan-pelajar/:id",
				name: "DetailTunggakanPelajar",
				component: () =>
					import(
						/* webpackChunkName: "detail-tunggakan-pelajar" */
						"../views/PembayaranSpp/TunggakanPelajar/DetailTunggakan"
						),
				meta: {
					title: "FormIpCctv Tunggakan Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pembayaran-kelas",
				name: "PembayaranKelas",
				component: () =>
					import(
						/* webpackChunkName: "spp-pembayaran-kelas" */
						"../views/PembayaranSpp/PembayaranKelas"
						),
				meta: {
					title: "Pembayaran Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "riwayat-pembayaran",
				name: "RiwayatPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-riwayat-pembayaran" */
						"../views/PembayaranSpp/RiwayatPembayaran"
						),
				meta: {
					title: "Riwayat Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-pelajar/:id",
				name: "DetailPelajarRiwayatPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-riwayat-detail-pelajar" */
						"../views/PembayaranSpp/RiwayatPembayaran/DetailPelajar"
						),
				meta: {
					title: "Riwayat Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan",
				name: "PengaturanPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "spp-pengaturan" */
						"../views/PembayaranSpp/Pengaturan"
						),
				meta: {
					title: "Pengaturan Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "penerima-diskon/:id",
				name: "PengaturanPenerimaDiskon",
				component: () =>
					import(
						/* webpackChunkName: "spp-penerima-diskon" */
						"../views/PembayaranSpp/Pengaturan/PenerimaDiskon"
						),
				meta: {
					title: "Pengaturan Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "biaya-admin",
				name: "BiayaAdminSPP",
				component: () =>
					import(
						/* webpackChunkName: "spp-biayadmin" */
						"../views/PembayaranSpp/BiayaAdmin"
						),
				meta: {
					title: "Biaya Admin SPP | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

	{
		path: "/klasite",
		name: "Klasite",
		component: () =>
			import(/* webpackChunkName: "klasite" */ "../views/Klasite"),
		meta: {
			title: "Klasite | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		beforeEnter: requirePermission,
		redirect: "klasite/domain-tema",
		children: [
			{
				path: "domain-tema",
				name: "DomainTema",
				component: () =>
					import(
						/* webpackChunkName: "klasite-domain-tema" */
						"../views/Klasite/DomainTema"
						),
				meta: {
					title: "Domain Tema | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "konten-website",
				name: "KlasiteKontenWebsite",
				redirect: "/klasite/konten-website/beranda",
				component: () =>
					import(
						/* webpackChunkName: "klasite-konten-website" */
						"../views/Klasite/KontenWebsite"
						),
				meta: {
					title: "Klasite | ",
					allowedRole: ["all"]
				},
				children: [
					{
						path: "beranda",
						name: "KontenWebsiteBeranda",
						component: () =>
							import(
								/* webpackChunkName: "beranda-website" */
								"../views/Klasite/KontenWebsite/Beranda"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "profil",
						name: "KontenWebsiteProfil",
						component: () =>
							import(
								/* webpackChunkName: "profil-website" */
								"../views/Klasite/KontenWebsite/Profil"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "berita",
						name: "KontenWebsiteBerita",
						component: () =>
							import(
								/* webpackChunkName: "berita-website" */
								"../views/Klasite/KontenWebsite/Berita"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "pengumuman",
						name: "KontenWebsitePengumuman",
						component: () =>
							import(
								/* webpackChunkName: "pengumuman-website" */
								"../views/Klasite/KontenWebsite/Pengumuman"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "galeri",
						name: "KontenWebsiteGaleri",
						component: () =>
							import(
								/* webpackChunkName: "galeri-website" */
								"../views/Klasite/KontenWebsite/Galeri"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "jurusan",
						name: "KontenWebsiteJurusan",
						component: () =>
							import(
								/* webpackChunkName: "jurusan-website" */
								"../views/Klasite/KontenWebsite/Jurusan"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "eks-kulikuler",
						name: "KontenWebsiteEksKul",
						component: () =>
							import(
								/* webpackChunkName: "ekskul-website" */
								"../views/Klasite/KontenWebsite/EksKulikuler"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "kontak",
						name: "KontenWebsiteKontak",
						component: () =>
							import(
								/* webpackChunkName: "kontak-website" */
								"../views/Klasite/KontenWebsite/Kontak"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					},
					{
						path: "postingan",
						name: "KontenWebsitePostingan",
						component: () =>
							import(
								/* webpackChunkName: "postingan-website" */
								"../views/Klasite/KontenWebsite/Postingan"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"]
						},
						beforeEnter: requirePermission
					}
				]
			}
		]
	},

	{
		path: "/pengguna",
		name: "Pengguna",
		redirect: "/pengguna/pelajar", // default call first page
		component: () =>
			import(/* webpackChunkName: "pengguna" */ "../views/Pengguna"),
		meta: {
			title: "Pengguna | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		children: [
			{
				path: "create-guru-staff",
				name: "CreateGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/GuruStaff/FormGuruStaff"
						),
				meta: {
					title: "Buat Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "edit-guru-staff/:id",
				name: "EditGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/GuruStaff/FormGuruStaff"
						),
				meta: {
					title: "Ubah Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-guru-staff/:id",
				name: "DetailGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/GuruStaff/FormGuruStaff"
						),
				meta: {
					title: "FormIpCctv Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "import-excel-guru-staff",
				name: "ImportExcelGuru",
				component: () =>
					import(
						/* webpackChunkName: "importexcelguru" */
						"../views/Pengguna/GuruStaff/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Guru | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "guru-staff",
				name: "GuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "gurustaff" */
						"../views/Pengguna/GuruStaff"
						),
				meta: {
					title: "Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "create-pelajar",
				name: "CreatePelajar",
				component: () =>
					import(
						/* webpackChunkName: "formpelajar" */
						"../views/Pengguna/Pelajar/FormPelajar"
						),
				meta: {
					title: "Buat Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "edit-pelajar/:id",
				name: "EditPelajar",
				component: () =>
					import(
						/* webpackChunkName: "formpelajar" */
						"../views/Pengguna/Pelajar/FormPelajar"
						),
				meta: {
					title: "Ubah Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-pelajar/:id",
				name: "DetailPelajar",
				component: () =>
					import(
						/* webpackChunkName: "formpelajar" */
						"../views/Pengguna/Pelajar/FileStorage"
						),
				meta: {
					title: "FormIpCctv Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "import-excel-pelajar",
				name: "PreviewImportExcelPelajar",
				component: () =>
					import(
						/* webpackChunkName: "preview-importexcelpelajar" */
						"../views/Pengguna/Pelajar/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pelajar",
				name: "Pelajar",
				component: () =>
					import(
						/* webpackChunkName: "pelajar" */
						"../views/Pengguna/Pelajar"
						),
				meta: {
					title: "Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "files",
				name: "SearchFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pelajar" */
						"../views/Pengguna/SearchFileStorage"
						),
				meta: {
					title: "Pencarian Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "files/bin",
				name: "BinFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pelajar" */
						"../views/Pengguna/BinFileStorage"
						),
				meta: {
					title: "Pencarian Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			/*{
        path: "files/storage",
        name: "DetailFileStorage",
        component: () =>
          import(
            /!* webpackChunkName: "pelajar" *!/
            "../views/Pengguna/DetailFileStorage"
          ),
        meta: {
          title: "FormIpCctv Penyimpanan | ",
          allowedRole: [
            "Head Master",
            "SuperAdmin",
            "Vice Principal",
            "School Admin"
          ]
        },
        beforeEnter: requirePermission
      },*/
			{
				path: "kartu-pelajar",
				name: "KartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "kartu-pelajar" */
						"../views/Pengguna/KartuPelajar"
						),
				meta: {
					title: "Kartu Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "edit-kartu-pelajar/:id",
				name: "EditKartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "edit-kartu-pelajar" */
						"../views/Pengguna/KartuPelajar/FormPelajar"
						),
				meta: {
					title: "Ubah Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan-kartu-pelajar",
				name: "PengaturanKartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "pengaturan-kartu-pelajar" */
						"../views/Pengguna/KartuPelajar/Pengaturan"
						),
				meta: {
					title: "Kartu Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "ortu-wali",
				name: "OrtuWali",
				component: () =>
					import(
						/* webpackChunkName: "ortuwali" */
						"../views/Pengguna/OrtuWali"
						),
				meta: {
					title: "Ortu Wali | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/cetak-kartu-pelajar/:id?/:type?",
		name: "CetakKartuPelajar",
		component: () =>
			import(
				/* webpackChunkName: "cetak-kartu-pelajar" */
				"../views/Pengguna/KartuPelajar/PreviewCetak"
				),
		meta: {
			title: "Kartu Pelajar | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/intro",
		name: "Intro",
		redirect: "/intro/hak-akses", // default call first page
		component: () => import(/* webpackChunkName: "intro" */ "../views/Intro"),
		meta: {
			title: "Intro | ",
			allowedRole: ["all"]
		},
		children: [
			{
				path: "hak-akses",
				name: "IntroHakAkses",
				component: () =>
					import(
						/* webpackChunkName: "intro-hakakses" */
						"../views/Intro/HakAkses"
						),
				meta: {
					title: "Hak Akses | ",
					allowedRole: ["all"]
				}
				// beforeEnter: requirePermission,
			},
			{
				path: "hak-akses/form-user",
				name: "IntroHakAksesFormUser",
				component: () =>
					import(
						/* webpackChunkName: "intro-form-user" */
						"../views/Intro/HakAkses/FormUser"
						),
				meta: {
					title: "Form Akses | ",
					allowedRole: ["all"]
				}
				// beforeEnter: requirePermission,
			},
			{
				path: "data-sekolah",
				name: "IntroDataSekolah",
				component: () =>
					import(
						/* webpackChunkName: "intro-datasekolah" */
						"../views/Intro/DataSekolah"
						),
				meta: {
					title: "Data Sekolah | ",
					allowedRole: ["all"]
				}
			},
			{
				path: "data-sekolah/step",
				name: "IntroDataSekolahStep",
				component: () =>
					import(
						/* webpackChunkName: "intro-step" */
						"../views/Intro/DataSekolah/Step"
						),
				meta: {
					title: "Form Step | ",
					allowedRole: ["all"]
				}
				// beforeEnter: requirePermission,
			},
			{
				path: "done",
				name: "IntroDone",
				component: () =>
					import(
						/* webpackChunkName: "intro-done" */
						"../views/Intro/Done"
						),
				meta: {
					title: "Selesai Pengaturan | ",
					allowedRole: ["all"]
				}
				// beforeEnter: requirePermission,
			}
		]
	},
	{
		path: "/klaspustaka/perpustakaan/buku/cetak-stok",
		name: "PerpustakaanBukuCetakStok",
		component: () =>
			import(
				/* webpackChunkName: "perpustakaan-buku-cetak-stok" */
				"../views/Klaspustaka/Perpustakaan/StokArsip/FormStokArsip/Stok/PreviewCetak"
				),
		meta: {
			title: "Klaspustaka Cetak Stok | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Librarian"
			]
		},
		props: true,
		beforeEnter: requirePermission
	},
	{
		path: "/klaspustaka",
		name: "Klaspustaka",
		redirect: "/klaspustaka/perpustakaan", // default call first page
		component: () =>
			import(
				/* webpackChunkName: "klaspustaka" */
				"../views/Klaspustaka"
				),
		meta: {
			title: "Klaspustaka | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Librarian"
			]
		},
		children: [
			{
				path: "perpustakaan",
				name: "Perpustakaan",
				component: () =>
					import(
						/* webpackChunkName: "perpustakaan" */
						"../views/Klaspustaka/Perpustakaan"
						),
				meta: {
					title: "Klaspustaka Perpustakaan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Librarian"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "perpustakaan/buku/form/:id?",
				name: "PerpustakaanBukuForm",
				component: () =>
					import(
						/* webpackChunkName: "perpustakaan-buku-form" */
						"../views/Klaspustaka/Perpustakaan/Buku/FormBuku"
						),
				meta: {
					title: "Klaspustaka Form Buku Perpustakaan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Librarian"
					]
				},
				props: true,
				beforeEnter: requirePermission
			},
			{
				path: "web-katalog",
				name: "KlaspustakaWebKatalog",
				component: () =>
					import(
						/* webpackChunkName: "klaspustaka-web-katalog" */
						"../views/Klaspustaka/WebKatalog"
						),
				meta: {
					title: "Klaspustaka | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Librarian"
					]
				}
			}
		]
	},

	/**
	 * KEUANGAN SEKOLAH
	 * */
	{
		path: "/keuangan",
		name: "Keuangan",
		redirect: "/keuangan/mutasi",
		component: () =>
			import(
				/* webpackChunkName: "keuangan" */
				"../views/Keuangan"
				),
		meta: {
			title: "Keuangan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		children: [
			{
				path: "mutasi",
				name: "KeuanganMutasi",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-mutasi" */
						"../views/Keuangan/Mutasi"
						),
				meta: {
					title: "Mutasi Dana | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "penarikan",
				name: "KeuanganPenarikan",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-penarikan" */
						"../views/Keuangan/Penarikan"
						),
				meta: {
					title: "Penarikan Dana | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "akun-bank",
				name: "KeuanganAkunBank",
				component: () =>
					import(
						/* webpackChunkName: "keuangan-akun-bank" */
						"../views/Keuangan/AkunBank"
						),
				meta: {
					title: "Akun Bank | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},


	/**
	 * KLAS MONITORING
	 * */
	{
		path: "/klasmonitoring",
		name: "produkKlasmonitoring",
		component: () =>
			import(
				/* webpackChunkName: "klasmoniotoring" */
				"../views/Klasmonitoring"
				),
		meta: {
			title: "Klasmonitoring | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/klassign",
		name: "produkKlassign",
		component: () =>
			import(
				/* webpackChunkName: "klassign" */
				"../views/Klassign"
				),
		meta: {
			title: "Klassign | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/klaskonseling",
		name: "produkKonseling",
		component: () =>
			import(
				/* webpackChunkName: "klassign" */
				"../views/KlasKonseling"
				),
		redirect: "/klaskonseling/kamus-poin",
		meta: {
			title: "KlasKonseling | ",
			allowedRole: [
				"Head Master",
				"Conseuling"
			]
		},
		children: [
			{
				path: "kamus-poin",
				name: "KlasKonselingKamusPoin",
				component: () => import("../views/KlasKonseling/KamusPoin"),
				meta: {
					title: "KlasKonseling Kamus Poin | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "penanganan",
				name: "KlasKonselingPenanganan",
				component: () => import("../views/KlasKonseling/Penanganan"),
				meta: {
					title: "KlasKonseling Penanganan | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "rapor",
				name: "KlasKonselingRapor",
				component: () => import("../views/KlasKonseling/Rapor"),
				meta: {
					title: "KlasKonseling Rapor | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan",
				name: "KlasKonselingPengaturan",
				component: () => import("../views/KlasKonseling/Pengaturan"),
				meta: {
					title: "KlasKonseling Pengaturan | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "poin-warna",
				name: "KlasKonselingPoinWarna",
				component: () => import("../views/KlasKonseling/PoinWarna"),
				meta: {
					title: "KlasKonseling Poin dan Warna | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "asesmen",
				name: "KlasKonselingAsesmen",
				component: () => import("../views/KlasKonseling/Asesmen"),
				meta: {
					title: "KlasKonseling Asesmen | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "asesmen/pengajuan",
				name: "KlasKonselingAsesmenPengajuan",
				component: () =>
					import(
						/* webpackChunkName: "Pengajuan" */
						"../views/KlasKonseling/Asesmen/Pengajuan"
						),
				meta: {
					title: "Assesment Pengajuan | ",
					allowedRole: [
						"Head Master",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/klaskonseling/rapor/cetak/:user_id",
		name: "KlasKonselingRaporCetak",
		component: () => import("../views/KlasKonseling/Rapor/Cetak"),
		meta: {
			title: "KlasKonseling Rapor Cetak | ",
			allowedRole: [
				"Head Master",
				"Conseuling"
			]
		},
		beforeEnter: requirePermission
	},

	{
		path: "/klasbisnis",
		name: "KlasbisnisMenu",
		redirect: "/klasbisnis",
		component: () =>
			import(
				/* webpackChunkName: "klasbisnis" */
				"../views/Klasbisnis"
				),
		meta: {
			title: "Klasbisnis | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		children: [
			{
				path: "/",
				name: "Klasbisnis",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-bisnis" */
						"../views/Klasbisnis/Bisnis"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/klasbisnis/produk-dijual/:id?",
				name: "KlasbisnisProdukDijual",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-produk-dijual" */
						"../views/Klasbisnis/Bisnis/DaftarPenjual/ProdukDijual"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "klasmart",
				name: "Klasmart",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-klasmart" */
						"../views/Klasbisnis/Klasmart"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "etalase",
				name: "Etalase",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-etalase" */
						"../views/Klasbisnis/Etalase"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengecualian-kerjasama",
				name: "PengecualianKerjasama",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-pengecualian-kerjasama" */
						"../views/Klasbisnis/PengecualianKerjaSama"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "detail-pengecualian-kerjasama/:id",
				name: "DetailPengecualianKerjasama",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-detail-pengecualian-kerjasama" */
						"../views/Klasbisnis/PengecualianKerjaSama/Detail"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "biaya-admin",
				name: "KlasbisnisBiayaAdmin",
				component: () =>
					import(
						/* webpackChunkName: "klasbisnis-biaya-admin" */
						"../views/Klasbisnis/BiayaAdmin"
						),
				meta: {
					title: "Klasbisnis | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/onklas",
		name: "DashboardOnklas",
		redirect: "/onklas/produk", // default call first page
		component: () =>
			import(/* webpackChunkName: "beranda" */ "../views/Beranda"),
		meta: {
			title: "Beranda | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance",
				"Teacher"
			]
		},
		children: [
			{
				path: "/onklas/produk",
				name: "BerandaProduk",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk" */
						"../views/Beranda/ProdukNew"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher",
						"Conseuling"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/pembayaran",
				name: "BerandaProdukPembayaran",
				component: () => import("../views/Beranda/Produk/MetodePembayaran"),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/pembayaran/checkout",
				name: "BerandaProdukPembayaranCheckout",
				component: () =>
					import("../views/Beranda/Produk/MetodePembayaran/Checkout"),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-mapel",
				name: "BerandaProdukTryoutForm",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-mapel" */
						"../views/Beranda/Produk/Tryout/FormPengajuanMapel"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-mapel/pilih-siswa",
				name: "BerandaProdukTryoutFormStudent",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-mapel-pilih-siswa" */
						"../views/Beranda/Produk/Tryout/FormPengajuanMapel/PilihPelajar"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-mapel/sukses",
				name: "BerandaProdukTryoutFormStudentSuccess",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-mapel-sukses" */
						"../views/Beranda/Produk/Tryout/FormPengajuanSuksesMapel"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-mapel/history",
				name: "BerandaProdukTryoutFormStudentSuccessHistory",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-mapel-history" */
						"../views/Beranda/Produk/Tryout/FormHistoryTryoutMapel"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-akm",
				name: "BerandaProdukTryoutFormAKM",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-akm" */
						"../views/Beranda/Produk/Tryout/FormPengajuanAKM"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-akm/pilih-siswa",
				name: "BerandaProdukTryoutFormStudentAKM",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-akm-pilih-siswa" */
						"../views/Beranda/Produk/Tryout/FormPengajuanAKM/PilihPelajar"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-akm/sukses",
				name: "BerandaProdukTryoutAkmFormStudentSuccess",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-akm-sukses" */
						"../views/Beranda/Produk/Tryout/FormPengajuanSuksesAKM"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/tryout/pengajuan-tryout-akm/history",
				name: "BerandaProdukTryoutAkmFormStudentSuccessHistory",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-tryout-pengajuan-akm-history" */
						"../views/Beranda/Produk/Tryout/FormHistoryTryoutAKM"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/produk/pemesanan/:type/:name",
				name: "BerandaProdukFormPemesanan",
				component: () =>
					import(
						/* webpackChunkName: "onklas-produk-pemesanan" */
						"../views/Beranda/Produk/FormPemesanan"
						),
				meta: {
					title: "Form Pemesanan Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/pembelian",
				name: "BerandaPembelian",
				component: () =>
					import(
						/* webpackChunkName: "onklas-pembelian" */
						"../views/Beranda/Pembelian"
						),
				meta: {
					title: "Onklas Pembelian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan",
				name: "BerandaTagihan",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan" */
						"../views/Beranda/Tagihan"
						),
				meta: {
					title: "Onklas Tagihan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan/detail/:id",
				name: "BerandaTagihanDetail",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan-detail" */
						"../views/Beranda/Tagihan/Detail"
						),
				meta: {
					title: "Onklas Tagihan FormIpCctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan/pembayaran/:id",
				name: "BerandaTagihanPembayaran",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan-pembayaran" */
						"../views/Beranda/Tagihan/MetodePembayaran"
						),
				meta: {
					title: "Onklas Tagihan Pembayaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan/pembayaran/:id/checkout",
				name: "BerandaTagihanPembayaranCheckout",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan-pembayaran-checkout" */
						"../views/Beranda/Tagihan/MetodePembayaran/Checkout"
						),
				meta: {
					title: "Onklas Tagihan Pembayaran Checkout | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan/pembayaran/klaspay/:id/quiz/:quiz_id",
				name: "BerandaTagihanPembayaranKlaspay",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan-pembayaran-klaspay" */
						"../views/Beranda/Tagihan/PembayaranKlaspay"
						),
				meta: {
					title: "Onklas Tagihan Pembayaran Klaspay| ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "/onklas/tagihan/detail/:id/quiz/:quiz_id",
				name: "BerandaTagihanDetailQuiz",
				component: () =>
					import(
						/* webpackChunkName: "onklas-tagihan-detail-quiz" */
						"../views/Beranda/Tagihan/DetailQuiz"
						),
				meta: {
					title: "Onklas Tagihan FormIpCctv Quiz | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/klastime",
		name: "Klastime",
		redirect: "/klastime/mesin-absensi",
		component: () =>
			import(/* webpackChunkName: "klastime" */ "../views/Klastime"),
		meta: {
			title: "Klastime | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		children: [
			// cctv ai START =====================
			{
				path: "pengguna-absensi-cctv",
				name: "AbsensiCctvPengguna",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-pengguna" */
						"../views/Klastime/PenggunaAbsensiCctv"
						),
				meta: {
					title: "Absensi Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv",
				name: "AbsensiCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv" */
						"../views/Klastime/AbsensiCctv"
						),
				meta: {
					title: "Absensi Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv/:id",
				name: "AbsensiCctvDetailNvr",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-ip" */
						"../views/Klastime/AbsensiCctv/Pengaturan"
						),
				meta: {
					title: "List Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv/:id/cctv/:cctv_id",
				name: "AbsensiCctvDetailDeviceCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-ip-detail" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan"
						),
				meta: {
					title: "List Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pelajar-cctv/:id",
				name: "TambahPelajarCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-cctv" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan/Pelajar/Tambah"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "guru-staff-cctv/:id",
				name: "TambahGuruCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-cctv" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan/GuruStaff/Tambah"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			// cctv ai END =====================


			{
				path: "mesin-absensi",
				name: "MesinAbsensi",
				component: () =>
					import(
						/* webpackChunkName: "klastime-mesin-absensi" */
						"../views/Klastime/MesinAbsensi"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "guru-staff-mesin/:id",
				name: "TambahUserMesin",
				component: () =>
					import(
						/* webpackChunkName: "klastime-guru-staff-mesin" */
						"../views/Klastime/MesinAbsensi/Pengaturan/GuruStaff/Tambah"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pelajar-mesin/:id",
				name: "TambahPelajarMesin",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-mesin" */
						"../views/Klastime/MesinAbsensi/Pengaturan/Pelajar/Tambah"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "mesin-absensi/:id",
				name: "PengaturanMesinAbsensi",
				component: () =>
					import(
						/* webpackChunkName: "klastime-mesin-absensi-pengaturan" */
						"../views/Klastime/MesinAbsensi/Pengaturan"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan-sidikjari/:device_id/:id",
				name: "PengaturanSidikJari",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pengaturan-sidikjari" */
						"../views/Klastime/MesinAbsensi/Pengaturan/GuruStaff/Pengaturan"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "presensi-guru-staff",
				name: "PresensiGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-guru-staff" */
						"../views/Klastime/PresensiGuru"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "presensi-guru-staff/rekap-presensi",
				name: "RekapPresensiGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "klastime-rekap-presensi-guru-staff" */
						"../views/Klastime/PresensiGuru/RekapPresensi"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "presensi-pelajar",
				name: "PresensiPelajar",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-pelajar" */
						"../views/Klastime/PresensiPelajar"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "presensi-pelajar/rekap-presensi",
				name: "RekapPresensiPelajar",
				component: () =>
					import(
						/* webpackChunkName: "klastime-rekap-presensi-pelajar" */
						"../views/Klastime/PresensiPelajar/RekapPresensi"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},

			{
				path: "presensi-kelas",
				name: "PresensiKelas",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas"
						),
				meta: {
					title: "Onklas Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pengaturan-jam",
				name: "PengaturanJam",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pengaturan-jam" */
						"../views/Klastime/PengaturanJam"
						),
				meta: {
					title: "Pengaturan Jam | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},
	{
		path: "/kalender-sekolah",
		name: "KalenderSekolah",
		component: () =>
			import(
				/* webpackChunkName: "klastime-kalender-sekolah" */
				"../views/Klastime/KalenderSekolah"
				),
		meta: {
			title: "Kalender Sekolah | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/pengaturan-hak-akses",
		name: "pengaturanHakAkses",
		component: () =>
			import(
				/* webpackChunkName: "pengaturan-hak-akses" */
				"../views/PengaturanHakAkses"
				),
		meta: {
			title: "Pengaturan Hak Akses | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/klasquiz",
		name: "TryOut",
		component: () =>
			import(
				/* webpackChunkName: "tryout" */
				"../views/TryOut"
				),
		redirect: "/klasquiz/mapel",
		meta: {
			title: "Try Out | ",
			allowedRole: ["Head Master", "Vice Principal", "Teacher", "School Admin"]
		},
		children: [
			// {path: "akm", name: "TryOutAkm", component: () => import(/* webpackChunkName: "tryout-akm" */"../views/TryOut/Akm"), meta: {title: "Try Out AKM | ", allowedRole: ["Head Master", "SuperAdmin", "Vice Principal", "School Admin", "Teacher",],}, beforeEnter: requirePermission,},
			{
				path: "peserta-akm/:tryout_schedule_id?/:tryout_request_id?",
				name: "TryOutPesertaAkm",
				component: () =>
					import(
						/* webpackChunkName: "tryout-peserta-akm" */
						"../views/TryOut/Akm/PesertaAkm"
						),
				meta: {
					title: "Try Out AKM | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path:
					"jawaban-peserta-akm/:tryout_schedule_id?/:student_id?/:template_id?/:tryout_request_id?",
				name: "JawabanTryOutPesertaAkm",
				component: () =>
					import(
						/* webpackChunkName: "jawaban-tryout-peserta-akm" */
						"../views/TryOut/Akm/PesertaAkm/JawabanPeserta"
						),
				meta: {
					title: "Try Out AKM | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path:
					"jawaban-peserta-mapel/:tryout_schedule_id?/:student_id?/:template_id?/:tryout_request_id?",
				name: "JawabanTryOutPesertaMapel",
				component: () =>
					import(
						/* webpackChunkName: "jawaban-tryout-peserta-mapel" */
						"../views/TryOut/Mapel/PesertaMapel/JawabanPeserta"
						),
				meta: {
					title: "Try Out AKM | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "mapel",
				name: "TryOutMapel",
				component: () =>
					import(
						/* webpackChunkName: "tryout-mapel" */
						"../views/TryOut/Mapel"
						),
				meta: {
					title: "Try Out Mapel | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "peserta-mapel/:tryout_schedule_id?/:tryout_request_id?",
				name: "PesertaTryOutPesertaAkmMapel",
				component: () =>
					import(
						/* webpackChunkName: "tryout-peserta-mapel" */
						"../views/TryOut/Mapel/PesertaMapel"
						),
				meta: {
					title: "Try Out AKM | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "assesment",
				name: "TryOutAssesment",
				component: () =>
					import(
						/* webpackChunkName: "tryout-assesment" */
						"../views/TryOut/AssesmentSiswa"
						),
				meta: {
					title: "Try Out Assesment | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "preview/:id?",
				name: "TryOutPreview",
				component: () =>
					import(
						/* webpackChunkName: "tryout-preview" */
						"../views/TryOut/Preview"
						),
				meta: {
					title: "Try Out Preview | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			}
		]
	},

	// cabdin

	{
		path: "/integrasi",
		name: "produkIntegrasi",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin",
		name: "produkIntegrasiCabdin",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},

	{
		path: "/integrasi/:uuid/cabdin/galeri",
		name: "produkIntegrasiCabdinGaleri",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Galeri"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/karya-inovasi",
		name: "produkIntegrasiCabdinKaryaInovasi",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Inovasi"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/karya-inovasi/import-excel",
		name: "produkIntegrasiCabdinDataKaryaInovasiImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Inovasi/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/karya-literasi",
		name: "produkIntegrasiCabdinKaryaLiterasi",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Literasi"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/karya-literasi/import-excel",
		name: "produkIntegrasiCabdinDataKaryaLiterasiImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Literasi/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/data-sekolah",
		name: "produkIntegrasiCabdinDataSekolah",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/PenghuniSekolah"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/data-sekolah/guru-import-excel",
		name: "produkIntegrasiCabdinDataSekolahGuruImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/PenghuniSekolah/Guru/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/data-sekolah/siswa-import-excel",
		name: "produkIntegrasiCabdinDataSekolahSiswaImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasiCabdinDataSekolahSiswaImport" */
				"../views/Integrasi/DetailIntegrasi/PenghuniSekolah/Siswa/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/data-sekolah/karyawan-import-excel",
		name: "produkIntegrasiCabdinDataSekolahKaryawanImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasiCabdinDataSekolahKaryawanImport" */
				"../views/Integrasi/DetailIntegrasi/PenghuniSekolah/Karyawan/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/surat-tugas",
		name: "produkIntegrasiCabdinSuratTugas",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/SuratTugas"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/pengembangan-sdm",
		name: "produkIntegrasiCabdinPengembanganSdm",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/PengembanganSDM"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/pengembangan-sdm/import-excel",
		name: "produkIntegrasiCabdinDataPengembanganSdmImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/PengembanganSDM/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/jurnal-kbm",
		name: "produkIntegrasiCabdinJurnalKbm",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/JurnalKBM"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/jurnal-kbm/import-excel",
		name: "produkIntegrasiCabdinDataAlumniBekerjaImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/JurnalKBM/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/alumni-ptn",
		name: "produkIntegrasiCabdinAlumniPtn",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Alumni/PTN"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/alumni-ptn/import-excel",
		name: "produkIntegrasiCabdinDataAlumniPtnImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Alumni/PTN/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/alumni-bekerja",
		name: "produkIntegrasiCabdinAlumniBekerja",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Alumni/Bekerja"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/alumni-bekerja/import-excel",
		name: "produkIntegrasiCabdinDataAlumniBekerjaImportPreview",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Alumni/Bekerja/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/prestasi-siswa",
		name: "produkIntegrasiCabdinPrestasiSiswa",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Prestasi/Siswa"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/prestasi-siswa/import-excel",
		name: "produkIntegrasiCabdinDataPrestasiSiswaImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Prestasi/Siswa/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/prestasi-guru",
		name: "produkIntegrasiCabdinPrestasiGuru",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Prestasi/Guru"
				),
		meta: {
			title: "Integrasi | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/prestasi-guru/import-excel",
		name: "produkIntegrasiCabdinDataPrestasiGuruImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/Prestasi/Guru/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/buku-tamu",
		name: "produkIntegrasiCabdinBukuTamu",
		component: () => import("../views/Integrasi/DetailIntegrasi/BukuTamu"),
		meta: {
			title: "Buku Tamu | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/buku-tamu/import-excel",
		name: "produkIntegrasiCabdinDataBukuTamuImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/BukuTamu/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/bimbingan-konseling",
		name: "produkIntegrasiCabdinBimbinganKonseling",
		component: () =>
			import("../views/Integrasi/DetailIntegrasi/BimbinganKonseling"),
		meta: {
			title: "Bimbingan Konseling | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/integrasi/:uuid/cabdin/bimbingan-konseling/import-excel",
		name: "produkIntegrasiCabdinDataBimbinganKonselingImport",
		component: () =>
			import(
				/* webpackChunkName: "produkIntegrasi" */
				"../views/Integrasi/DetailIntegrasi/BimbinganKonseling/ImportExcel/preview"
				),
		meta: {
			title: "Import | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		}
	},
	{
		path: "/koperasi/kantin/laporan/:year/:month",
		name: "KantinRekapLaporan",
		component: () =>
			import(
				/* webpackChunkName: "recap-tunggakan" */
				"../views/Koperasi/KoperasiKantin/Penjualan/CetakLaporan.vue"
				),
		meta: {
			title: "Rekap Tunggakan | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance",
				"Cooperative"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/survey",
		name: "Survey",
		redirect: "/survey/kuesioner", // default call first page
		// default call first page
		component: () =>
			import(
				/* webpackChunkName: "pembelajaran" */
				"../views/Survey/index.vue"
				),
		meta: {
			title: "Survei | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin"
			]
		},
		children: [
			{
				path: "kuesioner",
				name: "SurveyKuesioner",
				component: () =>
					import(
						"../views/Survey/Kuesioner"
						),
				meta: {
					title: "Kuesioner | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "kuesioner/:id/pertanyaan",
				name: "PertanyaanKuesioner",
				component: () =>
					import(
						"../views/Survey/Kuesioner/FormHalamanCreateKuesioner"
						),
				meta: {
					title: "Soal Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "survey-schedules",
				name: "SurveySchedules",
				component: () =>
					import(
						"../views/Survey/Schedules"
						),
				meta: {
					title: "Jadwal Survei | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "survey-schedules/:id",
				name: "SurveySchedulesDetails",
				component: () =>
					import(
						"../views/Survey/Schedules/Details"
						),
				meta: {
					title: "FormIpCctv Jadwal Survei | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "results",
				name: "SurveySchedulesResults",
				component: () =>
					import(
						"../views/Survey/Schedules/Details"
						),
				meta: {
					title: "Hasil Survei | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "results/:schedule_id",
				name: "SurveySchedulesResultDetail",
				component: () =>
					import(
						"../views/Survey/Schedules/Details"
						),
				meta: {
					title: "FormIpCctv Hasil Survei | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin"
					]
				},
				beforeEnter: requirePermission
			},
		]
	},
];

const router = new VueRouter({
	mode: "history",
	routes
});

router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title + process.env.VUE_APP_TITLE || "";
	let LocalDataVuex = JSON.parse(window.localStorage.getItem("storeonklas"));
	const isAuthenticated = !!(LocalDataVuex && LocalDataVuex.userToken && LocalDataVuex.userToken !== "");
	const publicPath = [
		"Login",
		"ForgotPassword",
		"ResetPassword",
		"KebijakanPrivasi",
		"VerifikasiEmail"
	];
	if (publicPath.includes(to.name)) {
		next();
	} else {

		// ambil token koperasi kalo sudah login
		if (isAuthenticated) {
			let list_menu_addons = store.state.menuAddOns.menu || [];

			// panggil api token koperasi kalau add ons koperasi sudah diaktifkan
			if (
				_.findIndex(list_menu_addons, {
					display_name: "Koperasi",
					status: "Activated"
				}) !== -1
			) {
				await store.dispatch("checkTokenKoperasi");
			}
			await store.dispatch("checkTokenDanaPartisipasi");
			// await store.dispatch("checkIntroState");
			// await store.dispatch("checkBaseProduct");
		}
		if (window.innerWidth <= 768) {
			await store.commit("updateState", {
				showsidebar: false
			});
		} else {
			await store.commit("updateState", {
				showsidebar: true
			});
		}

		if (!publicPath.includes(to.name) && !isAuthenticated) {
			next({name: "Login"});
		} else if (publicPath.includes(to.name) && isAuthenticated) {
			next({name: "Dashboard"});
		} else {
			next();
		}
	}
});

function requirePermission(to, from, next) {
	// let LocalDataVuex = JSON.parse(window.localStorage.getItem('storeonklas'));

	// let checkState2 = _.filter(LocalDataVuex.introState.state2, function(item) {
	//   return item == false;
	// });

	// let masterPath = [
	//   'IpCctv',
	//   'CreateGuruStaff',
	//   'EditGuruStaff',
	//   'DetailGuruStaff',
	//   'ImportExcelGuru',
	//   'Pelajar',
	//   'CreatePelajar',
	//   'EditPelajar',
	//   'DetailPelajar',
	//   'PreviewImportExcelPelajar',
	//   'Jurusan',
	//   'RuangKelas',
	//   'MataPelajaran',
	//   'JadwalPelajaran',
	//   'RuangKelasDataKelas',
	// ];

	// if (LocalDataVuex.introState.state1.length == 0) {
	//   if (!LocalDataVuex.introState.state1Done) {
	//     next({ name: 'IntroHakAkses' });
	//   } else {
	//     if (checkState2.length != 0) {
	//       if (!_.contains(masterPath, to.name)) {
	//         next({ name: 'IntroDataSekolah' });
	//       }
	//     }
	//   }
	// } else if (checkState2.length != 0) {
	//   if (!_.contains(masterPath, to.name)) {
	//     next({ name: 'IntroDataSekolah' });
	//   }
	// }

	let userStore = store.state.user.role;
	let userRole = _.pluck(userStore, "name");

	let routePermission = to.meta.allowedRole ? to.meta.allowedRole : [];
	let allowedAll = _.contains(routePermission, "all");

	if (allowedAll) {
		next();
	} else {
		let checkPermission = userRole.some(r => routePermission.indexOf(r) >= 0);
		if (!checkPermission) {
			if (to.name == "Jurusan") {
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkTeacher) {
					next({name: "Ujian"});
				} else {
					next({name: "Dashboard"});
				}
			} else if (to.name == "MesinAbsensi") {
				/* cek ketika di child klastime ada yang bisa dibuka sama teacher */
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkTeacher) {
					next({name: "PresensiKelas"});
				} else {
					next({name: "Dashboard"});
				}
			} else {
				let checkCooperative = _.contains(userRole, "Cooperative");
				let checkLibrarian = _.contains(userRole, "Librarian");
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkCooperative) {
					next({name: "Koperasi"});
				} else if (checkLibrarian) {
					next({name: "Klaspustaka"});
				} else if (checkTeacher) {
					next({name: "Ujian"});
				} else {
					next({name: "Dashboard"});
				}
			}
		}
		next();
	}
}

export default router;
