import {
  ApiGetRequest,
  // ApiPutRequest,
  ApiDeleteRequest
  // ApiPostRequest,
} from "@/utils/Api";

import { Message } from "element-ui";
import { _ } from "vue-underscore";

const state = {
  data: {
    labelMenu: "Ujian",
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "",
    filter: "",
    filterStatus: "",
    filterSubject: "",
    filterClass: "",
    loading: false,
    errors: [],
    paramsId: "",
    detail_header_penilaian: {
      total_participant: "",
      done_participant: ""
    },
    detail_exam: {
      name: "",
      classrooms: "",
      theory: ""
    },
    columns_nilai_siswa: [
      {
        prop: "exam_score_name",
        label: "Ujian",
        width: "200",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "exam_score_schedule",
        label: "Jadwal Ujian",
        width: "180",
        minWidth: "160",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "exam_score_class",
        label: "Ruang Kelas",
        width: "200",
        minWidth: "150",
        sortable: false,
        "class-name": " text-left body-14 col-black-2"
      },
      {
        prop: "exam_score_subject",
        label: "Mapel",
        minWidth: "180",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "exam_score_teacher",
        label: "Guru",
        minWidth: "180",
        sortable: false,
        "class-name": "text-left body-14 col-black-2"
      },
      // {
      //   prop: "exam_score_done",
      //   label: "Dikerjakan",
      //   minWidth: "110",
      //   sortable: false,
      //   "class-name": "text-center body-14 col-black-2",
      // },
      // {
      //   prop: "exam_score_clear",
      //   label: "Dinilai",
      //   minWidth: "100",
      //   sortable: false,
      //   "class-name": "body-14 col-black-2",
      // },
      {
        fixed: "right",
        label: "Aksi",
        width: "150",
        "class-name": "body-14 col-black-2"
      }
    ],
    example_nilai_siswa: [
      {
        id: "1",
        exam_score_name: "Ujian Tengah Semester",
        exam_score_schedule: "12 Mar 2021, 13:00 - 15:00",
        exam_score_class: `X IPA 2`,
        exam_score_subject: `Biologi`,
        exam_score_teacher: `Tutut Wurijanto S. Kom, M. Kom `,
        exam_score_done: `30/32`,
        exam_score_clear: `20/32`
      }
    ],
    items: [],
    mapel: [],
    kelas: [],
    columns_detail_ujian: [
      {
        prop: "details_exam_nis",
        label: "NIS / NISN",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "detail_name",
        label: "Nama",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "details_exam_status",
        label: "Status",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "details_exam_uploaded",
        label: "Ujian Diupload",
        minWidth: "100",
        sortable: false,
        "class-name": "text-center body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Penilaian",
        width: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    example_detail_ujian: [
      {
        id: "1",
        details_exam_nis: "Ujian Tengah Semester",
        details_exam_name: "12 Mar 2021, 13:00 - 15:00",
        details_exam_status: `X IPA 2`,
        details_exam_uploaded: `Biologi`,
        details_exam_score: 20
      }
    ],
    exam_score: [],
    exam_score_export: [],
    allStudent: []
  }
};

const mutations = {
  changePenilaianSoal(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  }
};

const actions = {
  async getListExamScore({ commit, state }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    if (payload) {
      await commit("changePenilaianSoal", {
        page: payload || data.page
      });
    }

    const { data } = state;

    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      subject: data.filterSubject,
      class: data.filterClass
    };

    const result = await ApiGetRequest(`school/exam/kkm/exam-schedule`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil data list jadwal ujian"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return null;
    } else {
      commit("changePenilaianSoal", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false
      });
    }
  },

  async getSearchMapel({ commit, state }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    if (payload) {
      await commit("changePenilaianSoal", {
        page: payload || data.page
      });
    }

    const { data } = state;
    const result = await ApiGetRequest(`school/subject?wherepage=exam`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return [];
    } else {
      await commit("changePenilaianSoal", {
        mapel: result.data.data,
        loading: false
      });
    }
  },

  async getSearchKelas({ commit, state }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    if (payload) {
      await commit("changePenilaianSoal", {
        page: payload || data.page
      });
    }

    const { data } = state;

    const result = await ApiGetRequest(`school/class?wherepage=exam`);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return [];
    } else {
      await commit("changePenilaianSoal", {
        kelas: result.data.data,
        loading: false
      });
    }
  },

  async getDeleteListExamScore({ commit }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    const result = await ApiDeleteRequest(
      `school/exam/kkm/exam-schedule/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal dihapus"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return true;
    }
  },

  async getDetailListExamScore({ commit }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-schedule/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return false;
    } else {
      commit("changePenilaianSoal", {
        detail_exam: {
          name: result.data.data.exam.name,
          classroom: result.data.data.class_room.name,
          theory: result.data.data.exam.subject.name
        },
        loading: false
      });

      return true;
    }
  },

  async getDataExamStudent({ commit, state }, payload) {
    await commit("changePenilaianSoal", {
      loading: true,
      exam_score: [],
    });

    if (payload) {
      await commit("changePenilaianSoal", {
        page: payload || data.page
      });
    }

    const { data} = state;

    const params = {
      page: state.data.page,
      limit: data.limit,
    };
    if(data.filter) params.q = data.filter
    if(data.filterStatus) params.status = data.filterStatus

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${data.paramsId}/student`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data list ujian"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return null;
    } else {
      commit("changePenilaianSoal", {
        exam_score: result.data.data,
        total: result.data.meta.total,
        loading: false
      });
    }
  },

  async getExportDataExamStudent({ commit, state }) {
    commit("changePenilaianSoal", {
      loading: true
    });

    const { data } = state;

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${data.paramsId}/student`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data list ujian"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return null;
    } else {
      commit("changePenilaianSoal", {
        exam_score_export: result.data.data,
        loading: false
      });
    }
  },

  async getDataHeaderExamStudent({ commit, state }, payload) {
    commit("changePenilaianSoal", {
      loading: true
    });

    if (payload) {
      await commit("changePenilaianSoal", {
        page: payload || data.page
      });
    }

    const { data } = state;

    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter
    };

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${data.paramsId}/header`,
      params
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data list ujian"
      });

      commit("changePenilaianSoal", {
        loading: false
      });

      return null;
    } else {
      commit("changePenilaianSoal", {
        detail_header_penilaian: {
          total_participant: result.data.data.total_participant,
          done_participant: result.data.data.done_participant
        },
        loading: false
      });
    }
  },

  async getDataExamStudentAll({ commit, state }) {
    commit("changePenilaianSoal", {
      allStudent: []
    });

    const { data } = state;

    const params = {};

    const result = await ApiGetRequest(
      `school/exam/kkm/grading/${data.paramsId}/student`,
      params
    );

    if (result.error) {
      commit("changePenilaianSoal", {
        allStudent: []
      });
      return null;
    } else {
      commit("changePenilaianSoal", {
        allStudent: _.where(result.data.data, { status: "Sudah Dikerjakan" })
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
