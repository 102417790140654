import { ApiGetRequest, ApiPostRequest } from "@/utils/Api";

import { Message } from "element-ui";

const state = {
  data: {
    labelMenu: "Sosial Media - Hashtag",
    dataDetailId: "",
    nameHashtag: "",
    sortBy: "",
    sortType: "",
    filter: "",
    periode: "1",
    tglawal: "",
    tglakhir: "",
    page: 1,
    total: 10,
    perPage: 10,
    limit: 10,
    loading: false,
    showModalDetail: false,
    detailModal: {
      thumbnail: "",
      user_name: "",
      role: "",
      class: "",
      caption: "",
    },
    items: [],
    itemsModal: [],
    errors: [],
    columns: [
      {
        prop: "name_hashtag",
        label: "Nama Hashtag ",
        sortable: false,
        minWidth: "80",
      },

      {
        prop: "date_post",
        label: "Tanggal Aktif",
        sortable: false,
        minWidth: "80",
      },
      {
        prop: "count_post",
        label: "Jumlah Postingan",
        sortable: false,
        minWidth: "60",
      },
      {
        prop: "status",
        label: "Status",
        sortable: false,
        minWidth: "50",
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "70",
        "class-name": "body-14 col-black-2",
      },
    ],
    columns_modal: [
      {
        prop: "name_hashtag",
        label: "Nama Hashtag",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },

      {
        prop: "date_post",
        label: "Tanggal Aktif",
        minWidth: "100",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "count_post",
        label: "Jumlah Postingan",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
      {
        prop: "status",
        label: "Status",
        minWidth: "90",
        sortable: false,
        "class-name": "body-14 col-black-2",
      },
    ],
  },
};

const mutations = {
  changeSosmedHashtag(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getListHashtag({ commit, state }, payload) {
    commit("changeSosmedHashtag", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedHashtag", {
        page: payload || data.page,
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      start_date: data.tglawal,
      end_date: data.tglakhir,
    };

    const result = await ApiGetRequest(`school/sosmed/hashtags`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedHashtag", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedHashtag", {
        items: result.data.data,
        total: result.data.meta.total,
        loading: false,
      });
    }
  },

  async getDetailHashtag({ commit, state }, payload) {
    commit("changeSosmedHashtag", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedHashtag", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    // const params = {
    //   name: payload.name,
    // };

    const result = await ApiGetRequest(
      `school/sosmed/hashtags/${payload.name}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedHashtag", {
        loading: false,
      });

      return [];
    } else {
      await commit("changeSosmedHashtag", {
        detailModal: {
          thumbnail: result.data.data.feed.feed_thumbnail_image,
          user_name: result.data.data.feed.user.name,
          role: result.data.data.feed.user.role,
          class: "",
          caption: result.data.data.feed.feed_body,
        },
        itemsModal: [
          {
            name_hashtag: result.data.data.name,
            date_post: result.data.data.feed.created_at_label,
            count_post: result.data.data.amount,
            is_active: result.data.data.is_active,
          },
        ],
        loading: false,
      });
    }
  },
  async inactiveFeed({ commit }, payload) {
    commit("changeSosmedHashtag", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedHashtag", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const formData = {
      hashtag: payload.name,
    };
    const result = await ApiPostRequest(
      `school/sosmed/hashtags/inactive`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedHashtag", {
        loading: false,
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Hashtag telah dinonaktifkan",
      });
      commit("changeSosmedHashtag", {
        loading: false,
      });
      return true;
    }
  },
  async activeFeed({ commit, state }, payload) {
    commit("changeSosmedHashtag", {
      loading: true,
    });

    if (payload) {
      await commit("changeSosmedHashtag", {
        page: payload || data.page,
      });
    }

    const { data } = state;

    const formData = {
      hashtag: payload.name,
    };

    const result = await ApiPostRequest(
      `school/sosmed/hashtags/active`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error,
      });

      commit("changeSosmedHashtag", {
        loading: false,
      });

      return [];
    } else {
      Message({
        type: "success",
        message: "Hashtag telah diaktifkan kembali",
      });
      commit("changeSosmedHashtag", {
        loading: false,
      });
      return true;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
