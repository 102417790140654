import {
  ApiGetRequest,
  ApiPutRequest,
  ApiDeleteRequest,
  ApiPostRequest
} from "@/utils/Api";

import { Message } from "element-ui";
import { _ } from "vue-underscore";
import { HandlerNotification, HandlerPageStartEnd } from "@/utils/Helpers";

const state = {
  data: {
    labelMenu: "Template Kuesioner",
    items: [],
    limit: 10,
    page: 1,
    total: 0,
    loading: false,
    sort_field: "",
    sort_dir: "",
    error: "",
    filter: "",
    currentPublish: "show",
    currentArchive: false,
    prosesCallTemplate: false,
    errors: [],
    exams: [],
    showModalAdd: false,
    formData: {
      id: null,
      name: "",
      is_shared: true
    },
    rules: {
      name: [
        {
          required: true,
          message: "tidak boleh kosong",
          trigger: "blur"
        }
      ]
    },
    columns: [
      {
        prop: "name",
        label: "Nama Kuesioner",
        minWidth: "250",
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "created_at",
        label: "Dibuat",
        width: 150,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "used_count",
        label: "Digunakan",
        width: 120,
        sortable: false,
        "class-name": "text-center body-14 col-black-2"
      },
      {
        prop: "creator_name",
        label: "Dibuat Oleh",
        widtth: 150,
        minWidth: 150,
        sortable: false,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "publish",
        label: "Publikasi ",
        width: 120,
        sortable: false,
        "class-name": "body-14 col-black-2 text-center"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: 130,
        "class-name": "body-14 col-black-2"
      }
    ],
    dataInstruction: {
      instruction: "",
      description: "",
      is_random: ""
    },
    listKuesionerAll: {
      items: [],
      loading: false,
      limit: 10,
      page: 1,
      errors: [],
      total: 0
    },
    detailKuesioners: {
      loading: false,
      prosesHandleFile: "drag",
      modeLayout: "table",
      maxImport: 25,
      limit: 10,
      page: 1,
      total: 0,
      template_name: "",
      template_id: null,
      aspect_id: null,
      errors: [],
      items: [],
      resultSurveyTemplate: {
        id: null,
        aspects: []
      },
      detailTemplateKuesioner: {},
      defaultFormDataArray: [
        {
          id: null,
          survey_template_id: null,
          survey_aspect_id: null,
          question: ""
        }
      ],
      formDataArray: [
        {
          id: null,
          survey_template_id: null,
          survey_aspect_id: null,
          question: ""
        }
      ],
      formData: {
        index: null,
        id: null,
        template_id: null,
        aspect_id: null,
        questions: [],
        questionText: ""
      },
      formEditQuestion: {
        id: null,
        question: "",
        survey_template_id: null,
        survey_aspect_id: null
      },
      columns: [
        {
          prop: "name",
          label: "Pertanyaan",
          minWidth: 400,
          sortable: false,
          "class-name": "body-14 col-black-2"
        },
        {
          fixed: "right",
          label: "Aksi",
          width: 100,
          "class-name": "body-14 col-black-2"
        }
      ],
      listAspects: [],
      defaultListAspects: [
        {
          id: null,
          aspect: "",
          aspect_id: null,
          questions: [],
          is_check_description: false,
          is_hide: true
        }
      ]
    }
  }
};

const mutations = {
  changeSurveyKuesioner(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
  changeSurveyDetailKuesioner(state, payload) {
    state.data.detailKuesioners = Object.assign(
      {},
      state.data.detailKuesioners,
      payload
    );
  },

  /**
   * @payload { indexAspect: required }
   * need: defaultFormDataArray
   * */
  addQuestion(state, payload) {
    state.data.detailKuesioners.listAspects[payload.indexAspect].questions = [
      ...state.data.detailKuesioners.listAspects[payload.indexAspect].questions,
      ...payload.newData
    ];
    state.data.detailKuesioners.formDataArray =
      state.data.detailKuesioners.defaultFormDataArray;
  },

  /**
   * @payload { indexAspect: required, indexQuestion: required, newData: required }
   * */
  updateQuestion(state, payload) {
    state.data.detailKuesioners.listAspects[payload.indexAspect].questions[
      payload.indexQuestion
    ] = payload.newData;
  },

  /**
   * @payload { newData: required|object }
   * */
  pushDataTemplateItems(state, payload) {
    state.data.items.push(payload.newData);
  },

  /**
   * @payload { id: required|string, updatedData: required|object }
   * */
  updateDataTemplateItems(state, payload) {
    const index = state.data.items.findIndex(item => item.id === payload.id);
    if (index !== -1) {
      state.data.items[index] = {
        ...state.data.items[index],
        ...payload.updatedData
      };
    }
  },
  sliceDataTemplateItems(state, payload) {
    state.data.items.splice(payload.index, 1);
  },
  pushDataAspect(state, payload) {
    if (!state.data.detailKuesioners.listAspects[payload.index].id) {
      //  proses dimana index ke 0 di update
      state.data.detailKuesioners.listAspects[payload.index] = payload.newData;
    }
  },
  spliceDataAspects(state, payload) {
    state.data.detailKuesioners.listAspects.splice(payload.index, 1);
  }
};

const actions = {
  async getListTemplate({ state, commit }, payload) {
    const { data } = state;
    commit("changeSurveyKuesioner", {
      loading: true
    });
    const params = {};
    params.limit = payload ? payload.limit || data.limit : data.limit;
    const resPage = HandlerPageStartEnd(
      data.page,
      data.items.length,
      data.limit
    );
    if (data.filter) {
      params.q = data.filter;
    }
    if (data.sort_field) {
      params.sort_field = data.sort_field;
    }
    if (data.sort_dir) {
      params.sort_dir = data.sort_dir;
    }
    if (data.currentPublish) {
      params.publish = data.currentPublish;
    }
    params.archive = "deactivate";
    if (data.currentArchive) {
      params.archive = "active";
    }
    params.page = payload ? payload.page || resPage : resPage;

    let result = await ApiGetRequest(`school/survey/survey-template`, params);

    if (result.error) {
      if (result.code === 403) {
        HandlerNotification("notAkses");
      } else {
        HandlerNotification("error", result.error);
      }
      commit("changeSurveyKuesioner", {
        items: [],
        prosesCallTemplate: false,
        loading: false
      });
      return false;
    }
    commit("changeSurveyKuesioner", {
      prosesCallTemplate: false,
      loading: false,
      items: result.data.data,
      page: (result.data.meta && result.data.meta.current_page) || 1,
      total: (result.data.meta && result.data.meta.total) || 0
    });
    return true;
  },
  async getTemplateById({ commit }, payload) {
    if (payload && !payload.id) {
      return false;
    }
    const params = {};
    let result = await ApiGetRequest(
      `school/survey/survey-template/${payload.id}`,
      params
    );

    if (result.error) {
      if (result.code === 403) {
        HandlerNotification("notAkses");
      } else {
        HandlerNotification("error", result.error);
      }
      commit("changeSurveyKuesioner", {
        prosesCallTemplate: false,
        formData: {
          id: null,
          name: ""
        }
      });
      return false;
    }
    const customAspects = result.data.data.aspects.map(item => {
      return {
        ...item,
        is_check_description: !!item.description,
        is_hide: true
      };
    });
    state.data.detailKuesioners.listAspects = customAspects;
    await commit("changeSurveyDetailKuesioner", {
      resultSurveyTemplate: result.data.data,
      template_id: result.data.data.id
    });
    await commit("changeSurveyKuesioner", {
      prosesCallTemplate: false,
      formData: {
        id: result.data.data.id,
        name: result.data.data.name
      }
    });
    return true;
  },
  async postStatusTemplate({ commit }, payload) {
    if (payload && payload.id === undefined) {
      console.log("payload.id null");
      return false;
    }
    commit("changeSurveyKuesioner", {
      loading: true
    });
    let result;
    if (payload.publish) {
      result = await ApiPutRequest(
        `school/survey/survey-template/publish/${payload.id}`
      );
    } else {
      result = await ApiPutRequest(
        `school/survey/survey-template/unpublish/${payload.id}`
      );
    }
    commit("changeSurveyKuesioner", {
      loading: false
    });
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    await commit("updateDataTemplateItems", {
      id: result.data.data.id,
      updatedData: { ...result.data.data }
    });
    commit("changeSurveyKuesioner", {
      error: []
    });
    return true;
  },
  async postTemplate({ state, commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });
    let result;
    const formData = {};
    formData.name = state.data.formData.name;
    if (payload && payload.id) {
      result = await ApiPutRequest(
        `school/survey/survey-template/${payload.id}`,
        formData
      );
    } else {
      result = await ApiPostRequest(`school/survey/survey-template`, formData);
    }
    commit("changeSurveyKuesioner", {
      loading: false
    });
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    if (payload && payload.id) {
      commit("changeSurveyKuesioner", {
        error: [],
        showModalAdd: false
      });
      return true;
    }
    await commit("pushDataTemplateItems", {
      newData: result.data.data
    });
    const newFormData = {
      id: result.data.data.id,
      name: result.data.data.name,
      ...result.data.data
    };
    commit("changeSurveyDetailKuesioner", {
      detailTemplateKuesioner: newFormData
    });
    commit("changeSurveyKuesioner", {
      formData: newFormData,
      showModalAdd: false,
      currentPublish: "hide",
      error: []
    });
    return true;
  },
  async archiveTemplate({ commit, getters }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });
    let result;
    if (payload && payload.archive) {
      result = await ApiPostRequest(
        `school/survey/survey-template/restore/${payload.id}`
      );
    } else {
      result = await ApiDeleteRequest(
        `school/survey/survey-template/${payload.id}`
      );
    }
    commit("changeSurveyKuesioner", {
      loading: false
    });
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    let indexItems = getters.searchIndexTemplateById(payload.id);
    await commit("sliceDataTemplateItems", {
      index: indexItems
    });
    console.log(state.data.items);
    console.log(indexItems);
    commit("changeSurveyKuesioner", {
      error: []
    });
    return true;
  },
  async postAspect({ state, commit }, payload) {
    console.log(payload);
    if (payload && [undefined, null].includes(payload.index)) {
      console.log("id, index harus diisi");
      return false;
    }
    commit("changeSurveyDetailKuesioner", {
      loading: true
    });
    const formData = {};
    formData.survey_template_id = state.data.detailKuesioners.template_id;
    formData.aspect =
      state.data.detailKuesioners.listAspects[payload.index].aspect;
    formData.description =
      state.data.detailKuesioners.listAspects[payload.index].description;
    let result;
    if (payload && payload.id) {
      result = await ApiPutRequest(
        `school/survey/survey-aspect/${payload.id}`,
        formData
      );
    } else {
      result = await ApiPostRequest(`school/survey/survey-aspect`, formData);
    }
    commit("changeSurveyDetailKuesioner", {
      loading: false
    });
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyDetailKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    if (payload.id) {
      commit("changeSurveyDetailKuesioner", {
        error: []
      });
      return true;
    }
    const res = result.data.data;
    state.data.detailKuesioners.listAspects.splice(payload.index, 1);
    state.data.detailKuesioners.listAspects.push({
      id: res.id,
      aspect: res.aspect,
      description: res.description,
      aspect_id: res.id,
      questions: [],
      is_check_description: !!res.description,
      is_hide: true
    });

    return result.data.data;
  },
  async deleteAspect({ commit }, payload) {
    if (payload && [undefined, null].includes(payload.index) && payload.id) {
      console.log(payload);
      console.log("index,id pastikan ada");
      return false;
    }
    let result = await ApiDeleteRequest(
      `school/survey/survey-aspect/${payload.id}`
    );
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyDetailKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    if (state.data.detailKuesioners.listAspects.length > 1) {
      await commit("spliceDataAspects", {
        index: payload.index
      });
      return true;
    }
    state.data.detailKuesioners.listAspects = state.data.detailKuesioners.defaultListAspects;
    return true;
  },

  /**
   * @payload: { indexAspect: required, id: [digunakan Update] }
   * */
  async postQuestion({ state, commit, getters }, payload) {
    commit("changeSurveyDetailKuesioner", {
      loading: true
    });
    const formData = {};
    let result;
    if (payload && payload.id) {
      if (!state.data.detailKuesioners.formEditQuestion.id) {
        console.log("formEdit harus ada ID");
        return false;
      }
      formData.question = state.data.detailKuesioners.formEditQuestion.question;
      result = await ApiPutRequest(
        `school/survey/survey-question/${state.data.detailKuesioners.formEditQuestion.id}`,
        formData
      );
    } else {
      formData.questions = state.data.detailKuesioners.formDataArray
        .map(item => {
          // Menghapus kunci 'id' dari setiap objek
          return {
            question: item.question,
            survey_template_id: item.survey_template_id,
            survey_aspect_id: item.survey_aspect_id
          };
        })
        .filter(item => item.question !== "");
      result = await ApiPostRequest(`school/survey/survey-question`, formData);
    }
    if (result.error) {
      commit("changeSurveyDetailKuesioner", {
        loading: false,
        modeLayout: "table"
      });
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyDetailKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    if (payload && payload.id) {
      const formEdit = state.data.detailKuesioners.formEditQuestion;
      const ia = getters.searchIndexAspctById(formEdit.survey_aspect_id);
      const iq = getters.searchIndexQuestionById(formEdit.id, ia);
      await commit("updateQuestion", {
        indexAspect: ia,
        indexQuestion: iq,
        newData: result.data.data
      });
      //state.data.detailKuesioners.listAspects[ia].questions[iq] = result.data.data;
      // lakukan push data
      commit("changeSurveyDetailKuesioner", {
        formEditQuestion: {
          id: null,
          question: "",
          survey_template_id: null,
          survey_aspect_id: null
        },
        error: [],
        loading: false,
        showModalAdd: false
      });
      return true;
    }
    await commit("addQuestion", {
      newData: result.data.data,
      indexAspect: payload.indexAspect
    });
    commit("changeSurveyDetailKuesioner", {
      loading: false
    });
    return true;
  },

  async deleteQuestion({ state, commit, getters }, payload) {
    commit("changeSurveyDetailKuesioner", {
      loading: true
    });
    let result = await ApiDeleteRequest(
      `school/survey/survey-question/${payload.id}`
    );
    commit("changeSurveyDetailKuesioner", {
      loading: false
    });
    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }
      HandlerNotification("error", message);
      commit("changeSurveyDetailKuesioner", {
        error: result.errorList ? result.errorList : false
      });
      return false;
    }
    HandlerNotification();
    const ia = getters.searchIndexAspctById(payload.survey_aspect_id);
    const iq = getters.searchIndexQuestionById(payload.id, ia);
    state.data.detailKuesioners.listAspects[ia].questions.splice(iq, 1);
    return true;
  },

  removePertanyaanInput({ state, commit }, payload) {
    if (
      state.data.detailKuesioners.formDataArray.length > 1 &&
      payload &&
      payload.index >= 0
    ) {
      if (!state.data.detailKuesioners.formDataArray[payload.index]) {
        return false;
      }
      state.data.detailKuesioners.formDataArray.splice(payload.index, 1);
      commit("changeSurveyDetailKuesioner", {
        formDataArray: state.data.detailKuesioners.formDataArray
      });
      return true;
    }
    return false;
  },
  async editLabelMenu({ commit, state }) {
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },
  changePage({ commit, state }) {
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },
  prosesDuplicate({ commit, state }, payload) {
    console.log(payload);
    let a = ``;
    if (state.data.filter) {
      a = `[ status: publish ]`;
    }
    commit("changeSurveyKuesioner", {
      labelMenu: `Kuesioner ${a}`
    });
  },

  async createUjian({ commit }, payload = null) {
    // const { data } = state;
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template`,
      payload.formData
    );

    if (result.error) {
      let message = result.error;
      if (result.errorList) {
        message = _.first(_.values(result.errorList));
      }

      Message({
        type: "error",
        message: message ? message : "Gagal menambahkan data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian baru berhasil ditambahkan"
      });

      window.location.href = `ujian/${result.data.data.id}/soal`;

      commit("changeSurveyKuesioner", {
        error: false
      });

      return true;
    }
  },

  async updateUjian({ commit }, payload = null) {
    const result = await ApiPutRequest(
      `school/exam/kkm/exam-template/${payload.id}`,
      payload.formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengubah data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diubah"
      });

      commit("changeSurveyKuesioner", {
        error: false
      });

      return true;
    }
  },

  async deleteUjian({ commit }, payload = null) {
    // const { data } = state;
    commit("changeSurveyKuesioner", {
      loading: true
    });
    const result = await ApiDeleteRequest(
      `school/exam/kkm/exam-template/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal dihapus"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return true;
    }
  },

  async duplicateUjian({ commit }, payload = null) {
    // const { data } = state;
    commit("changeSurveyKuesioner", {
      loading: true
    });

    let formData = {
      exam_template_id: payload.exam_template_id
    };
    const result = await ApiPostRequest(
      `school/exam/kkm/exam-template/duplicate`,
      formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Data ujian gagal diduplikat"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil diduplikat"
      });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return true;
    }
  },

  async listDataInstruction({ commit, state }, payload) {
    const { data } = state;

    commit("changeSurveyKuesioner", {
      loading: true
    });

    const result = await ApiGetRequest(
      `school/exam/kkm/exam-template/${payload}`
    );

    if (result.error) {
      Message({ type: "error", message: result.error });

      commit("changeSurveyKuesioner", {
        loading: false
      });

      return [];
    } else {
      data.dataFormListInstruction.modalDataName = result.data.data.name;
      data.dataFormListInstruction.templateId = result.data.data.id;
      data.dataFormListInstruction.templateScore = result.data.data.score_total;
      data.dataFormListInstruction.subject = result.data.data.subject;
      data.dataFormListInstruction.name = result.data.data.name;
      data.dataFormListInstruction.id = result.data.data.id;

      // if (
      //     result.data.data.instruction &&
      //     result.data.data.instruction.length > 0
      // ) {
      //     // result.data.data.instruction.map((instruction) => {
      //     //   data.dataFormListInstruction.listAspects = instruction;
      //     //   this.validationScore(instruction.score_total);
      //     // });

      //     data.dataFormListInstruction.listAspects =
      //         result.data.data.instruction;
      //     // result.data.data.instruction.map((instruction) => {
      //     //   this.validationScore(instruction.score_total);
      //     // });
      // }

      let resultInstruction = result.data.data.instruction;
      let instruction = [];
      for (let i = 0; i < resultInstruction.length; i++) {
        let tempList = {
          id: resultInstruction[i].id,
          exam_template_id: resultInstruction[i].exam_template_id,
          is_check_description: !!resultInstruction[i].description,
          description: resultInstruction[i].description,
          instruction: resultInstruction[i].instruction,
          is_random: resultInstruction[i].is_random,
          question: resultInstruction[i].question,
          score_total: resultInstruction[i].score_total,
          sequence: resultInstruction[i].sequence,
          is_hide: true
        };
        instruction.push(tempList);
      }
      data.dataFormListInstruction.listAspects = instruction;
    }
  },

  validationScore(score) {
    let self = this;
    if (score > 100) {
      self.$confirm(
        `Mohon maaf terdapat perintah yang memiliki skor lebih dari 100.`,
        {
          cancelButtonText: "Tutup",
          confirmButtonClass: "bg-red-1 col-black-9 border-0",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      );
    }
  },

  async resetSoal({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(
        `school/exam/kkm/exam-template/${payload.id}/reset`
      );

      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeSurveyKuesioner", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah berhasil direset"
        });

        commit("changeSurveyKuesioner", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async dataRemoveSoal({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiDeleteRequest(`${payload.urldelete}`);
      if (result.error) {
        Message({
          type: "error",
          message: result.error
        });

        commit("changeSurveyKuesioner", {
          errors: result.errorList,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data telah dihapus"
        });

        commit("changeSurveyKuesioner", {
          errors: [],
          loading: false
        });

        return true;
      }
    }
  },

  async createInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload) {
      const result = await ApiPostRequest(
        `school/exam/akm/exam-instruction`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data perintah berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },

  async editInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/akm/exam-instruction/${payload.id}`,
        payload.formData
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan perintah"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data perintah berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  },

  async deleteInstruction({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    const result = await ApiDeleteRequest(
      `school/exam/akm/exam-instruction/${payload.id}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal menghapus data ujian"
      });

      commit("changeSurveyKuesioner", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data ujian berhasil dihapus"
      });

      commit("changeSurveyKuesioner", {
        error: []
      });

      return true;
    }
  },
  async activationTemplateExam({ commit }, payload) {
    commit("changeSurveyKuesioner", {
      loading: true
    });

    if (payload && payload.id) {
      const result = await ApiPutRequest(
        `school/exam/kkm/exam-template/${payload.id}/activate`
      );

      if (result.error) {
        let message = result.error;
        if (result.errorList) {
          message = _.first(_.values(result.errorList));
        }

        Message({
          type: "error",
          message: message ? message : "Gagal menyimpan data"
        });

        commit("changeSurveyKuesioner", {
          error: result.errorList ? result.errorList : false,
          loading: false
        });

        return false;
      } else {
        Message({
          type: "success",
          message: "Data template berhasil tersimpan"
        });

        commit("changeSurveyKuesioner", {
          error: [],
          loading: false
        });

        return result.data.data;
      }
    }
  }
};

const getters = {
  listTemplateWithGetters: state => {
    const { data } = state;
    if (data.currentArchive) {
      return data.items
        .filter(
          item =>
            item.publish === (data.currentPublish === "show") &&
            item.archive === true
        )
        .sort((a, b) => b.id - a.id); // Pengurutan berdasarkan id secara menurun (DESC)
    }
    return data.items
      .filter(
        item =>
          item.publish === (data.currentPublish === "show") &&
          item.archive === false
      )
      .sort((a, b) => b.id - a.id); // Pengurutan berdasarkan id secara menurun (DESC)
  },
  searchKuesionerById: state => id => {
    const dataKuesioner = state.data.items;
    return dataKuesioner.find(item => item.id === parseInt(id));
  },
  searchIndexAspctById: state => id => {
    const data = state.data.detailKuesioners.listAspects;
    return data.findIndex(item => item.id === parseInt(id));
  },
  searchIndexQuestionById: state => (id, aspectId) => {
    const data = state.data.detailKuesioners.listAspects[aspectId].questions;
    return data.findIndex(item => item.id === parseInt(id));
  },
  searchIndexTemplateById: state => id => {
    return state.data.items.findIndex(item => item.id === parseInt(id));
  },
  searchTemplateById: state => id => {
    return state.data.items.find(item => item.id === parseInt(id));
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
